import axios from "axios";
import selectedBrand from "constants/brand";

import {
  BASE_URL,
  CHANGE_PASSWORD_ENDPOINT,
  GET_ADMINS_ENDPOINT,
  GET_BASIC_PROFILE_INFO_ENDPOINT,
  UPDATE_ADMIN_BY_ID_ENDPOINT,
  ADD_ADMIN_ENDPOINT,
  DELETE_ADMIN_ENDPOINT,
  UPDATE_PROFILE_END_POINT,
  GET_USER_PROFILE,
  DELETE_PROFILE_END_POINT,
} from "constants/api-config-constants";
import {
  ChangePasswordRequest,
  SuccessResponse,
  BasicProfileResponse,
  GetAdminsResponse,
  CrudResponse,
  GetDataRequest,
  UserProfileInfo,
} from "interfaces/user-profile.interface";

export const UserProfileChangePassword = async (
  request: ChangePasswordRequest
): Promise<SuccessResponse> => {
  // const { id, oldPassword, newPassword, confirmPassword } = request;
  const url =
    BASE_URL +
    CHANGE_PASSWORD_ENDPOINT 
    // "?userId=" +
    // id +
    // "&oldpassword=" +
    // oldPassword +
    // "&newPassword=" +
    // newPassword +
    // "&confirmPassword=" +
    // confirmPassword +
    // "&brand=" +
    // selectedBrand();
  return axios.put(url,request);
};

export const GetAdminUsers = async (
  request: GetDataRequest
): Promise<GetAdminsResponse> => {
  const type = "admin";
  const { pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_ADMINS_ENDPOINT +
    "?role=" +
    type +
    "&pageno=" +
    pageNumber +
    "&limit=" +
    limit;
  return axios.get(url);
};

export const GetUserBasicInfo = async (data: {
  id: number;
  brand: string;
}): Promise<BasicProfileResponse> => {
  const { brand, id } = data;
  const url =
    BASE_URL +
    GET_BASIC_PROFILE_INFO_ENDPOINT +
    "?id=" +
    id +
    "&brand=" +
    brand;
  return axios.get(url);
};

export const UpdateUserInfo = async (
  request: UserProfileInfo
): Promise<CrudResponse> => {
  const url = BASE_URL + UPDATE_ADMIN_BY_ID_ENDPOINT;
  return axios.put(url, request);
};

export const UpdateProfilePicture = async (request: any): Promise<any> => {
  const { id, profileImage } = request;
  // const formData = new FormData();
  // console.log(id, "idtag");

  // formData.append("file", profileImage);
  // const options = {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //   },
  // };
  const url = BASE_URL + UPDATE_PROFILE_END_POINT + "?id=" + id;
  return axios.put(url, { profilePicture: profileImage });
};
export const DeleteProfilePicture = async (id: number): Promise<any> => {
  const url = BASE_URL + DELETE_PROFILE_END_POINT + "?id=" + id;
  return axios.delete(url);
};

export const GetProfilePicture = async (id: number): Promise<any> => {
  const url = BASE_URL + GET_USER_PROFILE + "?id=" + id;
  return axios.get(url);
};

export const DeleteAdminInfo = async (id: number): Promise<SuccessResponse> => {
  const url =
    BASE_URL +
    DELETE_ADMIN_ENDPOINT +
    "?id=" +
    id +
    "&brand=" +
    selectedBrand();
  return axios.delete(url);
};

export const AddAdminInfo = async (
  request: UserProfileInfo
): Promise<CrudResponse> => {
  const url = BASE_URL + ADD_ADMIN_ENDPOINT;
  return axios.post(url, request);
};
