import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import {
  GridColDef,
  GridCellParams,
  GridRowModel,
} from "@material-ui/data-grid";

import { loadingHelper } from "constants/loadingHelper";
import { limit } from "constants/pagination-constants";

import { getLatestCarsThunk, setCurrentPageReducer } from "slices/latest-cars";
import { useSnackbar } from "notistack";

import PaginationFooter from "shared-components/pagination";
import TableData from "shared-components/table-data";
import { RenderCellExpand } from "shared-components/expanded-cel";

import {
  CustomTagGreen,
  StatusText,
} from "pages/services/service-bookings/components/service-bookings-table/styles";
import { CompletedCircle, CustomTagRed, InprogressCircleRed } from "./styles";

const LatestCarsTable = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<GridRowModel>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  const latestCars = useAppSelector((state) => state.latestCars.latestCarsInfo);

  useEffect(() => {
    if (brandName === "motorcity") {
      loadingHelper(
        dispatch(
          getLatestCarsThunk({
            pageNumber: 1,
            limit: 5,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
    } else {
      loadingHelper(
        dispatch(
          getLatestCarsThunk({
            pageNumber: 1,
            limit: limit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
    }
    dispatch(setCurrentPageReducer(1));
    // eslint-disable-next-line
  }, [brandName, dispatch]);

  useEffect(() => {
    if (brandName === "motorcity") {
      let newRow = latestCars?.allCars
        ?.map(({ models }) => models && Object?.values(models))
        .flat();
      newRow && setRows(newRow);
      setPaginationSize(Math.ceil(latestCars.recordsLength / 5));
    } else {
      setRows(latestCars.allCars);
      setPaginationSize(Math.ceil(latestCars.recordsLength / limit));
    }
  }, [latestCars, brandName]);

  const columns: GridColDef[] = [
    {
      field: "title",
      sortable: false,
      headerName: "Car Title",
      resizable: false,
      flex: 0.8,
    },
    {
      field: "category",
      sortable: false,
      headerName: "Category",
      resizable: false,
      flex: 0.8,
    },
    {
      field: "test_drive_availability",
      sortable: false,
      headerName: "Test Drive Availability",
      resizable: false,
      flex: 0.8,
      renderCell: (params: GridCellParams) => {
        const { test_drive_availability } = params.row;
        if (test_drive_availability === "show") {
          return (
            <CustomTagGreen>
              <CompletedCircle />
              <StatusText>{test_drive_availability}</StatusText>
            </CustomTagGreen>
          );
        } else {
          return (
            <CustomTagRed>
              <InprogressCircleRed />
              <StatusText>{test_drive_availability}</StatusText>
            </CustomTagRed>
          );
        }
      },
    },
    {
      field: "external_link",
      sortable: false,
      headerName: "External Link",
      resizable: false,
      flex: 1.5,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "latestCarLink", 400);
      },
    },
  ];

  const handlePagination = (event, number) => {
    loadingHelper(
      dispatch(
        getLatestCarsThunk({
          pageNumber: number,
          limit: limit,
        })
      ),
      setLoading,
      enqueueSnackbar
    );
    dispatch(setCurrentPageReducer(number));
  };

  return (
    <>
      <TableData
        rows={loading ? [] : (rows as GridRowModel[])}
        isLoading={loading}
        getRowId={(r) => Number(r.nid)}
        columns={columns}
        isCheckBox={false}
        pageSize={limit}
        isPagination={true}
      />
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
        />
      ) : null}
    </>
  );
};

export default LatestCarsTable;
