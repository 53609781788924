import React, { ReactElement } from "react";

import { useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import {
  CustomerContainer,
  WidgetsDiv,
  WidgetName,
  WidgetValue,
} from "pages/dashboard/components/customers-widgets/styles";

function CustomersList(): ReactElement {
  const customerDetails = useAppSelector((state: RootState) => {
    return state.dashboardDetails.dashboardInfo;
  });
  const customersAnalysis = [
    {
      name: "Total Customers",
      value: customerDetails.dashboardDetails.totalCustomers,
    },
    {
      name: "New Customers",
      value: customerDetails.dashboardDetails.newCustomers,
    },
    { name: "Owners", value: customerDetails.dashboardDetails.owners },
    { name: "Guest Users", value: customerDetails.dashboardDetails.guestUsers },
  ];
  return (
    <CustomerContainer>
      {customersAnalysis.map(({ name, value }, index) => {
        return (
          <WidgetsDiv key={`${name}_${index}`}>
            <WidgetName>{name}</WidgetName>
            <WidgetValue>{value}</WidgetValue>
          </WidgetsDiv>
        );
      })}
    </CustomerContainer>
  );
}

export default CustomersList;
