import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import newsInitialState from "slices/news/news-initial-state";

import {
  getAllNewsThunk, getNewsByIdThunk,
} from "slices/news/news-thunks";
import NewsInfoState from "./news-interface";

export const NewsSlice = createSlice({
  name: "NewsSlice",
  initialState: newsInitialState,
  reducers: {
    setCurrentPageReducer:setCurrentPageReducer
  },
  extraReducers: {
    //get all news
    [getAllNewsThunk.fulfilled.type]: (state, action) => {
      let { data:{totalResults, results} } = action.payload.data;
      state.newsInfo.allNews =
      results === undefined ? [] : results;
      state.newsInfo.recordsLength = totalResults;
      state.newsInfo.currentNews = {};
    },
    [getAllNewsThunk.rejected.type]: (state) => {
      state.newsInfo.allNews = [];
    },

    [getNewsByIdThunk.fulfilled.type]: (state, action) => {
      const { data} = action.payload.data;
      state.newsInfo.currentNews = data
    },

    [getNewsByIdThunk.rejected.type]: (state) => {
      state.newsInfo.currentNews = {};
    },

    //search
    // [newsSearchThunk.fulfilled.type]: (state, action) => {
    //   let { listentityClass, records } = action.payload.data;
    //   state.newsInfo.allNews =
    //     listentityClass === undefined ? [] : listentityClass;
    //   state.newsInfo.recordsLength = records;
    // },

  },
});


function setCurrentPageReducer(
  state: NewsInfoState,
  action: PayloadAction<number>
) {
  state.newsInfo.currentPage = action.payload;
}
