export const headerLinks = {
  services: [
    {
      link: "test-drive-bookings",
      text: "Test Drive Bookings",
    },
    {
      link: "service-bookings",
      text: "Service Bookings",
    },
    {
      link: "roadside-assistance",
      text: "Roadside Assistance",
    },
  ],
  management: [
    {
      link: "faq",
      text: "FAQ",
    },
    {
      link: "terms-and-conditions",
      text: "PP and T&C",
    },
    {
      link: "sales-force",
      text: "Sales Force",
    },
    {
      link: "link-management",
      text: "Links",
    },
    {
      link: "notifications",
      text: "Notifications",
    },
  ],
};
