import styled from "styled-components";

import { TextField, InputLabel, Button, Select } from "@material-ui/core";

import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { TEXTFIELD_HEADING } from "constants/style/color";
import CancelIcon from "@material-ui/icons/Cancel";

export const BasicInfoContainer = styled.div`
  background-color: #ffffff;
  padding: 2rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  border-radius: 0px 10px 10px 10px;
`;

export const FieldContainer = styled.div`
  margin-top: 1%;
  margin-bottom: 1%;
`;

export const InputTextField = styled(TextField)`
  width: 20rem;
`;

export const InputLabelText = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: ${SUBTEXT_FONT_SIZE} !important;
  color: ${TEXTFIELD_HEADING} !important;
`;

export const ButtonContainer = styled.div`
  margin: 3rem 0 !important;
`;

export const SubmitButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
`;
export const CancelButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 1rem !important;
`;

export const TimeZoneDropdownSelector = styled(Select)`
  height: 2.5rem;
  width: 23rem;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:50% 50%;
`;

export const LabelDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;
export const LabelText = styled.label`
  /* margin-left: 1rem; */
  /* font-weight: bold; */
  font-size: 16px;
  color: black;
  cursor: pointer;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.color} !important; ;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width:33%;
`;
export const RemoveImage = styled(CancelIcon)`
  position: absolute;
  cursor: pointer;
  top: 0%;
  right: 0%;
  color: ${({ theme }) => theme.color};
`;
export const ImageDiv = styled.div`
  position: relative;
  width: 8rem;
`;

export const LoaderDiv = styled.div`
  width: 8rem;
`;
