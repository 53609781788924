import React, { ReactElement, useState, useEffect } from "react";
import LoaderButton from "shared-components/loader-button";
import { loaderBtnStyleFunc } from "constants/style/props";

import { ObjProps } from "interfaces/privacy-policy";

import {
  DetailsDiv,
  DetailsArrayContainer,
  CancelButton,
  FlexBoxArabicEditorPP,
  RichTextEditorDiv,
} from "./styles";
import { FieldContainer } from "pages/management/faqs/components/edit-faq/styles";
import { InputLabelText } from "pages/auth/components/login/styles";
import { InnerFlexEditorCancelBtn } from "pages/latest-blogs/components/new-blog/styles";
import CustomRichTextEditor from "shared-components/rich-text-editor";
import {
  ALL_PLUGINS,
  QUICK_DESC_BLOCK,
  QUICK_INSERT_TOOLBOX_DECS,
  TOOLBAR_FOR_DESCRIPTION,
} from "constants/text-editor-tools";
import { CancelInputDescription } from "pages/best-deals/components/new-deal/styles";
import { TextWrapper } from "pages/location/components/add-location/styles";
import { Small } from "shared-components/arabic-input/enter-in-arabic-text/styles";

interface DataProps {
  field: string;
  handelSave: (newObj, field) => void;
  obj: ObjProps;
  loading: boolean;
  getPPandTc: () => void;
}

export default function DetailsComponent({
  handelSave,
  obj,
  field,
  loading,
  getPPandTc,
}: DataProps): ReactElement {
  const [inputVal, setInputValue] = useState<ObjProps>(obj);

  useEffect(() => {
    setInputValue(obj);
  }, [obj]);

  const handleEditorChange = (content, e, key) => {
    inputVal[key] = content;
    setInputValue({ ...inputVal });
  };
  const handelCancelSave = () => {
    getPPandTc();
  };
  const handelArabicInputShow = (prop) => {
    inputVal[prop] = "";
    setInputValue({ ...inputVal });
  };
  const handelArabicInputHide = (prop) => {
    inputVal[prop] = null;
    setInputValue({ ...inputVal });
  };
  const {
    privacyPolicy,
    privacyPolicyArabic,
    termsAndConditions,
    termsAndConditionsArabic,
  } = inputVal;
  const key = `${
    field === "privacyPolicy" ? "privacyPolicy" : "termsAndConditions"
  }`;
  const keyArabic = `${
    field === "privacyPolicy"
      ? "privacyPolicyArabic"
      : "termsAndConditionsArabic"
  }`;
  const editorValueArabic = field === "privacyPolicy" ? privacyPolicyArabic : termsAndConditionsArabic
  
  return (
    <DetailsDiv>
      <DetailsArrayContainer>
        <FieldContainer>
          <InputLabelText>
            {`${
              field === "privacyPolicy"
                ? "Privacy Policy"
                : "Terms And Conditions"
            }`}
          </InputLabelText>
          <RichTextEditorDiv>
            <CustomRichTextEditor
              pluginArray={ALL_PLUGINS}
              customToolBar={TOOLBAR_FOR_DESCRIPTION}
              quickAccessToolbar={QUICK_DESC_BLOCK}
              editorState={`${
                field === "privacyPolicy" ? privacyPolicy : termsAndConditions
              }`}
              handelChangeEditorState={(content, e) =>
                handleEditorChange(content, e, key)
              }
              quickInsertToolbar={QUICK_INSERT_TOOLBOX_DECS}
              editorHeight={180}
              toolbarMode="wrap"
              directionality="ltr"
            />
          </RichTextEditorDiv>
          {editorValueArabic === null && (
            <TextWrapper>
              <Small onClick={() => handelArabicInputShow(keyArabic)}>
                Enter in arabic
              </Small>
            </TextWrapper>
          )}
        </FieldContainer>
        <FieldContainer>
          {typeof editorValueArabic === "string" && (
            <>
              <InputLabelText>
                {`${
                  field === "privacyPolicy"
                    ? "Privacy Policy (Arabic)"
                    : "Terms And Conditions (Arabic)"
                }`}
              </InputLabelText>
              <FlexBoxArabicEditorPP>
                <InnerFlexEditorCancelBtn>
                  <CustomRichTextEditor
                    pluginArray={ALL_PLUGINS}
                    customToolBar={TOOLBAR_FOR_DESCRIPTION}
                    quickAccessToolbar={QUICK_DESC_BLOCK}
                    editorState={`${
                      field === "privacyPolicy"
                        ? privacyPolicyArabic
                        : termsAndConditionsArabic
                    }`}
                    handelChangeEditorState={(content, e) =>
                      handleEditorChange(content, e, keyArabic)
                    }
                    quickInsertToolbar={QUICK_INSERT_TOOLBOX_DECS}
                    editorHeight={180}
                    toolbarMode="wrap"
                    directionality="rtl"
                  />
                </InnerFlexEditorCancelBtn>
                <CancelInputDescription
                  onClick={() => {
                    handelArabicInputHide(keyArabic);
                  }}
                />
              </FlexBoxArabicEditorPP>
            </>
          )}
        </FieldContainer>
        <FieldContainer>
          <LoaderButton
            isLoading={loading}
            buttonText="Save"
            styles={loaderBtnStyleFunc("6rem", "2.4rem")}
            submitHandler={() => handelSave(inputVal, field)}
          />
          <CancelButton
            variant="outlined"
            color="primary"
            onClick={handelCancelSave}
          >
            Cancel
          </CancelButton>
        </FieldContainer>
      </DetailsArrayContainer>
    </DetailsDiv>
  );
}
