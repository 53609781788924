import axios from "axios";
import selectedBrand from "constants/brand";

import {
  GET_DASHBOARD_DETAILS,
  BASE_URL,
} from "constants/api-config-constants";

import { DashboardResponse } from "interfaces/dashboard-interface";

export const GetDashboardDetails = async (
  month: number
): Promise<DashboardResponse> => {
  const url =
    BASE_URL +
    GET_DASHBOARD_DETAILS +
    "?brand=" +
    selectedBrand() +
    "&month=" +
    month;
  return axios.get(url);
};
