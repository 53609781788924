import React, { ReactElement } from "react";

interface Props {
  color: string;
}

function BookingLogo({ color }: Props): ReactElement {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={.8} clipPath="url(#prefix__clip0)" fill={color}>
        <path d="M9.35 15.15a3.412 3.412 0 00-1.553-1.228V6.895c1.008-.582 1.933-1.764 1.92-3.262 0-1.787-1.022-3.11-2.726-3.537a.463.463 0 00-.573.481l.151 1.975c-.39.206-.86.206-1.25 0L5.484.568a.457.457 0 00-.582-.476 3.503 3.503 0 00-1.897 1.333c-.435.618-.669 1.379-.678 2.199 0 .82.225 1.585.664 2.203.385.55.926.976 1.553 1.223v7.055c-1.145.674-1.928 1.957-1.928 3.276 0 .843.238 1.608.687 2.213.481.65 1.2 1.1 2.075 1.3a.458.458 0 00.56-.48l-.152-1.975c.39-.206.861-.206 1.25 0l-.164 1.984a.457.457 0 00.582.476 3.47 3.47 0 001.896-1.338c.435-.622.664-1.383.664-2.208 0-.82-.224-1.585-.664-2.203zm-1.503 4.586l.124-1.498a.46.46 0 00-.178-.404l-.019-.013a2.262 2.262 0 00-2.753.018.47.47 0 00-.174.394l.119 1.553c-.92-.426-1.434-1.278-1.434-2.414 0-1.054.683-2.117 1.668-2.589a.451.451 0 00.252-.412v-7.65a.452.452 0 00-.321-.44C3.958 5.928 3.23 4.9 3.23 3.619c.009-1.04.48-1.901 1.269-2.373l-.124 1.498a.46.46 0 00.179.403l.018.014c.815.619 1.942.61 2.749-.014.004-.004.009-.004.009-.009a.453.453 0 00.165-.39l-.12-1.538c.903.444 1.407 1.3 1.407 2.428.014 1.223-.847 2.19-1.654 2.565a.45.45 0 00-.256.417v7.627a.452.452 0 00.32.44c1.173.353 1.902 1.38 1.902 2.666.009 1.045-.463 1.906-1.247 2.383zM18.215 11.989l-2.401-.01V3.85h.948a.47.47 0 00.458-.477c0-.019 0-.037-.004-.055L16.794.385A.45.45 0 0016.341 0h-2.057a.45.45 0 00-.454.385L13.41 3.32a.465.465 0 00.454.532h1.035v8.127l-2.62-.023a.464.464 0 00-.459.467.465.465 0 00.458.458l.88.005v5.955c0 1.187.957 2.149 2.144 2.158h.018a2.15 2.15 0 002.149-2.153V12.9l.747.018a.467.467 0 00.458-.477.471.471 0 00-.458-.453zm-3.821-9.057l.288-2.016h1.26l.289 2.016h-1.837zm2.153 15.915a1.234 1.234 0 01-1.228 1.241h-.014a1.24 1.24 0 01-1.232-1.241V12.89l2.474.01v5.946z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h21v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BookingLogo;
