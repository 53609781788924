import { BestDealsSlice } from "./best-deals-slice";

export {
    getAllDealsThunk,
    getDealByIdThunk,
    updateDealInfoThunk,
    deleteDealThunk,
    addNewDealThunk
  } from "slices/best-deals/best-deals-thunks";

  export default BestDealsSlice.reducer;

  export const { setCurrentDealReducer,setCurrentPageReducer,setTabIndicatorReducer,setFromEditPageReducer } = BestDealsSlice.actions;