import { MenuProps } from "@material-ui/core/Menu/index";
import { CreateCSSProperties } from "@material-ui/styles";

import BahrinFlag from "assets/bahrin-flag.png";
import UkFlag from "assets/uk-flag.png";


export const LOGIN_RESET_PASS_BTN_STYLES = {
    margin: "2rem 0 3rem !important",
    backgroundColor: "#2d609f !important",
    width: "100%",
    height: "2.8rem",
};

export const FORGOT_PASS_BTN_STYLES = {
    ...LOGIN_RESET_PASS_BTN_STYLES,
    margin: "2rem 0 0 !important",
};

export const DROPDOWN_MENU_PROPS:Partial<MenuProps>={
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    getContentAnchorEl: null,
}

export const BTN_DISABLED:CreateCSSProperties<{}> = {
  opacity: 0.5,
  pointerEvents: "none",
}

export const TEXTFIELD_FONTSIZE_INPUT_PROPS = { style: { fontSize: 14 } } 


export const LANGUAGE_FLAG_ARRAY = [
  {
    label: "English",
    flag: UkFlag,
  },
  {
    label: "Arabic",
    flag: BahrinFlag,
  },
]

export const limitArr = [10, 25, 50, 75, 100];

export const loaderBtnStyleFunc=(width,height)=>{
  return {
    width: width,
    height: height,
  }
}

export const sidebarStyles=(...args)=>{
  var slicedArg = args.slice(2)

  if(slicedArg.length===2){
    return args[0] === slicedArg[0] ||
    args[0] === slicedArg[1]
      ? args[1]
      : `black`
  }else if(slicedArg.length===3){
    return args[0] === slicedArg[0] ||
    args[0] === slicedArg[1]||
    args[0] === slicedArg[2]
      ? args[1]
      : `black`
  }else if(slicedArg.length===4){
    return args[0] === slicedArg[0] ||
    args[0] === slicedArg[1]||
    args[0] === slicedArg[2]||
    args[0] === slicedArg[3]
      ? args[1]
      : `black`
  }

}





