export const DASHBOARD = "/dashboard";
export const CUSTOMERS = "/customers";
export const ADD_LOCATION = "/add-location";
export const LOCATION = "/location";
export const EDIT_LOCATION = "/edit-location";
export const TRANSACTION = "/transaction";
export const USER_PROFILE = "/user-profile";
export const CUSTOMER_DETAILS = "/customer-details";
export const LOGIN = "/login";
export const LANDING_PAGE = "/";
export const FORGET_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const SERVICE_RATINGS = "/service-ratings";
export const NEWS = "/news";
export const NEWS_DETAIL = "/news-detail";
export const LATEST_CARS = "/latest-cars";
export const TEST_DRIVE_BOOKINGS = "/test-drive-bookings";
export const TEST_DRIVE_BOOKING_DETAILS = "/test-drive-booking-details";
export const LIVE_CHAT_CONFIG = "/live-chat-config";
export const BEST_DEALS = "/best-deals";
export const NEW_DEAL = "/new-deal";
export const EDIT_DEAL = "/edit-deal";
export const COPY_DEAL = "/copy-deal";
export const SERVICE_BOOKINGS = "/service-bookings";
export const ROADSIDE_ASSISTANCE = "/roadside-assistance";
export const FAQ = "/faq";
export const ADD_FAQ = "/add-faq";
export const EDIT_FAQ = "/edit-faq";
export const LINK_MANAGEMENT = "/link-management";
export const PRIVACY_POLICY = "/terms-and-conditions";
export const NOTIFICATION = "/notifications";
export const LATEST_BLOGS = "/latest-blogs";
export const NEW_BLOG = "/new-blog";
export const EDIT_BLOG = "/edit-blog";
export const COPY_BLOG = "/copy-blog";
export const EDIT_EMERGENCY_OPTIONS = "/edit-emergency-options";
export const SALES_FORCE = "/sales-force";
export const ADD_NOTIFICATION = "/notifications/web-notifications";
export const EDIT_NOTIFICATION = "/edit-notification";
