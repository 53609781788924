import { combineReducers, AnyAction, Reducer } from "@reduxjs/toolkit";
import { RootState } from "redux-store/store";
import UserSlice from "slices/user-auth-flow";
import BrandSlice from "slices/brand";
import SnackBarStatusSlice from "slices/snackbar-message";
import UserProfileSlice from "slices/user-profile";
import CustomersSlice from "slices/customers";
import RoadsideAssistanceSlice from "slices/services/road-side-assistance";

import { TYPE_LOGOUT } from "constants/action-types-constants";
import BestDealsSlice from "slices/best-deals";
import FaqSlice from "slices/faqs";
import NotificationSlice from "slices/notifications";
import NewsSlice from "slices/news";
import LocationSlice from "slices/locations";
import DashboardSlice from "slices/dashboard";
import  LatestBlogsSlice  from "slices/latest-blogs";
import ServiceBookingSlice from "slices/services/service-bookings";
import  TestDriveSlice  from "slices/test-drive-bookings";
import  LatestCarsSlice from "slices/latest-cars";

const combineReducer = combineReducers({
  user: UserSlice,
  brand: BrandSlice,
  SnackBarStatus: SnackBarStatusSlice,
  UserProfile: UserProfileSlice,
  bestDeals: BestDealsSlice,
  customers: CustomersSlice,
  roadsideDetails: RoadsideAssistanceSlice,
  faqs: FaqSlice,
  notifications: NotificationSlice,
  news: NewsSlice,
  locations: LocationSlice,
  dashboardDetails: DashboardSlice,
  blogs:LatestBlogsSlice,
  serviceBookings:ServiceBookingSlice,
  testDriveBookings:TestDriveSlice,
  latestCars:LatestCarsSlice
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === TYPE_LOGOUT) {
    state = {} as RootState;
  }
  return combineReducer(state, action);
};
export { rootReducer, combineReducer };
