import styled from "styled-components";

import Button from "@material-ui/core/Button";

import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import { SUBHEADER_FONT_SIZE } from "constants/style/font-size";

interface Props {
  styles: {
    margin?: string;
    backgroundColor?: string;
    width?: string;
    height?: string;
    isLoading?: boolean;
  };
}

export const SubmitButton = styled(Button)`
  width: ${({ styles }: Props) => styles?.width};
  height: ${({ styles }: Props) => styles?.height};
  text-transform: none !important;
  font-size: ${SUBHEADER_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
  color: white !important;
  margin: ${({ styles }: Props) => styles?.margin};
  background-color: ${({ styles }: Props) => styles?.backgroundColor};
`;
