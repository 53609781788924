import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserLogin } from "services/login-services";
import { LoginRequest } from "interfaces/login.interface";

export const authenticate = createAsyncThunk(
  "authenticate",
  async (loginObj: LoginRequest, { rejectWithValue }) => {
    try {
      const userAuthInfo = await UserLogin(loginObj);
      return userAuthInfo.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
