import React, { ReactElement, useState, useEffect } from "react";

import { useAppSelector, useAppDispatch } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import LoaderButton from "shared-components/loader-button";
import Loader from "shared-components/loader";

import { BTN_DISABLED, loaderBtnStyleFunc } from "constants/style/props";
import {
  EMPTY_INPUT,
  INVALID_EMAIL_ID,
  INVALID_ENTRY,
} from "constants/snackbar-messages-constants";
import {
  EMAIL_REGEX_EQUATION,
  HAS_NUMBER_REGEX,
} from "constants/regex-validations-constants";
import ProfileImage from "assets/profile.jpeg";

import { UserProfileInfo } from "interfaces/user-profile.interface";

import { updateUserProfileReducer } from "slices/user-profile";
import { updateAdminInfoThunk } from "slices/user-profile/user-profile-thunks";
import {
  UpdateProfilePicture,
  GetProfilePicture,
  DeleteProfilePicture,
} from "services/user-profile-services";

import {
  BasicInfoContainer,
  FieldContainer,
  InputTextField,
  InputLabelText,
  ButtonContainer,
  CancelButton,
  ProfilePicture,
  LabelText,
  ImageContainer,
  RemoveImage,
  ImageDiv,
  LoaderDiv,
  LabelDiv,
} from "pages/user-profile/components/basic-info/styles";

const errorObj = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
};

const useStyles = makeStyles({
  disabled: BTN_DISABLED,
});

function BasicInfo(): ReactElement {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [dummyInfo, setDummyInfo] = useState<UserProfileInfo>();

  const [basicInfoErrors, setBasicInfoErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
  });

  const userBrand = useAppSelector((state: RootState) => {
    return state.UserProfile.userProfileInfo;
  });
  const [basicInfo, setBasicInfo] = useState<UserProfileInfo>({
    ...userBrand.basicInfo,
  });

  const [adminImage, setAdminImage] = useState<string>("");
  const [settingImage, setSettingImage] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string | null>();

  useEffect(() => {
    setBasicInfo({ ...userBrand.basicInfo });
    setDummyInfo(JSON.parse(JSON.stringify(userBrand.basicInfo)));
    setAdminImage(userBrand.basicInfo.profilePicture as string);
  }, [userBrand.basicInfo]);

  const { firstNameError, lastNameError, emailError } = basicInfoErrors;
  const { firstName, lastName, email } = basicInfo;
  const { role, ...restInfo } = basicInfo;

  const handelInfoChange = (prop) => (e) => {
    const { value } = e.target;
    basicInfo[prop] = value;
    setBasicInfo({ ...basicInfo });
    if (prop === "email") {
      if (value === "") {
        basicInfoErrors.emailError = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        const reg = new RegExp(EMAIL_REGEX_EQUATION).test(value);
        if (!reg) {
          basicInfoErrors.emailError = INVALID_EMAIL_ID;
        } else {
          basicInfoErrors.emailError = "";
        }
      }
    } else {
      if (value === "") {
        basicInfoErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        const reg = new RegExp(HAS_NUMBER_REGEX).test(value);
        if (reg) {
          basicInfoErrors[prop + "Error"] = INVALID_ENTRY;
        } else {
          basicInfoErrors[prop + "Error"] = "";
        }
      }
    }

    if (JSON.stringify(basicInfo) === JSON.stringify(dummyInfo)) {
      setIsUpdated(false);
    } else {
      setIsUpdated(true);
    }
    // console.log(basicInfo,"info",dummyInfo);
    setBasicInfoErrors({ ...basicInfoErrors });
  };

  const handelUpdateInfo = async () => {
    if (isUpdated) {
      if (firstNameError === "" && firstName === "") {
        basicInfoErrors.firstNameError = `First Name ${EMPTY_INPUT}`;
        setBasicInfoErrors({ ...basicInfoErrors });
      }
      if (lastNameError === "" && lastName === "") {
        basicInfoErrors.lastNameError = `Last Name ${EMPTY_INPUT}`;
        setBasicInfoErrors({ ...basicInfoErrors });
      }
      if (emailError === "" && email === "") {
        basicInfoErrors.emailError = `Email ${EMPTY_INPUT}`;
        setBasicInfoErrors({ ...basicInfoErrors });
      }
      if (firstNameError === "" && lastNameError === "" && emailError === "") {
        // console.log(restInfo,'restInfo')
        setLoading(true);
        try {
          const result = await dispatch(
            updateAdminInfoThunk({ request: restInfo, type: "basicInfo" })
          ).unwrap();
          setLoading(false);
          enqueueSnackbar(result.response.data.status, { variant: "success" });
          setIsUpdated(false);
        } catch (error) {
          setLoading(false);
          enqueueSnackbar(error?.data, { variant: "error" });
        }
      }
    }
  };

  const handleImage = (e, type) => {
    if (type === "image") {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        setProfileImage(reader.result as string);
      };
    }
    // setProfileImage(e.target.files[0]);
  };

  useEffect(() => {
    if (profileImage !== "" && profileImage !== undefined) {
      setSettingImage(true);
      const uploadImage = async () => {
        try {
          const result = await UpdateProfilePicture({
            profileImage,
            id: userBrand?.basicInfo?.id,
          });
          try {
            const result = await GetProfilePicture(
              userBrand?.basicInfo?.id as number
            );
            setAdminImage(result?.data?.data?.profilePicture);
            dispatch(
              updateUserProfileReducer(result?.data?.data?.profilePicture)
            );
          } catch (err) {
            console.log(err.response);
          }
          enqueueSnackbar(result?.data?.response, {
            variant: "success",
          });
        } catch (err) {
          console.log(err.response);
        }
        setSettingImage(false);
      };
      uploadImage();
    }
    // eslint-disable-next-line
  }, [enqueueSnackbar, profileImage, userBrand?.basicInfo?.id]);


  const handelCancel = () => {
    basicInfoErrors.firstNameError = "";
    basicInfoErrors.lastNameError = "";
    basicInfoErrors.emailError = "";
    setBasicInfoErrors({ ...basicInfoErrors });
    setBasicInfo({ ...userBrand.basicInfo });
  };

  const deleteProfile = async () => {
    try {
      const result = await DeleteProfilePicture(
        userBrand?.basicInfo?.id as number
      );
      if (result?.data?.data?.profilePicture === null) {
        setAdminImage(ProfileImage);
        dispatch(updateUserProfileReducer(ProfileImage));
        UpdateProfilePicture({
          profileImage: ProfileImage,
          id: basicInfo?.id,
        });
      }
      enqueueSnackbar(result?.data?.response, {
        variant: "success",
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <BasicInfoContainer>
      <ImageContainer>
        {(adminImage === "" && !settingImage) || adminImage === ProfileImage ? (
          <ImageDiv>
            {/* <RemoveImage onClick={deleteProfile} /> */}
            <ProfilePicture src={ProfileImage as string}></ProfilePicture>
          </ImageDiv>
        ) : settingImage ? (
          <LoaderDiv>
            <Loader isLoading={true} />
          </LoaderDiv>
        ) : (
          <ImageDiv>
            <RemoveImage onClick={deleteProfile} />
            <ProfilePicture src={adminImage}></ProfilePicture>
          </ImageDiv>
        )}
        <input
          accept="image/*"
          type="file"
          id="profile-pic"
          name=""
          style={{ display: "none" }}
          onChange={(e) => handleImage(e, "image")}
        />
        <LabelDiv>
          <LabelText htmlFor="profile-pic">Upload Image</LabelText>
        </LabelDiv>
      </ImageContainer>
      <FieldContainer>
        <InputLabelText>First Name</InputLabelText>
        <InputTextField
          variant="outlined"
          size="small"
          value={firstName}
          onChange={handelInfoChange("firstName")}
          error={Boolean(firstNameError)}
          helperText={firstNameError}
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabelText>Last Name</InputLabelText>
        <InputTextField
          variant="outlined"
          size="small"
          value={lastName}
          onChange={handelInfoChange("lastName")}
          error={Boolean(lastNameError)}
          helperText={lastNameError}
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabelText>Email ID</InputLabelText>
        <InputTextField
          variant="outlined"
          size="small"
          value={email}
          onChange={handelInfoChange("email")}
          error={Boolean(emailError)}
          helperText={emailError}
          disabled
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabelText>Role</InputLabelText>
        <InputTextField value={role} variant="outlined" size="small" disabled />
      </FieldContainer>
      <ButtonContainer>
        <LoaderButton
          submitHandler={handelUpdateInfo}
          isLoading={loading}
          buttonText="Update"
          styles={loaderBtnStyleFunc("13%", "2.8rem")}
          className={`${!isUpdated ? classes.disabled : ""}`}
        />
        <CancelButton
          variant="contained"
          color="default"
          onClick={handelCancel}
        >
          Cancel
        </CancelButton>
      </ButtonContainer>
    </BasicInfoContainer>
  );
}

export default BasicInfo;
