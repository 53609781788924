import styled from "styled-components";

export const MainDiv = styled.div`
  height: 57vh;
  background-color: white;
  width: 100%;
  display: flex;
  border-radius: 10px;

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: red !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    border: none !important;
  }
  .MuiDataGrid-root {
    border: none !important;
  }

  svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator {
    display: none;
  }
`;
