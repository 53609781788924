import React, { ReactElement } from "react";

interface Props {
  color: string;
}

function SearchIcon({ color }: Props): ReactElement {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17A8 8 0 109 1a8 8 0 000 16zM19 19l-4.35-4.35"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SearchIcon;
