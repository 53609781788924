import styled from "styled-components";



export const DetailsContainer = styled.div`
  margin-top: 2rem;
  padding: 1.5rem 1.5rem;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06); 
`;

export const BoxDiv = styled.div`
    background-color:#F0F0F0;
    padding:1.25rem;
    border-radius:0.625rem;
`;

export const FlexDiv = styled.div`
    display:flex;
    font-size:0.875rem;
    padding:10px 0.625rem;
`;

export const FlexChild = styled.span`
    width:20%;
    margin: 0 0.625rem;
    font-weight: 600;
`;

export const FlexChildTwo = styled(FlexChild)`
    width:40%;
    font-weight: normal;
`;




