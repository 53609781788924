// export const BASE_URL = "http://ekk-dev-970836859.me-south-1.elb.amazonaws.com";
// export const BASE_URL = "http://ekkanoo-elb-280193655.me-south-1.elb.amazonaws.com";
export const BASE_URL = "https://mobapp.elb.ekkanoo.com";
// export const BASE_URL = "http://ekkanooapp.s3-website.me-south-1.amazonaws.com";
// export const BASE_URL = "https://1544842dd4e4.ngrok.app";
// export const BASE_URL = "http://ekkanoo-elb-280193655.me-south-1.elb.amazonaws.com"

export const LOGIN_ENDPOINT = "/login";
export const RESET_PASSWORD_LINK_ENDPOINT = "/forgot-password";
export const RESET_PASSWORD_ENDPOINT = "/reset-password";
export const CHANGE_PASSWORD_ENDPOINT = "/change-password";
export const GET_CUSTOMERS_ENDPOINT = "/get-customers";
export const GET_CUSTOMERS_CARS_ENDPOINT = "/myCars";
export const GET_CUSTOMERS_SERVICE_CARS_ENDPOINT = "/customer-service-history";
export const GET_ADMINS_ENDPOINT = "/get-admin-users";
export const GET_BASIC_PROFILE_INFO_ENDPOINT = "/get-basic-info";
export const UPDATE_ADMIN_BY_ID_ENDPOINT = "/update-admin";
export const CUSTOMER_SEARCH_ENDPOINT = "/customer-search";
export const CUSTOMER_FILTER_ENDPOINT = "/customer-filter";
export const DELETE_ADMIN_ENDPOINT = "/delete-admin";
export const ADD_ADMIN_ENDPOINT = "/add-admin";
export const GET_DEALS_ENDPOINT = "/get-deals";
export const ADD_NEW_DEAL_ENDPOINT = "/add-deal";
export const DEAL_INFO_ENDPOINT = "/get-deal-info";
export const DELETE_DEAL_ENDPOINT = "/delete-deal";
export const UPDATE_DEAL_ENDPOINT = "/update-deal";
export const GET_CUSTOMER_BASIC_INFO_BY_ID = "/get-customer-basicinfo";
export const UPDATE_CUSTOMER_STATUS = "/update-status";
export const SET_ACTIVE_DEAL_ENDPOINT = "/set-active";
export const SET_FINISHED_DEAL_ENDPOINT = "/set-finished";
export const GET_ROADSIDE_ASSISTANCE_DETAILS_ENDPOINT =
  "/get-roadside-assistance";
export const ROADSIDE_ASSISTANCE_FILTERED_DATA = "/roadside-assistance-filter";
export const DEAL_SEARCH_ENDPOINT = "/deal-search";
export const GET_ALL_FAQS_ENDPOINT = "/get-faq-list";
export const ADD_FAQ_ENDPOINT = "/add-faq";
export const UPDATE_FAQ_ENDPOINT = "/update-faq";
export const DELETE_FAQ_ENDPOINT = "/delete-faq";
export const SEARCH_FAQ_ENDPOINT = "/faq-search";
export const NOTIFICATION_ENDPOINT = "/notifications/web-notifications";
export const GET_NEWS_ENDPOINT = "/news/get-news";
export const GET_NEWS_BY_ID_ENDPOINT = "/news/get-details";
export const GET_PART_CENTER_ENDPOINT = "/get-part-center";
export const GET_SERVICE_CENTER_ENDPOINT = "/get-service-center";
export const GET_SHOWROOM_ENDPOINT = "/get-showroom";
export const ADD_LOCATION_ENDPOINT = "/add-location";
export const EDIT_LOCATION_ENDPOINT = "/edit-location";
export const DELETE_LOCATION_ENDPOINT = "/delete-location";
export const FILTER_LANGUAGE_ENDPOINT = "/location-filter";
export const GET_DASHBOARD_DETAILS = "/dashboard/get-dashboard-data";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const GET_ALL_BLOGS_ENDPOINT = "/latest-blogs/get-latest-blogs";
export const GET_BLOG_BY_ID_ENDPOINT = "/latest-blogs/get-latest-blog-by-id";
export const EDIT_BLOG_ENDPOINT = "/latest-blogs/update-latest-blog";
export const ADD_NEW_BLOG_ENDPOINT = "/latest-blogs/add-latest-blog";
export const DELETE_BLOG_ENDPOINT = "/latest-blogs/delete-latest-blog";
export const SEARCH_BLOGS_ENDPOINT = "/latest-blogs/search-latest-blogs";
export const UPDATE_PROFILE_END_POINT = "/update-profile-pic";
export const GET_USER_PROFILE = "/get-profile-pic";
export const DELETE_PROFILE_END_POINT = "/delete-profile-pic";
export const LIVE_CHAT_ENDPOINT = "/live-chat";
export const GET_SALES_FORCE_ENDPOINT = "/salesforce/get-sf-credentials";
export const UPDATE_SALES_FORCE_ENDPOINT = "/salesforce/add-sf-credentials";
export const GET_ONGOING_SERVICE_BOOKING_ENDPOINT =
  "/current-service-bookings-list";
export const GET_UPCOMING_SERVICE_BOOKING_ENDPOINT =
  "/upcoming-service-bookings-list";
export const GET_PREVIOUS_SERVICE_BOOKING_ENDPOINT =
  "/service-history-bookings-list";
export const SERVICE_BOOKING_SEARCH_ENDPOINT = "/service-bookings-search";
export const SERVICE_BOOKING_FILTER_ENDPOINT = "/service-bookings-filter";
export const TEST_DRIVE_FILTERED_DATA = "/test-drive-filter";
export const TEST_DRIVE_SEARCH_ENDPOINT = "/test-drive-search";
export const GET_TEST_DRIVE_DETAILS_ENDPOINT = "/get-test-drive-bookings";
export const GET_EMERGENCY_OPTIONS_ENDPOINT = "/get-emergency-options";
export const UPDATE_EMERGENCY_OPTIONS_ENDPOINT = "/add-emergency-options";
export const GET_FILTER_PARAMETERS_ENDPOINT = "/get-service-filter-parameters";
export const UPDATE_LINK_MGMT = "/link-mgmt/add-external-links";
export const GET_LINK_MGMT = "/link-mgmt/get-external-links";
export const GET_API_MGMT = "/integration/get-integration-metadata";
export const UPDATE_API_MGMT = "/integration/update-integration-metadata";
export const GET_LATEST_CARS_ENDPOINT = "/latest-cars/get-latest-cars";
export const UPDATE_LATEST_CARS_RECORD_STATUS = "/update-news-status";
