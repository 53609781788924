import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import customerInitialState from "slices/customers/customers-intitial-state";
import CustomerState from "slices/customers/customers-interface";

import {
  getCustomersThunk,
  getCustomersByFilterThunk,
  getCustomersBySearchThunk,
  getCustomerById,
  updateCustomerInfoThunk,
  getCustomersOwnedCarsThunk,
  getCustomersBookingHistoryThunk,
} from "slices/customers/customers-thunks";

export const CustomersSlice = createSlice({
  name: "CustomersSlice",
  initialState: customerInitialState,
  reducers: {
    setTabPageReducer: setTabPageReducer,
    setCurrentPageReducer:setCurrentPageReducer
  },
  extraReducers: {
    //set customers
    [getCustomersThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.customerInfo.customerDetails =
        listEntityClass === undefined ? [] : listEntityClass;
      state.customerInfo.totalRecordsLength = totalRecords;
    },
    [getCustomersThunk.rejected.type]: (state) => {
      state.customerInfo.customerDetails = [];
    },

    //filter
    [getCustomersByFilterThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.customerInfo.customerDetails =
        listEntityClass === undefined ? [] : listEntityClass;
      state.customerInfo.totalRecordsLength = totalRecords;
    },
    [getCustomersByFilterThunk.rejected.type]: (state) => {
      state.customerInfo.customerDetails = [];
    },

    //search
    [getCustomersBySearchThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      
      state.customerInfo.customerDetails =
        listEntityClass === undefined ? [] : listEntityClass;
      state.customerInfo.totalRecordsLength = totalRecords;
    },
    [getCustomersBySearchThunk.rejected.type]: (state) => {
      state.customerInfo.customerDetails = [];
    },

    //getCustomerById
    [getCustomerById.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      state.customerInfo.currentCustomer = data;
    },

    //updateCustomer status
    [updateCustomerInfoThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      const result = state.customerInfo.customerDetails.map((item) => {
        if (item.id === data.id) {
          item = { ...data };
        }
        return item;
      });
      state.customerInfo.customerDetails = [...result];
    },

    //getCustomersOwnedCarsThunk
    [getCustomersOwnedCarsThunk.fulfilled.type]: (state, action) => {
      let { carList } = action.payload.data.data;
      state.customerInfo.ownedCars =
      carList === undefined ? [] : carList;
    },

    //getCustomersBookingHistoryThunk
    [getCustomersBookingHistoryThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.customerInfo.carsServiceHistory =
        listEntityClass === undefined ? [] : listEntityClass;
      state.customerInfo.totalRecordsLength = totalRecords;
    },
  },
});

function setTabPageReducer(
  state: CustomerState,
  action: PayloadAction<string>
) {
  state.customerInfo.tabType = action.payload;
}

function setCurrentPageReducer(
  state: CustomerState,
  action: PayloadAction<number>
) {
  state.customerInfo.currentPage = action.payload;
}
