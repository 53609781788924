import React, { ReactElement } from "react";

import { CheckboxContainerDiv, CheckboxChild, CustomInput } from "./styles";

interface labelArray {
  id: number;
  status: boolean;
  label: string;
}
interface CheckProps {
  checkboxLabelArr?: Array<labelArray>;
  handleChange?: (e) => void;
  isStyleChanged?: boolean;
}

export default function CheckboxContainer({
  checkboxLabelArr,
  handleChange,
  isStyleChanged,
}: CheckProps): ReactElement {
  return (
    <CheckboxContainerDiv isStyleChanged={isStyleChanged as boolean}>
      {checkboxLabelArr?.map(({ id, label, status }) => (
        <CheckboxChild key={id+label}>
          <CustomInput
            name={label}
            value={id}
            color="primary"
            size="small"
            checked={status}
            onChange={handleChange}
            isStyleChanged={isStyleChanged as boolean}
          ></CustomInput>
          <label>{label}</label>
        </CheckboxChild>
      ))}
    </CheckboxContainerDiv>
  );
}
