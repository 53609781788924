import styled from "styled-components";
import { Tabs, Tab, Box } from "@material-ui/core/";

import { TEXTFIELD_HEADING } from "constants/style/font-size";

export const TabSelectorDiv = styled.div``;

export const HeaderDiv = styled.h1``;

export const TabContainer = styled(Tabs)`
  padding: 0.625rem 1rem;
`;

export const OneTab = styled(Tab)`
  text-transform: none !important;
  font-size: ${TEXTFIELD_HEADING} !important;
  margin: 0 0.625rem !important;
`;

export const BoxContainer = styled.div``;

export const DetailBox = styled(Box)``;
