import React, { ReactElement, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";

import LoaderButton from "shared-components/loader-button";

import { resetPassword, isResetPasswordValid } from "services/login-services";

import {
  EMPTY_INPUT,
  PASSWORD_AND_CONFIRMPASSWORD,
  PASSWORD_CONSTRAINTS_MESSAGE,
} from "constants/snackbar-messages-constants";
import { PASSWORD_REGEX_EQUATION } from "constants/regex-validations-constants";
import { LOGIN } from "constants/route/route-constants";

import {
  Heading,
  InputTextField,
  InputLabelText,
  BrandLogo,
  EmptyDiv,
  ShowPasswordIcon,
  HidePasswordIcon,
  LabelDiv,
} from "pages/auth/components/reset-password/styles";
import {
  MainContainer,
  LoginContainer,
  MainDiv,
  NavContainer,
  InputDiv,
} from "pages/auth/components/login/styles";
import { LOGIN_RESET_PASS_BTN_STYLES } from "constants/style/props";

function ResetPassword(): ReactElement {
  const history = useHistory();
  const search = useLocation().search;
  const { enqueueSnackbar } = useSnackbar();
  const id = new URLSearchParams(search).get("id");
  

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [passwordDetails, setPasswordDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordErrors, setPasswordErrors] = useState({
    passwordError: "",
    confirmPasswordError: "",
  });
  const [isLinkValidStatus, setIsLinkValidStatus] = useState<{
    message: string;
    status: boolean;
  }>({
    message: "",
    status: true,
  });

  useEffect(() => {
    const checkIsLinkValid = async () => {
      try {
        await isResetPasswordValid(encodeURIComponent(id as string));
        setIsLinkValidStatus({
          status: false,
          message: "",
        });
      } catch (err) {
        setIsLinkValidStatus({
          status: true,
          message: err?.response?.data?.data,
        });
      }
    };
    checkIsLinkValid();
  }, [id]);

  const togglePasswordVisibility = (type) => {
    if (type === "password") {
      setIsShowPassword({
        ...isShowPassword,
        password: isShowPassword.password ? false : true,
      });
    } else {
      setIsShowPassword({
        ...isShowPassword,
        confirmPassword: isShowPassword.confirmPassword ? false : true,
      });
    }
  };

  const submitResetPasswordUserDetails = async () => {
    if (passwordDetails.password === "") {
      passwordErrors.passwordError = `password ${EMPTY_INPUT}`;
      setPasswordErrors({ ...passwordErrors });
    }
    if (passwordDetails.confirmPassword === "") {
      passwordErrors.confirmPasswordError = `confirm password ${EMPTY_INPUT}`;
      setPasswordErrors({ ...passwordErrors });
    }
    if (
      passwordErrors.passwordError === "" &&
      passwordErrors.confirmPasswordError === ""
    ) {
      setPasswordErrors({ passwordError: "", confirmPasswordError: "" });
      try {
        setIsLoading(true);
        const result = await resetPassword({
          ...passwordDetails,
          id: id as string,
        });
        setIsLoading(false);
        enqueueSnackbar(result.data.data, { variant: "success" });
        history.push(LOGIN);
      } catch (err) {
        setIsLoading(false);
        if (err.response?.data?.error_description !== "") {
          enqueueSnackbar(err.response?.data?.error_description, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(err.response.data.data, { variant: "error" });
        }
      }
    }
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const reg = new RegExp(PASSWORD_REGEX_EQUATION).test(value);
    if (id === "password") {
      if (value === "") {
        passwordErrors.passwordError = `password ${EMPTY_INPUT}`;
      } else if (!reg) {
        passwordErrors.passwordError = `${PASSWORD_CONSTRAINTS_MESSAGE}`;
      } else {
        passwordErrors.passwordError = "";
        setPasswordDetails({ ...passwordDetails, password: value });
      }
    } else {
      if (value === "") {
        passwordErrors.confirmPasswordError = `confirm password ${EMPTY_INPUT}`;
      } else if (value !== passwordDetails.password) {
        passwordErrors.confirmPasswordError = `${PASSWORD_AND_CONFIRMPASSWORD}`;
      } else {
        passwordErrors.confirmPasswordError = "";
        setPasswordDetails({ ...passwordDetails, confirmPassword: value });
      }
    }
    setPasswordErrors({ ...passwordErrors });
  };

  const handleSubmitOnEnter = (event) => {
    if (event.key === "Enter") {
      submitResetPasswordUserDetails();
    }
  };

  return (
    <MainContainer>
      <MainDiv>
        <EmptyDiv></EmptyDiv>
        <LoginContainer>
          <BrandLogo />
          <InputDiv>
            {isLinkValidStatus?.status ? (
              <Heading>{isLinkValidStatus?.message}</Heading>
            ) : (
              <>
                <Heading>Reset Password</Heading>
                <NavContainer>
                  <LabelDiv>
                    <InputLabelText>Password</InputLabelText>
                    {isShowPassword.password ? (
                      <ShowPasswordIcon
                        onClick={() => togglePasswordVisibility("password")}
                      />
                    ) : (
                      <HidePasswordIcon
                        onClick={() => togglePasswordVisibility("password")}
                      />
                    )}
                  </LabelDiv>
                  <InputTextField
                    id="password"
                    variant="outlined"
                    onChange={handlePassword}
                    size="small"
                    type={isShowPassword.password ? "text" : "password"}
                    error={Boolean(passwordErrors.passwordError)}
                    helperText={passwordErrors.passwordError}
                    autoFocus
                    onKeyPress={handleSubmitOnEnter}
                  />
                </NavContainer>
                <NavContainer>
                  <LabelDiv>
                    <InputLabelText>Confirm Password</InputLabelText>
                    {isShowPassword.confirmPassword ? (
                      <ShowPasswordIcon
                        onClick={() =>
                          togglePasswordVisibility("confirmPassword")
                        }
                      />
                    ) : (
                      <HidePasswordIcon
                        onClick={() =>
                          togglePasswordVisibility("confirmPassword")
                        }
                      />
                    )}
                  </LabelDiv>
                  <InputTextField
                    id="confirmPassword"
                    variant="outlined"
                    size="small"
                    onChange={handlePassword}
                    type={isShowPassword.confirmPassword ? "text" : "password"}
                    error={Boolean(passwordErrors.confirmPasswordError)}
                    helperText={passwordErrors.confirmPasswordError}
                    onKeyPress={handleSubmitOnEnter}
                  />
                </NavContainer>
                <LoaderButton
                  submitHandler={submitResetPasswordUserDetails}
                  isLoading={isLoading}
                  buttonText="Reset Password"
                  styles={LOGIN_RESET_PASS_BTN_STYLES}
                />
              </>
            )}
          </InputDiv>
        </LoginContainer>
        <EmptyDiv></EmptyDiv>
      </MainDiv>
    </MainContainer>
  );
}

export default ResetPassword;
