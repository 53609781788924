import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "shared-components/icons/search-icon";

import { COMPONENT_HEADING } from "constants/style/font-size";
import { PRIMARY_MEDIUM_FONT } from "constants/style/fonts";

export const CustomerContainer = styled.div`
  padding: 1.5rem 2rem;
`;

export const Heading = styled.p`
  font-size: ${COMPONENT_HEADING};
  margin: 0;
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-weight: bold;
  user-select: none;
`;

export const InputTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.color} !important;
  }
  margin-left: 2rem !important;
`;

export const Search = styled(SearchIcon)``;

export const LeftDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
