import React from "react";

import MaterialUiTimePicker from "../time-picker";

import {
  DropdownBox,  
  TimeInputLabel,
  RemoveTimeSlot,
  AddIconLogoSvg,
} from "pages/location/components/accordian/styles";




interface Props {
  weekInd: number;
  index: number;
  open: string;
  close: string;
  handelRemoveTimeSlots: (weekInd, index) => void;
  handelOpenTime: (weekInd, index, value) => void;
  handelCloseTime: (weekInd, index, value) => void;
  handelAddTimeSlots: (weekInd) => void;
}
export default function WeeklyTimeSelector({
  open,
  close,
  index,
  weekInd,
  handelRemoveTimeSlots,
  handelOpenTime,
  handelCloseTime,
  handelAddTimeSlots,
}: Props) {

  const handleRemoveTime = () => {
    handelRemoveTimeSlots(weekInd, index);
  };

  const handleOpenTimeChange = (value) => {
    handelOpenTime(weekInd, index, value);
  };

  const handleCloseTimeChange = (value) => {
    handelCloseTime(weekInd, index, value);
  };

  const handelAddSlots = () => {
    handelAddTimeSlots(weekInd);
  };
  return (
    <DropdownBox>
      <TimeInputLabel>Open</TimeInputLabel>

      <MaterialUiTimePicker
        time={open}
        handelChangeParent={handleOpenTimeChange}
        style={{width:"31%",margin:"0 0.625rem"}}
      />

      <TimeInputLabel>Close</TimeInputLabel>

      <MaterialUiTimePicker
        time={close}
        handelChangeParent={handleCloseTimeChange}
        style={{width:"31%",margin:"0 0.5rem"}}
      />
      {index >= 1 ? (
        <RemoveTimeSlot onClick={handleRemoveTime} />
      ) : (
        <AddIconLogoSvg onClick={handelAddSlots} />
      )}
    </DropdownBox>
  );
}
