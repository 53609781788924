//12px
export const SUBTEXT_FONT_SIZE = "0.75rem";
//14px
export const PARAGRAPH_FONT_SIZE = "0.875rem";
//16px
export const SUBHEADER_FONT_SIZE = "1rem";
//22px - 1.375rem
export const HEADING_BOLD_FONT_SIZE = "1.2rem";
//18px
export const TEXTFIELD_HEADING = "1.125rem";
//28px
export const COMPONENT_HEADING = "1.75rem";
//32px
export const BOLD_HEADING = "2rem";
