import Styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Menu from "@material-ui/core/Menu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PersonOutlineSharpIcon from "@material-ui/icons/PersonOutlineSharp";
import { SUBHEADER_FONT_SIZE } from "constants/style/font-size";

export const HeaderDiv = Styled.div`
 min-height:4rem;
 background:#FFFFFF;
 box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
 display:flex;
 justify-content: space-between;
 padding:0 1.5rem;
 align-items: center;
 position: sticky;
 top: 0;
 z-index: 1;
`;

export const ProfileDiv = Styled.div`
  display:flex;
  align-items:center;
`;
export const UserName = Styled.p`
  margin:0;
  font-size: ${SUBHEADER_FONT_SIZE} !important;
`;
export const ImageContainer = Styled.div`
 width:2.6rem;
 margin-right:0.5rem;
 margin-top:0.2rem;
 cursor:pointer;
 color:white;
 height: 2.6rem;
 font-weight:bold;
 border-radius:50% !important;
 display: flex;
 justify-content: center;
 align-items: center;
`;
export const Image = Styled.img`
 width:100%;
 height:100%;
`;
export const UserProfileDiv = Styled.div`
  cursor:pointer;
`;
export const ExpandDiv = Styled.div`
  cursor:pointer;
  display:flex;
  align-items:center;
`;
export const BrandImage = Styled.img`
  width: 2.6rem;`;

export const MenuListItem = Styled(MenuItem)`
  padding: 4px 10px !important;
`;
export const BrandList = Styled.div``;

export const SelectedBrandDiv = Styled.div`
  display: flex;
  align-items: center;
`;
export const NavItem = styled(NavLink)`
  cursor: pointer;
  display: flex;
  text-decoration: none;
  color: #000000;
  align-items: center;
`;

export const NavMenu = Styled(Menu)`
  margin-top:3.125rem;

`;

export const FlexContainer = Styled.div`
  padding: 0.313rem 0.625rem;
`;

export const PowerIcon = styled(PowerSettingsNewIcon)`
  color: ${({ theme }) => theme.color};
`;

export const ProfileIcon = styled(PersonOutlineSharpIcon)`
  color: ${({ theme }) => theme.color};
`;

export const NavMenuItemList = Styled(MenuItem)`
    display: flex;
    cursor: pointer;
  `;

export const InnerFlex = Styled.div`
    margin-top:0.625rem;
    padding:0 0.625rem;
  `;

export const FlexChild = Styled.div`
  display:flex;
  align-items: center;
  justify-Content:center;
  margin:0 0.313rem;
`;

export const NavMenuItem = styled(NavItem)``;

export const MenuImageContainer = Styled.div`
    margin-right:0.75rem;
`;

export const MenuProfileDiv = Styled(ProfileDiv)`
  padding:0.313rem 2.188rem;
`;

export const ProfileFlex = Styled.div`
  display:flex;
  flex-direction:column;
`;

export const Name = Styled.div`
  font-size:1rem;
`;

export const SpanComp = Styled.span`
  font-size:0.625rem;
`;

export const LogoutTag = Styled.p`
margin:0
`;

export const ProfileImage = styled.img`
  border-radius:50%;
  width:2.5rem;
  height:2.5rem;
`;
