import { brandEnum } from "slices/brand/brand-slice";

import { createMuiTheme } from "@material-ui/core";

import {
  INPUT_FIELD_BACKGROUND,
  DISABLED_TEXT,
  TEXTBOX_BORDER,
  PRIMARY_TOYOTA,
  SECONDARY_TOYOTA,
  SECONDARY_MOTORCITY,
  PRIMARY_MOTORCITY,
  BLACK_COLOR as PRIMARY_LEXUS,
  SECONDARY_LEXUS,
  BLACK_COLOR,
} from "constants/style/color";

export default function getTheme(brand) {
  return createMuiTheme({
    palette: {
      primary: {
        main:
          brand === brandEnum.T
            ? PRIMARY_TOYOTA
            : brand === brandEnum.L
            ? PRIMARY_LEXUS
            : PRIMARY_MOTORCITY,
      },
      secondary: {
        main:
          brand === brandEnum.T
            ? SECONDARY_TOYOTA
            : brand === brandEnum.L
            ? SECONDARY_LEXUS
            : SECONDARY_MOTORCITY,
      },
    },
    typography: {
      fontFamily: ["Montserrat Regular", "Montserrat Bold"].join(","),
    },
    overrides: {
      MuiTextField: {
        root: {
          background: INPUT_FIELD_BACKGROUND,
        },
      },

      MuiInputBase: {
        root: {
          color: `${DISABLED_TEXT}`,
        },
      },
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: `${TEXTBOX_BORDER}`,
            borderWidth: "1px",
          },
          "&$focused $notchedOutline": {
            borderColor: `${TEXTBOX_BORDER}`,
            borderWidth: "1px",
          },
        },
        notchedOutline: {
          borderColor: `${TEXTBOX_BORDER}`,
        },
        focused: {},
      },
      MuiInput: {
        underline: {
          "&::after": {
            display: "none",
          },
          "&::before": {
            display: "none",
          },
        },
      },
      MuiTab: {
        textColorPrimary: {
          opacity: 0.6,
          borderRadius: "10px 10px 0px 0px",
          color: BLACK_COLOR,
          "&$selected": {
            boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.06) !important",
            opacity: 1,
          },
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "none",
          },
        },
      },
    },
  });
}
