import styled from "styled-components";

import { FormHelperText } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import { TextField, InputLabel, Button, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// interface Props {
//   isEdit: string;
// }

export const AddUserContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 1px 1px 2px 2px #ffffff;
  border-radius: 1%;
  padding: 2rem 2.5rem 0;
  display: flex;
  flex-direction: column;
`;

export const HeaderDiv = styled.div`
  padding-bottom: 1rem;
  font-weight: 600;
  font-size: 1.25rem;
`;

export const FormContainer = styled.div``;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2%;
`;

export const InputTextField = styled(TextField)`
  width: 20rem;
`;
export const InputEmailTextField = styled(InputTextField)``;

export const InputLabelText = styled(InputLabel)`
  width: 100px;
  align-self: center;
`;

export const ButtonContainer = styled.div`
  margin: 2rem 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const SubmitButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
`;
export const CancelButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 1rem !important;
`;

export const DropdownSelector = styled(Select)`
  height: 2.5rem;
  width: 20rem;
`;

export const HelperText = styled(FormHelperText)`
  text-align: center !important;
  color: #f44336 !important;
  margin-right: 1.3rem !important;
`;
export const HelperTextRadio = styled(HelperText)`
  margin-left: 1.9rem !important;
  margin-right: 0 !important;
`;

export const AutocompleteSelect = styled(Autocomplete)`
  width: 20rem;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  /* flex-direction: row; */
  margin-top: 2%;
  flex-direction: start;
`;

export const AddUserModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RadioContent = styled(RadioGroup)`
  margin-top: 0.5rem;
  margin-left: 0.4rem;
`;
export const RadioOption = styled(Radio)`
  height: 1rem;
`;
