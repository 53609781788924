import styled from "styled-components";
import { CustomTagGreen } from "pages/services/service-bookings/components/service-bookings-table/styles";

import {
  TAG_COLOR_RED,
  RED_TAG_FONT_COLOR,
  GREEN_TAG_FONT_COLOR,
} from "constants/style/color";

import {
  NavItem,
} from "pages/news/components/news-details/styles";



export const LinkRoute = styled(NavItem)`
  text-decoration:underline;
`;

export const CustomTagRed = styled(CustomTagGreen)`
    background-color: ${TAG_COLOR_RED};
`;
export const CompletedCircle = styled.div`
  width: 1rem;
  border-radius: 50%;
  background-color: ${GREEN_TAG_FONT_COLOR};
  height: 1rem;
  margin: 0 0.2rem !important;
`;
export const InprogressCircleRed = styled(CompletedCircle)`
  background-color: ${RED_TAG_FONT_COLOR};
`;

