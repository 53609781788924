import React, { ReactElement, useState } from "react";
import { Redirect } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";
import { authenticate } from "slices/user-auth-flow";
import { setBrand } from "slices/brand";
import { brandEnum } from "slices/brand/brand-slice";
import { useSnackbar } from "notistack";

import LoaderButton from "shared-components/loader-button";
import { LoginRequest } from "interfaces/login.interface";

import { DASHBOARD, FORGET_PASSWORD } from "constants/route/route-constants";
import { EMAIL_REGEX_EQUATION } from "constants/regex-validations-constants";
import {
  EMPTY_INPUT,
  INVALID_EMAIL_ID,
} from "constants/snackbar-messages-constants";

import {
  LoginContainer,
  MainDiv,
  NavContainer,
  ForgotPassword,
  // SubmitButton,
  InputTextField,
  InputLabelText,
  InputDiv,
  BrandLogo,
  EmptyDiv,
  ShowPasswordIcon,
  HidePasswordIcon,
  LabelDiv,
  MainContainer
} from "pages/auth/components/login/styles";
import { LOGIN_RESET_PASS_BTN_STYLES } from "constants/style/props";
// import { getUserBasicInfoThunk } from "slices/user-profile";


function Login(): ReactElement {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [userDetails, setUserDetails] = useState<LoginRequest>({
    email: "",
    password: "",
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  const [inputErrors, setErrors] = useState({
    emailError: "",
    passwordError: "",
  });

  const getUser = useAppSelector((state: RootState) => state.user.userInfo);
  const userBrand = useAppSelector((state: RootState) => {
    return state.user.userInfo.user;
  });

  const togglePasswordVisibility = () => {
    setIsShowPassword(isShowPassword ? false : true);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    if (id === "email") {
      if (value === "") {
        inputErrors.emailError = `${id} ${EMPTY_INPUT}`;
      } else {
        const reg = new RegExp(EMAIL_REGEX_EQUATION).test(value);
        if (!reg) {
          inputErrors.emailError = INVALID_EMAIL_ID;
        } else {
          inputErrors.emailError = "";
          userDetails.email = value;
        }
      }
    } else {
      if (value === "") {
        inputErrors.passwordError = `${id} ${EMPTY_INPUT}`;
      } else {
        inputErrors.passwordError = "";
        userDetails.password = value;
      }
    }
    setUserDetails({ ...userDetails });
    setErrors({ ...inputErrors });
  };

  const handleLogin = () => {
    if (userDetails.email === "") {
      inputErrors.emailError = `email ${EMPTY_INPUT}`;
    }

    if (userDetails.password === "") {
      inputErrors.passwordError = `password  ${EMPTY_INPUT}`;
    }
    setErrors({ ...inputErrors });
    for (const i in userDetails) {
      if (userDetails[i] === "") {
        setErrors[i + "Error"] = `${i} ${EMPTY_INPUT}`;
      }
    }
    const result = Object.keys(inputErrors).filter((key) => {
      return inputErrors[key] !== "";
    });
    if (result.length === 0) {
      // showLoader(true);
      setIsLoading(true);
      dispatch(authenticate(userDetails))
        .unwrap()
        .then((res) => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          enqueueSnackbar(err.data, { variant: "error" });
        });
    }
  };

  const handleSubmitOnEnter = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  if (getUser.isLoggedIn) {
    const brandType =
      getUser?.user?.role === "Super Admin"
        ? brandEnum.T
        : userBrand?.defautbrand;
    const brand =
      brandType === "Lexus"
        ? brandEnum.L
        : brandType === "Toyota"
        ? brandEnum.T
        : brandType === "Motor City"
        ? brandEnum.M
        : brandEnum.T;
    dispatch(setBrand(brand));
    return <Redirect to={DASHBOARD} />;
  } else
    return (
      <MainContainer>
        <MainDiv>
          <EmptyDiv></EmptyDiv>
          <LoginContainer>
            <BrandLogo />
            <InputDiv>
              <NavContainer>
                <InputLabelText>Email Address</InputLabelText>
                <InputTextField
                  id="email"
                  variant="outlined"
                  size="small"
                  onChange={handleInput}
                  error={Boolean(inputErrors?.emailError)}
                  helperText={inputErrors?.emailError}
                  onKeyPress={handleSubmitOnEnter}
                  autoFocus
                />
              </NavContainer>
              <NavContainer>
                <LabelDiv>
                  <InputLabelText>Password</InputLabelText>
                  {isShowPassword ? (
                    <ShowPasswordIcon onClick={togglePasswordVisibility} />
                  ) : (
                    <HidePasswordIcon onClick={togglePasswordVisibility} />
                  )}
                </LabelDiv>
                <InputTextField
                  id="password"
                  variant="outlined"
                  onChange={handleInput}
                  size="small"
                  type={isShowPassword ? "text" : "password"}
                  error={Boolean(inputErrors?.passwordError)}
                  helperText={inputErrors?.passwordError}
                  onKeyPress={handleSubmitOnEnter}
                />
              </NavContainer>
              <ForgotPassword to={FORGET_PASSWORD}>
                Forgot Password?
              </ForgotPassword>
              <br />
              <LoaderButton
                submitHandler={handleLogin}
                isLoading={isLoading}
                buttonText="Login"
                styles={LOGIN_RESET_PASS_BTN_STYLES}
              />
            </InputDiv>
          </LoginContainer>
          <EmptyDiv></EmptyDiv>
        </MainDiv>
      </MainContainer>
    );
}

export default Login;
