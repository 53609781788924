import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ServiceBookingInitialState from "slices/services/service-bookings/service-booking-initial-state";

import {
  getOngoingServiceBookingThunk,
  getUpcomingServiceBookingThunk,
  getPreviousServiceBookingThunk,
  ServiceBookingSearchThunk,
  getServiceBookingByFilter,
} from "slices/services/service-bookings/service-booking-thunks";
import ServiceBookingState from "./service-booking-interface";

export const ServiceBookingSlice = createSlice({
  name: "ServiceBookingSlice",
  initialState: ServiceBookingInitialState,
  reducers: {
    setCurrentTabReducer:setCurrentTabReducer,
    setCurrentPageReducer:setCurrentPageReducer 
  },
  extraReducers: {
    //set data
    [getOngoingServiceBookingThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.serviceBookingInfo.allServiceBookings =
        listEntityClass === undefined ? [] : listEntityClass;
      state.serviceBookingInfo.recordsLength = totalRecords;
    },

    [getOngoingServiceBookingThunk.rejected.type]: (state) => {
      state.serviceBookingInfo.allServiceBookings = [];
    },

    [getUpcomingServiceBookingThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.serviceBookingInfo.allServiceBookings =
        listEntityClass === undefined ? [] : listEntityClass;
      state.serviceBookingInfo.recordsLength = totalRecords;
    },

    [getUpcomingServiceBookingThunk.rejected.type]: (state) => {
      state.serviceBookingInfo.allServiceBookings = [];
    },

    [getPreviousServiceBookingThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.serviceBookingInfo.allServiceBookings =
        listEntityClass === undefined ? [] : listEntityClass;
      state.serviceBookingInfo.recordsLength = totalRecords;
    },

    [getPreviousServiceBookingThunk.rejected.type]: (state) => {
      state.serviceBookingInfo.allServiceBookings = [];
    },

    //search
    [ServiceBookingSearchThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.serviceBookingInfo.allServiceBookings =
        listEntityClass === undefined ? [] : listEntityClass;
      state.serviceBookingInfo.recordsLength = totalRecords;
    },

    //data by filter
    [getServiceBookingByFilter.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.serviceBookingInfo.allServiceBookings =
        listEntityClass === undefined ? [] : listEntityClass;
      state.serviceBookingInfo.recordsLength = totalRecords;
    },
  },
});


function setCurrentTabReducer(
  state: ServiceBookingState,
  action: PayloadAction<string>
) {
  state.serviceBookingInfo.currentTab = action.payload;
}


function setCurrentPageReducer(
  state: ServiceBookingState,
  action: PayloadAction<number>
) {
  state.serviceBookingInfo.currentPage = action.payload;
}