import styled from "styled-components";

import Autocomplete from "@material-ui/lab/Autocomplete";

import RemoveIcon from "shared-components/icons/remove-icon";


import { InputLabelText, InputTextField } from "pages/management/link-management/styles";
import { CircularProgress } from "@material-ui/core";

export const ParentDiv = styled.div`
  position: relative;
  margin: 1.3rem 0rem 0rem 0.8rem;
  min-height:70vh;
`;

export const LoadingComp = styled(CircularProgress)`
  position: absolute;
  top: 35%;
  left: 50%;
`;

export const AutocompleteSelect = styled(Autocomplete)`
  width: 100%;
`;

export const FlexDiv= styled.div`
  display:flex;
  margin-bottom: 2rem;
  align-items: center;
`;

export const LanguageText= styled(InputLabelText)`
  margin-right: 2rem;
  margin-bottom: 0;
`;

export const ButtonContainer = styled.div`
  margin:2rem 0 1rem;
`;

export const BodyParameters = styled.div`
  padding: 1.5rem;
  background: #f4f4f4;
  border-radius: 10px;
  width: 90%;
  margin-bottom: 1rem;
`;

export const TextBoxField = styled(InputTextField)`
  width: 100%;
`;

export const InputDiv = styled.div`
  display: grid;
  grid-template-columns: 44% 7% 44% 5%;
`;

export const RemoveIconLogo = styled(RemoveIcon)`
  cursor: pointer;
`;

export const HeadersContainer = styled.div`
  margin-bottom: 0.5rem;
`;
