import React, { ReactElement, useState } from "react";
import { withRouter } from "react-router";

import TabSelector from "shared-components/tab-selector";
import ArabicSwitchDropdown from "shared-components/arabic-input/arabic-switch-dropdown";

import LocationTable from "pages/location/components/location-table";
import { ArabicSwitch } from "pages/management/faqs/styles";

import BahrinFlag from "assets/bahrin-flag.png";
import UkFlag from "assets/uk-flag.png";

import {
  LocationContainer,
  LeftDiv,
  RightDiv,
  AddLocationButton,
  HeaderDiv,
  HeadingDiv,
} from "pages/location/styles";
import { LANGUAGE_FLAG_ARRAY } from "constants/style/props";



function Location({ history }): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLang, setSelectedLang] = useState(UkFlag);
  const [Language, setLanguage] = useState("English");
  const [tabValue, setTabValue] = useState<number | undefined>(undefined);

  const languageArr = LANGUAGE_FLAG_ARRAY

  const handleLanguage = (label) => {
    const newFlag = label === "English" ? UkFlag : BahrinFlag;
    setSelectedLang(newFlag);
    setAnchorEl(null);
    setLanguage(label);
  };

  const handleClick = () => {
    const elem = document.getElementById("flagDropdown");
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelTabActive = (tabNumber) => {
    setTabValue(tabNumber);
  };

  return (
    <LocationContainer>
      <HeaderDiv>
        <LeftDiv>
          <HeadingDiv>Locations</HeadingDiv>

          <ArabicSwitch>
            <ArabicSwitchDropdown
              selectedLang={selectedLang}
              handleClick={handleClick}
              anchorEl={anchorEl}
              languageArr={languageArr}
              handleClose={handleClose}
              handleLanguage={handleLanguage}
            />
          </ArabicSwitch>
        </LeftDiv>
        <RightDiv>
          <AddLocationButton
            variant="contained"
            color="primary"
            onClick={() => history.push("/add-location")}
          >
            Add Location
          </AddLocationButton>
        </RightDiv>
      </HeaderDiv>
      <TabSelector
        labelArray={["Service Centers", "Showrooms", "Parts Center"]}
        tabValue={tabValue as number}
        children={
          [
            <LocationTable
              filterBy={"Service Center"}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
            <LocationTable
              filterBy={"Showroom"}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
            <LocationTable
              filterBy={"Parts Center"}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
          ] as any
        }
      />
    </LocationContainer>
  );
}

export default withRouter(Location);
