import styled from "styled-components";
import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { TEXTFIELD_HEADING } from "constants/style/color";
import { InputLabel } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import { NewDealButton, SetFinishedButton } from "pages/best-deals/styles";


export const FaqsContainer = styled.div`
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

export const FieldContainer = styled.div`
  margin-top: 2%;
  margin-bottom: 2%;
`;
 export const FieldContainerEditor = styled(FieldContainer)`
  width:92%;
 `;

export const InputLabelText = styled(InputLabel)`
  margin-bottom: 0.625rem;
  font-size: ${SUBTEXT_FONT_SIZE} !important;
  color: ${TEXTFIELD_HEADING} !important;
`;

export const InputTextField = styled(TextField)`
  width: 92%;
`;

export const InputTextArea = styled(InputTextField)``;

export const SaveBtn = styled(NewDealButton)`
  padding: 8px 30px !important;
`;

export const CancelBtn = styled(SetFinishedButton)`
  padding: 7px 30px !important;
`;

export const TextWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const Small = styled.small`
  color: red;
  text-decoration: underline;
  cursor: pointer;
`;

export const CancelInput = styled(CancelIcon)`
  cursor: pointer;
  margin: 0.5rem 1.5rem;
  font-size: 1.375rem !important;
  color: ${({ theme }) => theme.color};
`;

export const FlexBoxArabic = styled.div`
  display: flex;
  /* align-items: center;
  width: 100%; */
  justify-content: space-between;
  width: 100%;
`;

export const InputArabicTextField = styled(InputTextField)`
  width: 95%;
`;

export const FlexBoxCon =styled.div`
  display:flex
`;

