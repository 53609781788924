import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector } from "redux-store/hooks";

import ParamterComponent from "pages/management/link-management/components/api-parameters";
import CustomTabSelector from "shared-components/custom-tab-selector";
import LoaderButton from "shared-components/loader-button";

import { useSnackbar } from "notistack";
import { loaderBtnStyleFunc } from "constants/style/props";

import {
  LinkManagementDetails,
  // parameters,
} from "interfaces/link-management.interface";
import { getLinks, updateLinks } from "services/links-services";

import { LoadingComp } from "pages/news/components/news-details/styles";
import {
  MainDiv,
  LeftDiv,
  RightDiv,
  HeaderDiv,
  CancelButton,
  Heading,
  ButtonContainer,
  InputFieldContainer,
  NavContainer,
  InputTextField,
  InputLabelText,
  SubHeading,
  RightContainer,
  LeftContainer,
  SocialMediaContainer,
  DiscoverMeContainer,
  DiscoverMeSubHeading,
} from "pages/management/link-management/styles";

const initialState: LinkManagementDetails = {
  discoverMeLink: "",
  socialMedia: {
    youtube: "",
    twitter: "",
    facebook: "",
    linkedIn: "",
    instagram: "",
  },
  apiManagement: {},
};
function LinkManagement(): ReactElement {
  const [linksState, setLinksState] =
    useState<LinkManagementDetails>(initialState);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  // const [errors, setErrors] = useState(initialErrors);
  const { discoverMeLink, socialMedia } = linksState;
  const [dummyState, setDummyState] = useState<LinkManagementDetails>();

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  useEffect(() => {
    getLinkDetails();
    // eslint-disable-next-line
  }, [brandName]);

  const getLinkDetails = async () => {
    setLoading(true);
    try {
      const result = await getLinks();
      setLinksState({ ...result?.data?.data?.data });
      setDummyState(
        JSON.parse(JSON.stringify({ ...result?.data?.data?.data }))
      );
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.status, { variant: "error" });
      setLoading(false);
    }
  };

  const updateLinkDetails = async () => {
    setBtnLoading(true);
    try {
      const result = await updateLinks({ data: linksState });
      if (result?.data?.response) {
        enqueueSnackbar(result.data.response, {
          variant: "success",
        });
        getLinkDetails();
      }
      setBtnLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
      setBtnLoading(false);
    }
  };

  const handleIsLinkValid = (e) => {
    const { value, id } = e.target;
    setLinksState({
      ...linksState,
      [id]: value,
    });
  };

  const handleMediaLink = (e, text) => {
    const { value, id } = e.target;

    setLinksState({
      ...linksState,
      socialMedia: {
        ...socialMedia,
        [id]: value,
      },
    });
  };


  const submitHandler = () => {
    updateLinkDetails();
  };

  const handleClose = () => {
    setLinksState(dummyState as LinkManagementDetails);
    // setErrors({ ...initialErrors });
  };

  return (
    <MainDiv>
      {loading ? (
        <LoadingComp color="primary" />
      ) : (
        <>
          <HeaderDiv>
            <LeftDiv>
              <Heading>Link Management</Heading>
            </LeftDiv>
            <RightDiv></RightDiv>
          </HeaderDiv>
          <InputFieldContainer>
            <NavContainer>
              <DiscoverMeSubHeading>Discovery Me Link</DiscoverMeSubHeading>
              <DiscoverMeContainer>
                <InputTextField
                  id="discoverMeLink"
                  variant="outlined"
                  size="small"
                  onChange={handleIsLinkValid}
                  value={discoverMeLink}
                  // error={Boolean(errors.discoverMeLinkError)}
                  // helperText={errors.discoverMeLinkError}
                />
              </DiscoverMeContainer>
            </NavContainer>
            <SubHeading>Social Media</SubHeading>
            <SocialMediaContainer>
              <LeftContainer>
                <NavContainer>
                  <InputLabelText>Facebook Link</InputLabelText>
                  <InputTextField
                    id="facebook"
                    variant="outlined"
                    size="small"
                    value={socialMedia?.facebook}
                    onChange={(e) => handleMediaLink(e, "FaceBook")}
                    // error={Boolean(errors.facebookError)}
                    // helperText={errors.facebookError}
                  />
                </NavContainer>
                <NavContainer>
                  <InputLabelText>Instagram Link</InputLabelText>
                  <InputTextField
                    id="instagram"
                    variant="outlined"
                    size="small"
                    value={socialMedia?.instagram}
                    onChange={(e) => handleMediaLink(e, "Instagram")}
                    // error={Boolean(errors.instagramError)}
                    // helperText={errors.instagramError}
                  />
                </NavContainer>
                <NavContainer>
                  <InputLabelText>Twitter</InputLabelText>
                  <InputTextField
                    id="twitter"
                    variant="outlined"
                    size="small"
                    value={socialMedia?.twitter}
                    onChange={(e) => handleMediaLink(e, "Twitter")}
                    // error={Boolean(errors.twitterError)}
                    // helperText={errors.twitterError}
                  />
                </NavContainer>
              </LeftContainer>
              <RightContainer>
                <NavContainer>
                  <InputLabelText>LinkedIn</InputLabelText>
                  <InputTextField
                    id="linkedIn"
                    variant="outlined"
                    size="small"
                    value={socialMedia?.linkedIn}
                    onChange={(e) => handleMediaLink(e, "LinkedIn")}
                    // error={Boolean(errors.linkedInError)}
                    // helperText={errors.linkedInError}
                  />
                </NavContainer>
                <NavContainer>
                  <InputLabelText>Youtube</InputLabelText>
                  <InputTextField
                    id="youtube"
                    variant="outlined"
                    size="small"
                    value={socialMedia?.youtube}
                    onChange={(e) => handleMediaLink(e, "Youtube")}
                    // error={Boolean(errors.youtubeError)}
                    // helperText={errors.youtubeError}
                  />
                </NavContainer>
              </RightContainer>
            </SocialMediaContainer>
            <ButtonContainer>
              <LoaderButton
                isLoading={btnLoading}
                buttonText="Save"
                styles={loaderBtnStyleFunc("6.8rem", "2.8rem")}
                submitHandler={submitHandler}
              />
              <CancelButton
                variant="contained"
                onClick={handleClose}
                color="default"
              >
                Cancel
              </CancelButton>
            </ButtonContainer>
            <hr></hr>
            <SubHeading>API Management</SubHeading>
            <CustomTabSelector
              labelArray={[
                "Latest Cars",
                "Latest Cars Details",
                "News",
                "News Details",
              ]}
              children={
                [
                  <ParamterComponent type={"latest_cars"} />,
                  <ParamterComponent type={"latest_cars_details"} />,
                  <ParamterComponent type={"news"} />,
                  <ParamterComponent type={"news_details"} />,
                ] as any
              }
            />
          </InputFieldContainer>
        </>
      )}
    </MainDiv>
  );
}

export default LinkManagement;
