import axios from "axios";

import {
  BASE_URL,
  TEST_DRIVE_FILTERED_DATA,
  TEST_DRIVE_SEARCH_ENDPOINT,
  GET_TEST_DRIVE_DETAILS_ENDPOINT,
} from "constants/api-config-constants";
import selectedBrand from "constants/brand";
import { limit } from "constants/pagination-constants";

import {
  TestDriveResponse,
  GetTestDriveRequest,
  TestDriveFilterInterface,
  TestDriveSearchRequest,
} from "interfaces/test-drive.interface";

export const getTestDriveDetails = async (
  request: GetTestDriveRequest
): Promise<TestDriveResponse> => {
  const { pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_TEST_DRIVE_DETAILS_ENDPOINT +
    "?brand=" +
    selectedBrand()+
    "&pageno=" +
    pageNumber +
    "&limit=" +
    limit;
  return axios.get(url);
};


export const testDriveSearch = async (
  request: TestDriveSearchRequest
): Promise<TestDriveResponse> => {
  const {pageNumber,searchText } = request;
  const url =
    BASE_URL +
    TEST_DRIVE_SEARCH_ENDPOINT +
    "?brand=" +
    selectedBrand()+
    "&pageno=" +
    pageNumber +
    "&limit=" +
    limit
  return axios.post(url, searchText, {
    headers: { "Content-Type": "text/plain" },
  });
};

export const testDriveFilter = async (
  request: TestDriveFilterInterface
): Promise<TestDriveResponse> => {
  const {startDate, endDate, pageNumber,limit} = request
  const url =
    BASE_URL +
    TEST_DRIVE_FILTERED_DATA +
    "?pageno=" +
    pageNumber +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand()+
    "&fromDate="+
    startDate+
    "&toDate="+
    endDate;
  return axios.get(url);
};
