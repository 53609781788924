import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { XAxis, YAxis } from "recharts";

import {
  PRIMARY_REGULAR_FONT,
  PRIMARY_MEDIUM_FONT,
} from "constants/style/fonts";

import {
  LineGraph,
  AppUsageContainer,
  WidgetName,
  WidgetMessage,
} from "pages/dashboard/components/customers-left-widgets/app-usage-chart/styles";

interface Props {
  chartColors: Array<string>;
}

function AppUsage({ chartColors }: Props): ReactElement {
  const [graphData, setGraphData] = useState<Array<number>>([]);

  const dashboardDetails = useAppSelector((state: RootState) => {
    return state.dashboardDetails.dashboardInfo;
  });

  useEffect(() => {
    setGraphData(
      dashboardDetails?.dashboardDetails?.ownersVSGuestUsers?.data as []
    );
  }, [dashboardDetails?.dashboardDetails?.ownersVSGuestUsers?.data]);

  const data = {
    labels: dashboardDetails?.dashboardDetails?.appUsage?.labels,
    datasets: [
      {
        // label: "My First dataset",
        fill: false,
        lineTension: 0.2,
        backgroundColor: chartColors[0],
        borderColor: chartColors[0],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: chartColors[0],
        pointBackgroundColor: chartColors[0],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: chartColors[0],
        pointHoverBorderColor: chartColors[0],
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 10,
        data: dashboardDetails?.dashboardDetails?.appUsage?.data,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
        labels: {
          Font: PRIMARY_REGULAR_FONT,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Weeks",
          font: {
            size: 14,
            family: PRIMARY_MEDIUM_FONT,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Users",
          font: {
            size: 14,
            family: PRIMARY_MEDIUM_FONT,
          },
        },
      },
    },
  };
  const checkValue = () => {
    const isBelowThreshold = (currentValue) => currentValue === 0;
    return graphData?.every(isBelowThreshold);
  };
  return (
    <>
      <AppUsageContainer>
        <WidgetName>App Usage</WidgetName>
        {!checkValue() ? (
          <LineGraph type="line" data={data} height={280} options={options}>
            <XAxis dataKey="time" />
            <YAxis yAxisId={2} label={{ value: "Temp", angle: -90 }} />
          </LineGraph>
        ) : (
          <WidgetMessage>Data is Empty</WidgetMessage>
        )}
      </AppUsageContainer>
    </>
  );
}

export default AppUsage;
