import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";

export const SelectedLangDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const FlagImage = styled.img``;

export const ExpandDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const LanguageList = styled.div``;

export const MenuListItem = styled(MenuItem)`
  padding: 4px 10px !important;
`;

export const LabelSpan = styled.span`
  margin-left: 0.625rem;
`;
