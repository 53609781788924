export const FAQ_QUICK_ACCESS_BLOCK =
  "bold italic | quicklink h1 h2 h3 blockquote quickimage quicktable";

export const FULL_TOOLBAR =
  "fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor|" +
  "image media emoticons link blockquote| alignleft aligncenter " +
  "alignright alignjustify |bullist numlist outdent indent print preview| " +
  "| ltr rtl |";

export const ALL_PLUGINS = [
  "lists advlist link image imagetools charmap print preview anchor emoticons",
  "searchreplace visualblocks code fullscreen media print preview",
  "insertdatetime media table paste code wordcount directionality quickbars",
];
export const QUICK_INSERT_TOOLBOX = 'quickimage quicktable'

export const QUICK_INSERT_TOOLBOX_DECS = 'quickimage'

export const FONT_LIST =
  "Montserrat Regular=montserrat regular,Montserrat Medium=Montserrat Medium,Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats";

export const TOOLBAR_FOR_DESCRIPTION =  "fontselect | fontsizeselect | formatselect |  forecolor backcolor|" +
" image media emoticons blockquote| alignleft aligncenter " +
"|bullist numlist outdent indent print preview| " +
"| ltr rtl |"

export const QUICK_DESC_BLOCK ="bold italic underline quicklink h1 h2 h3 quickimage alignleft aligncenter alignright| ";

