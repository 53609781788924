import { createSlice } from "@reduxjs/toolkit";
import userInitialState from "./user-initial-state";

import { authenticate } from "slices/user-auth-flow/user-thunks";

export const UserSlice = createSlice({
  name: "UserSlice",
  initialState: userInitialState,
  reducers: {},
  extraReducers: {
    [authenticate.fulfilled.type]: (state, action) => {
      const { data, responseBody, status, code } = action.payload;
      // console.log(action.payload, "payload");
      state.userInfo.user = data;
      state.userInfo.message = status;
      state.userInfo.statusCode = code;
      state.userInfo.jwt.access_token = responseBody.access_token;
      state.userInfo.jwt.expires_in = responseBody.expires_in;
      state.userInfo.isLoggedIn = true;
    },
  },
});
