import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { InputLabel, Button, Box, Select } from "@material-ui/core";
import { Heading, SubHeading } from "pages/customers/styles";
import RemoveIcon from "shared-components/icons/remove-icon";
import CancelIcon from "@material-ui/icons/Cancel";
import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { TEXTFIELD_HEADING } from "constants/style/color";
import MuiPhoneNumber from "material-ui-phone-number";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

export const LocationContainer = styled.div`
  padding: 1.5rem 2rem;
`;

export const HeadingDiv = styled(Heading)``;

export const LeftDiv = styled.div`
  display: flex;
`;

export const RightDiv = styled.div`
  width: 25%;
`;

export const SubHeadingLocation = styled(SubHeading)``;

export const FieldContainer = styled.div`
  margin-top: 2%;
  margin-bottom: 6%;
`;

export const FlexBoxContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 0.3rem 0.1rem;
`;

export const AddIconLogo = styled(AddCircleOutlineOutlinedIcon)`
  margin: 0.5rem 1.45rem;
  cursor: pointer;
  opacity:0.7
`;

export const AddIconLogoEdit = styled(AddIconLogo)`
  margin: 0 0.1rem;
`;

export const RemoveIconLogo = styled(RemoveIcon)`
  margin: 0.5rem 1.7rem;
  cursor: pointer;
  z-index: 5;
`;

export const InputTextField = styled(TextField)`
  width: 73%;
`;

export const FlexChildInput = styled(InputTextField)`
  width: 80%;
`;

export const FaxNumberInput = styled(MuiPhoneNumber)`
  width: 80%;
`;

export const PhoneNumberInput = styled(MuiPhoneNumber)`
  width: 80%;
`;

export const InputLabelText = styled(InputLabel)`
  margin-bottom: 0.625rem;
  font-size: ${SUBTEXT_FONT_SIZE} !important;
  color: ${TEXTFIELD_HEADING} !important;
`;

export const InputTextArea = styled(InputTextField)`
  height: 30%;
`;

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubmitButton = styled(Button)`
  width: 6.25rem;
  height: 2.5rem;
  text-transform: none !important;
  font-weight: 600 !important;
`;

export const CancelButton = styled(Button)`
  width: 6.25rem;
  height: 2.8rem;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 1rem !important;
  font-weight: 600 !important;
`;

export const ParentContainer = styled(Box)`
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  margin: 1rem;
`;

export const FormLeftSide = styled.div`
  width: 40%;
  float: left;
  margin: 0.625rem 0rem 0 1.25rem;
  padding: 0rem 0.5rem 0rem 1rem;
`;

export const FormRightSide = styled(FormLeftSide)`
  width: 46%;
  height: 100%;
`;

export const CoordinateBox = styled.div`
  display: flex;
`;

export const CoordinateInputLabel = styled(InputLabelText)`
  margin-bottom: 0;
  margin-top: 0.8rem !important;
`;

export const CoordinateInputField = styled(InputTextField)`
  width: 29%;
  margin: 0 0.625rem !important;
`;

export const DropdownSelector = styled(Select)`
  height: 2.3rem;
  width: 73%;
  border-radius: 3px !important;
  font-size: 14px !important;
`;

export const TimingDropdown = styled(DropdownSelector)`
  width: 34%;
  margin: 0 0.625rem;
`;


export const ChildImageDiv = styled.div`
  height: 18vh;
  width: 80%;
  margin-right: 2.2rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  background-color: grey !important;
  position: relative;
  cursor: pointer;

  /* &:hover {
    color: white;
  } */
`;

export const RemoveImageLogo = styled(CancelIcon)`
  position: absolute;
  top: -10%;
  right: -6%;
  color: ${({ theme }) => theme.color};
  z-index:2;
  background: white;
  border-radius:1rem;
`;

export const ChildImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;

  &:hover {
    opacity: 0.5;
  }
`;

export const ImageFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const AddImageBox = styled.label`
  height: 18vh;
  width: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' strokeWidth='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' strokeLinecap='square'/%3e%3c/svg%3e");
  margin-right: 2.2rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LabelFlex = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin: 0.2rem 0;
`;
export const ImageInput = styled.input`
  display: none;
`;

export const TextWrapper = styled.div``;

export const Small = styled.small`
  color: red;
  text-decoration: underline;
  cursor: pointer;
`;

export const CancelInput = styled(CancelIcon)`
  cursor: pointer;
  margin: 0.5rem 1.5rem;
  font-size: 1.375rem !important;
  color: ${({ theme }) => theme.color};
`;

export const FlexBoxArabic = styled(FlexBoxContainer)`
  width: 100%;
`;

export const CancelInputTextArea = styled(CancelInput)`
  margin: 1.5rem 1.5rem;
`;

export const ImgWholeContainer = styled.div`
  min-height: 25vh;
  margin:0 1rem 0 3rem;
  flex:0.5;
`;

export const ImgWholeContainerDeals = styled.div`
  min-height: 25vh;
  flex:0.5;
`;

export const ImgWholeContainerTwo = styled(ImgWholeContainerDeals)`
  flex:0.5;
`;



export const AddImageBoxTwo = styled(AddImageBox)`
  width:85%;
  margin-right: 1rem;
`;

export const ChildImageDivTwo = styled(ChildImageDiv)`
  width:85%;
  margin: 1.5rem 1rem 0.5rem 0;
`;


export const ChildImageContainer = styled(ChildImageDiv)`
  width:20%
`;

export const AddImageContainer = styled(AddImageBox)`
  width:20%
`;
