import {
  PRIMARY_TOYOTA,
  SECONDARY_TOYOTA,
  SECONDARY_MOTORCITY,
  PRIMARY_MOTORCITY,
  BLACK_COLOR as PRIMARY_LEXUS,
  SECONDARY_LEXUS,
} from "constants/style/color";

export const lexusTheme = {
  color: PRIMARY_LEXUS,
  background: SECONDARY_LEXUS,
};

export const motorCityTheme = {
  color: PRIMARY_MOTORCITY,
  background: SECONDARY_MOTORCITY,
};

export const toyotaTheme = {
  color: PRIMARY_TOYOTA,
  background: SECONDARY_TOYOTA,
};
