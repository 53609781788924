import React, { ReactElement } from "react";

interface Props {
  color: string;
}

function ExportArrowIcon({ color }: Props): ReactElement {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 9.333v5A1.666 1.666 0 0111.833 16H2.667A1.667 1.667 0 011 14.333V5.167A1.667 1.667 0 012.667 3.5h5M11 1h5v5M6.833 10.167L16 1"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ExportArrowIcon;
