import axios from "axios";

import {
  BASE_URL,
  LOGIN_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  RESET_PASSWORD_LINK_ENDPOINT,
} from "constants/api-config-constants";

import {
  LoginRequest,
  ResetPasswordRequest,
  LoginResponse,
  ResetPasswordResponse,
} from "interfaces/login.interface";

export const UserLogin = async (
  loginObj: LoginRequest
): Promise<LoginResponse> => {
  const url = BASE_URL + LOGIN_ENDPOINT;
  return axios.post(url, loginObj);
};

export const resetPasswordLink = async (
  email: string
): Promise<ResetPasswordResponse> => {
  const url = BASE_URL + RESET_PASSWORD_LINK_ENDPOINT;
  return await axios.post(url, { email: email });
};

export const resetPassword = async (
  request: ResetPasswordRequest
): Promise<ResetPasswordResponse> => {
  const { password, confirmPassword, id } = request;
  const url = BASE_URL + RESET_PASSWORD_ENDPOINT + "?id=" + encodeURIComponent(id);

  return await axios.put(url, { password, confirmPassword });
};

export const isResetPasswordValid = async (
  token: string
): Promise<ResetPasswordResponse> => {
  const url = BASE_URL + RESET_PASSWORD_ENDPOINT + "?id=" + token;
  return await axios.get(url);
};
