import React, { ReactElement } from "react";

import { loaderBtnStyleFunc } from "constants/style/props";

import LoaderButton from "shared-components/loader-button";

import {
  DeleteUserContainer,
  BtnContainer,
  CancelButton,
  HeaderDiv,
  SpanDiv,
  ConfirmDiv,
} from "./styles";

export default function DeleteUser(props): ReactElement {
  const {
    confirmDelete,
    closeModal,
    params,
    loading,
    headerText,
    message,
    paraText,
  } = props;

  return (
    <DeleteUserContainer>
      <ConfirmDiv>
        <HeaderDiv>{headerText}</HeaderDiv>
        <h4>{message}</h4>
        <SpanDiv>{paraText}</SpanDiv>
      </ConfirmDiv>

      <BtnContainer>
        <CancelButton variant="contained" color="default" onClick={closeModal}>
          No
        </CancelButton>
        <LoaderButton
          submitHandler={() => confirmDelete(params)}
          isLoading={loading}
          buttonText="Yes"
          styles={loaderBtnStyleFunc("6.25rem", "2.8rem")}
        />
      </BtnContainer>
    </DeleteUserContainer>
  );
}
