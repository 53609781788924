import styled from "styled-components";
import { Paper, Popper } from "@material-ui/core";

export const Wrapper = styled.div``;

export const DivWrap = styled.div`
    display: block;
    position: absolute;
    top: 0;
`;

export const CellWrap = styled.div``;

export const CellDivs = styled.div`
    padding: 0.625rem;
    font-size: 0.875rem;
`;

export const HoverPaper = styled(Paper)`
    border: 1px solid black;
    text-align: center;
`;

export const CustomPopper = styled(Popper)`
    margin-left: -1.1rem;
`;

