import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import { useSnackbar } from "notistack";

import { RenderCellExpand } from "shared-components/expanded-cel";
import PaginationFooter from "shared-components/pagination";
import TableData from "shared-components/table-data";

// import { DeletePopupModal } from "pages/best-deals/components/deals-table/styles";
// import DeleteUser from "pages/user-profile/components/delete-user";

import { limit } from "constants/pagination-constants";
import { loadingHelper } from "constants/loadingHelper";
// import { EDIT_NOTIFICATION } from "constants/route/route-constants";

import {
  GridCellParams,
  GridColDef,
  GridRowModel,
} from "@material-ui/data-grid";


// import {
//   EditIconLogo,
//   RemoveIconLogo,
// } from "pages/user-profile/components/user-management/styles";

import { setCurrentPageReducer } from "slices/notifications";
import {
  // deleteNotificationThunk,
  getAllNotificationsThunk,
} from "slices/notifications";

interface Props {
  selectedLang: string;
}

const NotificationTable = ({selectedLang}:Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  // const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const [selectedDeleteID, setSelectedDeletedId] = useState<number>(0);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [rows, setRows] = useState<GridRowModel>([]);
  
  const notifications = useAppSelector((state) => state.notifications.notificationsInfo);
  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  useEffect(() => {
      loadingHelper(
        dispatch(getAllNotificationsThunk({ pageno: 1, limit: limit})),
        setLoading,
        enqueueSnackbar
      );
      dispatch(setCurrentPageReducer(1));
          
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, brandName]);

  useEffect(() => {
    setRows(notifications?.allNotifications);
    setPaginationSize(Math.ceil(notifications?.recordsLength / limit));
  }, [notifications]);

  // const openDeleteDealModal = (id) => {
  //   setOpenDeleteModal(true);
  //   setSelectedDeletedId(id);
  // };

  // const closeDeleteModal = () => {
  //   setOpenDeleteModal(false);
  // };

  // const confirmDelete = async (id) => {
  //   try {
  //     setLoading(true);
  //       const result = await dispatch(deleteFaqThunk(id)).unwrap();
  //       enqueueSnackbar(result?.response?.data, {
  //         variant: "success",
  //       });
  //     setLoading(false);
  //     setOpenDeleteModal(false);
  //   } catch (error) {
  //     setLoading(false);
  //       enqueueSnackbar(error?.data, { variant: "error" });
  //     setOpenDeleteModal(false);
  //   }
  // };

  const handlePagination = (event, number) => {
      // dispatch(getAllNotificationsThunk({ pageno: number, limit: limit}));
      loadingHelper(
        dispatch(
          getAllNotificationsThunk({
            pageno: number,
            limit:limit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
    dispatch(setCurrentPageReducer(number)); //
  };
const handelDateFormat = (timedate) => {
  const day = new Date(timedate);
  const time = day.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  var dateTime =
    day.getDate() +
    "-" +
    (day.getMonth() + 1) +
    "-" +
    day.getFullYear() +
    " " +
    time;

  return dateTime;
};
  const columns: GridColDef[] = [
    {
      field: "createdOn",
      headerName: "Date",
      sortable: false,
      flex: 0.3,
      renderCell: (params: GridCellParams) => {
        const { createdOn } = params.row;
        return  handelDateFormat(createdOn) ;
      },
    },
    {
      field: "role",
      headerName: "User Type",
      sortable: false,
      resizable: false,
      flex: 0.3,
      renderCell:  RenderCellExpand
    },
    {
      field: `${selectedLang === "English" ? "title" : "titleArabic"}`,
      sortable: false,
      headerName: "Header Text Key",
      resizable: false,
      flex: 0.5,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "notificationTitle", 400, selectedLang);
      },
    },
    {
      field: `${selectedLang === "English" ? "body" : "bodyArabic"}`,
      headerName: "Sub Text key",
      sortable: false,
      resizable: false,
      flex: 0.6,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "notificationBody", 400, selectedLang);
      },
    },
    // {
    //   field: "notificationType",
    //   sortable: false,
    //   headerName: "Notification Type",
    //   resizable: false,
    //   flex: 0.5,
    //   renderCell: (params: GridCellParams) => {
    //     return <>{params.row.notificationType}</>;
    //   },
    // },
    // {
    //   field: "id",
    //   sortable: false,
    //   headerName: "Actions",
    //   resizable: false,
    //   flex: 0.3,
    //   renderCell: (params: GridCellParams) => {
    //     const rowData = params.row;
    //     return (
    //       <>
    //         <EditIconLogo
    //           onClick={() =>
    //             history.push({
    //               pathname: `${EDIT_NOTIFICATION}`,
    //               state: {
    //                 detail: "edit",
    //                 rowId: rowData?.id,
    //                 rowData: rowData,
    //               },
    //             })
    //           }
    //         />
    //         <RemoveIconLogo onClick={() => openDeleteDealModal(rowData.id)} />
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <TableData
        rows={loading ? [] : (rows as GridRowModel[])}
        isLoading={loading}
        columns={columns}
        isCheckBox={false}
        pageSize={limit}
        isPagination={true}
      />
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={notifications?.currentPage}
        />
      ) : null}

      {/* <DeletePopupModal open={openDeleteModal} onClose={closeDeleteModal}>
        <DeleteUser
          params={selectedDeleteID}
          closeModal={closeDeleteModal}
          confirmDelete={confirmDelete}
          loading={loading}
          headerText={"Delete Notification"}
          paraText={"Are you sure you want to delete this Notification?"}
          message={"All data related to this Notification will be deleted."}
        ></DeleteUser>
      </DeletePopupModal> */}
    </>
  );
};

export default NotificationTable;
