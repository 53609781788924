import { UserProfileSlice } from "./user-profile-slice";
export {
  getUserBasicInfoThunk,
  getAdminsInfoThunk,
  addAdminInfoThunk,
  updateAdminInfoThunk,
  deleteAdminInfoThunk,
} from "slices/user-profile/user-profile-thunks";

export default UserProfileSlice.reducer;

export const { updateUserProfileReducer} = UserProfileSlice.actions;
