import UserState from "slices/user-auth-flow/user-slice.interface";

const userInitialState: UserState = {
  userInfo: {
    user: {
      id: 0,
      firstName: "",
      lastName: "",
      role: "",
      active: false,
      brand: "",
      listofBrands: [],
      defautbrand: "",
      moduleList: [],
    },
    jwt: {
      access_token: "",
      expires_in: "",
    },
    isLoggedIn: false,
    message: "",
    statusCode: 0,
    isLoading: false,
  },
};

export default userInitialState;
