import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import { CustomerContainer, Heading, LeftDiv } from "pages/customers/styles";
import { HeaderDiv } from "pages/dashboard/styles";

import { loadingHelper } from "constants/loadingHelper";
import { NEWS } from "constants/route/route-constants";
import { useSnackbar } from "notistack";

import { getNewsByIdThunk } from "slices/news";
import { NewsDetails } from "interfaces/news.interface";

import {
  NewsContainer,
  Content,
  HeadingComp,
  ParaContent,
  LoadingComp,
  ImageDiv,
  HeadingDiv,
  HeadingContent,
  HeadingContainer,
  BodyContainer,
  BackIconLogo,
  NewImageDiv,
} from "./styles";
import ToyotaPlaceholder from "shared-components/icons/toyota-placeh";
import selectedBrand from "constants/brand";
import LexusPlaceholder from "shared-components/icons/lexus-placeh";
import MotorcityPlaceholder from "shared-components/icons/motorcity-placeh";

const imageObj = {
  Toyota: <ToyotaPlaceholder />,
  Lexus: <LexusPlaceholder />,
  motorcity: <MotorcityPlaceholder />,
};

function NewsDetail(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory<any>();
  const location = useLocation<any>();
  const ref = useRef<any>();

  const [newsData, setNewsData] = useState<NewsDetails>({});
  const [loading, setLoading] = useState(false);

  // for redirection only when edit screen ( brand switches )
  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });
  const news = useAppSelector((state) => state.news.newsInfo);

  useEffect(() => {
    if (location?.state?.detail === "view") {
      if (ref.current !== undefined && ref.current !== brandName) {
        return history.push(NEWS);
      }
      ref.current = brandName;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName, history]);

  useEffect(() => {
    loadingHelper(
      dispatch(getNewsByIdThunk({ id: location?.state?.id })),
      setLoading,
      enqueueSnackbar
    );
    // eslint-disable-next-line
  }, [dispatch, location?.state?.id]);

  useEffect(() => {
    setNewsData(news?.currentNews);
  }, [news]);

  const { title, contentText, createdDateAndTime, image } = newsData;

  useEffect(() => {
    let data = document.getElementsByTagName("a");
    for (let i = 0; i < data.length; i++) {
      data[i].target = "_blank";
    }
  }, [newsData]);

  const handelBack = () => {
    history.push({
      pathname: `${NEWS}`,
      state: { from: "Back" },
    });
  };

  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <BackIconLogo onClick={handelBack} />
          <Heading>News Detail Page</Heading>
        </LeftDiv>
      </HeaderDiv>

      <NewsContainer>
        {loading ? (
          <LoadingComp color="primary" />
        ) : (
          <>
            <HeadingContainer>
              {Boolean(image) ? (
                <ImageDiv
                  dangerouslySetInnerHTML={{
                    __html: image?.includes("<img")
                      ? (image as string)
                      : `<img src="${image}" alt="${title}"/>`,
                  }}
                ></ImageDiv>
              ) : (
                <NewImageDiv>{imageObj[selectedBrand()]}</NewImageDiv>
              )}
              {/* <HeadingComp>Title</HeadingComp> */}
              {Boolean(image) && <HeadingDiv>
                <HeadingContent>{title}</HeadingContent>
              </HeadingDiv>}
            </HeadingContainer>
            <BodyContainer>
              <Content>
                <HeadingComp>Content</HeadingComp>
                <ParaContent
                  dangerouslySetInnerHTML={{
                    __html:
                      "contentTextTeaser" in newsData
                        ? (newsData["contentTextTeaser"] as string)
                        : (contentText as string),
                  }}
                ></ParaContent>
              </Content>
              <Content>
                <HeadingComp>Published Date</HeadingComp>
                <ParaContent>{createdDateAndTime}</ParaContent>
              </Content>
            </BodyContainer>
          </>
        )}
      </NewsContainer>
    </CustomerContainer>
  );
}

export default NewsDetail;
