import React, { ReactElement, useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "redux-store/hooks";

import InputAdornment from "@material-ui/core/InputAdornment";
import { useSnackbar } from "notistack";

import CustomTabSelector from "shared-components/custom-tab-selector";
import CheckboxContainer from "shared-components/checkbox-container";
import CustomPopover from "shared-components/custom-filter-popup";
import TabSelector from "shared-components/tab-selector";
import DateRange from "shared-components/date-range";

import { getFilterParameters } from "services/service-booking-services";

import { RelativeDiv } from "pages/services/roadside-assistance/styles";
import { RemoveFilter } from "pages/services/test-drive-bookings/styles";

import ServiceBookingTable from "pages/services/service-bookings/components/service-bookings-table";

import {
  CustomerContainer,
  Heading,
  ExportToExcel,
  FilterDiv,
  InputTextField,
  Search,
  LeftDiv,
  RightDiv,
  FilterIcon,
  ExportIcon,
  SubHeading,
  FilterHeading,
  HeaderDiv,
} from "pages/services/service-bookings/styles";

import {
  ServiceBookingSearchThunk,
  getOngoingServiceBookingThunk,
  getUpcomingServiceBookingThunk,
  getPreviousServiceBookingThunk,
  getServiceBookingByFilter,
  setCurrentPageReducer,
} from "slices/services/service-bookings";

import {
  DropdownDiv,
  LimitDiv,
  SpanTag,
} from "pages/customers/components/customers-table/styles";
import { DropdownSelector } from "pages/best-deals/components/new-deal/styles";
import { DROPDOWN_MENU_PROPS, limitArr } from "constants/style/props";
import { TabSelectorContainer } from "pages/customers/styles";
import { MenuItem } from "@material-ui/core";

interface filterInterface {
  id: number;
  label: string;
  status: boolean;
  type?: string;
}

const filterInitState = {
  startDate: "",
  endDate: "",
  center: "",
  serviceType: "",
};

function Customer(): ReactElement {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [filterClear, setFilterClear] = useState<boolean>(false);
  const [exportData, setExportData] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabValue, setTabValue] = useState<number | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>("");
  const [recordLimit, setRecordLimit] = useState<number>(limitArr[0]);
  const [serviceTypes, setServiceTypes] = useState<Array<filterInterface>>([]);
  const [serviceCenters, setServiceCenters] = useState<Array<filterInterface>>(
    []
  );
  const [filterState, setFilterState] = useState(
    JSON.parse(JSON.stringify(filterInitState))
  );

  const [dummyFilterState] = useState({
    startDate: "",
    endDate: "",
    center: "",
    serviceType: "",
  });

  const { startDate, endDate } = filterState;

  const serviceBookings = useAppSelector(
    (state) => state.serviceBookings.serviceBookingInfo
  );
  const brandColor = useAppSelector((state) => state.brand.brandColor);
  const brandName = useAppSelector((state) => {
  return state.brand.brandType;
});
  useEffect(() => {
    getFilterParamsApi();
    // eslint-disable-next-line
  }, [brandName]);

  useEffect(() => {
    if (JSON.stringify(filterState) !== JSON.stringify(dummyFilterState)) {
      setFilterClear(true);
      filterFunc(1, serviceBookings?.currentTab);
      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line
  }, [filterState]);

  const filterFunc = (pageNumber, currentTab) => {
    dispatch(
      getServiceBookingByFilter({
        ...filterState,
        pageNumber: pageNumber,
        serviceStatus: currentTab,
        limit: recordLimit,
      })
    )
      .unwrap()
      .then((res) => {
        if ("data" in res.data) {
          enqueueSnackbar(res.data.data, { variant: "warning" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: "error" });
      });
  };

  const handleClearFilter = () => {
    if (searchValue !== "") {
      dispatch(
        ServiceBookingSearchThunk({
          pageNumber: 1,
          serviceStatus: serviceBookings.currentTab,
          searchText: searchValue,
          limit: recordLimit,
        })
      );
    } else {
      if (serviceBookings?.currentTab === "On-Going") {
        ApiCallHelper(
          getOngoingServiceBookingThunk,
          serviceBookings?.currentPage,
          recordLimit
        );
      } else if (serviceBookings?.currentTab === "Upcoming") {
        ApiCallHelper(
          getUpcomingServiceBookingThunk,
          serviceBookings?.currentPage,
          recordLimit
        );
      } else {
        ApiCallHelper(
          getPreviousServiceBookingThunk,
          serviceBookings?.currentPage,
          recordLimit
        );
      }
    }

    setFilterClear(false);
    setFilterState(JSON.parse(JSON.stringify(filterInitState)));
    getFilterParamsApi();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handelExport = () => {
    setExportData((prevExportData) => !prevExportData);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTabValue(undefined);
  };

  const handelTabActive = (tabNumber) => {
    setTabValue(tabNumber);
  };

  const ApiCallHelper = (thunk, pageNumber, limit) => {
    dispatch(thunk({ pageNumber: pageNumber, limit: limit }));
  };

  const handleSearchDispatch = async (event) => {
    if (event.key === "Enter") {
      try {
        if (searchValue !== "") {
          await dispatch(
            ServiceBookingSearchThunk({
              pageNumber: 1,
              serviceStatus: serviceBookings.currentTab,
              searchText: searchValue,
              limit: recordLimit,
            })
          );
        } else {
          if (filterClear) {
            filterFunc(1, serviceBookings.currentTab);
          } else {
            if (serviceBookings.currentTab === "On-Going") {
              ApiCallHelper(
                getOngoingServiceBookingThunk,
                serviceBookings.currentPage,
                recordLimit
              );
            } else if (serviceBookings.currentTab === "Upcoming") {
              ApiCallHelper(
                getUpcomingServiceBookingThunk,
                serviceBookings.currentPage,
                recordLimit
              );
            } else {
              ApiCallHelper(
                getPreviousServiceBookingThunk,
                serviceBookings.currentPage,
                recordLimit
              );
            }
          }
        }
      } catch (error) {
        enqueueSnackbar("invalid search", {
          variant: "error",
        });
      }
    }
  };

  const getFilterParamsApi = async () => {
    try {
      const result = await getFilterParameters();
      
      if (result?.data) {
        const { serviceType, serviceCenter } = result?.data?.data;
        var emt: any = [];
        var emt2: any = [];
        serviceType.forEach((item, index) => {
          emt.push({
            id: index,
            label: item,
            status: false,
          });
        });

        serviceCenter.forEach((item, index) => {
          emt2.push({
            id: index,
            label: item,
            status: false,
          });
        });

        setServiceTypes([...emt]);
        setServiceCenters([...emt2]);
        
      }
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
    }
  };

  const handleChangeServiceTypes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    const resultServiceTypes = serviceTypes?.map((data) => {
      if (parseInt(value) === data.id) {
        data.status = !data.status;
      }
      return data;
    });
    setServiceTypes([...resultServiceTypes]);

    const emtOne: Array<string> = [];

    resultServiceTypes.map((data) => {
      if (data?.status) {
        emtOne?.push(data.label);
      }
      return data;
    });

    filterState["serviceType"] = emtOne.length === 0 ? "" : emtOne.join(",");
    setFilterState({ ...filterState });

    checkIsFilterCleared();
  };

  const handleChangeServiceCenters = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget;
    // console.log(name, value);
    const resultServiceCenters = serviceCenters?.map((data) => {
      if (parseInt(value) === data.id) {
        data.status = !data.status;
      }
      return data;
    });
    setServiceCenters([...resultServiceCenters]);

    const emtOne: Array<string> = [];

    resultServiceCenters.map((data) => {
      if (data?.status) {
        emtOne?.push(data.label);
      }
      return data;
    });

    filterState["center"] = emtOne.length === 0 ? "" : emtOne.join(",");
    setFilterState({ ...filterState });

    checkIsFilterCleared();
  };

  const handleDate = (e) => {
    const { id, value } = e.target;
    filterState[id] = value;
    setFilterState({ ...filterState });

    checkIsFilterCleared();
  };

  const checkIsFilterCleared = () => {
    // console.log(JSON.stringify(filterState) === JSON.stringify(dummyFilterState),"flag")
    if (JSON.stringify(filterState) === JSON.stringify(dummyFilterState)) {
      if (serviceBookings?.currentTab === "On-Going") {
        ApiCallHelper(getOngoingServiceBookingThunk, 1, recordLimit);
      } else if (serviceBookings?.currentTab === "Upcoming") {
        ApiCallHelper(getUpcomingServiceBookingThunk, 1, recordLimit);
      } else {
        ApiCallHelper(getPreviousServiceBookingThunk, 1, recordLimit);
      }
      setFilterClear(false);
    }
  };

  const handleLimitChange = (e) => {
    const { value } = e?.target;
    setRecordLimit(value);
  };

  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Service Bookings</Heading>
          <InputTextField
            id="serviceBooking"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            onKeyDown={handleSearchDispatch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color={brandColor} />
                </InputAdornment>
              ),
            }}
          />
        </LeftDiv>
        <RightDiv>
          <ExportToExcel onClick={handelExport}>
            <ExportIcon color={brandColor} />
            <SubHeading>Export to Excel</SubHeading>
          </ExportToExcel>
          <RelativeDiv>
            <FilterDiv onClick={handleClick}>
              <FilterIcon />
              <FilterHeading>Filter</FilterHeading>
            </FilterDiv>
            {filterClear && <RemoveFilter onClick={handleClearFilter} />}
          </RelativeDiv>
        </RightDiv>
      </HeaderDiv>

      <CustomPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClose={handleClose}
        childComp={
          <CustomTabSelector
            labelArray={["Service Type", "Center", "Date Range"]}
            children={
              [
                <CheckboxContainer
                  checkboxLabelArr={serviceTypes}
                  handleChange={handleChangeServiceTypes}
                />,
                <CheckboxContainer
                  checkboxLabelArr={serviceCenters}
                  handleChange={handleChangeServiceCenters}
                />,
                <DateRange
                  handleDate={handleDate}
                  endDate={endDate}
                  startDate={startDate}
                  styles={{ width: "70%" }}
                />,
              ] as any
            }
          />
        }
      />

      <TabSelectorContainer>
        <LimitDiv top={0}>
          <SpanTag>Limit - </SpanTag>
          <DropdownDiv>
            <DropdownSelector
              onChange={handleLimitChange}
              variant="outlined"
              value={recordLimit}
              MenuProps={DROPDOWN_MENU_PROPS}
            >
              {limitArr.map((item, index) => {
                return (
                  <MenuItem key={`${item}_${index}`} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </DropdownSelector>
          </DropdownDiv>
        </LimitDiv>
        <TabSelector
          labelArray={["On-Going", "Upcoming", "History"]}
          tabValue={tabValue as number}
          children={[
            <ServiceBookingTable
              filterBy="On-Going"
              exportData={exportData}
              setExportData={setExportData}
              handelTabActive={handelTabActive}
              searchValue={searchValue}
              filterFlag={filterClear}
              filterFunc={filterFunc}
              transactionLimit={recordLimit}
              filterState={filterState}
            />,
            <ServiceBookingTable
              filterBy="Upcoming"
              exportData={exportData}
              setExportData={setExportData}
              handelTabActive={handelTabActive}
              searchValue={searchValue}
              filterFlag={filterClear}
              filterFunc={filterFunc}
              transactionLimit={recordLimit}
              filterState={filterState}
            />,
            <ServiceBookingTable
              filterBy="History"
              exportData={exportData}
              setExportData={setExportData}
              handelTabActive={handelTabActive}
              searchValue={searchValue}
              filterFlag={filterClear}
              filterFunc={filterFunc}
              transactionLimit={recordLimit}
              filterState={filterState}
            />,
          ]}
        />
      </TabSelectorContainer>
    </CustomerContainer>
  );
}

export default Customer;
