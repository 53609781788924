import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PRIMARY_LEXUS,
  PRIMARY_MOTORCITY,
  PRIMARY_TOYOTA,
} from "constants/style/color";

export enum brandEnum {
  T = "Toyota",
  L = "Lexus",
  M = "motorcity",
}

interface BrandState {
  brandType: brandEnum;
  brandColor: string;
}

const BrandInitialState: BrandState = {
  brandType: brandEnum.T,
  brandColor: PRIMARY_TOYOTA,
};

export const BrandSlice = createSlice({
  name: "BrandSlice",
  initialState: BrandInitialState,
  reducers: {
    setBrand,
  },
});

function setBrand(state: BrandState, action: PayloadAction<brandEnum>) {
  // console.log(action, "slice");
  const brandType = action.payload;
  state.brandType = brandType;
  state.brandColor =
    brandType === brandEnum.T
      ? PRIMARY_TOYOTA
      : brandType === brandEnum.L
      ? PRIMARY_LEXUS
      : PRIMARY_MOTORCITY;
}
