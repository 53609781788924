import { LatestCarsSlice } from "./latest-cars-slices";

export {
  getLatestCarsThunk,
} from "slices/latest-cars/latest-cars-thunks";

export default LatestCarsSlice.reducer;

export const { setCurrentPageReducer } = LatestCarsSlice.actions;

