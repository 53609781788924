import React, { ReactElement } from "react";

import errorImg from "assets/error-img.png";
import {
  BtnDiv,
  Container,
  H1Tag,
  H3Tag,
  InnerDiv,
  NewButton,
  SpanTag,
} from "./styles";
import { useHistory } from "react-router";

interface Props {
  error: string;
  errorMessage: string;
}

function CustomErrorPage({errorMessage,error}:Props): ReactElement {
  const history = useHistory<any>();
  const handelBack = () => {
    history.push("/");
  };

  return (
    <Container>
      <InnerDiv>
        <H1Tag>
          {error[0]}{" "}
          <SpanTag>
          {error[1]}<img src={errorImg} alt="" className="error-img" />
          </SpanTag>{" "}
          {error[2]}
        </H1Tag>
        <H3Tag>{errorMessage}</H3Tag>
        <BtnDiv>
          <NewButton color="primary" variant="contained" onClick={handelBack}>
            Back to Dashboard
          </NewButton>
        </BtnDiv>
      </InnerDiv>
    </Container>
  );
}

export default CustomErrorPage;
