import { ServiceBookingSlice } from "./service-booking-slice";
export {
    getOngoingServiceBookingThunk,
    getUpcomingServiceBookingThunk,
    getPreviousServiceBookingThunk,
    ServiceBookingSearchThunk,
    getServiceBookingByFilter,
  } from "slices/services/service-bookings/service-booking-thunks";


export default ServiceBookingSlice.reducer;

  export const { setCurrentTabReducer, setCurrentPageReducer } = ServiceBookingSlice.actions;