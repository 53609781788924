import styled from "styled-components";
import { NavLink } from "react-router-dom";

import Logo from "shared-components/icons/login-logo";
import DashboardIcon from "shared-components/icons/dashboard";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import {
  SUBTEXT_FONT_SIZE,
  SUBHEADER_FONT_SIZE,
} from "constants/style/font-size";

export const SideBarDiv = styled.div`
  min-width: 5rem;
  background-color: white;
  height: 100%;
  position: fixed;
  top: 0;
  box-shadow: 6px 0px 12px rgba(0, 0, 0, 0.06);
  overflow-y: auto;
`;
export const LogoContainer = styled.div`
  width: 10rem;
  padding: 1rem 3rem 0.4rem 2rem;
`;
export const NavLinkDiv = styled.div`
    width: 240px;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    z-index: 1001;
    top: 65px;
  overflow-y: auto;
`;
export const NavItem = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 0.8rem;
  font-family: ${PRIMARY_REGULAR_FONT};
  font-size: ${SUBTEXT_FONT_SIZE};
  &.active {
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    font-family: ${PRIMARY_REGULAR_FONT};
    color: ${({ theme }) => theme.color};
    background: ${({ theme }) => theme.background};
    border-left: 3px solid ${({ theme }) => theme.color};
  }
`;

interface DivProps {
  color?: string;
}
export const NavText = styled.p`
  font-size: 1rem;
  margin-left: 1rem;
  padding: 0;
  margin-top: 0.2rem;
  font-size: ${SUBHEADER_FONT_SIZE} !important;
  font-size: ${SUBTEXT_FONT_SIZE};
`;

export const NavDiv = styled.div`
  padding: 0.6rem 0rem 0rem 1.5rem;
  display: flex;
  height: 2.2rem;
  font-size: ${SUBHEADER_FONT_SIZE} !important;
  color: ${(p: DivProps) => p.color} !important;
`;

export const MainDiv = styled.div`
  margin-left: 15rem;
`;
export const ListIcon = styled(ListItemIcon)`
  margin-left: 0.6rem;
  padding: 0;
  margin-right: 0;
  /* min-width: 33px; */
`;
export const ListItemDiv = styled(ListItemText)`
  margin: 0.2rem 0 0 -1.4rem !important;
  font-weight: normal !important;
  font-size: ${SUBHEADER_FONT_SIZE} !important;
`;
export const ListItemContent = styled(ListItem)`
  &:hover {
    background: none !important;
  }
`;

export const BrandLogo = styled(Logo)`
  width: 100%;
  height: 100%;
`;

export const ForwardArrow = styled(ArrowForwardIosIcon)`
  width: 0.9rem !important;
  height: 0.9rem !important;
`;

export const DashboardLogo = styled(DashboardIcon)`
  margin-top: 0.2rem;
`;
