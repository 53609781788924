import styled from "styled-components";

export const TextWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const Small = styled.small`
  color: red;
  text-decoration: underline;
  cursor: pointer;
`;
