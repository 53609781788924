import React, { ReactElement, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "redux-store/hooks";

import InputAdornment from "@material-ui/core/InputAdornment";
import TabSelector from "shared-components/tab-selector";
import CustomPopover from "shared-components/custom-filter-popup";
import CustomTabSelector from "shared-components/custom-tab-selector";
import DateRange from "shared-components/date-range";
import TestDriveTable from "pages/services/test-drive-bookings/components/test-drive-table";
import { useSnackbar } from "notistack";
import {
  MainDiv,
  Heading,
  FilterDiv,
  InputTextField,
  Search,
  LeftDiv,
  RightDiv,
  FilterIcon,
  FilterHeading,
  HeaderDiv,
  RemoveFilter,
} from "pages/services/test-drive-bookings/styles";
import {
  getTestDriveByFilter,
  getTestDriveThunk,
  testDriveSearchThunk,
} from "slices/test-drive-bookings";
import {
  ExportIcon,
  ExportToExcel,
  SubHeading,
} from "../roadside-assistance/styles";
import {
  SpanTag,
  DropdownDiv,
  LimitDiv,
} from "pages/customers/components/customers-table/styles";
import { DROPDOWN_MENU_PROPS, limitArr } from "constants/style/props";
// import { DropdownSelector } from "pages/dashboard/styles";
import { MenuItem } from "@material-ui/core";
import { TabSelectorContainer } from "pages/customers/styles";
import { DropdownSelector } from "pages/best-deals/components/new-deal/styles";

const initState = {
  startDate: "",
  endDate: "",
};

function TestDriveBookings(): ReactElement {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const brandColor = useAppSelector((state) => state.brand.brandColor);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterClear, setFilterClear] = useState<boolean>(false);
  const [exportData, setExportData] = useState<boolean>(false);
 const [recordLimit, setRecordLimit] = useState<number>(limitArr[0]);
  const [dateRange, setDateRange] = useState(
    JSON.parse(JSON.stringify(initState))
  );
  const [dummyDateRange] = useState(JSON.parse(JSON.stringify(initState)));

  const testDriveBookings = useAppSelector(
    (state) => state.testDriveBookings.testDriveInfo
  );

  const { startDate, endDate } = dateRange;

  useEffect(() => {
    if (JSON.stringify(dateRange) !== JSON.stringify(dummyDateRange)) {
      setFilterClear(true);
      dispatch(getTestDriveByFilter({ ...dateRange, pageNumber: 1 , limit: recordLimit}))
        .unwrap()
        .then((res) => {
          if ("data" in res.data) {
            enqueueSnackbar(res.data.data, { variant: "warning" });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.data, { variant: "error" });
        });
    }
    // eslint-disable-next-line
  }, [dateRange,recordLimit]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchDispatch = async (event) => {
    if (event.key === "Enter") {
      try {
        if (searchValue !== "") {
          await dispatch(
            testDriveSearchThunk({
              pageNumber: 1,
              searchText: searchValue,
            })
          );
        } else {
          await dispatch(
            getTestDriveThunk({
              pageNumber: testDriveBookings.currentPage,
              limit: recordLimit,
            })
          );
        }
      } catch (error) {
        enqueueSnackbar("invalid search", {
          variant: "error",
        });
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelExport = () => {
    setExportData((prevExportData) => !prevExportData);
  };
  const handleDate = (e) => {
    const { id, value } = e.target;
    dateRange[id] = value;
    setDateRange({ ...dateRange });

    if (JSON.stringify(dateRange) === JSON.stringify(dummyDateRange)) {
      dispatch(getTestDriveThunk({ pageNumber: 1, limit: recordLimit }));
      setFilterClear(false);
    }
  };

  const handleClearFilter = () => {
    dispatch(
      getTestDriveThunk({
        pageNumber: testDriveBookings.currentPage,
        limit: recordLimit,
      })
    );
    setFilterClear(false);
    setDateRange(JSON.parse(JSON.stringify(dummyDateRange)));
  };

  const filterFunc = (pageNumber) => {
    dispatch(
      getTestDriveByFilter({
        ...dateRange,
        pageNumber: pageNumber,
        limit:recordLimit,
      })
    );
  };
const handleLimitChange = (e) => {
  const { value } = e?.target;
  setRecordLimit(value);
};
  return (
    <MainDiv>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Test Drive Bookings</Heading>
          <InputTextField
            id="testDriveBooking"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            onKeyDown={handleSearchDispatch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color={brandColor} />
                </InputAdornment>
              ),
            }}
          />
        </LeftDiv>
        <RightDiv>
          <ExportToExcel onClick={handelExport}>
            <ExportIcon color={brandColor} />
            <SubHeading>Export to Excel</SubHeading>
          </ExportToExcel>
          <FilterDiv onClick={handleClick}>
            <FilterIcon />
            <FilterHeading>Filter</FilterHeading>
          </FilterDiv>
          {filterClear && <RemoveFilter onClick={handleClearFilter} />}
        </RightDiv>
      </HeaderDiv>

      <CustomPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClose={handleClose}
        childComp={
          <CustomTabSelector
            labelArray={["Date Range"]}
            children={
              [
                <DateRange
                  handleDate={handleDate}
                  endDate={endDate}
                  startDate={startDate}
                  styles={{ width: "100%", padding: "1rem 2rem 1rem 1rem" }}
                />,
              ] as any
            }
          />
        }
      />
        <TabSelectorContainer>
        <LimitDiv top={0}>
          <SpanTag>Limit - </SpanTag>
          <DropdownDiv>
            <DropdownSelector
              onChange={handleLimitChange}
              variant="outlined"
              value={recordLimit}
              MenuProps={DROPDOWN_MENU_PROPS}
            >
              {limitArr.map((item, index) => {
                return (
                  <MenuItem key={`${item}_${index}`} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </DropdownSelector>
          </DropdownDiv>
          </LimitDiv>
          <TabSelector
            labelArray={["All"]}
            children={
              [
                <TestDriveTable
                  exportData={exportData}
                  setExportData={setExportData}
                  searchValue={searchValue}
                  filterFlag={filterClear}
                  filterFunc={filterFunc}
                  recordLimit={recordLimit}
                  dateRange={dateRange}
                />,
              ] as any
            }
          />
        </TabSelectorContainer>
    </MainDiv>
  );
}

export default TestDriveBookings;
