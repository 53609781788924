import styled from 'styled-components'
import {
    FieldContainer,
    NotificationContainer
} from "pages/management/notification/add-notification/styles";
import { TextField } from '@material-ui/core';

export const BtnFieldContainer = styled(FieldContainer)`
  margin-top: 4%;
  margin-bottom: 4%;
`;

export const SalesForceContainer = styled(NotificationContainer)`
  padding: .5rem 1rem .5rem 2rem;
`;


export const InputTextField = styled(TextField)`
  width: 35%;
`;