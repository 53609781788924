import React, { ReactElement } from "react";

import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowParams,
  GridRowIdGetter
} from "@material-ui/data-grid";

import { MainDiv } from "shared-components/table-data/styles";

interface Props {
  columns: Array<GridColDef>;
  rows: Array<GridRowModel>;
  filterBy?: string | boolean;
  filterColumn?: string;
  isCheckBox?: boolean;
  handleCheckedData?: (newSelection) => void;
  pageSize?: number;
  rowHeight?: number;
  getRowClassName?: (params: GridRowParams) => string;
  selectionModel?: any;
  getRowId?: GridRowIdGetter;
  isPagination?: boolean;
  isLoading?: boolean;
  className?: string;
  autoPageSize?: boolean;
}

function TableData({
  columns,
  rows,
  getRowId,
  filterBy,
  filterColumn,
  handleCheckedData,
  isCheckBox,
  pageSize,
  rowHeight,
  isPagination,
  getRowClassName,
  selectionModel,
  isLoading,
  className,
  autoPageSize,
}: Props): ReactElement {
  const filterData = () => {
    if (filterBy !== undefined) {
      return rows.filter(
        (item) =>
          item[filterColumn === undefined ? "" : filterColumn] === filterBy
      );
    } else {
      return rows;
    }
  };

  return (
    <MainDiv>
      <DataGrid
        // autoHeight
        rows={filterData()}
        getRowId={getRowId}
        sortingOrder={["desc", "asc"]}
        rowsPerPageOptions={[]}
        checkboxSelection={isCheckBox}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        disableDensitySelector
        disableColumnSelector
        hideFooterSelectedRowCount={true}
        disableExtendRowFullWidth={true}
        onSelectionModelChange={handleCheckedData as () => void}
        pageSize={pageSize}
        rowHeight={rowHeight}
        loading={isLoading}
        hideFooterPagination={isPagination}
        // selectionModel={selectionModel}
        selectionModel={selectionModel}
        getRowClassName={getRowClassName}
        className={className}
        autoPageSize={autoPageSize}
      />
    </MainDiv>
  );
}

export default TableData;
