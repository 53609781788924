import { LatestBlogsSlice } from "./latest-blogs-slice";

export {
  getAllBlogsThunk,
  getBlogsBySearchThunk,
  getBlogByIdThunk,
  updateBlogInfoThunk,
  deleteBlogThunk,
  addNewBlogThunk
  } from "slices/latest-blogs/latest-blogs-thunks";

  export default LatestBlogsSlice.reducer;

  export const { setCurrentBlogReducer,setCurrentPageReducer,setTabIndicatorReducer,setFromEditPageReducer } = LatestBlogsSlice.actions;