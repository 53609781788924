import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  GetUserBasicInfo,
  GetAdminUsers,
  AddAdminInfo,
  DeleteAdminInfo,
  UpdateUserInfo,
} from "services/user-profile-services";

import {
  GetDataRequest,
  UserProfileInfo,
} from "interfaces/user-profile.interface";

export const getUserBasicInfoThunk = createAsyncThunk(
  "getUserBasicInfoThunk",
  async (
    data: { userId: number; brand: string; type?: string },
    { rejectWithValue }
  ) => {
    const { userId, type, brand } = data;
    try {
      const result = await GetUserBasicInfo({
        id: userId,
        brand: brand,
      });
      const data = {
        result: result.data,
        type: type,
      };
      if (type === "editModal") {
        return data;
      } else {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdminsInfoThunk = createAsyncThunk(
  "getAdminsInfoThunk",
  async (request: GetDataRequest, { rejectWithValue }) => {
    try {
      const result = await GetAdminUsers(request);
      const data = {
        pageNumber: request.pageNumber,
        response: result.data,
      };
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addAdminInfoThunk = createAsyncThunk(
  "addAdminInfoThunk",
  async (request: UserProfileInfo, { rejectWithValue }) => {
    try {
      const result = await AddAdminInfo(request);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAdminInfoThunk = createAsyncThunk(
  "deleteAdminInfoThunk",
  async (id: number, { rejectWithValue }) => {
    try {
      const result = await DeleteAdminInfo(id);
      const data = {
        id: id as number,
        response: result.data,
      };
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdminInfoThunk = createAsyncThunk(
  "updateAdminInfoThunk",
  async (
    response: { request: UserProfileInfo; type?: string },
    { rejectWithValue }
  ) => {
    try {
      const { request, type } = response;
      const result = await UpdateUserInfo(request);
      const data = {
        response: result,
        type: type,
      };
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
