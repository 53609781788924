import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import InputAdornment from "@material-ui/core/InputAdornment";
import { useSnackbar } from "notistack";

import ArabicSwitchDropdown from "shared-components/arabic-input/arabic-switch-dropdown";
import { TableContainer, ArabicSwitch } from "pages/management/faqs/styles";
import FaqsTable from "pages/management/faqs/components/faqs-table";
import { RightDiv, AddLocationButton } from "pages/location/styles";

import { ADD_FAQ } from "constants/route/route-constants";
import { LANGUAGE_FLAG_ARRAY } from "constants/style/props";

import BahrinFlag from "assets/bahrin-flag.png";
import UkFlag from "assets/uk-flag.png";

import { getAllFaqsThunk, getFaqsBySearchThunk } from "slices/faqs";

import {
  CustomerContainer,
  Heading,
  InputTextField,
  Search,
  LeftDiv,
  HeaderDiv,
} from "pages/news/styles";

function Faqs(): ReactElement {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [searchValue, setSearchValue] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLang, setSelectedLang] = useState(UkFlag);
  const [Language, setLanguage] = useState("English");

  const brandColor = useAppSelector((state) => state.brand.brandColor);
  const faqs = useAppSelector((state) => state.faqs.faqsInfo);

  const languageArr = LANGUAGE_FLAG_ARRAY;

  const handleClick = () => {
    const elem = document.getElementById("flagDropdown");
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleCloseSnackbar = () => {
    closeSnackbar();
  };
  const handleLanguage = (label) => {
    const newFlag = label === "English" ? UkFlag : BahrinFlag;
    setSelectedLang(newFlag);
    setAnchorEl(null);
    setLanguage(label);
  };

  const handleSearchDispatch = async (event) => {
    if (event.key === "Enter") {
      try {
        if (searchValue !== "") {
          await dispatch(
            getFaqsBySearchThunk({
              query: searchValue,
              pageno: 1,
              limit: 5,
            })
          );
        } else {
          dispatch(getAllFaqsThunk({ pageno: faqs?.currentPage, limit: 5 }));
        }
      } catch (error) {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    }
  };

  return (
    <CustomerContainer onClick={handleCloseSnackbar}>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Frequently Asked Questions</Heading>
          <InputTextField
            id="faqs"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            onKeyDown={handleSearchDispatch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color={brandColor} />
                </InputAdornment>
              ),
            }}
          />
          <ArabicSwitch>
            <ArabicSwitchDropdown
              selectedLang={selectedLang}
              handleClick={handleClick}
              anchorEl={anchorEl}
              languageArr={languageArr}
              handleClose={handleClose}
              handleLanguage={handleLanguage}
            />
          </ArabicSwitch>
        </LeftDiv>
        <RightDiv>
          <AddLocationButton
            variant="contained"
            color="primary"
            onClick={() => history.push(ADD_FAQ)}
          >
            Add FAQ
          </AddLocationButton>
        </RightDiv>
      </HeaderDiv>
      <TableContainer>
        <FaqsTable searchValue={searchValue} selectedLang={Language} />,
      </TableContainer>
    </CustomerContainer>
  );
}

export default Faqs;
