import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector } from "redux-store/hooks";

import { useSnackbar } from "notistack";

import LoaderButton from "shared-components/loader-button";

import { resetPasswordLink } from "services/login-services";

import {
  EMPTY_INPUT,
  INVALID_EMAIL_ID,
} from "constants/snackbar-messages-constants";

import { EMAIL_REGEX_EQUATION } from "constants/regex-validations-constants";
import { LOGIN } from "constants/route/route-constants";

import {
  LoginContainer,
  MainDiv,
  NavContainer,
  ForgotPasswordDiv,
  InputTextField,
  InputLabelText,
  InputDiv,
  BrandLogo,
  InstructionText,
  RememberPasswordText,
  NavItem,
  EmptyDiv,
} from "pages/auth/components/forgot-password-link/styles";
import { FORGOT_PASS_BTN_STYLES } from "constants/style/props";
import { MainContainer } from "../login/styles";

function ForgotPassword(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUser = useAppSelector((state) => state.user.userInfo);

  const submitForgotPasswordUserDetails = async () => {
    if (email === "") {
      setEmailError(`email ${EMPTY_INPUT}`);
    } else {
      try {
        setIsLoading(true);
        const result = await resetPasswordLink(email);
        setIsLoading(false);
        enqueueSnackbar(result.data.data, { variant: "success" });
      } catch (err) {
        setIsLoading(false);
        enqueueSnackbar(err.response.data.data, { variant: "error" });
      }
    }
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    if (value === "") {
      setEmailError(`${id} ${EMPTY_INPUT}`);
    } else {
      const reg = new RegExp(EMAIL_REGEX_EQUATION).test(value);
      if (!reg) {
        setEmailError(INVALID_EMAIL_ID);
      } else {
        setEmailError("");
        setEmail(value);
      }
    }
  };
  const handleSubmitOnEnter = (event) => {
    if (event.key === "Enter") {
      submitForgotPasswordUserDetails();
    }
  };

  useEffect(() => {
    setIsLoading(getUser.isLoading);
  }, [getUser.isLoading]);

  return (
    <MainContainer>
    <MainDiv>
      <EmptyDiv></EmptyDiv>
      <LoginContainer>
        <BrandLogo />
        <InputDiv>
          <ForgotPasswordDiv>Forgot Password?</ForgotPasswordDiv>
          <InstructionText>
            {`Enter your email below to receive your password \nreset instructions`}
          </InstructionText>
          <NavContainer>
            <InputLabelText>Email Address</InputLabelText>
            <InputTextField
              onChange={handleEmail}
              id="email"
              variant="outlined"
              size="small"
              error={Boolean(emailError)}
              helperText={emailError}
              autoFocus
              onKeyPress={handleSubmitOnEnter}
            />
          </NavContainer>
          <LoaderButton
            submitHandler={submitForgotPasswordUserDetails}
            isLoading={isLoading}
            buttonText="Send Verification Link"
            styles={FORGOT_PASS_BTN_STYLES}
          />
          <RememberPasswordText>
            Remember your password? <NavItem to={LOGIN}>Login</NavItem>{" "}
          </RememberPasswordText>
        </InputDiv>
      </LoginContainer>
      <EmptyDiv></EmptyDiv>
    </MainDiv>
    </MainContainer>
  );
}

export default ForgotPassword;
