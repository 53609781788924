import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { DealDetails } from "interfaces/best-deals-interfaces";
import bestDealsInitialState from "slices/best-deals/best-deals-initial-state";
import bestDealState from "slices/best-deals/best-deals-interface";

import {
  addNewDealThunk,
  deleteDealThunk,
  getAllDealsThunk,
  getDealByIdThunk,
  getDealsBySearchThunk,
  updateDealInfoThunk,
} from "slices/best-deals/best-deals-thunks";

export const BestDealsSlice = createSlice({
  name: "BestDealsSlice",
  initialState: bestDealsInitialState,
  reducers: {
    setCurrentDealReducer:setCurrentDealReducer,
    setCurrentPageReducer:setCurrentPageReducer,
    setTabIndicatorReducer:setTabIndicatorReducer,
    setFromEditPageReducer:setFromEditPageReducer
  },
  extraReducers: {
    //set allDeals
    [getAllDealsThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.bestDealsInfo.allDeals =
        listEntityClass === undefined ? [] : listEntityClass;
      state.bestDealsInfo.recordsLength = totalRecords;
    },

    [getAllDealsThunk.rejected.type]: (state) => {
      state.bestDealsInfo.allDeals = [];
    },

    // get deal by id deals
    [getDealByIdThunk.fulfilled.type]: (state, action) => {
      let { data } = action.payload.data;
      state.bestDealsInfo.currentDeal = data;
    },


    [getDealByIdThunk.rejected.type]: (state) => {
      state.bestDealsInfo.currentDeal= {};
    },

    // search deal by text and Title
    [getDealsBySearchThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.bestDealsInfo.allDeals =
        listEntityClass === undefined ? [] : listEntityClass;
      state.bestDealsInfo.recordsLength = totalRecords;
    },

    [getAllDealsThunk.rejected.type]: (state) => {
      state.bestDealsInfo.allDeals = [];
    },

    //updateDeal info
    [updateDealInfoThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      const result = state.bestDealsInfo.allDeals.map((item) => {
        if (item.dealId === data.dealId) {
          item = { ...data };
        }
        return item;
      });
      state.bestDealsInfo.allDeals = [...result];
    },

    //delete deal
    [deleteDealThunk.fulfilled.type]: (state, action) => {
      const { id } = action.payload;
      state.bestDealsInfo.allDeals = state.bestDealsInfo.allDeals.filter(
        (data) => data.dealId !== id
      );
    },

    // add new deal
    [addNewDealThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      state.bestDealsInfo.allDeals.push(data);
    },
  },
});

function setCurrentDealReducer(
  state: bestDealState,
  action: PayloadAction<DealDetails>
) {
  state.bestDealsInfo.currentDeal = action.payload;
}

function setCurrentPageReducer(
  state: bestDealState,
  action: PayloadAction<number>
) {
  state.bestDealsInfo.currentPage = action.payload;
}


function setTabIndicatorReducer(
  state: bestDealState,
  action: PayloadAction<string|undefined>
) {
  state.bestDealsInfo.tabIndicator = action.payload;
}

function setFromEditPageReducer(
  state: bestDealState,
  action: PayloadAction<boolean>
) {
  state.bestDealsInfo.fromEditPage = action.payload;
}
