import axios from "axios";
import selectedBrand from "constants/brand";

import {
  ADD_LOCATION_ENDPOINT,
  BASE_URL,
  DELETE_LOCATION_ENDPOINT,
  EDIT_LOCATION_ENDPOINT,
  FILTER_LANGUAGE_ENDPOINT,
  GET_PART_CENTER_ENDPOINT,
  GET_SERVICE_CENTER_ENDPOINT,
  GET_SHOWROOM_ENDPOINT,
} from "constants/api-config-constants";

import {
  LocationInfo,
  AddLocationResponse,
  GetLocationsRequest,
  GetLocationResponse,
  SuccessResponse,
  FilterLangRequest,
  UpdateLocationResponse,
} from "slices/locations/locations.interface";

export const getPartCenterLocations = async (
  request: GetLocationsRequest
): Promise<GetLocationResponse> => {
  const { pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_PART_CENTER_ENDPOINT +
    "?pageno=" +
    pageNumber +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const getServiceCenterLocations = async (
  request: GetLocationsRequest
): Promise<GetLocationResponse> => {
  const { pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_SERVICE_CENTER_ENDPOINT +
    "?pageno=" +
    pageNumber +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const getShowroomLocations = async (
  request: GetLocationsRequest
): Promise<GetLocationResponse> => {
  const { pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_SHOWROOM_ENDPOINT +
    "?pageno=" +
    pageNumber +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const filterLanguages = async (
  request: FilterLangRequest
): Promise<GetLocationResponse> => {
  const { pageNumber, limit, locType, language } = request;
  const url =
    BASE_URL +
    FILTER_LANGUAGE_ENDPOINT +
    "?locType=" +
    locType +
    "&language=" +
    language +
    "&pageno=" +
    pageNumber +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const addLocation = async (
  request: LocationInfo
): Promise<AddLocationResponse> => {
  const url = BASE_URL + ADD_LOCATION_ENDPOINT + "?brand=" + selectedBrand();
  return axios.post(url, request);
};

export const updateLocation = async (
  request: LocationInfo
): Promise<UpdateLocationResponse> => {
  const url = BASE_URL + EDIT_LOCATION_ENDPOINT + "?id=" + request.locationId;
  return axios.put(url, request);
};

export const deleteLocation = async (id: number): Promise<SuccessResponse> => {
  const url = BASE_URL + DELETE_LOCATION_ENDPOINT + "?id=" + id;
  return axios.delete(url);
};

// export const getLocationById = async (id: number): Promise<any> => {
//   const url = BASE_URL
//   // + GET_LOCATION_BY_ID + "?id=" + id;
//   return axios.get(url);
// };
