import LatestCarsState from "slices/latest-cars/latest-cars-interface";

const latestCarsInitialState: LatestCarsState = {
  latestCarsInfo: {
    allCars: [],
    currentCar: {},
    recordsLength: 0,
    currentPage: 1,
  },
};

export default latestCarsInitialState;
