import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";

import AddIcon from "shared-components/icons/add-icon";
import RemoveIcon from "shared-components/icons/remove-icon";

import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import { PARAGRAPH_FONT_SIZE } from "constants/style/font-size";
import { BLACK_COLOR } from "constants/style/color";

export const InputTextField = styled(TextField)`
  width: 18rem;
`;

export const NavContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const InputLabelText = styled(InputLabel)`
  margin-bottom: 1%;
  color: ${BLACK_COLOR} !important;
  font-size: ${PARAGRAPH_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;

export const MainDiv = styled.div`
  margin: 0.8rem 0 0.6rem 0.5rem;
`;
export const OptionsContainer = styled(NavContainer)`
  margin-top: 1.5rem;
`;

export const AddIconLogo = styled(AddIcon)`
  margin: 0.5rem 1.5rem;
  cursor: pointer;
`;

export const RemoveIconLogo = styled(RemoveIcon)`
  margin: 0.5rem 1.7rem;
  cursor: pointer;
  z-index: 5;
`;

export const ButtonContainer = styled.div`
  margin: 3rem 0 1rem 0.5rem !important;
`;

export const SubmitButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
`;
export const CancelButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 1rem !important;
`;
