import React, { ReactElement } from "react";
import {
  DetailsContainer,
  BoxDiv,
  FlexChild,
  FlexDiv,
  FlexChildTwo,
} from "./styles";
import { useLocation,useHistory } from "react-router-dom";
import { CustomerContainer, HeaderDiv, Heading, LeftDiv } from "pages/news/styles";
import { BackIconLogo } from "pages/news/components/news-details/styles";
import { TEST_DRIVE_BOOKINGS } from "constants/route/route-constants";

const detailsObj = {
  sfCaseNumber: "Booking ID",
  carModel: "Model Name",
  driverName: "Customer Name",
  mobileNumber: "Customer Phone number",
  showroom: "Showroom",
  testDriveDate: "Appointment time",
  comments: "Comments",
};

function TestDriveDetails(): ReactElement {
  const location = useLocation<any>();
  const history = useHistory<any>();


  const handelBack = () => {
    history.push({
      pathname: `${TEST_DRIVE_BOOKINGS}`,
      state: { from: "Back" },
    });
  };

  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <BackIconLogo onClick={handelBack} />
          <Heading>Test Drive Booking Details</Heading>
        </LeftDiv>
      </HeaderDiv>
      <DetailsContainer>
        <BoxDiv>
          {Object.keys(detailsObj).map((key, ind) => {
            if (key === "testDriveDate") {
              return (
                <FlexDiv key={`testDriveDetails${ind}`}>
                  <FlexChild>{detailsObj[key]}</FlexChild>
                  <FlexChildTwo>
                    {location?.state?.rowData[key]} |  {location?.state?.rowData["testDriveTime"]}
                  </FlexChildTwo>
                </FlexDiv>
              );
            } else {
              return (
                <FlexDiv key={`testDriveDetails${ind}`}>
                  <FlexChild>{detailsObj[key]}</FlexChild>
                  <FlexChildTwo>{location?.state?.rowData[key]}</FlexChildTwo>
                </FlexDiv>
              );
            }
          })}
        </BoxDiv>
      </DetailsContainer>
      </CustomerContainer>
  );
}

export default TestDriveDetails;
