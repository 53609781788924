import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { GridColDef, GridCellParams } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";

import { RenderCellExpand } from "shared-components/expanded-cel";
import PaginationFooter from "shared-components/pagination";
import TableData from "shared-components/table-data";

import DeleteUser from "pages/user-profile/components/delete-user";
import AddUser from "pages/user-profile/components/add-user";

import { limit } from "constants/pagination-constants";

import { UserProfileInfo } from "interfaces/user-profile.interface";
import {
  getAdminsInfoThunk,
  getUserBasicInfoThunk,
  deleteAdminInfoThunk,
} from "slices/user-profile";

import {
  UserManagementContainer,
  RemoveIconLogo,
  EditIconLogo,
  AddUserDiv,
  AddUserModal,
  AddIconLogo,
  AddUserText,
} from "./styles";

const UserManagement = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [rows, setRows] = useState<UserProfileInfo[]>([]);
  const [modalType, setModalType] = React.useState("");

  const [selectedDeleteID, setSelectedDeletedId] = useState<number>(0);
  const [paginationSize, setPaginationSize] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    dispatch(getAdminsInfoThunk({ pageNumber: 1, limit: limit }))
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const adminProfile = useSelector(
    (state: RootState) => state.UserProfile.userProfileInfo
  );

  useEffect(() => {
    setRows(adminProfile.adminUsers);
    setPaginationSize(Math.ceil(adminProfile.totalRecordsLength / limit));
  }, [adminProfile]);

  const openDeleteUserModal = (id) => {
    setOpenDeleteModal(true);
    setSelectedDeletedId(id);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const confirmDelete = async (id) => {
    try {
      setLoading(true);
      const result = await dispatch(deleteAdminInfoThunk(id)).unwrap();
      enqueueSnackbar(result?.response?.data, {
        variant: "success",
      });
      setLoading(false);
      setOpenDeleteModal(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.data, { variant: "error" });
      setOpenDeleteModal(false);
    }
  };

  const openEditModal = (id: number, brand: string) => {
    dispatch(
      getUserBasicInfoThunk({ userId: id, type: "editModal", brand: brand })
    );
    setOpenModal(true);
    setModalType("Edit User");
  };
  const openAddModal = () => {
    setModalType("Add User");
    setOpenModal(true);
  };

  const closeAddUserModal = () => {
    setOpenModal(false);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData = params.row;
        return (
          <>
            <p>{rowData?.firstName + " " + rowData.lastName}</p>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email ID",
      sortable: false,
      flex: 1.2,
      renderCell: RenderCellExpand,
    },
    {
      field: "role",
      sortable: false,
      headerName: "Role",
      resizable: false,
      flex: 0.8,
    },
    {
      field: "brand",
      headerName: "Brand",
      sortable: false,
      resizable: false,
      flex: 1,
      renderCell: (params: GridCellParams) =>
        RenderCellExpand(params, "user-management-brand"),
    },
    {
      field: "actions",
      sortable: false,
      headerName: "Actions",
      resizable: false,
      flex: 0.4,
      renderCell: (params: GridCellParams) => {
        const rowData = params.row;
        return (
          <>
            <EditIconLogo
              onClick={() =>
                openEditModal(rowData.id, rowData?.listofBrands[0])
              }
            />
            <RemoveIconLogo onClick={() => openDeleteUserModal(rowData.id)} />
          </>
        );
      },
    },
  ];

  const handlePagination = (event, number) => {
    setLoading(true);
    dispatch(getAdminsInfoThunk({ pageNumber: number, limit: limit }))
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <UserManagementContainer>
        <TableData
          rows={loading ? [] : rows}
          columns={columns}
          isCheckBox={false}
          rowHeight={60}
          pageSize={limit}
          isPagination={true}
          isLoading={loading}
        />
        <AddUserDiv onClick={openAddModal}>
          <AddIconLogo/>
          <AddUserText>Add user</AddUserText>
        </AddUserDiv>
        <AddUser
          modalType={modalType}
          closeModal={closeAddUserModal}
          currentAdmin={adminProfile.currentUser}
          open={openModal}
        ></AddUser>

        <AddUserModal open={openDeleteModal} onClose={closeDeleteModal}>
          <DeleteUser
            params={selectedDeleteID}
            closeModal={closeDeleteModal}
            confirmDelete={confirmDelete}
            loading={loading}
            headerText={"Delete User"}
            paraText={"Are you sure you want to delete the user?"}
            message={"All data related to the user profile will be deleted."}
          ></DeleteUser>
        </AddUserModal>
      </UserManagementContainer>
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
        />
      ) : null}
    </>
  );
};

export default UserManagement;
