import React, { ReactElement, useState } from "react";
import { withRouter } from "react-router";

import ArabicSwitchDropdown from "shared-components/arabic-input/arabic-switch-dropdown";

import { ArabicSwitch } from "pages/management/faqs/styles";

import BahrinFlag from "assets/bahrin-flag.png";
import UkFlag from "assets/uk-flag.png";

import { LANGUAGE_FLAG_ARRAY } from "constants/style/props";
import { ADD_NOTIFICATION } from "constants/route/route-constants";
import NotificationTable from "./notification-table";

import { NotificationContainer, TableDiv } from "./styles";
import {
  LeftDiv,
  RightDiv,
  AddLocationButton,
  HeaderDiv,
  HeadingDiv,
} from "pages/location/styles";

function Notification({ history }): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLang, setSelectedLang] = useState(UkFlag);
  const [Language, setLanguage] = useState("English");

  const languageArr = LANGUAGE_FLAG_ARRAY;

  const handleLanguage = (label) => {
    const newFlag = label === "English" ? UkFlag : BahrinFlag;
    setSelectedLang(newFlag);
    setAnchorEl(null);
    setLanguage(label);
  };

  const handleClick = () => {
    const elem = document.getElementById("flagDropdown");
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <NotificationContainer>
      <HeaderDiv>
        <LeftDiv>
          <HeadingDiv>Notification</HeadingDiv>

          <ArabicSwitch>
            <ArabicSwitchDropdown
              selectedLang={selectedLang}
              handleClick={handleClick}
              anchorEl={anchorEl}
              languageArr={languageArr}
              handleClose={handleClose}
              handleLanguage={handleLanguage}
            />
          </ArabicSwitch>
        </LeftDiv>
        <RightDiv>
          <AddLocationButton
            variant="contained"
            color="primary"
            onClick={() => history.push(ADD_NOTIFICATION)}
          >
            Add Notification
          </AddLocationButton>
        </RightDiv>
      </HeaderDiv>
      <TableDiv>
        <NotificationTable selectedLang={Language} />
      </TableDiv>
    </NotificationContainer>
  );
}

export default withRouter(Notification);
