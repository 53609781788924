import React from "react";
import {
  MainDiv,
  InputTextField,
  InputLabelText,
  FieldContainer,
} from "shared-components/date-range/styles";
interface Props {
  handleDate: (e) => void;
  startDate?:Date | string;
  endDate?:Date | string;
  styles?:React.CSSProperties;
}
function DatePicker({ handleDate,startDate,endDate,styles}: Props) {
  return (
    <MainDiv style={{...styles}}>
      <FieldContainer>
        <InputLabelText>Start Date</InputLabelText>
        <InputTextField
          id="startDate"
          type="date"
          size="small"
          value={startDate}
          onChange={handleDate}
          variant="outlined"
        />
      </FieldContainer>
      <div></div>
      <FieldContainer>
        <InputLabelText>End Date</InputLabelText>
        <InputTextField
          id="endDate"
          type="date"
          size="small"
          value={endDate}
          onChange={handleDate}
          variant="outlined"
        />
      </FieldContainer>
    </MainDiv>
  );
}
export default DatePicker;
