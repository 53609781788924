import { NotificationSlice } from "./notifications-slice";

export {
  getAllNotificationsThunk,
  addNewNotificationThunk,
  // updateNotificationThunk,
  // deleteNotificationThunk,
} from "slices/notifications/notifications-thunks";

export default NotificationSlice.reducer;

export const { setCurrentNotificationReducer, setCurrentPageReducer } =
  NotificationSlice.actions;
