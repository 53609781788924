import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getOngoingServiceBookings,
  getUpcomingServiceBookings,
  getPreviousServiceBookings,
  ServiceBookingSearch,
  ServiceBookingFilter,
} from "services/service-booking-services";

import {
  ServiceBookingFilterInterface,
  GetServiceBookingRequest,
  ServiceBookingSearchRequest,
} from "interfaces/service-booking.interface";

export const getOngoingServiceBookingThunk = createAsyncThunk(
  "getOngoingServiceBookingThunk",
  async (request: GetServiceBookingRequest, { rejectWithValue }) => {
    try {
      const data = await getOngoingServiceBookings(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const getUpcomingServiceBookingThunk = createAsyncThunk(
  "getUpcomingServiceBookingThunk",
  async (request: GetServiceBookingRequest, { rejectWithValue }) => {
    try {
      const data = await getUpcomingServiceBookings(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const getPreviousServiceBookingThunk = createAsyncThunk(
  "getPreviousServiceBookingThunk",
  async (request: GetServiceBookingRequest, { rejectWithValue }) => {
    try {
      const data = await getPreviousServiceBookings(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const ServiceBookingSearchThunk = createAsyncThunk(
  "ServiceBookingSearchThunk",
  async (request: ServiceBookingSearchRequest, { rejectWithValue }) => {
    try {
      const data = await ServiceBookingSearch(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getServiceBookingByFilter = createAsyncThunk(
  "getServiceBookingByFilter",
  async (request: ServiceBookingFilterInterface, { rejectWithValue }) => {
    try {
      const result = await ServiceBookingFilter(request);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
