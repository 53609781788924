import styled from "styled-components";

import { Paper } from "@material-ui/core";

import {
  PARAGRAPH_FONT_SIZE,
  SUBTEXT_FONT_SIZE,
} from "constants/style/font-size";
import { PRIMARY_MEDIUM_FONT } from "constants/style/fonts";
import { TEXTFIELD_HEADING } from "constants/style/color";

export const ServiceDetails = styled(Paper)`
  padding: 1rem 3rem 1rem 1rem;
`;

export const RatingDetails = styled.div`
  background: #f0f0f0;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
`;

export const SubHeading = styled.p`
  margin-top: 0;
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-size: ${PARAGRAPH_FONT_SIZE};
  color: ${TEXTFIELD_HEADING};
`;

export const Comment = styled(SubHeading)`
  font-size: ${SUBTEXT_FONT_SIZE};
  margin-top: 1rem;
  margin-bottom: 0;
  line-height: 24px;
`;
