import { FaqSlice } from "./faqs-slice.";

export {
  getAllFaqsThunk,
  getFaqsBySearchThunk,
  addNewFaqThunk,
  updateFaqThunk,
  deleteFaqThunk
} from "slices/faqs/faqs-thunks";

export default FaqSlice.reducer;

export const { setCurrentFaqReducer,setCurrentPageReducer } = FaqSlice.actions;
