import styled from "styled-components";
import CopyIcon from "shared-components/icons/copy-icon";
import { AddUserModal } from 'pages/user-profile/components/user-management/styles'
import { Link } from "react-router-dom";
import { WHITE_COLOR } from "constants/style/color";
import CopyToClipboard from "shared-components/icons/copy-to-clipboard";

export const CopyIconLogo = styled(CopyIcon)`
  margin: 6px ;
  cursor: pointer ;
`;

export const CopyLinkToClipboard = styled(CopyToClipboard)`
  cursor: pointer ;
  margin: 6px ;
`;

export const DeletePopupModal = styled(AddUserModal)``;
export const RouteLink = styled(Link)``;
export const DateDiv = styled.span`
  background: ${WHITE_COLOR};
  display:flex;
  justify-content: center;
  align-items: center;
  padding:0.75rem;
  height:0.635rem;
`;


