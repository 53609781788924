import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useAppSelector } from "redux-store/hooks";

import { useSnackbar } from "notistack";

import TabSelector from "shared-components/tab-selector";

import DetailsComponent from "pages/management/privacy-policy/components/details";

import { LoadingComp } from "pages/news/components/news-details/styles";

import {
  updateTermsAndConditions,
  updatePrivacyPolicy,
  getPrivacyPolicy,
  getTermsAndConditions,
} from "services/privacy-policy";

import { ObjProps } from "interfaces/privacy-policy";

import {
  CustomerContainer,
  Heading,
  LeftDiv,
  HeaderDiv,
} from "pages/customers/styles";

function PrivacyPolicyComponent(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const initialState = {
    privacyPolicy: "",
    privacyPolicyArabic: "",
    termsAndConditions: "",
    termsAndConditionsArabic: "",
  };

  const [obj, setObj] = useState<ObjProps>(initialState);
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<{
    privacy: boolean;
    termsAndConditions: boolean;
  }>({
    privacy: false,
    termsAndConditions: false,
  });

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  useEffect(() => {
    getPPandTc();
    // eslint-disable-next-line
  }, [brandName]);

  const getPPandTc = async () => {
    setLoadingSpin(true);
    try {
      const result = await getPrivacyPolicy();
      const resultTc = await getTermsAndConditions();
      setObj({ ...result?.data?.data, ...resultTc?.data?.data });
      setLoadingSpin(false);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.status, { variant: "error" });
      setLoadingSpin(false);
    }
  };

  const handelSave = async (newObj, field) => {
    // setObj({ ...newObj });
    const {
      privacyPolicy,
      privacyPolicyArabic,
      termsAndConditions,
      termsAndConditionsArabic,
    } = newObj;

    if (field === "privacyPolicy") {
      setLoading({ ...isLoading, privacy: true });
      try {
        const result = await updatePrivacyPolicy({
          privacyPolicy: privacyPolicy as string,
          privacyPolicyArabic: privacyPolicyArabic as string,
        });
        enqueueSnackbar(result?.data?.response, {
          variant: "success",
        });
      } catch (err) {
        console.log(err);
      }
      setLoading({ ...isLoading, privacy: false });
    } else {
      setLoading({ ...isLoading, termsAndConditions: true });
      try {
        const result = await updateTermsAndConditions({
          termsAndConditions: termsAndConditions as string,
          termsAndConditionsArabic: termsAndConditionsArabic as string,
        });
        enqueueSnackbar(result?.data?.response, {
          variant: "success",
        });
      } catch (err) {
        console.log(err);
      }
      setLoading({ ...isLoading, termsAndConditions: false });
    }
    getPPandTc();
  };

  const components: Array<ReactNode> = [
    <DetailsComponent
      obj={obj}
      field={"privacyPolicy"}
      handelSave={handelSave}
      loading={isLoading?.privacy}
      getPPandTc={getPPandTc}
    />,
    <DetailsComponent
      field={"termsAndConditions"}
      obj={obj}
      handelSave={handelSave}
      loading={isLoading?.termsAndConditions}
      getPPandTc={getPPandTc}
    />,
  ];



  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Privacy Policy & Terms and Conditions</Heading>
        </LeftDiv>
      </HeaderDiv>
      {loadingSpin ? (
        <LoadingComp color="primary" />
      ) : (
        <TabSelector
          labelArray={["Privacy Policy", "Terms and Conditions"]}
          children={components as Array<ReactNode>}
        />
      )}
    </CustomerContainer>
  );
}

export default PrivacyPolicyComponent;
