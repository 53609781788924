import React, { ReactElement, useState } from "react";
import { withRouter } from "react-router";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import InputAdornment from "@material-ui/core/InputAdornment";
import { useSnackbar } from "notistack";

import BlogsTable from "pages/latest-blogs/components/blogs-table";
import { ArabicSwitch } from "pages/management/faqs/styles";

import TabSelector from "shared-components/tab-selector";
import ArabicSwitchDropdown from "shared-components/arabic-input/arabic-switch-dropdown";

import { NEW_BLOG } from "constants/route/route-constants";
import { LANGUAGE_FLAG_ARRAY } from "constants/style/props";

import BahrinFlag from "assets/bahrin-flag.png";
import UkFlag from "assets/uk-flag.png";

import { getAllBlogsThunk, getBlogsBySearchThunk } from "slices/latest-blogs";

import {
  CustomerContainer,
  Heading,
  InputTextField,
  Search,
  LeftDiv,
  RightDiv,
  HeaderDiv,
  NewDealButton,
} from "pages/latest-blogs/styles";

function LatestBlogs({ history }): ReactElement {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLang, setSelectedLang] = useState(UkFlag);
  const [Language, setLanguage] = useState("English");
  const [tabValue, setTabValue] = useState<number | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>("");

  const brandColor = useAppSelector((state) => state.brand.brandColor);
  const latestBlogs = useAppSelector((state) => state.blogs.blogsInfo);

  const languageArr = LANGUAGE_FLAG_ARRAY;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchDispatch = async (event) => {
    if (event.key === "Enter") {
      try {
        if (searchValue !== "") {
          await dispatch(
            getBlogsBySearchThunk({
              query: searchValue,
              pageno: 1,
              limit: 5,
              status: latestBlogs?.tabIndicator as string,
            })
          );
        } else {
          dispatch(
            getAllBlogsThunk({
              status: `${latestBlogs?.tabIndicator}`,
              pageno: latestBlogs?.currentPage,
              limit: 5,
            })
          );
        }
      } catch (error: any) {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    }
  };

  const handleCloseSnackbar = () => {
    closeSnackbar();
  };

  const handleLanguage = (label) => {
    const newFlag = label === "English" ? UkFlag : BahrinFlag;
    setSelectedLang(newFlag);
    setAnchorEl(null);
    setLanguage(label);
    // dispatch(
    //   filterLanguagesThunk({
    //     limit: 5,
    //     pageNumber: 1,
    //     language: flag,
    //     locType: locations?.currentTab+"s",
    //   })
    // );
  };

  const handleClick = () => {
    const elem = document.getElementById("flagDropdown");
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelTabActive = (tabNumber) => {
    setTabValue(tabNumber);
  };

  return (
    <CustomerContainer onClick={handleCloseSnackbar}>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Latest Blogs</Heading>

          <InputTextField
            id="deals"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            onKeyDown={handleSearchDispatch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color={brandColor} />
                </InputAdornment>
              ),
            }}
          />

          <ArabicSwitch>
            <ArabicSwitchDropdown
              selectedLang={selectedLang}
              handleClick={handleClick}
              anchorEl={anchorEl}
              languageArr={languageArr}
              handleClose={handleClose}
              handleLanguage={handleLanguage}
            />
          </ArabicSwitch>
        </LeftDiv>

        <RightDiv>
          <NewDealButton
            variant="contained"
            color="primary"
            onClick={() => history.push(NEW_BLOG)}
          >
            New Blog
          </NewDealButton>
        </RightDiv>
      </HeaderDiv>

      <TabSelector
        labelArray={["All Blogs", "Active", "Inactive"]}
        tabValue={tabValue as number}
        children={
          [
            <BlogsTable
              searchValue={searchValue}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
            <BlogsTable
              filterBy={"Active"}
              filterColumn={"status"}
              searchValue={searchValue}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
            <BlogsTable
              filterBy={"In-Active"}
              filterColumn={"status"}
              searchValue={searchValue}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
          ] as any
        }
      />
    </CustomerContainer>
  );
}

export default withRouter(LatestBlogs);
