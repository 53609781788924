import styled from "styled-components";
import { Tabs, Tab, Box, Paper } from "@material-ui/core/";

import { TEXTFIELD_HEADING } from "constants/style/font-size";

export const TabSelectorContainer = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: none !important;
  margin-top: 1.5rem;
`;

export const HeaderDiv = styled.h1``;

export const UnionDiv = styled.div``;

export const AppBar = styled(Paper)`
  width: fit-content;
  background: #ffffff;
  box-shadow: none !important;
`;

export const TabsContainer = styled(Tabs)`
  background-color: #fafafa !important;
`;

export const SingleTab = styled(Tab)`
  margin-right: 0.5rem !important;
  background: white !important;
  border-radius: 10px 10px 0px 0px !important;
  text-transform: none !important;
  font-size: ${TEXTFIELD_HEADING} !important;
  background: #c4c4c4;
  border-radius: 10px 10px 0px 0px;
`;

export const BoxContainer = styled.div``;

export const DetailBox = styled(Box)`
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
`;
