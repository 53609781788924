import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateRange } from "@mui/lab/DateRangePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import WeeklyTimeSelector from "../dropdown-box";
import { DAYS_DISABLED, DAYS_SELECTED } from "constants/style/color";
import { BTN_DISABLED } from "constants/style/props";

import {
  Days,
  DaysContainer,
  AddWeekBtn,
  WeekContainer,
  FlexContainer,
  RemoveWeek,
  InputTextField,
  AccordianContainer,
  AccordionDetailsContainer,
} from "pages/location/components/accordian/styles";

const useStyles = makeStyles(() =>
  createStyles({
    selected: {
      backgroundColor: `${DAYS_SELECTED + "!important"}`,
    },
    disabled: {
      backgroundColor: `${DAYS_DISABLED + "!important"}`,
      cursor: "default",
      pointerEvents: "none",
    },
    addWeekDisabled: BTN_DISABLED,
  })
);

const initialState = {
  days: [
    { day: "Mon", isSelected: false, isAlreadyAdded: false },
    { day: "Tue", isSelected: false, isAlreadyAdded: false },
    { day: "Wed", isSelected: false, isAlreadyAdded: false },
    { day: "Thu", isSelected: false, isAlreadyAdded: false },
    { day: "Fri", isSelected: false, isAlreadyAdded: false },
    { day: "Sat", isSelected: false, isAlreadyAdded: false },
    { day: "Sun", isSelected: false, isAlreadyAdded: false },
  ],
  timeSlots: [
    {
      open: "9:00 AM",
      close: "7:00 PM",
    },
  ],
};

interface Props {
  mainState: any;
  setMainState: any;
  handelTimeStatus: (boolean) => void;
}

export default function TimingComponent({
  mainState,
  setMainState,
  handelTimeStatus,
}: Props) {
  const classes = useStyles();
  const location = useLocation<any>();
  // console.log(mainState, "mainState");

  const [dummyState, setDummyState] = useState(
    JSON.parse(JSON.stringify(initialState))
  );
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const [eventNote, setEventNote] = useState("");
  const [isWeekFilled, setIsWeekFilled] = useState(false);
  const [isWeekFilledCustom, setIsWeekFilledCustom] = useState(false);

  const {
    normalTimings,
    customTimings,
    customStartDate,
    customEndDate,
    noteForCustomTimings,
  } = mainState;

  useEffect(() => {
    if (location?.state?.detail === "edit") {
      setValue([customStartDate, customEndDate]);
      setEventNote(noteForCustomTimings);
    }
  }, [
    customEndDate,
    customStartDate,
    location?.state?.detail,
    noteForCustomTimings,
  ]);

  useEffect(() => {
    if (location?.state?.detail === "edit") {
      handelDummyStateForEdit(
        JSON.parse(JSON.stringify(normalTimings)),
        "normalTimings"
      );
      handelDummyStateForEdit(
        JSON.parse(JSON.stringify(customTimings)),
        "customTimings"
      );
      checkIfWeekFilled(normalTimings[0], "isWeekFilled");
      checkIfWeekFilled(customTimings[0], "isWeekFilledCustom");
    }
  }, [customTimings, normalTimings, location?.state?.detail]);

  const handelDummyStateForEdit = (timings, key) => {
    const result = timings[0].days.map((item) => {
      if (item.isSelected) {
        item.isSelected = false;
        return item;
      } else {
        return item;
      }
    });

    var newState = {
      days: result,
      timeSlots: initialState.timeSlots,
    };

    key === "normalTimings"
      ? setDummyState(JSON.parse(JSON.stringify(newState)))
      : setDummyStateForCustom(JSON.parse(JSON.stringify(newState)));
  };

  const checkIfWeekFilled = (obj, key) => {
    const res = obj.days.filter((item) => item.isAlreadyAdded);
    if (res.length === 7) {
      key === "isWeekFilled"
        ? setIsWeekFilled(true)
        : setIsWeekFilledCustom(true);
    } else {
      key === "isWeekFilled"
        ? setIsWeekFilled(false)
        : setIsWeekFilledCustom(false);
    }
  };

  const handelEventNote = (value) => {
    setEventNote(value);
    mainState.noteForCustomTimings = value;
    setMainState({ ...mainState });
    handelTimeStatus(true);
  };

  const handelDateRangeChange = (newValue) => {
    setValue(newValue);
    mainState.customStartDate = newValue[0];
    mainState.customEndDate = newValue[1];
    setMainState({ ...mainState });
    handelTimeStatus(true);
  };

  const handelDaySelect = function (weekInd, dayInd) {
    const result = normalTimings[weekInd].days.map((item, index) => {
      if (index === dayInd) {
        item.isSelected = !item.isSelected;
        return item;
      } else {
        return item;
      }
    });

    const dummyStateResult = dummyState.days.map((item, index) => {
      if (index === dayInd) {
        item.isAlreadyAdded = !item.isAlreadyAdded;
        return item;
      } else {
        return item;
      }
    });
    setDummyState({ ...dummyState, days: dummyStateResult });

    normalTimings[weekInd].days = result;
    const len = normalTimings.length;
    for (let i = 0; i < len; i++) {
      const result = normalTimings[i].days.map((item, index) => {
        if (index === dayInd) {
          item.isAlreadyAdded = !item.isAlreadyAdded;
          return item;
        } else {
          return item;
        }
      });
      normalTimings[i].days = result;
    }

    checkIfWeekFilled(dummyState, "isWeekFilled");
    setMainState({ ...mainState, normalTimings: normalTimings });
    handelTimeStatus(true);
    // console.log(mainState);
  };

  const handelAlreadySelectedOnDelete = (weekInd) => {
    const deletedSelects = normalTimings[weekInd].days.filter(
      (item) => item.isSelected
    );
    return deletedSelects.map((item) => item.day);
  };

  const handelAddTimeSlots = (weekInd) => {
    normalTimings[weekInd].timeSlots.push({
      open: "9:00 AM",
      close: "7:00 PM",
    });
    setMainState({ ...mainState, normalTimings: normalTimings });
    handelTimeStatus(true);
  };

  const handelAddWeek = () => {
    normalTimings.push(JSON.parse(JSON.stringify(dummyState)));
    setMainState({ ...mainState, normalTimings: normalTimings });
    handelTimeStatus(true);
    // console.log(mainState);
  };

  const handelDeleteWeek = (index) => {
    const deleted = handelAlreadySelectedOnDelete(index);
    const result = normalTimings.filter((item, ind) => ind !== index);
    const len = result.length;

    for (let i = 0; i < len; i++) {
      const newTiming = result[i].days.map((item) => {
        if (deleted.includes(item.day)) {
          item.isAlreadyAdded = !item.isAlreadyAdded;
          return item;
        } else {
          return item;
        }
      });
      result[i].days = newTiming;
    }
    setMainState({ ...mainState, normalTimings: result });
    handelTimeStatus(true);

    const dummyStateResult = dummyState.days.map((item) => {
      if (deleted.includes(item.day)) {
        item.isAlreadyAdded = !item.isAlreadyAdded;
        return item;
      } else {
        return item;
      }
    });
    setDummyState({ ...dummyState, days: dummyStateResult });
    checkIfWeekFilled(dummyState, "isWeekFilled");
  };

  const handelRemoveTimeSlots = (weekInd, timeSlotInd) => {
    const result = normalTimings[weekInd].timeSlots.filter(
      (item, ind) => ind !== timeSlotInd
    );
    normalTimings[weekInd].timeSlots = result;
    setMainState({ ...mainState, normalTimings: normalTimings });
    handelTimeStatus(true);
  };

  const handelOpenTime = (weekInd, timeSlotInd, value) => {
    const result = normalTimings[weekInd].timeSlots.map((item, index) => {
      if (index === timeSlotInd) {
        item.open = value;
        return item;
      } else {
        return item;
      }
    });
    normalTimings[weekInd].timeSlots = result;
    setMainState({ ...mainState, normalTimings: normalTimings });
    handelTimeStatus(true);
    //;
  };

  const handelCloseTime = (weekInd, timeSlotInd, value) => {
    const result = normalTimings[weekInd].timeSlots.map((item, index) => {
      if (index === timeSlotInd) {
        item.close = value;
        return item;
      } else {
        return item;
      }
    });
    normalTimings[weekInd].timeSlots = result;
    setMainState({ ...mainState, normalTimings: normalTimings });
    handelTimeStatus(true);
    //;
  };

  // Custom Timing things

  const [dummyStateForCustom, setDummyStateForCustom] = useState(
    JSON.parse(JSON.stringify(initialState))
  );

  const handelDaySelectCustom = function (weekInd, dayInd) {
    const result = customTimings[weekInd].days.map((item, index) => {
      if (index === dayInd) {
        item.isSelected = !item.isSelected;
        return item;
      } else {
        return item;
      }
    });

    const dummyStateResult = dummyStateForCustom.days.map((item, index) => {
      if (index === dayInd) {
        item.isAlreadyAdded = !item.isAlreadyAdded;
        return item;
      } else {
        return item;
      }
    });
    setDummyStateForCustom({ ...dummyStateForCustom, days: dummyStateResult });

    customTimings[weekInd].days = result;
    const len = customTimings.length;
    for (let i = 0; i < len; i++) {
      const result = customTimings[i].days.map((item, index) => {
        if (index === dayInd) {
          item.isAlreadyAdded = !item.isAlreadyAdded;
          return item;
        } else {
          return item;
        }
      });
      customTimings[i].days = result;
    }

    checkIfWeekFilled(dummyStateForCustom, "isWeekFilledCustom");
    setMainState({ ...mainState, customTimings: customTimings });
    handelTimeStatus(true);
  };

  const handelAlreadySelectedOnDeleteCustom = (weekInd) => {
    const deletedSelects = customTimings[weekInd].days.filter(
      (item) => item.isSelected
    );
    return deletedSelects.map((item) => item.day);
  };

  const handelAddTimeSlotsCustom = (weekInd) => {
    customTimings[weekInd].timeSlots.push({
      open: "9:00 AM",
      close: "7:00 PM",
    });
    setMainState({ ...mainState, customTimings: customTimings });
    handelTimeStatus(true);
  };

  const handelAddWeekCustom = () => {
    customTimings.push(JSON.parse(JSON.stringify(dummyStateForCustom)));
    setMainState({ ...mainState, customTimings: customTimings });
    handelTimeStatus(true);
  };

  const handelDeleteWeekCustom = (index) => {
    const deleted = handelAlreadySelectedOnDeleteCustom(index);
    const result = customTimings.filter((item, ind) => ind !== index);
    const len = result.length;

    for (let i = 0; i < len; i++) {
      const newTiming = result[i].days.map((item) => {
        if (deleted.includes(item.day)) {
          item.isAlreadyAdded = !item.isAlreadyAdded;
          return item;
        } else {
          return item;
        }
      });
      result[i].days = newTiming;
    }
    setMainState({ ...mainState, customTimings: result });
    handelTimeStatus(true);

    const dummyStateResult = dummyStateForCustom.days.map((item) => {
      if (deleted.includes(item.day)) {
        item.isAlreadyAdded = !item.isAlreadyAdded;
        return item;
      } else {
        return item;
      }
    });
    setDummyStateForCustom({ ...dummyStateForCustom, days: dummyStateResult });
    checkIfWeekFilled(dummyStateForCustom, "isWeekFilledCustom");
  };

  const handelRemoveTimeSlotsCustom = (weekInd, timeSlotInd) => {
    const result = customTimings[weekInd].timeSlots.filter(
      (item, ind) => ind !== timeSlotInd
    );
    customTimings[weekInd].timeSlots = result;
    setMainState({ ...mainState, customTimings: customTimings });
    handelTimeStatus(true);
  };

  const handelOpenTimeCustom = (weekInd, timeSlotInd, value) => {
    const result = customTimings[weekInd].timeSlots.map((item, index) => {
      if (index === timeSlotInd) {
        item.open = value;
        return item;
      } else {
        return item;
      }
    });
    customTimings[weekInd].timeSlots = result;
    setMainState({ ...mainState, customTimings: customTimings });
    handelTimeStatus(true);
  };

  const handelCloseTimeCustom = (weekInd, timeSlotInd, value) => {
    const result = customTimings[weekInd].timeSlots.map((item, index) => {
      if (index === timeSlotInd) {
        item.close = value;
        return item;
      } else {
        return item;
      }
    });
    customTimings[weekInd].timeSlots = result;
    setMainState({ ...mainState, customTimings: customTimings });
    handelTimeStatus(true);
  };

  return (
    <AccordianContainer>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Timings</Typography>
        </AccordionSummary>
        <AccordionDetailsContainer>
          {/* Second Approach */}

          <WeekContainer>
            {normalTimings?.map((mainWeek, index) => {
              return (
                <DaysContainer key={`mainWeek_${index}`}>
                  <FlexContainer>
                    {mainWeek?.days.map((item, ind) => (
                      <Days
                        key={`${item}_${ind}`}
                        className={`${
                          item.isSelected
                            ? classes.selected
                            : item.isAlreadyAdded
                            ? classes.disabled
                            : ""
                        }`}
                        onClick={() => handelDaySelect(index, ind)}
                      >
                        {item.day}
                      </Days>
                    ))}

                    {normalTimings.length >= 2 && (
                      <RemoveWeek onClick={() => handelDeleteWeek(index)} />
                    )}
                  </FlexContainer>

                  {mainWeek.timeSlots.length !== 0 &&
                    mainWeek.timeSlots.map((item, ind) => (
                      <WeeklyTimeSelector
                        key={`${item}_${ind}`}
                        open={item.open}
                        close={item.close}
                        handelRemoveTimeSlots={handelRemoveTimeSlots}
                        weekInd={index}
                        index={ind}
                        handelOpenTime={handelOpenTime}
                        handelCloseTime={handelCloseTime}
                        handelAddTimeSlots={handelAddTimeSlots}
                      />
                    ))}
                </DaysContainer>
              );
            })}
          </WeekContainer>
          {normalTimings.length <= 6 && (
            <AddWeekBtn
              variant="contained"
              color="primary"
              size="small"
              className={`${isWeekFilled ? classes.addWeekDisabled : ""}`}
              onClick={handelAddWeek}
            >
              Add Week
            </AddWeekBtn>
          )}
        </AccordionDetailsContainer>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Custom Timings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InputTextField
            variant="outlined"
            size="small"
            placeholder="Event note"
            value={eventNote}
            style={{ width: "50%" }}
            onChange={(e) => handelEventNote(e.target.value)}
          />
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={startDate}
              onChange={handleStartChange}
              format="d MMM yyyy"
              inputVariant="outlined"
              size="small"
              label="Start"
              disablePast
              helperText={null}
              error={undefined}
            />
            <DatePicker
              value={endDate}
              onChange={handleEndChange}
              format="d MMM yyyy"
              inputVariant="outlined"
              size="small"
              label="End"
              disablePast
              helperText={null}
            />
          </MuiPickersUtilsProvider> */}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateRangePicker
              startText="Start"
              value={value}
              minDate={new Date()}
              inputFormat="d MMM yyyy"
              reduceAnimations
              onChange={handelDateRangeChange}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} size="small" />
                  <Box sx={{ mx: 1 }}>to</Box>
                  <TextField {...endProps} size="small" />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </AccordionDetails>

        <AccordionDetailsContainer>
          <WeekContainer>
            {customTimings?.map((mainWeek, index) => {
              return (
                <DaysContainer key={`mainWeek_${index}`}>
                  <FlexContainer>
                    {mainWeek?.days.map((item, ind) => (
                      <Days
                        key={`${item}_${ind}`}
                        className={`${
                          item.isSelected
                            ? classes.selected
                            : item.isAlreadyAdded
                            ? classes.disabled
                            : ""
                        }`}
                        onClick={() => handelDaySelectCustom(index, ind)}
                      >
                        {item.day}
                      </Days>
                    ))}

                    {customTimings.length >= 2 && (
                      <RemoveWeek
                        onClick={() => handelDeleteWeekCustom(index)}
                      />
                    )}
                  </FlexContainer>

                  {mainWeek.timeSlots.length !== 0 &&
                    mainWeek.timeSlots.map((item, ind) => (
                      <WeeklyTimeSelector
                        key={`${item}_${ind}`}
                        open={item.open}
                        close={item.close}
                        handelRemoveTimeSlots={handelRemoveTimeSlotsCustom}
                        weekInd={index}
                        index={ind}
                        handelOpenTime={handelOpenTimeCustom}
                        handelCloseTime={handelCloseTimeCustom}
                        handelAddTimeSlots={handelAddTimeSlotsCustom}
                      />
                    ))}
                </DaysContainer>
              );
            })}
          </WeekContainer>
          {customTimings.length <= 6 && (
            <AddWeekBtn
              variant="contained"
              color="primary"
              size="small"
              className={`${isWeekFilledCustom ? classes.addWeekDisabled : ""}`}
              onClick={handelAddWeekCustom}
            >
              Add Week
            </AddWeekBtn>
          )}
        </AccordionDetailsContainer>
      </Accordion>
    </AccordianContainer>
  );
}
