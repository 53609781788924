import React, { ReactElement } from "react";

function FilterIcon(props): ReactElement {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 5a2 2 0 100-4 2 2 0 000 4zM1 3h8M13 3h4M5 11a2 2 0 100-4 2 2 0 000 4zM1 9h2M7 9h10M14 17a2 2 0 100-4 2 2 0 000 4zM1 15h11M16 15h1"
        stroke="#000"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FilterIcon;
