import React, { ReactElement } from "react";

function CopyToClipboard(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 4.75H3.5C2.83696 4.75 2.20107 5.01339 1.73223 5.48223C1.26339 5.95107 1 6.58696 1 7.25V18.5C1 19.163 1.26339 19.7989 1.73223 20.2678C2.20107 20.7366 2.83696 21 3.5 21H14.75C15.413 21 16.0489 20.7366 16.5178 20.2678C16.9866 19.7989 17.25 19.163 17.25 18.5V12.25"
        stroke="black"
        opacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 13.5L21 1"
        stroke="black"
        opacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 1H21V7.25"
        stroke="black"
        opacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CopyToClipboard;
