export const BLACK_COLOR = "#000000";
export const INPUT_FIELD_BACKGROUND = "#FDFDFD";
export const DISABLED_TEXT = "#666666";
export const TEXTBOX_BORDER = "#D1D2D4";
export const PRIMARY_TOYOTA = "#EB0A1E";
export const SECONDARY_TOYOTA = "#fde7e8";
export const TERTIARY_TOYOTA = "#FF7272";
export const PRIMARY_LEXUS = "#000000";
export const SECONDARY_LEXUS = "#BDBDBD";
export const TERTIARY_LEXUS = "#BDBDBD";
export const PRIMARY_MOTORCITY = "#123F90";
export const SECONDARY_MOTORCITY = "#E7ECF4";
export const TERTIARY_MOTORCITY = "#2D9CDB";
export const NAVLINK_COLOR = "#2D609F";
export const TEXTFIELD_HEADING = "#222223";
export const WHITE_COLOR = "#ffffff";
export const DASHBOARD_TOYOTA_CIRCLE_COLOR = "rgba(255, 114, 114, 0.5)";
export const TAG_COLOR_GREEN = "#DFF3E7";
export const TAG_COLOR_RED = "#FCDADD";
export const GREEN_TAG_FONT_COLOR = "#27AE60";
export const RED_TAG_FONT_COLOR = "#EB0A1E";
export const SWITCH_BG_COLOR = "#BDBDBD";
export const DAYS_SELECTED = "#27AE60";
export const DAYS_DISABLED = "#9E9D89";
export const AMBER_COLOR = "#ffbf00";
export const GREY_COLOR = "#808080";


