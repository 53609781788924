import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getTestDriveDetails,
  testDriveSearch,
  testDriveFilter,
} from "services/test-drive-services";

import {
  TestDriveFilterInterface,
  GetTestDriveRequest,
  TestDriveSearchRequest,
} from "interfaces/test-drive.interface";

export const getTestDriveThunk = createAsyncThunk(
  "getTestDriveThunk",
  async (request: GetTestDriveRequest, { rejectWithValue }) => {
    try {
      const data = await getTestDriveDetails(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const testDriveSearchThunk = createAsyncThunk(
  "testDriveSearchThunk",
  async (request: TestDriveSearchRequest, { rejectWithValue }) => {
    try {
      const data = await testDriveSearch(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTestDriveByFilter = createAsyncThunk(
  "getTestDriveByFilter",
  async (request: TestDriveFilterInterface, { rejectWithValue }) => {
    try {
      const result = await testDriveFilter(request);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
