import { createAsyncThunk } from "@reduxjs/toolkit";

import { GetDashboardDetails } from "services/dashboard-services";

export const getDashboardDetailsThunk = createAsyncThunk(
  "getDashboardDetailsThunk",
  async (request: number, { rejectWithValue }) => {
    try {
      const data = await GetDashboardDetails(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
