import styled from "styled-components";

import { Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core";

import {
  COMPONENT_HEADING,
  PARAGRAPH_FONT_SIZE,
  SUBHEADER_FONT_SIZE,
} from "constants/style/font-size";
import {
  PRIMARY_MEDIUM_FONT,
  PRIMARY_REGULAR_FONT,
} from "constants/style/fonts";
import { BLACK_COLOR } from "constants/style/color";

export const MainDiv = styled.div`
  padding: 1.5rem 2rem;
`;

export const LeftDiv = styled.div`
  display: flex;
`;

export const RightDiv = styled.div``;

export const Heading = styled.p`
  font-size: ${COMPONENT_HEADING};
  margin: 0;
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-weight: bold;
  user-select: none;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  margin: 0 0 2rem;
  padding: 0.625rem 1rem;
`;

export const DiscoverMeContainer = styled.div`
  padding: 0 1rem 0;
`;

export const CancelButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 1rem !important;
`;

export const InputFieldContainer = styled(Paper)`
  padding: 1.5rem 3rem 1rem 1.5rem;
  margin-top: 1rem;
`;
export const NavContainer = styled.div`
  margin-bottom: 1rem;
`;

export const InputTextField = styled(TextField)`
  width: 20rem;
  font-size: ${PARAGRAPH_FONT_SIZE};
`;
export const InputLabelText = styled(InputLabel)`
  margin-bottom: 0.8rem;
  color: ${BLACK_COLOR} !important;
  font-size: ${PARAGRAPH_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;

export const SubHeading = styled.p`
  font-size: ${SUBHEADER_FONT_SIZE};
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-weight: bold;
  user-select: none;
  margin: 2rem 0 1rem;
`;

export const DiscoverMeSubHeading = styled(SubHeading)`
  margin: 0 0 1rem;
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4rem;
  padding: 0.625rem 1rem;
`;
export const LeftContainer = styled.div``;
export const RightContainer = styled.div`
  margin-right: 10rem;
`;
export const TabContainer = styled.div`
  padding: 0 !important;
  margin: 0 !important;
  width: auto;
`;
