import React, { ReactElement, ReactNode } from "react";

import {
  MainDiv,
  Heading,
  ChildrenDiv,
} from "shared-components/paper-component/styles";

interface Props {
  children?: ReactNode;
  heading: string;
}

function PaperComponent({ children, heading }: Props): ReactElement {
  return (
    <>
      <MainDiv>
        <Heading>{heading}</Heading>
        <ChildrenDiv>{children}</ChildrenDiv>
      </MainDiv>
    </>
  );
}

export default PaperComponent;
