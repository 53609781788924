import axios from "axios";
import selectedBrand from "constants/brand";

import {
  BASE_URL,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
} from "constants/api-config-constants";

import { PrivacyDetails, PrivacyDetailsResponse, TermsAndConditions, TermsAndConditionsResponse } from "interfaces/privacy-policy";

export const getPrivacyPolicy = async (): Promise<PrivacyDetailsResponse> => {
  const url = BASE_URL + PRIVACY_POLICY + "?brand=" + selectedBrand();
  return axios.get(url);
};

export const getTermsAndConditions = async (): Promise<TermsAndConditionsResponse> => {
  const url = BASE_URL + TERMS_AND_CONDITIONS + "?brand=" + selectedBrand();
  return axios.get(url);
};

export const updatePrivacyPolicy = async (
  request: PrivacyDetails
): Promise<PrivacyDetailsResponse> => {
  const url = BASE_URL + PRIVACY_POLICY + "?brand=" + selectedBrand();
  return axios.post(url, request);
};

export const updateTermsAndConditions = async (
  request: TermsAndConditions
): Promise<TermsAndConditionsResponse> => {
  const url = BASE_URL + TERMS_AND_CONDITIONS + "?brand=" + selectedBrand();
  return axios.post(url, request);
};
