import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

interface Props {
  iscolor?: boolean;
}
export const Loader = styled(CircularProgress)`
  position: absolute;
  color: ${({ iscolor }: Props) =>
    !iscolor ? "white !important" : `${({ theme }) => theme.color}`};
`;
