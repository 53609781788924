import React, { ReactElement } from "react";

interface Props {
  color: string;
}

function DashboardLogo({ color }: Props): ReactElement {
  return (
    <svg
      width={18}
      height={20}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <path
        d="M1 8l9-7 9 7v11a2 2 0 01-2 2H3a2 2 0 01-2-2V8z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DashboardLogo;
