import React, { ReactElement } from "react";

import {
  FlexBoxArabic,
  CancelInput,
  InputArabicTextField,
  InputLabelText,
} from "shared-components/arabic-input/textfield/styles";

interface Props {
  value: string | number | null;
  errorMessage?: string;
  handleArabicChange: (e, val) => void;
  handleArabicText: (val, type) => void;
  typeOfField: string;
  labelText: string;
  width: string;
}
export default function ArabicInput({
  value,
  errorMessage,
  typeOfField,
  handleArabicText,
  handleArabicChange,
  labelText,
  width,
}: Props): ReactElement {
  return (
    <>
      <InputLabelText> {labelText} (Arabic)</InputLabelText>
      <FlexBoxArabic>
        <InputArabicTextField
          width={width}
          variant="outlined"
          size="small"
          value={value}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          onChange={(e) => handleArabicChange(e, typeOfField)}
          dir="rtl"
        />
        <CancelInput onClick={() => handleArabicText(typeOfField, "hide")} />
      </FlexBoxArabic>
    </>
  );
}
