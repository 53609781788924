import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import { useSnackbar } from "notistack";
import { CsvBuilder } from "filefy";
import { format } from 'date-fns'
import {
  GridColDef,
  GridRowModel,
  GridRowId,
  GridCellParams,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core";

import { RenderCellExpand } from "shared-components/expanded-cel";
import PaginationFooter from "shared-components/pagination";
import TableData from "shared-components/table-data";

// import { loadingHelper } from "constants/loadingHelper";

import {
  MainContainer,
  CustomTagGreen,
  PendingCircle,
  CompletedCircle,
  StatusText,
  OtherCircle,
  InprogressCircle,
  CompleteCircle,
} from "pages/services/service-bookings/components/service-bookings-table/styles";

import {
  getOngoingServiceBookingThunk,
  getUpcomingServiceBookingThunk,
  getPreviousServiceBookingThunk,
  ServiceBookingSearchThunk,
} from "slices/services/service-bookings";

import {
  setCurrentPageReducer,
  setCurrentTabReducer,
} from "slices/services/service-bookings";
import { ServiceBooking } from "interfaces/service-booking.interface";
import { ServiceBookingFilter } from "services/service-booking-services";
import { getPreviousServiceBookings } from "services/service-booking-services";
import { getUpcomingServiceBookings } from "services/service-booking-services";
import { getOngoingServiceBookings } from "services/service-booking-services";

interface Props {
  filterBy?: string;
  exportData?: boolean;
  setExportData?: any;
  handelTabActive: (tabNumber: number) => void;
  searchValue: string;
  filterFunc: (pageNumber, currentTab) => void;
  filterFlag: boolean;
  transactionLimit: number;
  filterState: any,
}

const tabObj = {
  "On-Going": 0,
  Upcoming: 1,
  History: 2,
};

const useStyles = makeStyles({
  root: {
    "& .centered": {
      textAlign: "center",
    },
  },
});

const ServiceBookingTable = ({
  filterBy,
  exportData,
  setExportData,
  handelTabActive,
  searchValue,
  filterFunc,
  filterFlag,
  transactionLimit,
  filterState,
}: Props) => {

  let allRecords: ServiceBooking[] = []
  let filteredRecords: ServiceBooking[] = []

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<GridRowModel>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);


  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  const serviceBookings = useAppSelector(
    (state) => state.serviceBookings.serviceBookingInfo
  );

  const handleCheckedData = (newSelection) => {
    setSelectionModel(newSelection.selectionModel);
  };

  const ApiCallHelper = (thunk, pageNumber, limit) => {
    // loadingHelper(
    //   dispatch(thunk({ pageNumber: pageNumber, limit: limit })),
    //   setLoading,
    //   enqueueSnackbar
    // );
    setLoading(true);
    dispatch(thunk({ pageNumber: pageNumber, limit: limit })).unwrap()
      .then((res) => {
        setLoading(false);
        if ('data' in res?.data) {
          enqueueSnackbar(res?.data?.data, { variant: "warning" });
        }
      })
      .catch((err) => {
        setLoading(false);
        if ('error' in err) {
          enqueueSnackbar(err.error, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong..!!", { variant: "error" });
        }
      });
  };

  useEffect(() => {
    if (filterFlag) {
      filterFunc(1, filterBy);
    } else {
      if (filterBy === "On-Going") {
        ApiCallHelper(getOngoingServiceBookingThunk, 1, transactionLimit);
      } else if (filterBy === "Upcoming") {
        ApiCallHelper(getUpcomingServiceBookingThunk, 1, transactionLimit);
      } else {
        ApiCallHelper(getPreviousServiceBookingThunk, 1, transactionLimit);
      }
      handelTabActive(tabObj[`${filterBy}`]);
      dispatch(setCurrentTabReducer(filterBy as string));
      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line
  }, [filterBy, dispatch, brandName, transactionLimit]);

  useEffect(() => {
    setRows(serviceBookings?.allServiceBookings);
    setPaginationSize(Math.ceil(serviceBookings.recordsLength / transactionLimit));
    // eslint-disable-next-line
  }, [serviceBookings]);


  const handlePagination = (event, number) => {
    if (filterFlag) {
      filterFunc(number, filterBy);
    } else if (searchValue !== "") {
      dispatch(
        ServiceBookingSearchThunk({
          pageNumber: number,
          serviceStatus: filterBy,
          searchText: searchValue,
          limit: transactionLimit
        })
      );
    } else {
      if (filterBy === "On-Going") {
        ApiCallHelper(getOngoingServiceBookingThunk, number, transactionLimit);
      } else if (filterBy === "Upcoming") {
        ApiCallHelper(getUpcomingServiceBookingThunk, number, transactionLimit);
      } else {
        ApiCallHelper(getPreviousServiceBookingThunk, number, transactionLimit);
      }
    }

    dispatch(setCurrentPageReducer(number));
  };

  const columns: GridColDef[] = [
    {
      field: "sfId",
      headerName: "Booking ID",
      sortable: false,
      flex: 0.6,
      cellClassName: "centered",
    },
    {
      field: "name",
      headerName: "Customer  Name",
      sortable: false,
      resizable: false,
      flex: .7,
      renderCell: RenderCellExpand,
    },
    {
      field: "vinNumber",
      sortable: false,
      headerName: "Vehicle Vin Number",
      resizable: false,
      flex: .7,
      renderCell: RenderCellExpand,
    },
    {
      field: "serviceCenter",
      headerName: "Service Center",
      sortable: false,
      resizable: false,
      flex: .5,
      renderCell: RenderCellExpand,
    },
    {
      field: "serviceDate",
      sortable: false,
      headerName: "Date and Time",
      resizable: false,
      flex: .8,
      renderCell: (params: GridCellParams) => {
        const { serviceDate, serviceTime } = params.row;
        return (
          <>
            {serviceDate} | {serviceTime}
          </>
        );
      },
    },
    {
      field: "serviceType",
      sortable: false,
      headerName: "Type",
      resizable: false,
      flex: .6,
      renderCell: RenderCellExpand,
    },
    {
      field: "status",
      headerName: "Service Status",
      sortable: false,
      resizable: false,
      flex: 0.9,
      renderCell: (params: GridCellParams) => {
        const { status } = params.row;
        return (
          <CustomTagGreen>
            {params.row.status === "Confirmed" ? <CompletedCircle /> : (params.row.status === "Completed" ? <CompleteCircle /> :
              ((params.row.status === "pending" || params.row.status === "Confirmation Pending" || params.row.status === "Cancellation Pending") ? <PendingCircle /> :
                (params.row.status === "Cancelled" || params.row.status === "Failed") ? <InprogressCircle /> : <OtherCircle />))}
            <StatusText>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </StatusText>
          </CustomTagGreen>
        );
      },
    },
  ];

  const csvBuilder = (filename, rowsToCsv) => {
    const currentDateTime = format(new Date(), 'yyyy_MM_dd_HH_mm');
    const formattedFilename = `${filename}_${currentDateTime}.csv`;

    new CsvBuilder(formattedFilename)
      .setColumns(columns.map((col) => col?.headerName as string) as string[])
      .addRows(rowsToCsv as string[][])
      .exportFile();
  };

  const filterRowsData = (data) => {
    let res: Array<string> = [];
    columns.map((col) => {
      if (col.field !== "name") {
        if (Object.keys(data).indexOf(col.field) === -1) {
          // console.log(col.field, "field");
          res.push("");
        }
      }
      Object.entries(data).forEach(([key, value]) => {
        if (col.field === "name" && key === "firstName") {
          res.push(
            ((data?.firstName as string) + " " + data?.lastName) as string
          );
        }
        if (key === col.field) {
          res.push(value as string);
        }
      });
      return res;
    });
    return res;
  };

  const exportServiceBookingHistoryData = async () => {
    try {
      let request = {

        pageNumber: 1,
        limit: transactionLimit,
      }
      let response = await getPreviousServiceBookings(request)
      const totalRecords = response.data.totalRecords

      request = {
        pageNumber: 1,
        limit: Math.ceil(totalRecords),
      }
      response = await getPreviousServiceBookings(request)
      allRecords = response.data.listEntityClass
      console.log(allRecords)

      let allRecordsArray = allRecords.map(obj => [
        obj.sfId,
        obj.name,
        obj.vinNumber,
        obj.serviceCenter,
        `${obj.serviceDate} | ${obj.serviceTime}`,
        obj.serviceType,
        obj.status,
      ]);

      csvBuilder('Service_Bookings', allRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  const exportServiceBookingUpcomingData = async () => {
    try {
      let request = {
        pageNumber: 1,
        limit: transactionLimit,
      }
      let response = await getUpcomingServiceBookings(request)
      const totalRecords = response.data.totalRecords

      request = {
        pageNumber: 1,
        limit: Math.ceil(totalRecords),
      }
      response = await getUpcomingServiceBookings(request)
      allRecords = response.data.listEntityClass
      console.log(allRecords)

      let allRecordsArray = allRecords.map(obj => [
        obj.sfId,
        obj.name,
        obj.vinNumber,
        obj.serviceCenter,
        `${obj.serviceDate} | ${obj.serviceTime}`,
        obj.serviceType,
        obj.status,
      ]);

      csvBuilder('Service_Bookings', allRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  const exportServiceBookingOngoingData = async () => {
    try {
      let request = {
        pageNumber: 1,
        limit: transactionLimit,
      }
      let response = await getOngoingServiceBookings(request)
      const totalRecords = response.data.totalRecords

      request = {
        pageNumber: 1,
        limit: Math.ceil(totalRecords),
      }
      response = await getOngoingServiceBookings(request)
      allRecords = response.data.listEntityClass
      console.log(allRecords)

      let allRecordsArray = allRecords.map(obj => [
        obj.sfId,
        obj.name,
        obj.vinNumber,
        obj.serviceCenter,
        `${obj.serviceDate} | ${obj.serviceTime}`,
        obj.serviceType,
        obj.status,
      ]);

      csvBuilder('Service_Bookings', allRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  const exportFilteredServiceBookingHistoryData = async () => {
    try {

      let request = {
        pageNumber: 1,
        serviceStatus: serviceBookings.currentTab,
        limit: transactionLimit,
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        center: filterState.center,
        serviceType: filterState.serviceType,
      }

      let response = await ServiceBookingFilter(request)
      const filteredCustomers = response.data.totalRecords

      request = {
        pageNumber: 1,
        serviceStatus: serviceBookings.currentTab,
        limit: filteredCustomers,
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        center: filterState.center,
        serviceType: filterState.serviceType,
      }
      response = await ServiceBookingFilter(request)
      filteredRecords = response.data.listEntityClass

      let filteredRecordsArray = filteredRecords.map(obj => [
        obj.sfId,
        obj.name,
        obj.vinNumber,
        obj.serviceCenter,
        `${obj.serviceDate} | ${obj.serviceTime}`,
        obj.serviceType,
        obj.status,
      ]);
      csvBuilder('Service_Bookings', filteredRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  const exportFilteredServiceBookingUpcomingData = async () => {
    try {

      let request = {
        pageNumber: 1,
        serviceStatus: serviceBookings.currentTab,
        limit: transactionLimit,
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        center: filterState.center,
        serviceType: filterState.serviceType,
      }

      let response = await ServiceBookingFilter(request)
      const filteredCustomers = response.data.totalRecords

      request = {
        pageNumber: 1,
        serviceStatus: serviceBookings.currentTab,
        limit: filteredCustomers,
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        center: filterState.center,
        serviceType: filterState.serviceType,
      }
      response = await ServiceBookingFilter(request)
      filteredRecords = response.data.listEntityClass

      let filteredRecordsArray = filteredRecords.map(obj => [
        obj.sfId,
        obj.name,
        obj.vinNumber,
        obj.serviceCenter,
        `${obj.serviceDate} | ${obj.serviceTime}`,
        obj.serviceType,
        obj.status,
      ]);
      csvBuilder('Service_Bookings', filteredRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  const exportFilteredServiceBookingOngoingData = async () => {
    try {

      let request = {
        pageNumber: 1,
        serviceStatus: serviceBookings.currentTab,
        limit: transactionLimit,
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        center: filterState.center,
        serviceType: filterState.serviceType,
      }

      let response = await ServiceBookingFilter(request)
      const filteredCustomers = response.data.totalRecords

      request = {
        pageNumber: 1,
        serviceStatus: serviceBookings.currentTab,
        limit: filteredCustomers,
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        center: filterState.center,
        serviceType: filterState.serviceType,
      }
      response = await ServiceBookingFilter(request)
      filteredRecords = response.data.listEntityClass

      let filteredRecordsArray = filteredRecords.map(obj => [
        obj.sfId,
        obj.name,
        obj.vinNumber,
        obj.serviceCenter,
        `${obj.serviceDate} | ${obj.serviceTime}`,
        obj.serviceType,
        obj.status,
      ]);
      csvBuilder('Service_Bookings', filteredRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  useEffect(() => {

    if (exportData) {
      if (!filterFlag && filterBy === 'History') {
        exportServiceBookingHistoryData()
      }
      else if (filterFlag && filterBy === 'History') {
        exportFilteredServiceBookingHistoryData()
      }
      else if (!filterFlag && filterBy === 'Upcoming') {
        exportServiceBookingUpcomingData()
      }
      else if (filterFlag && filterBy === 'Upcoming') {
        exportFilteredServiceBookingUpcomingData()
      }
      else if (!filterFlag && filterBy === 'On-Going') {
        exportServiceBookingOngoingData()
      }
      else if (filterFlag && filterBy === 'On-Going') {
        exportFilteredServiceBookingOngoingData()
      }
      setExportData(false);
    }

  }, [exportData]);



  return (
    <>
      <MainContainer>
        <TableData
          rows={loading ? [] : (rows as GridRowModel[])}
          isLoading={loading}
          getRowId={(r) => r.id}
          columns={columns}
          isCheckBox={true}
          handleCheckedData={handleCheckedData}
          pageSize={transactionLimit}
          isPagination={true}
          className={classes.root}
        />
      </MainContainer>

      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={serviceBookings?.currentPage}
        />
      ) : null}
    </>
  );
};

export default ServiceBookingTable;
