import { LocationSlice } from "./location-slice";

export {
  getPartCenterLocationsThunk,
  getServiceCenterLocationsThunk,
  getShowroomLocationsThunk,
  UpdateLocationThunk,
  deleteLocationInfoThunk,
  addLocationInfoThunk,
  filterLanguagesThunk
} from "slices/locations/location-thunks";

export default LocationSlice.reducer;

export const { setCurrentLocationReducer, setCurrentTabReducer , setCurrentPageReducer} = LocationSlice.actions;
