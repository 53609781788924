import React, { ReactElement } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

import { PaginationDiv } from "./styles";

interface Props {
  handleChange: (event, value) => void;
  dataSize?: number;
  pageNumber?: number;
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiPaginationItem-rounded": {
      // color: "#666666",
      fontSize: "0.7rem",
    },
  },
}));

export default function PaginationPage({
  handleChange,
  dataSize,
  pageNumber
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <PaginationDiv>
      <Pagination
        page={pageNumber}
        size="small"
        count={dataSize}
        onChange={handleChange}
        shape="rounded"
        className={classes.root}
        color="primary"
      />
    </PaginationDiv>
  );
}
