import React, { ReactElement } from "react";

function AddIconLogo(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="1"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.051 2.898H2.79A1.79 1.79 0 001 4.687V17.21A1.79 1.79 0 002.79 19h12.523a1.79 1.79 0 001.79-1.79V10.95" />
        <path d="M15.76 1.556a1.898 1.898 0 012.684 2.684l-8.499 8.498-3.578.895.895-3.579 8.498-8.498z" />
      </g>
    </svg>
  );
}

export default AddIconLogo;
