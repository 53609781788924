import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getLatestCarsDetails,
} from "services/latest-cars-services";

import { LatestCarsRequest } from "interfaces/latest-cars-interface";

export const getLatestCarsThunk = createAsyncThunk(
  "getLatestCarsThunk",
  async (request: LatestCarsRequest, { rejectWithValue }) => {
    try {
      const data = await getLatestCarsDetails(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

