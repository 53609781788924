import styled from "styled-components";

import { TextField, InputLabel, Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { DISABLED_TEXT } from "constants/style/color";
import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { TEXTFIELD_HEADING } from "constants/style/color";

export const ChangePasswordContainer = styled.div`
  background-color: #ffffff;
  padding: 2rem 1rem 0rem;
  display: flex;
  flex-direction: column;
  border-radius: 0px 10px 10px 10px;
`;
export const FieldContainer = styled.div`
  margin-top: 1%;
  margin-bottom: 1%;
  width:45%;
`;

export const InputTextField = styled(TextField)`
  width:77%;
`;
export const InputLabelText = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: ${SUBTEXT_FONT_SIZE} !important;
  color: ${TEXTFIELD_HEADING} !important;
`;

export const ButtonContainer = styled.div`
  margin: 3rem 0 !important;
`;

export const SubmitButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
`;
export const CancelButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 1rem !important;
`;

export const LabelDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width:77%;
`;

export const ShowPasswordIcon = styled(VisibilityIcon)`
  color: ${DISABLED_TEXT} !important;
  font-size: 1.2em !important;
  cursor: pointer;
`;

export const HidePasswordIcon = styled(VisibilityOffIcon)`
  color: ${DISABLED_TEXT} !important;
  font-size: 1.2em !important;
  cursor: pointer;
`;