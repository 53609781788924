import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationObject } from "interfaces/notifications-interfaces";
import notificationsInitialState from "slices/notifications/notifications-intitia-state";

import {
  getAllNotificationsThunk,
  addNewNotificationThunk,
  // updateNotificationThunk,
  // deleteNotificationThunk,
} from "slices/notifications/notifications-thunks";
import NotificationState from "./notifications-interface";

export const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState: notificationsInitialState,
  reducers: {
    setCurrentNotificationReducer: setCurrentNotificationReducer,
    setCurrentPageReducer: setCurrentPageReducer,
  },
  extraReducers: {
    //get all notifications
    [getAllNotificationsThunk.fulfilled.type]: (state, action) => {
      let { results, totalResults } = action.payload.data.data;
      state.notificationsInfo.allNotifications =
        results === undefined ? [] : results;
      state.notificationsInfo.recordsLength = totalResults;
    },

    //update notification
    // [updateNotificationThunk.fulfilled.type]: (state, action) => {
    //   const { data } = action.payload.data;
    //   const result = state.notificationsInfo.allNotifications.map((item) => {
    //     if (item.id === data.id) {
    //       item = { ...data };
    //     }
    //     return item;
    //   });
    //   state.notificationsInfo.allNotifications = [...result];
    // },

    // add new notification
    [addNewNotificationThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      state.notificationsInfo.allNotifications.push(data);
    },

    //delete notification
    // [deleteNotificationThunk.fulfilled.type]: (state, action) => {
    //   const { id } = action.payload;
    //   state.notificationsInfo.allNotifications =
    //     state.notificationsInfo.allNotifications.filter(
    //       (data) => data.id !== id
    //     );
    // },
  },
});

function setCurrentNotificationReducer(
  state: NotificationState,
  action: PayloadAction<NotificationObject>
) {
  state.notificationsInfo.currentNotification = action.payload;
}

function setCurrentPageReducer(
  state: NotificationState,
  action: PayloadAction<number>
) {
  
  state.notificationsInfo.currentPage = action.payload;
  // console.log(state.notificationsInfo.currentPage);
}
