import React, { ReactElement, useState, useEffect } from "react";

import { useAppSelector, useAppDispatch } from "redux-store/hooks";

import InputAdornment from "@material-ui/core/InputAdornment";
import { useSnackbar } from "notistack";

import TabSelector from "shared-components/tab-selector";
import CustomPopover from "shared-components/custom-filter-popup";
import CustomTabSelector from "shared-components/custom-tab-selector";
import CheckboxContainer from "shared-components/checkbox-container";
import DateRange from "shared-components/date-range";

import UsersTable from "pages/customers/components/customers-table";

import { RelativeDiv } from "pages/services/roadside-assistance/styles";
import { RemoveFilter } from "pages/services/test-drive-bookings/styles";

import {
  CustomerContainer,
  Heading,
  ExportToExcel,
  FilterDiv,
  InputTextField,
  Search,
  LeftDiv,
  RightDiv,
  FilterIcon,
  ExportIcon,
  SubHeading,
  FilterHeading,
  HeaderDiv,
  TabSelectorContainer
} from "pages/customers/styles";

import {
  getCustomersByFilterThunk,
  getCustomersBySearchThunk,
  getCustomersThunk,
  setCurrentPageReducer,
} from "slices/customers";
import { DropdownDiv, LimitDiv, SpanTag } from "./components/customers-table/styles";
import { DropdownSelector } from "pages/best-deals/components/new-deal/styles";
import { DROPDOWN_MENU_PROPS, limitArr } from "constants/style/props";
import { MenuItem } from "@material-ui/core";

interface filterInterface {
  id: number;
  label: string;
  status: boolean;
}

const filterInitState = {
  status: "",
  startDate: "",
  endDate: "",
};

function Customer(): ReactElement {
  const initialStatusState: Array<filterInterface> = [
    {
      id: 1,
      label: "Active",
      status: false,
    },
    {
      id: 2,
      label: "In-Active",
      status: false,
    },

  ];

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [filterClear, setFilterClear] = useState<boolean>(false);
  const [statusArr, setStatusArr] = useState<Array<filterInterface>>([]);

  const [filterState, setFilterState] = useState(
    JSON.parse(JSON.stringify(filterInitState))
  );
  const [dummyFilterState] = useState({
    status: "",
    startDate: "",
    endDate: "",
  });


  const { startDate, endDate } = filterState;

  const [exportData, setExportData] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabValue, setTabValue] = useState<number | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>("");
  const [customerLimitSearch, setCustomerLimitSearch] = useState<number>(10);

  const brandColor = useAppSelector((state) => state.brand.brandColor);
  const customersData = useAppSelector((state) => state.customers.customerInfo);

  useEffect(() => {
    setStatusArr(JSON.parse(JSON.stringify(initialStatusState)));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (JSON.stringify(filterState) !== JSON.stringify(dummyFilterState)) {
      setFilterClear(true);
      filterFunc(1, customersData?.tabType);
      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line
  }, [filterState]);


  const filterFunc = (pageNumber, currentTab) => {
    dispatch(
      getCustomersByFilterThunk({
        ...filterState,
        pageNumber: pageNumber,
        userType: currentTab,
        customerLimit: customerLimitSearch,
      })
    )
      .unwrap()
      .then((res) => {
        if ("data" in res.data) {
          enqueueSnackbar(res.data.data, { variant: "warning" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: "error" });
      });
  };

  const handleClearFilter = () => {
    if (searchValue !== "") {
      dispatch(
        getCustomersBySearchThunk({
          pageNumber: 1,
          customerLimit: customerLimitSearch,
          role: customersData.tabType,
          searchContent: searchValue,
        })
      );
    } else {
      dispatch(
        getCustomersThunk({
          pageNumber: customersData.currentPage,
          customerLimit: customerLimitSearch,
          role: customersData.tabType,
        })
      );
    }
    setFilterClear(false);
    setFilterState(JSON.parse(JSON.stringify(filterInitState)));
    setStatusArr(JSON.parse(JSON.stringify(initialStatusState)));
  };

  const handelExport = () => {
    setExportData((prevExportData) => !prevExportData);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchDispatch = async (event) => {
    if (event.key === "Enter") {
      try {
        if (searchValue !== "") {
          await dispatch(
            getCustomersBySearchThunk({
              pageNumber: 1,
              customerLimit: customerLimitSearch,
              role: customersData.tabType,
              searchContent: searchValue,
            })
          );
        } else {
          if (filterClear) {
            filterFunc(1, customersData?.tabType);
          } else {
            dispatch(
              getCustomersThunk({
                pageNumber: customersData.currentPage,
                customerLimit: customerLimitSearch,
                role: customersData.tabType,
              })
            );
          }
        }
      } catch (error) {
        enqueueSnackbar("invalid search", {
          variant: "error",
        });
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTabValue(undefined);
  };

  const handelTabActive = (tabNumber) => {
    setTabValue(tabNumber);
  };

  const handleDate = (e) => {
    const { id, value } = e.target;
    console.log({ id, value })
    filterState[id] = value;
    setFilterState({ ...filterState });

    if (JSON.stringify(filterState) === JSON.stringify(dummyFilterState)) {
      dispatch(getCustomersThunk({ pageNumber: 1, customerLimit: customerLimitSearch, role: customersData.tabType, }));
      checkIsFilterCleared();
    }
  };

  const checkIsFilterCleared = () => {
    // console.log(JSON.stringify(filterState) === JSON.stringify(dummyFilterState),"flag")
    if (JSON.stringify(filterState) === JSON.stringify(dummyFilterState)) {
      dispatch(
        getCustomersThunk({
          pageNumber: 1,
          customerLimit: customerLimitSearch,
          role: customersData.tabType,
        })
      );
      setFilterClear(false);
    }
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const resultStatusArr = statusArr?.map((data) => {
      if (parseInt(value) === data.id) {
        data.status = !data.status;
      }
      return data;
    });
    setStatusArr([...resultStatusArr]);

    const emtOne: Array<string> = [];

    resultStatusArr.map((data) => {
      if (data?.status) {
        emtOne?.push(data.label);
      }
      return data;
    });

    filterState["status"] = emtOne.length === 0 ? "" : emtOne.join(",");
    setFilterState({ ...filterState });

    checkIsFilterCleared();
  };

  const handleLimitChange = (e) => {
    const { value } = e?.target;
    setCustomerLimitSearch(value)
  };

  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Customers</Heading>
          <InputTextField
            id="email"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            onKeyDown={handleSearchDispatch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color={brandColor} />
                </InputAdornment>
              ),
            }}
          />
        </LeftDiv>
        <RightDiv>
          <ExportToExcel onClick={handelExport}>
            <ExportIcon color={brandColor} />
            <SubHeading>Export to Excel</SubHeading>
          </ExportToExcel>
          <RelativeDiv>
            <FilterDiv onClick={handleClick}>
              <FilterIcon />
              <FilterHeading>Filter</FilterHeading>
            </FilterDiv>
            {filterClear && <RemoveFilter onClick={handleClearFilter} />}
          </RelativeDiv>
        </RightDiv>
      </HeaderDiv>

      <CustomPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClose={handleClose}
        childComp={
          <CustomTabSelector
            labelArray={["Status", "Date Range"]}
            children={
              [
                <CheckboxContainer
                  checkboxLabelArr={statusArr}
                  handleChange={handleChangeStatus}
                />,
                <DateRange
                  handleDate={handleDate}
                  endDate={endDate}
                  startDate={startDate}
                  styles={{ width: "100%" }}
                />,
              ] as any
            }
          />
        }
      />

      <TabSelectorContainer>
        <LimitDiv top={0}>
          <SpanTag>Limit - </SpanTag>
          <DropdownDiv>
            <DropdownSelector
              onChange={handleLimitChange}
              variant="outlined"
              value={customerLimitSearch}
              MenuProps={DROPDOWN_MENU_PROPS}
            >
              {limitArr.map((item, index) => {
                return (
                  <MenuItem key={`${item}_${index}`} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </DropdownSelector>
          </DropdownDiv>
        </LimitDiv>
        <TabSelector
          labelArray={["All Users", "Owners", "Guest"]}
          tabValue={tabValue as number}
          children={[
            <UsersTable
              filterBy="All"
              exportData={exportData}
              setExportData={setExportData}
              handelTabActive={handelTabActive}
              searchValue={searchValue}
              filterFlag={filterClear}
              filterFunc={filterFunc}
              customerLimit={customerLimitSearch}
              filterState={filterState}
            />,
            <UsersTable
              filterBy="Owner"
              filterColumn={"user_type"}
              exportData={exportData}
              setExportData={setExportData}
              handelTabActive={handelTabActive}
              searchValue={searchValue}
              filterFlag={filterClear}
              filterFunc={filterFunc}
              customerLimit={customerLimitSearch}
              filterState={filterState}
            />,
            <UsersTable
              filterBy="Guest"
              filterColumn={"user_type"}
              exportData={exportData}
              setExportData={setExportData}
              handelTabActive={handelTabActive}
              searchValue={searchValue}
              filterFlag={filterClear}
              filterFunc={filterFunc}
              customerLimit={customerLimitSearch}
              filterState={filterState}
            />,
          ]}
        />
      </TabSelectorContainer>
    </CustomerContainer>
  );
}

export default Customer;