import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import userProfileInitialState from "slices/user-profile/user-profile-initial-state";
import ProfileImage from "assets/profile.jpeg";
import {
  getUserBasicInfoThunk,
  getAdminsInfoThunk,
  addAdminInfoThunk,
  deleteAdminInfoThunk,
  updateAdminInfoThunk,
} from "slices/user-profile/user-profile-thunks";
import UserProfileState from "./user-profile-interface";
import { UpdateProfilePicture } from "services/user-profile-services";

export const UserProfileSlice = createSlice({
  name: "UserProfileSlice",
  initialState: userProfileInitialState,
  reducers: {
    updateUserProfileReducer:updateUserProfileReducer,
  },
  extraReducers: {
    //get Basic Info and get current edit user
    [getUserBasicInfoThunk.fulfilled.type]: (state, action) => {
      const { result, type } = action.payload;
      if (type === "editModal") {
        state.userProfileInfo.currentUser = result?.data;
      } else {
        state.userProfileInfo.basicInfo = result?.data;
        if (!result.data.profilePicture) {
          state.userProfileInfo.basicInfo.profilePicture = ProfileImage
          UpdateProfilePicture({
            profileImage: ProfileImage,
            id: result?.data?.id,
          });
        }
      }
    },
    //get admins
    [getAdminsInfoThunk.fulfilled.type]: (state, action) => {
      let { pageNumber, response } = action?.payload;
      const { totalRecords, listEntityClass } = response;

      state.userProfileInfo.adminUsers =
        listEntityClass === undefined ? [] : listEntityClass;
      state.userProfileInfo.totalRecordsLength = totalRecords;
      state.userProfileInfo.currentPage = pageNumber;
    },
    //addAdmin
    [addAdminInfoThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      if (data.role === "Admin") {
        state.userProfileInfo.adminUsers.unshift(data);
      }
    },
    //delete Admin
    [deleteAdminInfoThunk.fulfilled.type]: (state, action) => {
      const { id } = action.payload;
      state.userProfileInfo.adminUsers =
        state.userProfileInfo.adminUsers.filter((data) => data.id !== id);
    },

    //update admin
    [updateAdminInfoThunk.fulfilled.type]: (state, action) => {
      const { response, type } = action.payload;
      const { data } = response.data;
      if (type === "basicInfo") {
        state.userProfileInfo.basicInfo = data;
      } else {
        const result = state.userProfileInfo.adminUsers.map((item) => {
          if (item.id === data.id) {
            item = { ...data };
          }
          return item;
        });
        state.userProfileInfo.adminUsers = [...result];
      }
    },
  },
});


function updateUserProfileReducer(
  state: UserProfileState,
  action: PayloadAction<string>
) {
  state.userProfileInfo.basicInfo.profilePicture = action.payload;
}