import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import snackbarInitialState from "slices/snackbar-message/snackbar-message-initial-state";
import SnackbarMessage from "slices/snackbar-message/snackbar-message-slice.interface";

import { SnackbarMessageRequest } from "interfaces/snackbar.interface";

export const SnackBarStatusSlice = createSlice({
  name: "SnackBarSlice",
  initialState: snackbarInitialState,
  reducers: { setStatus, clearSetStatus },
});

function setStatus(
  state: SnackbarMessage,
  action: PayloadAction<SnackbarMessageRequest>
) {
  const { message, isSnackbarOpen, alertType } = action.payload;
  state.apiResponseStatus.isSnackbarOpen = isSnackbarOpen;
  state.apiResponseStatus.alertType = alertType;
  state.apiResponseStatus.message = message;
}

function clearSetStatus(state: SnackbarMessage) {
  state.apiResponseStatus.isSnackbarOpen = false;
  state.apiResponseStatus.alertType = "";
  state.apiResponseStatus.message = "";
}
