import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import roadsideAssistanceInitialState from "slices/services/road-side-assistance/roadside-assistance-initial-state";

import {
  getRoadsideAssistanceThunk,
  getRoadsideAssistanceByFilter,
} from "slices/services/road-side-assistance/roadside-assistance-thunks";
import RoadsideAssistanceState from "./roadside-assistance-interface";

export const RoadsideAssistanceSlice = createSlice({
  name: "RoadsideAssistanceSlice",
  initialState: roadsideAssistanceInitialState,
  reducers: {
    setCurrentPageReducer:setCurrentPageReducer
  },
  extraReducers: {
    //set data
    [getRoadsideAssistanceThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.roadsideAssistanceInfo.roadsideAssistanceDetails =
        listEntityClass === undefined ? [] : listEntityClass;
      state.roadsideAssistanceInfo.recordsLength = totalRecords;
    },

    [getRoadsideAssistanceThunk.rejected.type]: (state) => {
      state.roadsideAssistanceInfo.roadsideAssistanceDetails = [];
    },

    //data by filter
    [getRoadsideAssistanceByFilter.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.roadsideAssistanceInfo.roadsideAssistanceDetails =
        listEntityClass === undefined ? [] : listEntityClass;
      state.roadsideAssistanceInfo.recordsLength = totalRecords;
    },
    [getRoadsideAssistanceByFilter.rejected.type]: (state, action) => {
      state.roadsideAssistanceInfo.roadsideAssistanceDetails = [];
    },
  },
});

function setCurrentPageReducer(
  state: RoadsideAssistanceState,
  action: PayloadAction<number>
) {
  state.roadsideAssistanceInfo.currentPage = action.payload;
}
