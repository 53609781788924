import { CustomersSlice } from "./customers-slice.";

export {
  getCustomersThunk,
  updateCustomerInfoThunk,
  getCustomersByFilterThunk,
  getCustomersBySearchThunk,
  getCustomerById,
  getCustomersOwnedCarsThunk,
  getCustomersBookingHistoryThunk,
} from "slices/customers/customers-thunks";

export default CustomersSlice.reducer;

export const { setTabPageReducer,setCurrentPageReducer } = CustomersSlice.actions;
