import axios from "axios";
import selectedBrand from "constants/brand";
import { limit } from "constants/pagination-constants";

import {
  BASE_URL,
  GET_CUSTOMERS_ENDPOINT,
  GET_CUSTOMERS_CARS_ENDPOINT,
  GET_CUSTOMERS_SERVICE_CARS_ENDPOINT,
  CUSTOMER_SEARCH_ENDPOINT,
  CUSTOMER_FILTER_ENDPOINT,
  GET_CUSTOMER_BASIC_INFO_BY_ID,
  UPDATE_CUSTOMER_STATUS,
} from "constants/api-config-constants";
import {
  GetCustomersRequest,
  GetCustomersDetailsResponse,
  GetCustomersByFilterRequest,
  GetCustomerByIdResponse,
  GetCustomersBookingHistoryRequest,
  GetCustomersBookingHistoryResponse,
  GetCustomersCarsResponse,
} from "interfaces/customers.interface";

export const GetCustomerDetails = async (
  request: GetCustomersRequest
): Promise<GetCustomersDetailsResponse> => {
  const { role, pageNumber, customerLimit } = request;
  const url =
    BASE_URL +
    GET_CUSTOMERS_ENDPOINT +
    "?role=" +
    role +
    "&pageno=" +
    pageNumber +
    "&limit=" +
    customerLimit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const GetCustomerCars = async (
  userId: number
): Promise<GetCustomersCarsResponse> => {
  const url =
    BASE_URL +
    GET_CUSTOMERS_CARS_ENDPOINT +
    "?id=" +
    userId +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const GetCustomerCarsBookingHistoryList = async (
  request: GetCustomersBookingHistoryRequest
): Promise<GetCustomersBookingHistoryResponse> => {
  const { customerId, pageno } = request
  const url =
    BASE_URL +
    GET_CUSTOMERS_SERVICE_CARS_ENDPOINT +
    "?id=" +
    customerId +
    "&brand=" +
    selectedBrand() +
    "&pageno=" +
    pageno +
    "&limit=" +
    limit;
  return axios.get(url);
};

export const CustomersSearch = async (
  request: GetCustomersRequest
): Promise<GetCustomersDetailsResponse> => {
  const { role, pageNumber, customerLimit } = request;
  const url =
    BASE_URL +
    CUSTOMER_SEARCH_ENDPOINT +
    "?role=" +
    role +
    "&pageno=" +
    pageNumber +
    "&limit=" +
    customerLimit +
    "&brand=" +
    selectedBrand();
  return axios.post(url, request?.searchContent, {
    headers: { "Content-Type": "text/plain" },
  });
};

export const CustomersFilter = async (
  request: GetCustomersByFilterRequest
): Promise<GetCustomersDetailsResponse> => {
  const { pageNumber, userType, status, customerLimit, startDate, endDate } = request;
  const url =
    BASE_URL +
    CUSTOMER_FILTER_ENDPOINT +
    "?pageno=" +
    pageNumber +
    "&limit=" +
    customerLimit +
    "&role=" +
    userType +
    "&status=" +
    status +
    "&brand=" +
    selectedBrand() +
    "&fromDate=" +
    startDate +
    "&toDate=" +
    endDate;
  return axios.get(url);
};

export const GetCustomerById = async (
  id: number
): Promise<GetCustomerByIdResponse> => {
  const url =
    BASE_URL +
    GET_CUSTOMER_BASIC_INFO_BY_ID +
    "?id=" +
    id +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const UpdateCustomerStatus = async (
  id: number
): Promise<GetCustomerByIdResponse> => {
  const url = BASE_URL + UPDATE_CUSTOMER_STATUS + "?id=" + id;
  return axios.put(url);
};
