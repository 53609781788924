import axios from "axios";
import { store } from "redux-store/store";

axios.interceptors.request.use((request) => {
  const userToken = store.getState().user.userInfo.jwt.access_token;
  const token = "bearer " + userToken;
  if (userToken !== "") {
    request.headers.Authorization = token;
  } else {
    request.headers.Authorization = "";
  }
  return request;
});
