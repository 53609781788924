import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getRoadsideAssistanceDetails,
  roadsideAssistanceFilter,
} from "services/services/roadside-assistance-services";

import {
  RoadSideAssistanceFilterInterface,
  GetRoadSideAssistanceRequest,
} from "interfaces/services/roadside-assistance-interface";

export const getRoadsideAssistanceThunk = createAsyncThunk(
  "getRoadsideAssistanceThunk",
  async (request: GetRoadSideAssistanceRequest, { rejectWithValue }) => {
    try {
      const data = await getRoadsideAssistanceDetails(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRoadsideAssistanceByFilter = createAsyncThunk(
  "getRoadsideAssistanceByFilter",
  async (request: RoadSideAssistanceFilterInterface, { rejectWithValue }) => {
    try {
      const result = await roadsideAssistanceFilter(request);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
