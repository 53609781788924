import React, { ReactElement, useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import PaperComponent from "shared-components/paper-component";
import LoaderButton from "shared-components/loader-button";
import { FieldContainer } from "pages/management/notification/add-notification/styles";

import { BTN_DISABLED, loaderBtnStyleFunc } from "constants/style/props";

import { CancelButton } from "../privacy-policy/components/details/styles";
import { InputLabelText } from "pages/user-profile/components/basic-info/styles";
import { LoadingComp } from "pages/news/components/news-details/styles";

import {
  BtnFieldContainer,
  SalesForceContainer,
  InputTextField,
} from "./styles";

import {
  getSalesForceCredentials,
  updateSalesForceCredentials,
} from "services/sales-force-services";

import { SalesForceDetails } from "interfaces/sales-force.interface";

const initState = {
  userName: "",
  password: "",
  clientSecret: "",
  clientId: "",
};

const useStyles = makeStyles({
  disabled: BTN_DISABLED,
});

function SalesForce(): ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const [salesForceState, setSalesForceState] = useState(initState);
  const [dummyState, setDummyState] = useState<SalesForceDetails>();

  const getSalesForceData = async () => {
    setLoading(true);
    try {
      const result = await getSalesForceCredentials();
      if (result?.data) {
        const { id, ...restData } = result?.data?.data;
        setSalesForceState(restData);
        setDummyState(JSON.parse(JSON.stringify(restData)));
      }
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
      setLoading(false);
    }
  };

  const updateSalesForceData = async () => {
    setBtnLoading(true);
    try {
      const result = await updateSalesForceCredentials(salesForceState);
      if (result?.data?.response) {
        enqueueSnackbar(result.data.response, {
          variant: "success",
        });
        setIsUpdated(false);
      }
      setBtnLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    getSalesForceData();
    // eslint-disable-next-line
  }, []);

  const handelChange = (prop) => (e) => {
    const { value } = e.target;
    salesForceState[prop] = value;
    setSalesForceState({ ...salesForceState });

    if (JSON.stringify(salesForceState) === JSON.stringify(dummyState)) {
      setIsUpdated(false);
    } else {
      setIsUpdated(true);
    }
  };

  const handelSave = () => {
    if (isUpdated) {
      updateSalesForceData();
    }
  };

  const handelCancel = () => {
    setSalesForceState(JSON.parse(JSON.stringify(dummyState)));
    setIsUpdated(false);
  };

  const { userName, password, clientSecret, clientId } = salesForceState;

  return (
    <PaperComponent heading={"SalesForce Integration"}>
      {loading ? (
        <LoadingComp color="primary" />
      ) : (
        <SalesForceContainer>
          <FieldContainer>
            <InputLabelText>Username</InputLabelText>
            <InputTextField
              variant="outlined"
              size="small"
              value={userName}
              onChange={handelChange("userName")}
            />
          </FieldContainer>

          <FieldContainer>
            <InputLabelText>Password</InputLabelText>
            <InputTextField
              variant="outlined"
              size="small"
              value={password}
              onChange={handelChange("password")}
            />
          </FieldContainer>

          <FieldContainer>
            <InputLabelText>Client Secret</InputLabelText>
            <InputTextField
              variant="outlined"
              size="small"
              value={clientSecret}
              onChange={handelChange("clientSecret")}
            />
          </FieldContainer>

          <FieldContainer>
            <InputLabelText>Client ID</InputLabelText>
            <InputTextField
              variant="outlined"
              size="small"
              value={clientId}
              onChange={handelChange("clientId")}
            />
          </FieldContainer>

          <BtnFieldContainer>
            <LoaderButton
              isLoading={btnLoading}
              buttonText="Update"
              styles={loaderBtnStyleFunc("7rem", "2.5rem")}
              submitHandler={handelSave}
              className={`${!isUpdated ? classes.disabled : ""}`}
            />
            <CancelButton
              variant="outlined"
              color="primary"
              onClick={handelCancel}
            >
              Cancel
            </CancelButton>
          </BtnFieldContainer>
        </SalesForceContainer>
      )}
    </PaperComponent>
  );
}

export default SalesForce;
