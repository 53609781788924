import React, { ReactElement, ReactNode, useEffect } from "react";

import {
  TabSelectorContainer,
  TabsContainer,
  SingleTab,
  AppBar,
  DetailBox,
  UnionDiv,
  BoxContainer,
} from "./styles";
interface Props {
  labelArray: Array<string>;
  children?: Array<ReactNode>;
  tabValue?: number;
  clearFilter?: () => void;
}

function TabSelector({
  labelArray,
  children,
  tabValue,
  clearFilter,
}: Props): ReactElement {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(clearFilter);
    if (clearFilter !== undefined) {
      clearFilter();
    }
  };

  useEffect(() => {
    if (tabValue !== undefined) {
      setValue(tabValue);
    }
  }, [tabValue]);

  return (
    <TabSelectorContainer>
      <UnionDiv>
        <AppBar>
          <TabsContainer
            textColor={"primary"}
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                display: "none",
                // boxShadow:"none !important"
              },
            }}
          >
            {labelArray.map((value, index) => {
              return (
                <SingleTab
                  key={`SingleTab_${value}_${index}`}
                  classes={{ root: "" }}
                  label={value}
                ></SingleTab>
              );
            })}
          </TabsContainer>
        </AppBar>
        {children?.map((child, index) => {
          return (
            <TabDetails key={`TabDetails_${index}`} value={value} index={index}>
              {child}
            </TabDetails>
          );
        })}
      </UnionDiv>
    </TabSelectorContainer>
  );
}

function TabDetails(props) {
  const { children, value, index } = props;
  return (
    <BoxContainer>
      {value === index && <DetailBox>{children}</DetailBox>}
    </BoxContainer>
  );
}

export default TabSelector;
