import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dashboardInitialState from "slices/dashboard/dashboard-initial-state";
import DashboardState from "slices/dashboard/dashboard-interface";

import { getDashboardDetailsThunk } from "slices/dashboard/dashboard-thunk";

export const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: dashboardInitialState,
  reducers: { setMonthReducer: setMonthReducer },
  extraReducers: {
    //set dashboard details
    [getDashboardDetailsThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      state.dashboardInfo.dashboardDetails = data;
    },
    [getDashboardDetailsThunk.rejected.type]: (state, action) => {
      state.dashboardInfo.dashboardDetails = {
        appUsage: { data: [0, 0, 0, 0, 0], labels: ["1", "2", "3", "4", "5"] },
        guestUsers: 0,
        newCustomers: 0,
        owners: 0,
        ownersVSGuestUsers: { data: [], lables: [] },
        recentActivities: [],
        serviceBookigs: { data2: [], data1: [], labels: [] },
        testDriveBookings: { data: [0, 0, 0, 0, 0], labels: [] },
        totalCustomers: 10,
      };
    },
  },
});

function setMonthReducer(state: DashboardState, action: PayloadAction<number>) {
  state.dashboardInfo.selectedMonth = action.payload;
}
