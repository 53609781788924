import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Typography from "@mui/material/Typography";
import { Popover } from "@mui/material";

import TableData from "shared-components/table-data";
import PaginationFooter from "shared-components/pagination";
import { RenderCellExpand } from "shared-components/expanded-cel";
import { HoverPaper } from "shared-components/expanded-cel/styles";

import { COPY_DEAL, EDIT_DEAL } from "constants/route/route-constants";
import { loadingHelper } from "constants/loadingHelper";
import { limit } from "constants/pagination-constants";

import DeleteUser from "pages/user-profile/components/delete-user";

import {
  getAllDealsThunk,
  deleteDealThunk,
  setCurrentPageReducer,
  setTabIndicatorReducer,
} from "slices/best-deals";

import {
  GridColDef,
  // GridRowsProp,
  GridCellParams,
  GridRowModel,
  GridRowId,
} from "@material-ui/data-grid";

import {
  CopyIconLogo,
  DeletePopupModal,
  DateDiv,
  CopyLinkToClipboard,
} from "pages/best-deals/components/deals-table/styles";

import {
  RemoveIconLogo,
  EditIconLogo,
} from "pages/user-profile/components/user-management/styles";

import {
  getDealsBySearchThunk,
  setActiveDealThunk,
  setFinishedDealThunk,
} from "slices/best-deals/best-deals-thunks";
import selectedBrand from "constants/brand";


interface Props {
  filterBy?: string | undefined;
  filterColumn?: string;
  active: boolean;
  inactive: boolean;
  toggleActiveFunc: (value: boolean) => void;
  toggleInactiveFunc: (value: boolean) => void;
  searchValue: string;
  selectedLang: string;
  handelTabActive: (tabNumber: number) => void;
}

const tabObj = {
  undefined: 0,
  Active: 1,
  "In-Active": 2,
};

const useStyles = makeStyles({
  root: {
    "& .active": {
      backgroundColor: "rgba(39, 174, 96, 0.1)",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#D6D2C4",
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: "#D6D2C4",
      },
      "&.MuiDataGrid-row": {
        borderBottom: ".625rem solid white",
        alignItems: "center",
      },
    },
    "& .inactive": {
      backgroundColor: "rgba(235, 10, 30, 0.1);",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#D6D2C4",
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: "#D6D2C4",
      },
      "&.MuiDataGrid-row": {
        borderBottom: ".625rem solid white",
        alignItems: "center",
      },
    },
  },
});

const DealsTable = ({
  filterBy,
  filterColumn,
  active,
  inactive,
  toggleActiveFunc,
  toggleInactiveFunc,
  searchValue,
  selectedLang,
  handelTabActive,
}: Props) => {
  const history = useHistory<any>();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<GridRowModel>([]);
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [selectedDeleteID, setSelectedDeletedId] = useState<number>(0);
  const [isDealUpdated, setIsDealUpdated] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });
  const bestDeals = useSelector(
    (state: RootState) => state.bestDeals.bestDealsInfo
  );

  useEffect(() => {
    if (history?.location?.state?.from === "edit") {
      loadingHelper(
        dispatch(
          getAllDealsThunk({
            status: `${bestDeals?.tabIndicator}`,
            pageno: bestDeals?.currentPage,
            limit: limit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );

      const state = { ...history.location.state };
      delete state.from;
      history.replace({ ...history.location, state });
      handelTabActive(tabObj[`${bestDeals?.tabIndicator}`]);
    } else {
      loadingHelper(
        dispatch(
          getAllDealsThunk({
            status: `${filterBy}`,
            pageno: 1,
            limit: limit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );

      handelTabActive(tabObj[`${filterBy}`]);
      dispatch(setTabIndicatorReducer(filterBy));
      dispatch(setCurrentPageReducer(1));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, brandName, filterBy]);

  useEffect(() => {
    setRows(bestDeals.allDeals);
    setPaginationSize(Math.ceil(bestDeals.recordsLength / limit));
  }, [bestDeals]);

  useEffect(() => {
    if (active && selectionModel.length !== 0) {
      makeActiveSelectedRows();
      setSelectionModel([]);
    }
    toggleActiveFunc(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    if (inactive && selectionModel.length !== 0) {
      makeInactiveSelectedRows();
      setSelectionModel([]);
    }
    toggleInactiveFunc(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactive]);

  const handelDateFormat = (timedate) => {
    const day = new Date(timedate);
    const time = day.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    var dateTime =
      day.getDate() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getFullYear() +
      " " +
      time;

    return dateTime;
  };

  const handlePagination = (event, number) => {
    if (searchValue === "") {
      loadingHelper(
        dispatch(
          getAllDealsThunk({
            status: `${filterBy}`,
            pageno: number,
            limit: limit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
    } else {
      loadingHelper(
        dispatch(
          getDealsBySearchThunk({
            query: searchValue,
            pageno: number,
            limit: limit,
            status: bestDeals?.tabIndicator as string,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
    }
    dispatch(setCurrentPageReducer(number)); //
  };

  const handleCheckedData = (newSelection) => {
    setSelectionModel(newSelection.selectionModel);
  };

  const openDeleteDealModal = (id) => {
    setOpenDeleteModal(true);
    setSelectedDeletedId(id);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const confirmDelete = async (id) => {
    try {
      setLoading(true);
      const result = await dispatch(deleteDealThunk(id)).unwrap();
      enqueueSnackbar(result?.response?.data, {
        variant: "success",
      });
      setLoading(false);
      setOpenDeleteModal(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.data, { variant: "error" });
      setOpenDeleteModal(false);
    }
  };

  const makeActiveSelectedRows = async () => {
    try {
      await dispatch(setActiveDealThunk({ array: selectionModel })).unwrap();
      enqueueSnackbar(
        selectionModel.length > 1
          ? "Deals Activated..!!"
          : "Deal Activated..!!",
        {
          variant: "success",
        }
      );
      setIsDealUpdated(!isDealUpdated);
      loadingHelper(
        dispatch(
          getAllDealsThunk({
            status: `${filterBy}`,
            pageno: bestDeals?.currentPage,
            limit: limit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
    } catch (error) {
      enqueueSnackbar(error?.data, { variant: "error" });
    }
  };

  const makeInactiveSelectedRows = async () => {
    try {
      await dispatch(
        setFinishedDealThunk({
          array: selectionModel,
        })
      ).unwrap();
      enqueueSnackbar(
        selectionModel.length > 1 ? "Deals Finished !!" : "Deal Finished !!",
        {
          variant: "success",
        }
      );
      setIsDealUpdated(!isDealUpdated);
      loadingHelper(
        dispatch(
          getAllDealsThunk({
            status: `${filterBy}`,
            pageno: bestDeals?.currentPage,
            limit: limit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
    } catch (error) {
      enqueueSnackbar(error?.data, { variant: "error" });
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setShow(true)
  };

  const handlePopoverClose = () => {
    setShow(false)
    setAnchorEl(null);
    setIsCopied(false);
  };

  const open = Boolean(anchorEl);

  const handelCopy = (dealId) => {
    setIsCopied(true);
  };


  const columns: GridColDef[] = [
    {
      field: "dealId",
      headerName: "ID",
      sortable: true,
      flex: 0.5,
    },
    {
      field: `${selectedLang === "English" ? "dealTitle" : "dealTitleArabic"}`,
      headerName: "Title",
      sortable: true,
      resizable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "dealTitleArabic", 300, selectedLang);
      },
    },
    {
      field: `${selectedLang === "English" ? "dealText" : "dealTextArabic"}`,
      sortable: false,
      headerName: "Sub Text",
      resizable: false,
      flex: 1.4,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "dealTextArabic", 300, selectedLang);
      },
    },
    {
      field: "dealStartDateTimeStamp",
      headerName: "Starts",
      sortable: false,
      resizable: false,
      flex: 1.1,
      renderCell: (params: GridCellParams) => {
        const { dealStartDateTimeStamp } = params.row;
        return <DateDiv>{handelDateFormat(dealStartDateTimeStamp)}</DateDiv>;
      },
    },
    {
      field: "dealEndDateTimeStamp",
      sortable: false,
      headerName: "Ends",
      resizable: false,
      flex: 1.1,
      renderCell: (params: GridCellParams) => {
        const { dealEndDateTimeStamp } = params.row;
        return <DateDiv>{handelDateFormat(dealEndDateTimeStamp)}</DateDiv>;
      },
    },
    {
      field: "actions",
      sortable: false,
      headerName: "Actions",
      resizable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData = params.row;
        return (
          <>
            <CopyToClipboard
              text={`${selectedBrand()}://deal/autogen/${rowData.dealId}`}
              onCopy={() => handelCopy(rowData.dealId)}
            >
              <CopyLinkToClipboard
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              ></CopyLinkToClipboard>
            </CopyToClipboard>
            {show && (
              <Popover
                elevation={0}
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <HoverPaper elevation={1}>
                  <Typography sx={{ p: 1 }}>
                    {isCopied ? (
                      <span>&#x2714; Copied to Clipboard</span>
                    ) : (
                      "Click to copy the link."
                    )}
                  </Typography>
                </HoverPaper>
              </Popover>
            )}
            <CopyIconLogo
              onClick={() =>
                history.push({
                  pathname: COPY_DEAL,
                  state: {
                    detail: "copy",
                    rowId: rowData?.dealId,
                    rowData: rowData,
                  },
                })
              }
            />
            <EditIconLogo
              onClick={() =>
                history.push({
                  pathname: EDIT_DEAL,
                  state: {
                    detail: "edit",
                    rowId: rowData?.dealId,
                    rowData: rowData,
                  },
                })
              }
            />
            <RemoveIconLogo
              onClick={() => openDeleteDealModal(rowData.dealId)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div>
      <TableData
        className={classes.root}
        rows={loading ? [] : (rows as GridRowModel[])}
        isLoading={loading}
        getRowId={(r) => r.dealId}
        filterBy={filterBy}
        filterColumn={filterColumn}
        columns={columns}
        isCheckBox={true}
        handleCheckedData={handleCheckedData}
        pageSize={limit}
        isPagination={true}
        selectionModel={selectionModel}
        getRowClassName={(params) => {
          if (params.row.dealStatus === "Active") {
            return "active";
          }
          return "inactive";
        }}
        rowHeight={65}
      />
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={bestDeals?.currentPage}
        />
      ) : null}

      <DeletePopupModal open={openDeleteModal} onClose={closeDeleteModal}>
        <DeleteUser
          params={selectedDeleteID}
          closeModal={closeDeleteModal}
          confirmDelete={confirmDelete}
          loading={loading}
          headerText={"Delete Deal"}
          paraText={"Are you sure you want to delete this deal?"}
          message={"All data related to this deal will be deleted."}
        ></DeleteUser>
      </DeletePopupModal>
    </div>
  );
};

export default DealsTable;
