import React, { ReactElement } from "react";

import TabSelector from "shared-components/tab-selector";
import NewsTable from "./components/news-table/index";

import {
  CustomerContainer,
  Heading,
  LeftDiv,
  HeaderDiv,
} from "pages/news/styles";

function News(): ReactElement {
  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <Heading>News</Heading>
        </LeftDiv>
      </HeaderDiv>

      <TabSelector
        labelArray={["All News"]}
        children={[<NewsTable />] as any}
      />
    </CustomerContainer>
  );
}

export default News;
