import React, { ReactElement } from "react";
import Popover from "@material-ui/core/Popover";

interface PopoverProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: any;
  childComp: React.ReactNode;
  handleClose: () => void;
}

export default function CustomPopover({
  anchorEl,
  setAnchorEl,
  childComp,
  handleClose,
}: PopoverProps): ReactElement {
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {childComp}
    </Popover>
  );
}
