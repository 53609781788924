import React, { ReactElement } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { brandEnum } from "slices/brand/brand-slice";

import { ThemeProvider } from "@material-ui/styles";
import { ThemeProvider as StyledComponentTheme } from "styled-components";
import { useSnackbar } from "notistack";

import "interceptors/request";
import "interceptors/response";

import { lexusTheme, motorCityTheme, toyotaTheme } from "themes/styled-theme";
import ProtectedRoute from "routes/protected-route";
import getTheme from "themes/global-theme";

import SideBar from "shared-components/sidebar";
import CustomErrorPage from "shared-components/error-page";

import Login from "pages/auth/components/login";
import ForgotPassword from "pages/auth/components/forgot-password-link";
import ResetPassword from "pages/auth/components/reset-password";
import Dashboard from "pages/dashboard";
import Customers from "pages/customers";
import CustomerDetails from "pages/customers/components/customer-details";
import Location from "pages/location";
import AddLocation from "pages/location/components/add-location";
// import Transaction from "pages/transaction";
import LatestCars from "pages/latest-cars";
import News from "pages/news";
import NewsDetail from "pages/news/components/news-details";
import BestDeals from "pages/best-deals";
import NewDeal from "pages/best-deals/components/new-deal";
import LiveChatConfig from "pages/live-chat-config";
import LatestBlogs from "pages/latest-blogs";
import NewBlog from "pages/latest-blogs/components/new-blog";
import TestDriveBookings from "pages/services/test-drive-bookings";
import TestDriveDetails from "pages/services/test-drive-bookings/components/test-drive-details";
import ServiceBookings from "pages/services/service-bookings";
import ServiceRatings from "pages/location/components/service-ratings";
import RoadsideAssistance from "pages/services/roadside-assistance";
import EmergencyOptionsRoadsideAssistance from "pages/services/roadside-assistance/components/edit-emergency-options";
import EmergencyOptions from "pages/services/roadside-assistance/components/edit-emergency-options";
import PrivacyPolicyComponent from "pages/management/privacy-policy";
import SalesForce from "pages/management/sales-force";
import LinkManagement from "pages/management/link-management";
import Notification from "pages/management/notification";
import AddNotification from "pages/management/notification/add-notification";
import Faqs from "pages/management/faqs";
import AddEditFaq from "pages/management/faqs/components/edit-faq";
import UserProfile from "pages/user-profile";

import {
  DASHBOARD,
  CUSTOMERS,
  LOCATION,
  // TRANSACTION,
  USER_PROFILE,
  CUSTOMER_DETAILS,
  LOGIN,
  LANDING_PAGE,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  ADD_LOCATION,
  SERVICE_RATINGS,
  NEWS,
  NEWS_DETAIL,
  LATEST_CARS,
  TEST_DRIVE_BOOKINGS,
  LIVE_CHAT_CONFIG,
  BEST_DEALS,
  NEW_DEAL,
  EDIT_DEAL,
  SERVICE_BOOKINGS,
  ROADSIDE_ASSISTANCE,
  FAQ,
  ADD_FAQ,
  EDIT_FAQ,
  PRIVACY_POLICY,
  NOTIFICATION,
  LINK_MANAGEMENT,
  EDIT_LOCATION,
  COPY_DEAL,
  LATEST_BLOGS,
  NEW_BLOG,
  EDIT_BLOG,
  COPY_BLOG,
  EDIT_EMERGENCY_OPTIONS,
  SALES_FORCE,
  TEST_DRIVE_BOOKING_DETAILS,
  ADD_NOTIFICATION,
  EDIT_NOTIFICATION,
} from "constants/route/route-constants";

function App(): ReactElement {
  const { closeSnackbar } = useSnackbar();
  const brand = useAppSelector((state) => state.brand.brandType);

  const theme = getTheme(brand);
  const styledTheme =
    brand === brandEnum.T
      ? toyotaTheme
      : brand === brandEnum.L
        ? lexusTheme
        : motorCityTheme;

  const handleCloseSnackbar = () => {
    closeSnackbar();
  };
  const userDetails = useAppSelector((state: RootState) => {
    return state.UserProfile.userProfileInfo.basicInfo?.moduleList;
  });

  return (
    <div onClick={handleCloseSnackbar}>
      <ThemeProvider theme={theme}>
        <StyledComponentTheme theme={styledTheme}>
          <Router>
            <SideBar>
              <Switch>
                <ProtectedRoute exact path={DASHBOARD}>
                  <Dashboard />
                </ProtectedRoute>
                <ProtectedRoute exact path={CUSTOMERS}>
                  {/* {userDetails?.includes("customers") ? <Customers /> : null} */}
                  <Customers />
                </ProtectedRoute>
                <ProtectedRoute exact path={LOCATION}>
                  {userDetails?.includes("location") ? <Location /> : null}
                </ProtectedRoute>
                {/* <ProtectedRoute exact path={TRANSACTION}>
                  {userDetails?.includes("transaction") ? (
                    <Transaction />
                  ) : null}
                </ProtectedRoute> */}
                <ProtectedRoute exact path={USER_PROFILE}>
                  <UserProfile />
                </ProtectedRoute>
                <ProtectedRoute exact path={CUSTOMER_DETAILS}>
                  {/* {userDetails?.includes("customers") ? (
                    <CustomerDetails />
                  ) : null} */}
                  <CustomerDetails />
                </ProtectedRoute>
                <ProtectedRoute exact path={SERVICE_RATINGS}>
                  {userDetails?.includes("service-ratings") ? (
                    <ServiceRatings />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={NEWS}>
                  {userDetails?.includes("news") ? <News /> : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={NEWS_DETAIL}>
                  {userDetails?.includes("news") ? <NewsDetail /> : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={LATEST_CARS}>
                  {userDetails?.includes("latest-cars") ? <LatestCars /> : null}
                </ProtectedRoute>
                <ProtectedRoute path={ADD_LOCATION} exact>
                  {userDetails?.includes("location") ? <AddLocation /> : null}
                </ProtectedRoute>
                <ProtectedRoute path={EDIT_LOCATION} exact>
                  {userDetails?.includes("location") ? <AddLocation /> : null}
                </ProtectedRoute>
                <ProtectedRoute path={LIVE_CHAT_CONFIG} exact>
                  {userDetails?.includes("live-chat-config") ? (
                    <LiveChatConfig />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={BEST_DEALS}>
                  {userDetails?.includes("best-deals") ? <BestDeals /> : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={TEST_DRIVE_BOOKINGS}>
                  {/* {userDetails?.includes("test-drive-bookings") ? (
                    <TestDriveBookings />
                  ) : null} */}
                  <TestDriveBookings />
                </ProtectedRoute>
                <ProtectedRoute exact path={TEST_DRIVE_BOOKING_DETAILS}>
                  {userDetails?.includes("test-drive-bookings") ? (
                    <TestDriveDetails />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={NEW_DEAL}>
                  {userDetails?.includes("best-deals") ? <NewDeal /> : null}
                </ProtectedRoute>
                <ProtectedRoute path={EDIT_DEAL} exact>
                  {userDetails?.includes("best-deals") ? <NewDeal /> : null}
                </ProtectedRoute>
                <ProtectedRoute path={COPY_DEAL} exact>
                  {userDetails?.includes("best-deals") ? <NewDeal /> : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={SERVICE_BOOKINGS}>
                  {/* {userDetails?.includes("service-bookings") ? (
                    <ServiceBookings />
                  ) : null} */}
                  <ServiceBookings />
                </ProtectedRoute>
                <ProtectedRoute exact path={ROADSIDE_ASSISTANCE}>
                  {userDetails?.includes("roadside-assistance") ? (
                    <RoadsideAssistance />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={EDIT_EMERGENCY_OPTIONS}>
                  {userDetails?.includes("roadside-assistance") ? (
                    <EmergencyOptions />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={FAQ}>
                  {userDetails?.includes("faq") ? <Faqs /> : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={ADD_FAQ}>
                  {userDetails?.includes("faq") ? <AddEditFaq /> : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={EDIT_FAQ}>
                  {userDetails?.includes("faq") ? <AddEditFaq /> : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={LINK_MANAGEMENT}>
                  {userDetails?.includes("link-management") ? (
                    <LinkManagement />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={PRIVACY_POLICY}>
                  {userDetails?.includes("terms-and-conditions") ? (
                    <PrivacyPolicyComponent />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={NOTIFICATION}>
                  {userDetails?.includes("notifications") ? (
                    <Notification />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={ADD_NOTIFICATION}>
                  {userDetails?.includes("notifications") ? (
                    <AddNotification />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={EDIT_NOTIFICATION}>
                  {userDetails?.includes("notifications") ? (
                    <AddNotification />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={LATEST_BLOGS}>
                  {userDetails?.includes("latest-blogs") ? (
                    <LatestBlogs />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute exact path={NEW_BLOG}>
                  {userDetails?.includes("latest-blogs") ? <NewBlog /> : null}
                </ProtectedRoute>
                <ProtectedRoute path={EDIT_BLOG} exact>
                  {userDetails?.includes("latest-blogs") ? <NewBlog /> : null}
                </ProtectedRoute>
                <ProtectedRoute path={COPY_BLOG} exact>
                  {userDetails?.includes("latest-blogs") ? <NewBlog /> : null}
                </ProtectedRoute>
                <ProtectedRoute path={EDIT_EMERGENCY_OPTIONS} exact>
                  {userDetails?.includes("edit-emergency-options") ? (
                    <EmergencyOptionsRoadsideAssistance />
                  ) : null}
                </ProtectedRoute>
                <ProtectedRoute path={SALES_FORCE} exact>
                  {userDetails?.includes("sales-force") ? <SalesForce /> : null}
                </ProtectedRoute>
                <ProtectedRoute path="*">
                  <CustomErrorPage error={"404"} errorMessage={"Sorry, Page not found"} />
                </ProtectedRoute>
              </Switch>
            </SideBar>
            <Route path={LOGIN} exact component={Login} />
            <Route path={LANDING_PAGE} exact component={Login} />
            <Route path={FORGET_PASSWORD} exact component={ForgotPassword} />
            <Route path={RESET_PASSWORD} exact component={ResetPassword} />
          </Router>
        </StyledComponentTheme>
      </ThemeProvider>
    </div>
  );
}

export default App;
