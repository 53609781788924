import RoadsideAssistanceState from "slices/services/road-side-assistance/roadside-assistance-interface";

const roadsideAssistanceInitialState: RoadsideAssistanceState = {
  roadsideAssistanceInfo: {
    roadsideAssistanceDetails: [],
    recordsLength: 0,
    currentPage: 1,
  },
};

export default roadsideAssistanceInitialState;
