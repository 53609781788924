import { TestDriveSlice } from "./test-drive-slice";

export {
    getTestDriveThunk,
    testDriveSearchThunk,
    getTestDriveByFilter,
} from "slices/test-drive-bookings/test-drive-thunks";


export default TestDriveSlice.reducer;

export const { setCurrentPageReducer } = TestDriveSlice.actions;