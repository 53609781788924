import styled from "styled-components";

import { Button } from "@material-ui/core";

import { Heading, SubHeading } from "pages/customers/styles";
import { BrandList, SelectedBrandDiv } from "shared-components/header/styles";

export const LocationContainer = styled.div`
  padding: 1.5rem 2rem;
`;

export const HeadingDiv = styled(Heading)``;

export const AddLocationButton = styled(Button)`
  width: 10rem;
  text-transform: none !important;
  font-weight: 600 !important;
  height: 2.8rem;
`;

export const LeftDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RightDiv = styled.div``;

export const SubHeadingLocation = styled(SubHeading)``;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlagImage = styled.img``;

export const LanguageList = styled(BrandList)``;
export const SelectedLangDiv = styled(SelectedBrandDiv)``;
export const LabelSpan = styled.span`
  margin-left: 0.625rem;
`;
