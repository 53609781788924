
  export const customer = {
    "Basic info": {
      "Customer ID": 123456,
      "Full Name": "John Doe",
      "Mobile Number": "99999-99999",
      "Email ID": "johndoe@example.com",
      "Gender": "Male",
      "CR/CPR/GCC ID number": "9999-9999-99",
      "Address": "1-2-345 Building name, locality, Bahrain -00000",
      "Nationality": "Bahrain",
      "License": "1234567890",
      "Type of User": "Owner",
    },
    "Owned Cars": [
      {
        "Car Model Number": 123456,
        "Vin Number": "1M8GDM9A_KP042788",
        "Registration date": "12-02-2021",
        "Insurance Expiration": "21-02-2021",
        "Last Odometer Reading": "61.5 KM",
      },
      {
        "Car Model Number": 123456,
        "Vin Number": "1M8GDM9A_KP042788",
        "Registration date": "12-02-2021",
        "Insurance Expiration": "21-02-2021",
        "Last Odometer Reading": "61.5 KM",
      },
    ],

    "Booking history": [
      {
        "Booking ID": 123456,
        "Service Center": "Center Name",
        "Service Advisor": "Advisor Name",
        "Car For Service": "Car Model Name",
        "Date and Time": "21-02-2021  |  00:00 PM",
        "Service Type": "Repair",
      },
      {
        "Booking ID": 123456,
        "Service Center": "Center Name",
        "Service Advisor": "Advisor Name",
        "Car For Service": "Car Model Name",
        "Date and Time": "21-02-2021  |  00:00 PM",
        "Service Type": "Repair",
      },
    ],

    // "Transaction history": [
    //   {
    //     "Booking ID": 123456,
    //     "Service Center": "Center Name",
    //     "Service Advisor": "Advisor Name",
    //     "Car For Service": "Car Model Name",
    //     "Date and Time": "21-02-2021  |  00:00 PM",
    //     "Service Type": "Repair",
    //   },
    // ],
  };




