import ServiceBookingState from "slices/services/service-bookings/service-booking-interface"

const ServiceBookingInitialState: ServiceBookingState = {
  serviceBookingInfo: {
    allServiceBookings: [],
    currentBooking:{},
    recordsLength: 0,
    currentPage: 1,
    currentTab:""
  },
};

export default ServiceBookingInitialState;
