import React, { ReactElement } from "react";

import { useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import formatDistance from "date-fns/formatDistance";
import AnyoneImage from "assets/profile.jpeg";

import {
  RecentActivityContainer,
  WidgetName,
  WidgetCard,
  ContentDiv,
  // CircleLogo,
  WidgetValue,
  WidgetHeading,
  WidgetMessage,
} from "pages/dashboard/components/customers-right-widgets/recent-activity/styles";
import { ProfileImage } from "shared-components/header/styles";

interface Props {
  chartColors: Array<string>;
}

function RecentActivity({ chartColors }: Props): ReactElement {
  const dashboardDetails = useAppSelector((state: RootState) => {
    return state.dashboardDetails.dashboardInfo.dashboardDetails;
  });

  return (
    <RecentActivityContainer>
      <WidgetHeading>Recent Activity</WidgetHeading>
      {dashboardDetails?.recentActivities.length > 0 ? (
        dashboardDetails?.recentActivities?.map((data, index) => {
          const timestamp = data?.timestamp as string;
          const diffTime = formatDistance(new Date(), new Date(timestamp), {
            addSuffix: true,
          });
          return (
            <WidgetCard key={index}>
              {/* <CircleLogo color={chartColors[1]} /> */}
              <ProfileImage alt={""} src={!data.profilePicture? AnyoneImage:`data:image/png;base64,${data.profilePicture}`} />
              <ContentDiv>
                <WidgetName>{data?.activity_type}</WidgetName>
                <WidgetValue>{data?.user}</WidgetValue>
                <WidgetValue>{diffTime}</WidgetValue>
              </ContentDiv>
            </WidgetCard>
          );
        })
      ) : (
        <WidgetMessage>No Recent activities</WidgetMessage>
      )}
    </RecentActivityContainer>
  );
}

export default RecentActivity;
