import React, { ReactElement, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { withRouter } from "react-router";

import { useSnackbar } from "notistack";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

import TabSelector from "shared-components/tab-selector";
import ArabicSwitchDropdown from "shared-components/arabic-input/arabic-switch-dropdown";

import DealsTable from "pages/best-deals/components/deals-table";
import { ArabicSwitch } from "pages/management/faqs/styles";

import BahrinFlag from "assets/bahrin-flag.png";
import UkFlag from "assets/uk-flag.png";

import { NEW_DEAL } from "constants/route/route-constants";
import { limit } from "constants/pagination-constants";
import { BTN_DISABLED, LANGUAGE_FLAG_ARRAY } from "constants/style/props";

import {
  getAllDealsThunk,
  getDealsBySearchThunk,
} from "slices/best-deals/best-deals-thunks";

import {
  CustomerContainer,
  Heading,
  InputTextField,
  Search,
  LeftDiv,
  RightDiv,
  HeaderDiv,
  NewDealButton,
  SetActiveButton,
  SetFinishedButton,
} from "pages/best-deals/styles";


const useStyles = makeStyles({
  disabled: BTN_DISABLED
});

function BestDeals({ history }): ReactElement {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLang, setSelectedLang] = useState(UkFlag);
  const [Language, setLanguage] = useState("English");
  const [tabValue, setTabValue] = useState<number | undefined>(undefined);
  const [active, setActive] = useState(false);
  const [inactive, setInActive] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const brandColor = useAppSelector((state) => state.brand.brandColor);
  const bestDeals = useAppSelector((state) => state.bestDeals.bestDealsInfo);
  
  const languageArr = LANGUAGE_FLAG_ARRAY;

  const toggleActiveFunc = (value) => {
    setActive(value);
  };

  const toggleInactiveFunc = (value) => {
    setInActive(value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  // console.log(bestDeals)

  const handleSearchDispatch = async (event) => {
    if (event.key === "Enter") {
      try {
        if (searchValue !== "") {
          await dispatch(
            getDealsBySearchThunk({
              query: searchValue,
              pageno: 1,
              limit: limit,
              status: bestDeals?.tabIndicator as string,
            })
          );
        } else {
          dispatch(
            getAllDealsThunk({
              status: `${bestDeals?.tabIndicator}`,
              pageno: bestDeals?.currentPage,
              limit: limit,
            })
          );
        }
      } catch (error) {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    }
  };

  const handleCloseSnackbar = () => {
    closeSnackbar();
  };

  const handleLanguage = (label) => {
    const newFlag = label === "English" ? UkFlag : BahrinFlag;
    setSelectedLang(newFlag);
    setAnchorEl(null);
    setLanguage(label);
  };

  const handleClick = () => {
    const elem = document.getElementById("flagDropdown");
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelTabActive = (tabNumber) => {
    setTabValue(tabNumber);
  };

  return (
    <CustomerContainer onClick={handleCloseSnackbar}>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Best Deals</Heading>
          
          <InputTextField
            id="deals"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            onKeyDown={handleSearchDispatch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color={brandColor} />
                </InputAdornment>
              ),
            }}
          />

          <ArabicSwitch>
            <ArabicSwitchDropdown
              selectedLang={selectedLang}
              handleClick={handleClick}
              anchorEl={anchorEl}
              languageArr={languageArr}
              handleClose={handleClose}
              handleLanguage={handleLanguage}
            />
          </ArabicSwitch>
        </LeftDiv>

        <RightDiv>
          <NewDealButton
            variant="contained"
            color="primary"
            onClick={() => history.push(NEW_DEAL)}
          >
            New Deal
          </NewDealButton>

          <SetActiveButton
            variant="contained"
            onClick={() => toggleActiveFunc(true)}
            className={`${
              bestDeals?.tabIndicator === "Active" ? classes.disabled : ""
            }`}
          >
            Set Active
          </SetActiveButton>

          <SetFinishedButton
            variant="outlined"
            color="primary"
            className={`${
              bestDeals?.tabIndicator === "In-Active" ? classes.disabled : ""
            }`}
            onClick={() => toggleInactiveFunc(true)}
          >
            Set Finished
          </SetFinishedButton>
        </RightDiv>
      </HeaderDiv>

      <TabSelector
        labelArray={["All Deals", "Active", "Inactive"]}
        tabValue={tabValue as number}
        children={
          [
            <DealsTable
              active={active}
              inactive={inactive}
              toggleActiveFunc={toggleActiveFunc}
              toggleInactiveFunc={toggleInactiveFunc}
              searchValue={searchValue}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
            <DealsTable
              filterBy={"Active"}
              filterColumn={"dealStatus"}
              active={active}
              inactive={inactive}
              toggleActiveFunc={toggleActiveFunc}
              toggleInactiveFunc={toggleInactiveFunc}
              searchValue={searchValue}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
            <DealsTable
              filterBy={"In-Active"}
              filterColumn={"dealStatus"}
              active={active}
              inactive={inactive}
              toggleActiveFunc={toggleActiveFunc}
              toggleInactiveFunc={toggleInactiveFunc}
              searchValue={searchValue}
              selectedLang={Language}
              handelTabActive={handelTabActive}
            />,
          ] as any
        }
      />
    </CustomerContainer>
  );
}

export default withRouter(BestDeals);
