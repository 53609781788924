import styled from "styled-components";

import { Paper } from "@material-ui/core";

import { Line } from "react-chartjs-2";

import { WHITE_COLOR, BLACK_COLOR } from "constants/style/color";
import { SUBHEADER_FONT_SIZE } from "constants/style/font-size";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";

export const AppUsageContainer = styled(Paper)`
  flex-grow: 1;
  background: ${WHITE_COLOR};
  box-shadow: 0px 0px 18px rgb(0 0 0 / 6%) !important;
  border-radius: 10px;
  padding: 2%;
  position: relative;
  width: 96%;
  max-height: 8rem;
  /* padding-left: 1rem; */
  min-height: 18rem;
`;

export const WidgetName = styled.p`
  margin: 0;
  font-size: ${SUBHEADER_FONT_SIZE};
  font-family: ${PRIMARY_REGULAR_FONT};
  color: ${BLACK_COLOR};
  position: absolute;
  top: 1rem;
  left: 2rem !important;
`;

export const LineGraph = styled(Line)`
  width: 95% !important;
  padding-top: 2.5rem;
`;
export const WidgetMessage = styled(WidgetName)`
  /* margin-top: 8rem;
  margin-left: 8rem; */
  position: absolute;
  top: 45%;
  left: 32% !important;
  font-weight: bold;
`;
