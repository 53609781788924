import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import PaperComponent from "shared-components/paper-component";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import { RootState } from "redux-store/store";
import { useSnackbar } from "notistack";
import {
  NotificationContainer,
  FieldContainer,
  InputLabelText,
  InputTextField,
  // NotificationTypeDropdown,
  UserTypeDropdown,
} from "pages/management/notification/add-notification/styles";

import ArabicInput from "shared-components/arabic-input/textfield";
import EnterArabicText from "shared-components/arabic-input/enter-in-arabic-text";
import LoaderButton from "shared-components/loader-button";

import { MenuItem } from "@material-ui/core";

import {
  EMPTY_INPUT,
  INVALID_LENGTH_BODY_INPUT,
  INVALID_LENGTH_TITLE_INPUT,
} from "constants/snackbar-messages-constants";
import { NOTIFICATION } from "constants/route/route-constants";

import {
  ErrorHelperText,
  Required,
} from "pages/best-deals/components/new-deal/styles";
import { CancelButton } from "pages/management/privacy-policy/components/details/styles";
import { loaderBtnStyleFunc } from "constants/style/props";
import {
  addNewNotificationThunk,
  // setCurrentNotificationReducer,
} from "slices/notifications";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

// const notificationTypes = [
//   "Promotional notification",
//   "Update notifications",
//   "Reminder notifications",
// ];
const userArr = ["All", "Owner", "Guest"];
const addNotification = {
  role: "",
  title: "",
  body: "",
  titleArabic: "",
  bodyArabic: "",
  brand: "",
};
const errorObj = {
  role: "User Type",
  title: "Header text key",
  body: "Sub text key",
  notificationType: "Notification Type",
  titleArabic: "Header text key",
  bodyArabic: "Sub text key",
};

function AddNotification(): ReactElement {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  // const ref = useRef<any>();
  // const location = useLocation<any>();
  const [notiState, setNotiState] = useState({ ...addNotification });

  const [notificationErrors, setnotificationErrors] = useState({
    roleError: "",
    titleError: "",
    bodyError: "",
    notificationTypeError: "",
    titleArabicError: "",
    bodyArabicError: "",
  });
  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });

  // useEffect(() => {
  //   if (location?.state?.detail === "edit") {
  //     if (ref.current !== undefined && ref.current !== brandName) {
  //       return history.push(NOTIFICATION);
  //     }
  //     ref.current = brandName;
  //   }
  //   console.log(location)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [brandName, history]);

  // useEffect(() => {
  //   if (location?.state?.detail === "edit") {
  //     // faqState["question"] = location?.state?.rowData?.question;

  //     setNotiState({ ...location?.state?.rowData });
  //     dispatch(setCurrentNotificationReducer({ ...location?.state?.rowData }));
  //   }
  // }, [location?.state?.rowData, dispatch, location?.state?.detail]);

  const handelChange = (props) => (e) => {
    const { value } = e.target;
    notiState[props] = value;
    if (value === "") {
      notificationErrors[props + "Error"] = `${errorObj[props]} ${EMPTY_INPUT}`;
    } else {
      notificationErrors[props + "Error"] = "";
    }
    if (title.length > 60) {
      notificationErrors[
        "titleError"
      ] = `${errorObj["title"]} ${INVALID_LENGTH_TITLE_INPUT}`;
    }
    if (body.length > 200) {
      notificationErrors[
        "bodyError"
      ] = `${errorObj["body"]} ${INVALID_LENGTH_BODY_INPUT}`;
    }
    setNotiState({ ...notiState, brand: brandName });
    setnotificationErrors({ ...notificationErrors });
  };

  const handelSave = async () => {
    if (role === "") {
      notificationErrors["roleError"] = `${errorObj["role"]} ${EMPTY_INPUT}`;
    }
    if (title === "") {
      notificationErrors["titleError"] = `${errorObj["title"]} ${EMPTY_INPUT}`;
    }
    if (body === "") {
      notificationErrors["bodyError"] = `${errorObj["body"]} ${EMPTY_INPUT}`;
    }
    if (title.length > 60) {
      notificationErrors[
        "titleError"
      ] = `${errorObj["title"]} ${INVALID_LENGTH_TITLE_INPUT}`;
    }
    if (body.length > 200) {
      notificationErrors[
        "bodyError"
      ] = `${errorObj["body"]} ${INVALID_LENGTH_BODY_INPUT}`;
    }
    if (titleArabic.length > 60) {
      notificationErrors[
        "titleArabicError"
      ] = `${errorObj["titleArabic"]} ${INVALID_LENGTH_TITLE_INPUT}`;
    }
    if (bodyArabic.length > 200) {
      notificationErrors[
        "bodyArabicError"
      ] = `${errorObj["bodyArabic"]} ${INVALID_LENGTH_BODY_INPUT}`;
    }
    // if (notificationType === "Type") {
    //   notificationErrors[
    //     "notificationTypeError"
    //   ] = `${errorObj["notificationType"]} ${EMPTY_INPUT}`;
    // }
    if (role !== "" && title !== "" && body !== "") {
      try {
        setLoading(true);
        var AddNewResult = await dispatch(
          addNewNotificationThunk(notiState)
        ).unwrap();
        enqueueSnackbar(AddNewResult.data.status, {
          variant: "success",
        });
        setLoading(false);
        history.push({
          pathname: `${NOTIFICATION}`,
        });
        setNotiState({
          role: "",
          title: "",
          brand: "",
          body: "",
          titleArabic: "",
          bodyArabic: "",
        });
      } catch (error: any) {
        setLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
      // enqueueSnackbar("Success", { variant: "success" });
    }
    // console.log(notiState);
    setnotificationErrors({ ...notificationErrors });
  };

  const handleArabicText = (text, type) => {
    if (type === "hide") {
      notiState[text] = null;
      notificationErrors[text + "Error"] = "";
    } else {
      notiState[text] = "";
    }
    setNotiState({ ...notiState });
    setnotificationErrors({ ...notificationErrors });
  };
  const handleArabicChange = (e, prop) => {
    const { value } = e.target;
    // const result = new RegExp(/^[\u0621-\u064A\u0660-\u0669 ]+$/).test(value);
    // if (!result) {
    //   notificationErrors[prop + "Error"] = `Invalid ${errorObj[prop]}`;
    // } else {
    //   notificationErrors[prop + "Error"] = ``;
    // }
    if (titleArabic.length > 60) {
      notificationErrors[
        "titleArabicError"
      ] = `${errorObj["titleArabic"]} ${INVALID_LENGTH_TITLE_INPUT}`;
    }
    if (bodyArabic.length > 200) {
      notificationErrors[
        "bodyArabicError"
      ] = `${errorObj["bodyArabic"]} ${INVALID_LENGTH_BODY_INPUT}`;
    }
    notiState[prop] = value;
    setNotiState({ ...notiState });
    setnotificationErrors({ ...notificationErrors });
  };

  const handelCancel = () => {
    history.push(NOTIFICATION);
  };

  const { role, title, body, titleArabic, bodyArabic } = notiState;

  const {
    roleError,
    titleError,
    bodyError,
    // notificationTypeError,
    titleArabicError,
    bodyArabicError,
  } = notificationErrors;

  return (
    <PaperComponent heading={"Notification"}>
      <NotificationContainer>
        <FieldContainer>
          <InputLabelText>
            User Type<Required>*</Required>
          </InputLabelText>
          <UserTypeDropdown
            onChange={handelChange("role")}
            variant="outlined"
            value={role}
            error={Boolean(roleError)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="User Type" disabled>
              User Type
            </MenuItem>
            {userArr.map((item, index) => {
              return (
                <MenuItem key={`${item}_${index}`} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </UserTypeDropdown>
          {Boolean(roleError) && <ErrorHelperText>{roleError}</ErrorHelperText>}

          {/* {roleInArabic === null && (
            <EnterArabicText
              handleArabicText={handleArabicText}
              typeOfField={"roleInArabic"}
            />
          )} */}
        </FieldContainer>
        {/* <FieldContainer>
          {typeof roleInArabic === "string" && (
            <>
              <ArabicInput
                labelText={"User Type In "}
                value={roleInArabic}
                errorMessage={roleInArabicError}
                typeOfField={"roleInArabic"}
                handleArabicText={handleArabicText}
                handleArabicChange={handleArabicChange}
                width={"35%"}
              />
            </>
          )}
        </FieldContainer> */}

        <FieldContainer>
          <InputLabelText>
            Header text key<Required>*</Required> (must be less than 60
            character)
          </InputLabelText>

          <InputTextField
            value={title}
            variant="outlined"
            size="small"
            onChange={handelChange("title")}
            error={Boolean(titleError)}
            helperText={titleError}
            inputProps={{
              style: { fontSize: 14, fontFamily: PRIMARY_REGULAR_FONT },
            }}
          />
          {titleArabic === null && (
            <EnterArabicText
              handleArabicText={handleArabicText}
              typeOfField={"titleArabic"}
            />
          )}
        </FieldContainer>
        <FieldContainer>
          {typeof titleArabic === "string" && (
            <>
              <ArabicInput
                labelText={"Header Text In "}
                value={titleArabic}
                errorMessage={titleArabicError}
                typeOfField={"titleArabic"}
                handleArabicText={handleArabicText}
                handleArabicChange={handleArabicChange}
                width={"35%"}
              />
            </>
          )}
        </FieldContainer>

        <FieldContainer>
          <InputLabelText>
            Sub text key<Required>*</Required> (must be less than 200 character)
          </InputLabelText>
          <InputTextField
            value={body}
            variant="outlined"
            size="small"
            onChange={handelChange("body")}
            error={Boolean(bodyError)}
            helperText={bodyError}
            inputProps={{
              style: { fontSize: 14, fontFamily: PRIMARY_REGULAR_FONT },
            }}
          />
          {bodyArabic === null && (
            <EnterArabicText
              handleArabicText={handleArabicText}
              typeOfField={"bodyArabic"}
            />
          )}
        </FieldContainer>
        <FieldContainer>
          {typeof bodyArabic === "string" && (
            <>
              <ArabicInput
                labelText={"Sub Text Key In "}
                value={bodyArabic}
                errorMessage={bodyArabicError}
                typeOfField={"bodyArabic"}
                handleArabicText={handleArabicText}
                handleArabicChange={handleArabicChange}
                width={"35%"}
              />
            </>
          )}
        </FieldContainer>

        {/* <FieldContainer>
          <InputLabelText>
            Notification Type <Required>*</Required>
          </InputLabelText>
          <NotificationTypeDropdown
            onChange={handelChange("notificationType")}
            variant="outlined"
            value={notificationType}
            error={Boolean(notificationTypeError)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="Notification Type" disabled>
              Notification Type
            </MenuItem>
            {notificationTypes.map((item, index) => {
              return (
                <MenuItem key={`${item}_${index}`} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </NotificationTypeDropdown>
          {Boolean(notificationTypeError) && (
            <ErrorHelperText>{notificationTypeError}</ErrorHelperText>
          )}
        </FieldContainer> */}
        <LoaderButton
          isLoading={loading}
          buttonText="Save"
          styles={loaderBtnStyleFunc("6rem", "2.6rem")}
          submitHandler={handelSave}
        />
        <CancelButton variant="outlined" color="primary" onClick={handelCancel}>
          Cancel
        </CancelButton>
      </NotificationContainer>
    </PaperComponent>
  );
}

export default AddNotification;
