import React, { ReactElement } from "react";

interface Props {
  color: string;
}

function CircleIcon({ color }: Props): ReactElement {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={25} cy={25} r={25} fill={color} fillOpacity={0.5} />
    </svg>
  );
}

export default CircleIcon;
