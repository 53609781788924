import styled from "styled-components";
import Box from "@material-ui/core/Box";


export const NewBox = styled(Box)`
    display:flex;
    align-items:center;
`;

export const SpanTag = styled.span`
    font-size:12px;
`;