import axios from "axios";
import selectedBrand from "constants/brand";

import {
  BASE_URL,
  NOTIFICATION_ENDPOINT,
  // UPDATE_NOTIFICATION_ENDPOINT,
  // DELETE_NOTIFICATION_ENDPOINT,
} from "constants/api-config-constants";

import { limit } from "constants/pagination-constants";

import {
  GetNotificationsRequest,
  NotificationObject,
  GetNotificationsResponse,
  // DeleteNotificationResponse,
  // SearchNotifications,
} from "interfaces/notifications-interfaces";

// get all notifications from database
export const getAllNotifications = async (
  request: GetNotificationsRequest
): Promise<GetNotificationsResponse> => {
  const { pageno } = request;
  const url =
    BASE_URL +
    NOTIFICATION_ENDPOINT +
    "?pageno=" +
    pageno +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

// add new notification in database
export const addNewNotification = async (
  request: NotificationObject
): Promise<GetNotificationsResponse> => {
  const url =
    BASE_URL + NOTIFICATION_ENDPOINT + "?brand=" + selectedBrand();
  return axios.post(url, request);
};

// update notification by id in database
// export const updateNotification = async (
//   request: NotificationObject
// ): Promise<GetNotificationsResponse> => {
//   const url = BASE_URL + UPDATE_NOTIFICATION_ENDPOINT + "?id=" + request.id;
//   return axios.put(url, request);
// };

// // delete notification by id from database
// export const deleteNotification = async (
//   notificationId: number
// ): Promise<DeleteNotificationResponse> => {
//   const url = BASE_URL + DELETE_NOTIFICATION_ENDPOINT + "?id=" + notificationId;
//   return axios.delete(url);
// };
