import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import { makeStyles } from "@material-ui/styles";
import {
  GridColDef,
  GridCellParams,
  GridRowModel,
} from "@material-ui/data-grid";

import { UserRoute } from "pages/customers/components/customers-table/styles";

import { RenderCellExpand } from "shared-components/expanded-cel";
import PaginationFooter from "shared-components/pagination";
import TableData from "shared-components/table-data";

// import { NEWS_DETAIL } from "constants/route/route-constants"
import { NEWS_DETAIL } from "constants/route/route-constants";
import { loadingHelper } from "constants/loadingHelper";
import { limit } from "constants/pagination-constants";

import { getAllNewsThunk, setCurrentPageReducer } from "slices/news";

import // NavItem,
"pages/news/components/news-details/styles";
import { useSnackbar } from "notistack";

interface Props {
  filterBy?: string | boolean;
  exportData?: boolean;
  setExportData?: any;
  filterColumn?: string;
}

const useStyles = makeStyles({
  root: {
    "& .centered": {
      textAlign: "center",
    },
  },
});

const NewsTable = ({ filterBy, filterColumn }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory<any>();
  const classes = useStyles();

  const [rows, setRows] = useState<GridRowModel>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const news = useAppSelector((state) => state.news.newsInfo);
  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  useEffect(() => {
    if (history?.location?.state?.from === "Back") {
      loadingHelper(
        dispatch(
          getAllNewsThunk({ pageNumber: news?.currentPage, limit: limit })
        ),
        setIsLoading,
        enqueueSnackbar
      );
      const state = { ...history.location.state };
      delete state.from;
      history.replace({ ...history.location, state });
    } else {
      loadingHelper(
        dispatch(getAllNewsThunk({ pageNumber: 1, limit: limit })),
        setIsLoading,
        enqueueSnackbar
      );

      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, brandName]);

  useEffect(() => {
    setRows(news?.allNews);
    setPaginationSize(Math.ceil(news?.recordsLength / limit));
  }, [news]);

  const handlePagination = (event, number) => {
    loadingHelper(
      dispatch(getAllNewsThunk({ pageNumber: number, limit: limit })),
      setIsLoading,
      enqueueSnackbar
    );
    dispatch(setCurrentPageReducer(number));
  };

  const columns: GridColDef[] = [
    {
      field: "contentID",
      headerName: "News ID",
      sortable: true,
      resizable: false,
      flex: 0.4,
      cellClassName: "centered",
      renderCell: (params: GridCellParams) => {
        const contentID = params.row.contentID;
        return (
          <UserRoute
            to={{
              pathname: NEWS_DETAIL,
              state: {
                id: contentID,
                detail: "view",
              },
            }}
          >
            {params.row.contentID}
          </UserRoute>
        );
      },
    },
    {
      field: "title",
      sortable: true,
      headerName: "Title",
      resizable: false,
      flex: 1.8,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "newsTitle", 400);
      },
    },
    {
      field: "images",
      sortable: false,
      headerName: "Image URL",
      resizable: false,
      flex: 2.2,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "newsLink", 500);
      },
    },
    {
      field: "createdDateAndTime",
      sortable: false,
      headerName: "Published date",
      resizable: false,
      flex: 1.2,
      renderCell: RenderCellExpand,
    },
  ];

  return (
    <>
      <TableData
        // rows={rows as GridRowModel[]}
        rows={isLoading ? [] : (rows as GridRowModel[])}
        filterBy={filterBy}
        filterColumn={filterColumn}
        columns={columns}
        isCheckBox={false}
        pageSize={limit}
        isPagination={true}
        getRowId={(r) => Number(r.contentID)}
        className={classes.root}
        isLoading={isLoading}
      />
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={news?.currentPage}
        />
      ) : null}
    </>
  );
};

export default NewsTable;
