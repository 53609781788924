import SnackbarMessageInterface from "slices/snackbar-message/snackbar-message-slice.interface";

const snackbarInitialState: SnackbarMessageInterface = {
  apiResponseStatus: {
    isSnackbarOpen: false,
    alertType: "",
    message: "",
  },
};

export default snackbarInitialState;
