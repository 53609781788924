import FaqState from "slices/faqs/faqs-interface";


const faqsInitialState: FaqState = {
  faqsInfo: {
    allFaqs: [],
    currentFaq: {},
    recordsLength: 0,
    currentPage:1,
  },
};

export default faqsInitialState;
