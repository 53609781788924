import axios from "axios";
import { BASE_URL, LIVE_CHAT_ENDPOINT } from "constants/api-config-constants";
import selectedBrand from "constants/brand";
import { GetLiveChatResponse, LiveChatDetails } from "interfaces/live-chat.interface";


export const getLiveChat = async (): Promise<GetLiveChatResponse> => {
    const url =
      BASE_URL +
      LIVE_CHAT_ENDPOINT +
      "?brand=" +
      selectedBrand();
    return axios.get(url);
};

export const updateLiveChat = async (
    request: LiveChatDetails
  ): Promise<GetLiveChatResponse> => {
    const url = BASE_URL + LIVE_CHAT_ENDPOINT + "?brand=" + selectedBrand();
    return axios.post(url, request);
  };