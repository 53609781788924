import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import { RenderCellExpand } from "shared-components/expanded-cel";
import PaginationFooter from "shared-components/pagination";
import TableData from "shared-components/table-data";

import { useSnackbar } from "notistack";
import {
  GridColDef,
  GridRowModel,
  GridCellParams,
} from "@material-ui/data-grid";

import { EDIT_FAQ } from "constants/route/route-constants";
import { loadingHelper } from "constants/loadingHelper";
import { limit } from "constants/pagination-constants";

import DeleteUser from "pages/user-profile/components/delete-user";
import { DeletePopupModal } from "pages/best-deals/components/deals-table/styles";
import {
  RemoveIconLogo,
  EditIconLogo,
} from "pages/user-profile/components/user-management/styles";

import { setCurrentPageReducer } from "slices/faqs";
import {
  deleteFaqThunk,
  getAllFaqsThunk,
  getFaqsBySearchThunk,
} from "slices/faqs";

interface Props {
  searchValue: string;
  selectedLang: string;
}

const FaqsTable = ({ searchValue, selectedLang }: Props) => {
  const history = useHistory<any>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDeleteID, setSelectedDeletedId] = useState<number>(0);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [rows, setRows] = useState<GridRowModel>([]);

  const faqs = useAppSelector((state) => state.faqs.faqsInfo);
  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  useEffect(() => {
    if (history?.location?.state?.from === "edit") {
      loadingHelper(
        dispatch(getAllFaqsThunk({ pageno: faqs?.currentPage, limit: limit })),
        setLoading,
        enqueueSnackbar
      );
      const state = { ...history.location.state };
      delete state.from;
      history.replace({ ...history.location, state });
    } else {
      loadingHelper(
        dispatch(getAllFaqsThunk({ pageno: 1, limit: limit })),
        setLoading,
        enqueueSnackbar
      );
      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, brandName]);

  useEffect(() => {
    setRows(faqs?.allFaqs);
    setPaginationSize(Math.ceil(faqs?.recordsLength / limit));
  }, [faqs]);

  const handlePagination = (event, number) => {
    if (searchValue === "") {
      dispatch(getAllFaqsThunk({ pageno: number, limit: limit }));
    } else {
      loadingHelper(
        dispatch(
          getFaqsBySearchThunk({
            query: searchValue,
            pageno: number,
            limit: limit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
    }
    dispatch(setCurrentPageReducer(number)); //
  };

  const openDeleteDealModal = (id) => {
    setOpenDeleteModal(true);
    setSelectedDeletedId(id);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const confirmDelete = async (id) => {
    try {
      setLoading(true);
      const result = await dispatch(deleteFaqThunk(id)).unwrap();
      enqueueSnackbar(result?.response?.data, {
        variant: "success",
      });
      setLoading(false);
      setOpenDeleteModal(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.data, { variant: "error" });
      setOpenDeleteModal(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: `${selectedLang === "English" ? "question" : "questionArabic"}`,
      headerName: "Question",
      sortable: false,
      resizable: false,
      flex: 1.1,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "faqQuestion", 400, selectedLang);
      },
    },
    {
      field: `${selectedLang === "English" ? "answer" : "answerArabic"}`,
      sortable: false,
      headerName: "Answer",
      resizable: false,
      flex: 1.2,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "faqAnswer", 480, selectedLang);
      },
    },
    {
      field: "id",
      sortable: false,
      headerName: "Actions",
      resizable: false,
      flex: 0.3,
      renderCell: (params: GridCellParams) => {
        const rowData = params.row;
        return (
          <>
            <EditIconLogo
              onClick={() =>
                history.push({
                  pathname: `${EDIT_FAQ}`,
                  state: {
                    detail: "edit",
                    rowId: rowData?.id,
                    rowData: rowData,
                  },
                })
              }
            />
            <RemoveIconLogo onClick={() => openDeleteDealModal(rowData.id)} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <TableData
        rows={loading ? [] : (rows as GridRowModel[])}
        isLoading={loading}
        columns={columns}
        isCheckBox={false}
        pageSize={limit}
        isPagination={true}
      />

      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={faqs?.currentPage}
        />
      ) : null}

      <DeletePopupModal open={openDeleteModal} onClose={closeDeleteModal}>
        <DeleteUser
          params={selectedDeleteID}
          closeModal={closeDeleteModal}
          confirmDelete={confirmDelete}
          loading={loading}
          headerText={"Delete FAQ"}
          paraText={"Are you sure you want to delete this FAQ?"}
          message={"All data related to this FAQ will be deleted."}
        ></DeleteUser>
      </DeletePopupModal>
    </>
  );
};

export default FaqsTable;
