import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { InputLabel, Box, Select } from "@material-ui/core";
import { Heading } from "pages/customers/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { TEXTFIELD_HEADING } from "constants/style/color";
import {
  CancelInput,
  CancelInputTextArea,
} from "pages/location/components/add-location/styles";
import MaterialUIPickers from "../date-time";

export const LocationContainer = styled.div`
  padding: 1.5rem 2rem;
`;

export const HeadingDiv = styled(Heading)``;

export const LeftDiv = styled.div`
  display: flex;
`;

export const RightDiv = styled.div`
  width: 25%;
`;

export const FieldContainer = styled.div`
  margin-top: 2%;
  margin-bottom: 6%;
`;

export const FieldContainerTC = styled(FieldContainer)`
  margin-top: 1%;
  margin-bottom: 3%;
`;

export const InputTextField = styled(TextField)`
  width: 90%;
`;

export const EndTimeDatePicker = styled(MaterialUIPickers)`
`;

export const StartTimeDatePicker = styled(MaterialUIPickers)`
  
`;

export const InputLabelText = styled(InputLabel)`
  margin-bottom: 0.625rem;
  font-size: ${SUBTEXT_FONT_SIZE} !important;
  color: ${TEXTFIELD_HEADING} !important;
`;

export const InputLabelTextTC = styled(InputLabelText)`
  margin-bottom: 0.5rem;
`;

export const CheckboxTC = styled(Checkbox)`
  width: 1rem; 
  padding:0 .5rem 0 0 !important;
`;

export const InputTextArea = styled(InputTextField)`
  height: 30%;
`;

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ParentContainer = styled(Box)`
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  margin: 1rem;
  // background-color:aqua;
`;

export const FormLeftSide = styled.div`
  width: 32%;
  float: left;
  margin: 0.625rem 0rem 0 1rem;
  padding: 0.5rem 0rem 0rem 1rem;
`;

export const FormRightSide = styled(FormLeftSide)`
  width: 45%;
  height: 100%;
  float: left;
  margin: 0.625rem 0rem 0 .5rem;
`;

export const DropdownSelector = styled(Select)`
  height: 2.5rem;
  width: 90%;
  border-radius: 3px !important;
  font-size: 14px !important;
`;

export const ImageInput = styled.input`
  display: none;
`;

export const ErrorHelperText = styled(FormHelperText)`
  color: rgba(255, 0, 0, 0.8) !important;
  margin: 0.2rem 0.625rem !important;
`;

export const Required = styled.span`
  color: red;
`;

export const FlexBoxArabicDeals = styled.div`
  display: flex;
  width: 100%;
`;

export const CancelIcon = styled(CancelInput)`
  margin: 0.5rem 0rem 0.5rem 0.5rem;
`;

export const CancelInputDescription = styled(CancelInputTextArea)`
  margin: 2.5rem 1.5rem;
`;

export const TwoImageFlexCon = styled.div`
  display: flex;
  flex: 0.34;
`;
