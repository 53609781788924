import React, { ReactElement } from "react";

import LoaderButton from "shared-components/loader-button";

import { loaderBtnStyleFunc } from "constants/style/props";

import {
  DeleteDealsContainer,
  ConfirmBox,
  HeaderCon,
  ButtonCon,
  CancelBtn,
  SpanCon,
} from "pages/best-deals/components/delete-deal/styles";

export default function DeleteBlog(props): ReactElement {
  const { confirmDelete, closeModal, params, loading } = props;

  return (
    <DeleteDealsContainer>
      <ConfirmBox>
        <HeaderCon>Delete Blog</HeaderCon>
        <h4>Are you sure you want to delete this blog?</h4>
        <SpanCon>All data related to this blog will be deleted.</SpanCon>
      </ConfirmBox>

      <ButtonCon>
        <CancelBtn variant="contained" color="default" onClick={closeModal}>
          No
        </CancelBtn>
        <LoaderButton
          submitHandler={() => confirmDelete(params)}
          isLoading={loading}
          buttonText="Yes"
          styles={loaderBtnStyleFunc("25%", "2.5rem")}
        />
      </ButtonCon>
    </DeleteDealsContainer>
  );
}
