import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  ReactNode,
} from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GridCellParams, isOverflown } from "@material-ui/data-grid";
// import { handelTimings } from "pages/location/components/location-table";
import {
  CellDivs,
  CellWrap,
  CustomPopper,
  DivWrap,
  HoverPaper,
  Wrapper,
} from "./styles";

interface CellExpandProps {
  value: string | ReactNode;
  width: number;
  maxWidth: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      lineHeight: "1.5rem",
      width: "100%",
      height: "100%",
      position: "relative",
      display: "flex",
      "& .MuiRating-root": {
        marginRight: theme.spacing(1),
      },
      "& .cellValue": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  })
);

function extractContent(s) {
  var span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const getNewsLink =(params)=>{
  if(Boolean(params.row.images)){
    if("images" in params.row && params.row.images.includes("https://")){
      return params.row.images
    }else{
      return !params.row.image.includes("https://")? "https://files.ekkanoo.com/":params.row.image
    }
  }else{
    return "https://files.ekkanoo.com/"
  }
}

const CellExpand = React.memo(function CellExpand(props: CellExpandProps) {
  const { width, value, maxWidth } = props;
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const classes = useStyles();
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const showCell = useCallback(() => {
    setShowFullCell(true);
  }, []);

  const hideCell = useCallback(() => {
    setShowFullCell(false);
  }, []);

  useEffect(() => {
    if (cellDiv.current) {
      setAnchorEl(cellDiv.current);
    }
  }, []);

  useEffect(() => {
    if (cellValue && cellValue.current) {
      const isCurrentlyOverflown = isOverflown(cellValue.current!);
      setShowPopper(isCurrentlyOverflown);
    }
  }, [width]);

  return (
    <Wrapper
      ref={wrapper}
      className={classes.root}
      onMouseEnter={showCell}
      onMouseLeave={hideCell}
    >
      <DivWrap style={{ width }} ref={cellDiv} />

      <CellWrap ref={cellValue} className="cellValue">
        {value}
      </CellWrap>

      <CustomPopper
        open={showFullCell && anchorEl != null && showPopper}
        anchorEl={anchorEl}
        style={{ maxWidth: maxWidth }}
      >
        <HoverPaper elevation={1}>
          <CellDivs>{value}</CellDivs>
        </HoverPaper>
      </CustomPopper>
    </Wrapper>
  );
});
export function RenderCellExpand(
  params: GridCellParams,
  type?: string,
  maxWidth?: number,
  flag?: string
) {
  let val;
  if (type === "user-management-brand") {
    val = params.row.listofBrands?.join(", ").replace("motorcity","Motor City");
  } else if (type === "newsLink") {
    const newLink = getNewsLink(params)
    const absoluteUrl = new URL(
      newLink
    ).href;
    val = (
      <a
        href={absoluteUrl}
        target={"_blank"}
        rel="noopener noreferrer external"
      >
        {absoluteUrl==="https://files.ekkanoo.com/"?"Not valid image url":absoluteUrl.slice(0,100)+"..."}
      </a>
    );
  } else if (type === "latestCarLink") {
    // const absoluteUrl = new URL(params.row.external_link).href;
    val = (
      <a
        href={params.row.external_link}
        target={"_blank"}
        rel="noopener noreferrer external"
      >
        {params.row.external_link}
      </a>
    );
  }else if (type === "locationNameArabic") {
    if (flag === "English") {
      val = params.row.locationName;
    } else {
      val = params.row.locationNameArabic;
      if (!Boolean(params.row.locationNameArabic)) {
        val = params.row.locationName;
      }
    }
  }else if (type === "serviceCenter") {
      val = params.row.serviceCenter;
  }else if (type === "addressArabic") {
    if (flag === "English") {
      val = params.row.areaName;
    } else {
      val = params.row.areaNameArabic;
      if (!Boolean(params.row.areaNameArabic)) {
        val = params.row.areaName;
      }
    }
  }else if (type === "blogTitleArabic") {
    if (flag === "English") {
      val = params.row.title;
    } else {
      val = params.row.titleArabic;
      if (!Boolean(params.row.titleArabic)) {
        val = params.row.title;
      }
    }
  } else if (type === "blogSubtextArabic") {
    if (flag === "English") {
      val = params.row.subText;
    } else {
      val = params.row.subTextArabic;
      if (!Boolean(params.row.subTextArabic)) {
        val = params.row.subText;
      }
    }
  } else if (type === "dealTitleArabic") {
    if (flag === "English") {
      val = params.row.dealTitle;
    } else {
      val = params.row.dealTitleArabic;
      if (!Boolean(params.row.dealTitleArabic)) {
        val = params.row.dealTitle;
      }
    }
  } else if (type === "dealTextArabic") {
    if (flag === "English") {
      val = params.row.dealText;
    } else {
      val = params.row.dealTextArabic;
      if (!Boolean(params.row.dealTextArabic)) {
        val = params.row.dealText;
      }
    }
  } else if (type === "faqQuestion") {
    if (flag === "English") {
      val = params.row.question;
    } else {
      val = params.row.questionArabic;
      if (!Boolean(params.row.questionArabic)) {
        val = params.row.question;
      }
    }
  }else if (type === "faqAnswer") {
    if (flag === "English") {
      val = params.row.answer.length > 100
      ? extractContent(params.row.answer).slice(0, 100) + "..."
      : extractContent(params.row.answer);
    } else {
      val = extractContent(params.row.answerArabic);
      if (!Boolean(params.row.answerArabic)) {
        val = params.row.answer.length > 100
        ? extractContent(params.row.answer).slice(0, 100) + "..."
        : extractContent(params.row.answer);
      }
    }
  }else if (type === "emergencyTypeArabic") {
    if (flag === "English") {
      val = params.row.emergencyType;
    } else {
      val = params.row.emergencyTypeArabic;
      if (!Boolean(params.row.emergencyTypeArabic)) {
        val = params.row.emergencyType;
      }
    }
  }else if (type === "phoneNo") {
    val = params.row.phoneNo.join(" , ")
  }else if (type === "locationTimings") {
    // var time = handelTimings(params.row.timingObj.normalTimings);
    // val = time;
  } else if (type === "coordinates") {
    val =
      "Latitude:- " +
      params.row.googleXcord +
      " Longitude:- " +
      params.row.googleYcord;
  }
  else if (type === "notificationTitle") {
    if (flag === "English") {
      val = params.row.title?.length > 100
      ? extractContent(params.row.title).slice(0, 100) + "..."
      : extractContent(params.row.title);
    } else {
      val = extractContent(params.row.titleArabic);
      if (!Boolean(params.row.titleArabic)) {
        val = params.row.title?.length > 100
        ? extractContent(params.row.title).slice(0, 100) + "..."
        : extractContent(params.row.title);
      }
    }
  } else if (type === "notificationBody") {
    if (flag === "English") {
      val = params.row.body?.length > 100
      ? extractContent(params.row.body).slice(0, 100) + "..."
      : extractContent(params.row.body);
    } else {
      val = extractContent(params.row.bodyArabic);
      if (!Boolean(params.row.bodyArabic)) {
        val = params.row.body?.length > 100
        ? extractContent(params.row.body).slice(0, 100) + "..."
        : extractContent(params.row.body);
      }
    }
  }   else {
    val = params.value;
  }
  return (
    <CellExpand
      value={val}
      width={params.colDef.width}
      maxWidth={maxWidth as number}
    />
  );
}
