import styled from 'styled-components'
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import {
    DateTimePicker
  } from '@material-ui/pickers';

interface DateProps{
    width?:number
}
export const CustomDateTimePicker = styled(DateTimePicker)`
  width:${(p: DateProps) => p.width? p.width+"%": 90+"%"};
  cursor:pointer;
  color: ${({ theme }) => theme.color} !important;
`;

export const CalenderIcon = styled(CalendarTodayIcon)`
  color: ${({ theme }) => theme.color} !important;
`;