import { NewsSlice } from "./news-slice";

export {
  getAllNewsThunk,
  getNewsByIdThunk
} from "slices/news/news-thunks";

export default NewsSlice.reducer;

export const { setCurrentPageReducer } = NewsSlice.actions;
