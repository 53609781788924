import React, { ReactElement, useState } from "react";

import { useSnackbar } from "notistack";
import LoaderButton from "shared-components/loader-button";

import { PASSWORD_REGEX_EQUATION } from "constants/regex-validations-constants";
import { loaderBtnStyleFunc } from "constants/style/props";
import {
  EMPTY_INPUT,
  PASSWORD_AND_CONFIRMPASSWORD,
  PASSWORD_AND_OLDPASSWORD,
  PASSWORD_CONSTRAINTS_MESSAGE,
} from "constants/snackbar-messages-constants";

import { UserProfileChangePassword } from "services/user-profile-services";

import {
  ChangePasswordContainer,
  FieldContainer,
  InputTextField,
  InputLabelText,
  ButtonContainer,
  CancelButton,
  ShowPasswordIcon,
  HidePasswordIcon,
  LabelDiv,
} from "./styles";

import { TYPE_LOGOUT } from "constants/action-types-constants";
import { persistor } from "redux-store/store";
import { useAppDispatch } from "redux-store/hooks";

function ChangePassword(): ReactElement {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // const getUser = useAppSelector((state) => state.user.userInfo);

  const [hide, setHide] = useState({
    isHideOldPassword: false,
    isHideNewPassword: false,
    isHideConfirmPassword: false,
  });

  const togglePasswordVisibility = (prop) => {
    setHide({ ...hide, [prop]: hide[prop] ? false : true });
  };

  const [values, setValues] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [passwordErrors, setPasswordErrors] = useState({
    oldPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: "",
  });

  const { oldPassword, newPassword, confirmPassword } = values;
  const { isHideOldPassword, isHideNewPassword, isHideConfirmPassword } = hide;
  const { oldPasswordError, newPasswordError, confirmPasswordError } =
    passwordErrors;

  const handelBlur = () => {
    if (oldPassword === newPassword) {
      passwordErrors.newPasswordError = `${PASSWORD_AND_OLDPASSWORD}`;
    }
    setPasswordErrors({ ...passwordErrors });
  };

  const handleChange = (prop) => (event) => {
    const { value } = event.target;
    const reg = new RegExp(PASSWORD_REGEX_EQUATION).test(value);
    values[prop] = value;
    setValues({ ...values });

    if (prop === "oldPassword") {
      if (value === "") {
        passwordErrors.oldPasswordError = `Current password ${EMPTY_INPUT}`;
      } else {
        passwordErrors.oldPasswordError = "";
      }
    } else if (prop === "newPassword") {
      if (value === "") {
        passwordErrors.newPasswordError = `Password ${EMPTY_INPUT}`;
      } else if (!reg) {
        passwordErrors.newPasswordError = `${PASSWORD_CONSTRAINTS_MESSAGE}`;
      } else {
        passwordErrors.newPasswordError = "";
      }
    } else {
      if (value === "") {
        passwordErrors.confirmPasswordError = `Confirm password ${EMPTY_INPUT}`;
      } else if (value !== newPassword) {
        passwordErrors.confirmPasswordError = `${PASSWORD_AND_CONFIRMPASSWORD}`;
      } else {
        passwordErrors.confirmPasswordError = "";
      }
    }
    setPasswordErrors({ ...passwordErrors });
    // console.log(values)
  };

  const submitChangePassword = async () => {
    handelBlur();
    if (oldPassword === "") {
      passwordErrors.oldPasswordError = `Current password ${EMPTY_INPUT}`;
      setPasswordErrors({ ...passwordErrors });
    }
    if (newPassword === "") {
      passwordErrors.newPasswordError = `Password ${EMPTY_INPUT}`;
      setPasswordErrors({ ...passwordErrors });
    }
    if (confirmPassword === "") {
      passwordErrors.confirmPasswordError = `Confirm password ${EMPTY_INPUT}`;
      setPasswordErrors({ ...passwordErrors });
    }
    if (
      passwordErrors.oldPasswordError === "" &&
      passwordErrors.newPasswordError === "" &&
      passwordErrors.confirmPasswordError === ""
    ) {
      setPasswordErrors({
        oldPasswordError: "",
        newPasswordError: "",
        confirmPasswordError: "",
      });
      try {
        setIsLoading(true);
        const result = await UserProfileChangePassword({
          ...values,
          // id: getUser.user.id,
        });
        setIsLoading(false);
        enqueueSnackbar(result.data.status, { variant: "success" });
        setValues({ oldPassword: "", newPassword: "", confirmPassword: "" });
        persistor.purge();
        dispatch({ type: TYPE_LOGOUT });
      } catch (err) {
        setIsLoading(false);
        enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
      }
    }
  };

  const handelCancel = () => {
    setValues({ oldPassword: "", newPassword: "", confirmPassword: "" });
    setPasswordErrors({
      oldPasswordError: "",
      newPasswordError: "",
      confirmPasswordError: "",
    });
  };

  return (
    <ChangePasswordContainer>
      <FieldContainer>
        <LabelDiv>
          <InputLabelText>Current Password</InputLabelText>
          {isHideOldPassword ? (
            <ShowPasswordIcon
              onClick={() => togglePasswordVisibility("isHideOldPassword")}
            />
          ) : (
            <HidePasswordIcon
              onClick={() => togglePasswordVisibility("isHideOldPassword")}
            />
          )}
        </LabelDiv>
        <InputTextField
          type={isHideOldPassword ? "text" : "password"}
          onChange={handleChange("oldPassword")}
          variant="outlined"
          size="small"
          value={oldPassword}
          error={Boolean(oldPasswordError)}
          helperText={oldPasswordError}
        />
      </FieldContainer>
      <FieldContainer>
        <LabelDiv>
          <InputLabelText>New Password</InputLabelText>
          {isHideNewPassword ? (
            <ShowPasswordIcon
              onClick={() => togglePasswordVisibility("isHideNewPassword")}
            />
          ) : (
            <HidePasswordIcon
              onClick={() => togglePasswordVisibility("isHideNewPassword")}
            />
          )}
        </LabelDiv>
        <InputTextField
          type={isHideNewPassword ? "text" : "password"}
          onChange={handleChange("newPassword")}
          variant="outlined"
          size="small"
          error={Boolean(newPasswordError)}
          helperText={newPasswordError}
          value={newPassword}
        />
      </FieldContainer>
      <FieldContainer>
        <LabelDiv>
          <InputLabelText>Confirm Password</InputLabelText>
          {isHideConfirmPassword ? (
            <ShowPasswordIcon
              onClick={() => togglePasswordVisibility("isHideConfirmPassword")}
            />
          ) : (
            <HidePasswordIcon
              onClick={() => togglePasswordVisibility("isHideConfirmPassword")}
            />
          )}
        </LabelDiv>
        <InputTextField
          type={isHideConfirmPassword ? "text" : "password"}
          onChange={handleChange("confirmPassword")}
          variant="outlined"
          size="small"
          error={Boolean(confirmPasswordError)}
          helperText={confirmPasswordError}
          value={confirmPassword}
        />
        <ButtonContainer>
          <LoaderButton
            submitHandler={submitChangePassword}
            isLoading={isLoading}
            buttonText="Save"
            styles={loaderBtnStyleFunc("20%", "2.8rem")}
          />
          <CancelButton
            variant="contained"
            color="default"
            onClick={handelCancel}
          >
            Cancel
          </CancelButton>
        </ButtonContainer>
      </FieldContainer>
    </ChangePasswordContainer>
  );
}

export default ChangePassword;
