import styled from "styled-components";
import { Link } from "react-router-dom";
import { Modal } from "@material-ui/core";

import {
  TAG_COLOR_GREEN,
  GREEN_TAG_FONT_COLOR,
  TAG_COLOR_RED,
  RED_TAG_FONT_COLOR,
  BLACK_COLOR,
} from "constants/style/color";

export const customTag = styled.button`
  width: 86px;
  height: 27px;
  border-radius: 3px;
  border: none;
  font-weight: 400;
  outline: none;
`;

export const CustomTagGreen = styled(customTag)`
  background-color: ${TAG_COLOR_GREEN};
  color: ${GREEN_TAG_FONT_COLOR};
`;

export const CustomTagRed = styled(customTag)`
  background-color: ${TAG_COLOR_RED};
  color: ${RED_TAG_FONT_COLOR};
`;

export const UserRoute = styled(Link)`
  color: ${BLACK_COLOR};
`;

export const MainContainer = styled.div`
  background-color: #ffffff;
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0px 10px 10px 10px;
  position: relative;
`;

interface Props {
  top: number
}

export const LimitDiv = styled.div`
  position: absolute;
  width: 13%;
  right:0;
  top: ${(p: Props) => p.top};
  display:flex;
  align-items: center;
`;
export const SpanTag = styled.span`
  flex:.6;
  color:rgba(0,0,0,0.5);
`;
export const DropdownDiv = styled.div`
  flex:0.68;
`;

export const AddUserModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const ErrorIconDiv = styled.div``;
