import React, { ReactElement } from "react";
import { Loader } from "./styles";

interface props {
  isLoading: boolean;
  iscolor?: boolean;
}
export default function LoaderSpin({
  isLoading,
  iscolor,
}: props): ReactElement {
  return (
    <>
      {isLoading ? <Loader iscolor={iscolor} size={iscolor ? 40 : 24} /> : null}
    </>
  );
}
