import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import newsInitialState from "slices/latest-cars/latest-cars-initial-state";

import {
  getLatestCarsThunk,
} from "slices/latest-cars/latest-cars-thunks";
import LatestCarsState from "./latest-cars-interface";

export const LatestCarsSlice = createSlice({
  name: "LatestCarsSlice",
  initialState: newsInitialState,
  reducers: {
    setCurrentPageReducer:setCurrentPageReducer
  },
  extraReducers: {
    //set LatestCars
    [getLatestCarsThunk.fulfilled.type]: (state, action) => {
      let { data:{results, totalResults} } = action.payload.data;
      state.latestCarsInfo.allCars =
      results === undefined ? [] : results;
      state.latestCarsInfo.recordsLength = totalResults;
    },

    [getLatestCarsThunk.rejected.type]: (state) => {
      state.latestCarsInfo.allCars = [];
    },

  },
});


function setCurrentPageReducer(
  state: LatestCarsState,
  action: PayloadAction<number>
) {
  state.latestCarsInfo.currentPage = action.payload;
}
