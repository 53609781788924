import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { PRIMARY_MEDIUM_FONT } from "constants/style/fonts";

import {
  TestDrivenBookings,
  WidgetName,
  BarChartGraph,
  WidgetMessage,
} from "pages/dashboard/components/customers-right-widgets/test-driving-bookings/styles";

interface Props {
  chartColors: Array<string>;
}

function TestDriveBookings({ chartColors }: Props): ReactElement {
  const [graphData, setGraphData] = useState<Array<number>>([]);

  const dashboardDetails = useAppSelector((state: RootState) => {
    return state.dashboardDetails.dashboardInfo;
  });

  const userDetails = useAppSelector((state: RootState) => {
    return state.UserProfile.userProfileInfo;
  });

  useEffect(() => {
    setGraphData(
      dashboardDetails?.dashboardDetails?.testDriveBookings?.data as []
    );
  }, [dashboardDetails?.dashboardDetails?.testDriveBookings?.data]);

  const data = {
    labels: dashboardDetails?.dashboardDetails?.testDriveBookings?.labels,
    datasets: [
      {
        label: "Bookings",
        data: graphData,
        backgroundColor: chartColors[0],
      },
    ],
  };
  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Weeks",
          font: {
            size: 14,
            family: PRIMARY_MEDIUM_FONT,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
          font: {
            size: 14,
            family: PRIMARY_MEDIUM_FONT,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
        },
      },
    },
    maintainAspectRatio: false,
  };

  const checkValue = () => {
    const isBelowThreshold = (currentValue) => currentValue === 0;
    return graphData?.every(isBelowThreshold);
  };

  return (
    <React.Fragment>
      {userDetails?.basicInfo?.moduleList?.includes("test-drive-bookings") ? (
        <TestDrivenBookings>
          <WidgetName>Test Drive Bookings</WidgetName>
          {!checkValue() ? (
            <BarChartGraph
              data={data}
              type="bar"
              options={options}
            ></BarChartGraph>
          ) : (
            <WidgetMessage>No Test Drive Bookings</WidgetMessage>
          )}
        </TestDrivenBookings>
      ) : null}
    </React.Fragment>
  );
}

export default TestDriveBookings;
