import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { InputLabel, Box, Select } from "@material-ui/core";
import { Heading } from "pages/customers/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { TEXTFIELD_HEADING } from "constants/style/color";
import {
  AddImageBox,
  CancelInputTextArea,
  ChildImageDiv,
  FlexBoxContainer,
  CancelInput,
} from "pages/location/components/add-location/styles";
// import { Select, TextField } from "@mui/material";

export const LocationContainer = styled.div`
  padding: 1.5rem 2rem;
`;

export const HeadingDiv = styled(Heading)``;

export const LeftDiv = styled.div`
  display: flex;
`;

export const RightDiv = styled.div`
  width: 25%;
`;

export const FieldContainer = styled.div`
  margin-top: 2%;
  margin-bottom: 6%;
`;

export const RichTextEditorDiv = styled.div`
  width: 95%;
`;

export const InputTextField = styled(TextField)`
  /* width:90%; */
  width: 90%;
`;

export const InputLabelText = styled(InputLabel)`
  margin-bottom: 0.625rem;
  font-size: ${SUBTEXT_FONT_SIZE} !important;
  color: ${TEXTFIELD_HEADING} !important;
`;

export const InputTextArea = styled(InputTextField)`
  height: 30%;
`;

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ParentContainer = styled(Box)`
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  margin: 0.5rem;
  /* background-color:aqua; */
`;

export const FormLeftSide = styled.div`
  width: 32%;
  float: left;
  margin: 0.625rem 0rem 0 1rem;
  padding: 0.5rem 0rem 0rem 1rem;
`;

export const FormRightSide = styled(FormLeftSide)`
  width: 45%;
  height: 100%;
  float: left;
  margin: 0.625rem 0rem 0 .5rem;

`;

export const RightFormFlex = styled.div`
  display:flex;
  flex-direction: column;
`;

export const FlexOne = styled.div``;
export const FlexTwo = styled.div`
  width:95%;
  padding:0rem 1.5rem;
`;


export const DropdownSelector = styled(Select)`
  height: 2.5rem;
  width: 90%;
  border-radius: 3px !important;
  font-size: 14px !important;
`;

export const ImageInput = styled.input`
  display: none;
`;

export const ErrorHelperText = styled(FormHelperText)`
  color: rgba(255, 0, 0, 0.8) !important;
  margin: 0.2rem 0.625rem !important;
`;

export const Required = styled.span`
  color: red;
`;

export const FlexBoxArabicDeals = styled.div`
  display: flex;
  width: 100%;
`;

export const CancelIcon = styled(CancelInput)`
  margin: 0.5rem 0rem 0.5rem 0.5rem;
`;

export const FlexBoxArabicEditor = styled(FlexBoxContainer)`
  width: 106% !important;
`;

export const CancelInputDescription = styled(CancelInputTextArea)`
  margin: 5rem 1.5rem;
`;

export const ImgWholeContainer = styled.div`
  min-height: 25vh;
  margin: 2rem 1rem 1rem 0rem;
  width: 92%;
`;

// export const FlexChildOne = styled.div`
//   flex: 0.52;
//   margin-top: 0.8rem;
// `;

// export const FlexChildTwo = styled.div`
//   flex: 1;
// `;

export const InnerFlexEditorCancelBtn = styled.div`
  flex: 2;
`;

export const ChildImageContainer = styled(ChildImageDiv)`
  width: 70%;
  margin-top: 1rem;
`;

export const AddImageContainer = styled(AddImageBox)`
  width: 70%;
`;
