import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import testDriveInitialState from "slices/test-drive-bookings/test-drive-initial-state";

import {
  getTestDriveThunk,
  testDriveSearchThunk,
  getTestDriveByFilter,
} from "slices/test-drive-bookings/test-drive-thunks";
import TestDriveState from "./test-drive-interface";

export const TestDriveSlice = createSlice({
  name: "TestDriveSlice",
  initialState: testDriveInitialState,
  reducers: {
    setCurrentPageReducer:setCurrentPageReducer 
  },
  extraReducers: {
    //set data
    [getTestDriveThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.testDriveInfo.allTestDrive =
        listEntityClass === undefined ? [] : listEntityClass;
      state.testDriveInfo.recordsLength = totalRecords;
    },

    //search
    [testDriveSearchThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.testDriveInfo.allTestDrive =
        listEntityClass === undefined ? [] : listEntityClass;
      state.testDriveInfo.recordsLength = totalRecords;
    },

    //data by filter
    [getTestDriveByFilter.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.testDriveInfo.allTestDrive =
        listEntityClass === undefined ? [] : listEntityClass;
      state.testDriveInfo.recordsLength = totalRecords;
    },
  },
});


function setCurrentPageReducer(
  state: TestDriveState,
  action: PayloadAction<number>
) {
  state.testDriveInfo.currentPage = action.payload;
}
