import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";

interface Props {
  isStyleChanged: boolean;
}

export const CheckboxContainerDiv = styled.div`
  margin: ${({ isStyleChanged }: Props) =>
    isStyleChanged ? "0rem" : "0.2rem 1.25rem"};
  padding: ${({ isStyleChanged }: Props) =>
    isStyleChanged ? "0rem" : "0.625rem"};
  display: ${({ isStyleChanged }: Props) => (isStyleChanged ? "flex" : "")};
`;

export const CheckboxChild = styled.div`
  margin: 0.1rem !important;
`;

export const CustomInput = styled(Checkbox)`
  margin: ${({ isStyleChanged }: Props) =>
    isStyleChanged ? "0rem !important" : "0 0.5rem !important"};
  padding: 5px !important;
`;
