import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import PaginationFooter from "shared-components/pagination";
import TableData from "shared-components/table-data";
import { CustomSwitch } from "shared-components/switch";
import { RenderCellExpand } from "shared-components/expanded-cel";
import ErrorIcon from "shared-components/icons/error-icon";

import DeleteUser from "pages/user-profile/components/delete-user";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { useSnackbar } from "notistack";
import { CsvBuilder } from "filefy";
import { format } from 'date-fns'

import { CUSTOMER_DETAILS } from "constants/route/route-constants";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import { loadingHelper } from "constants/loadingHelper";

import { CustomersFilter, GetCustomerDetails } from "services/customers-services";
import { CustomerDetails } from "interfaces/customers.interface";

import {
  GridColDef,
  GridCellParams,
  GridRowModel,
  GridRowId,
} from "@material-ui/data-grid";

import {
  getCustomersThunk,
  updateCustomerInfoThunk,
  getCustomersBySearchThunk,
  setTabPageReducer,
  setCurrentPageReducer,
} from "slices/customers";

import {
  CustomTagGreen,
  CustomTagRed,
  UserRoute,
  MainContainer,
  AddUserModal,
  ErrorIconDiv,
} from "pages/customers/components/customers-table/styles";


const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 185,
    fontFamily: PRIMARY_REGULAR_FONT,
  },
});

interface Props {
  filterBy: string;
  exportData?: boolean;
  setExportData?: any;
  filterColumn?: string;
  handelTabActive: (tabNumber: number) => void;
  searchValue?: string;
  filterFunc: (pageNumber, currentTab) => void;
  filterFlag: boolean;
  customerLimit: number;
  filterState: any;
}

const tabObj = {
  "All Users": 0,
  Owners: 1,
  Guest: 2,
};

const UsersTable = ({
  filterBy,
  exportData,
  setExportData,
  filterColumn,
  searchValue,
  handelTabActive,
  filterFunc,
  customerLimit,
  filterFlag,
  filterState,
}: Props) => {

  let allRecords: CustomerDetails[] = []
  let filteredRecords: CustomerDetails[] = []

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<Array<GridRowModel>>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [selectedDeleteID, setSelectedDeletedId] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState({
    headerText: "",
    message: "",
    paraText: "",
  });


  const errorTooltip =
    "Registered as owned. But entered data mismatched with salesforce";

  const customersData = useSelector(
    (state: RootState) => state.customers.customerInfo
  );

  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (filterFlag) {
      filterFunc(1, filterBy);
    } else {
      loadingHelper(
        dispatch(
          getCustomersThunk({
            pageNumber: 1,
            customerLimit: customerLimit,
            role: filterBy,
          })
        ),
        setIsLoading,
        enqueueSnackbar
      );
    }
    handelTabActive(tabObj[`${filterBy}`]);
    dispatch(setTabPageReducer(filterBy as string));
    dispatch(setCurrentPageReducer(1));
    // eslint-disable-next-line
  }, [dispatch, filterBy, brandName, customerLimit]);

  useEffect(() => {
    setRows(customersData.customerDetails);
    setPaginationSize(
      Math.ceil(customersData.totalRecordsLength / customerLimit)
    );
    // eslint-disable-next-line
  }, [customersData]);

  const handleCheckedData = (newSelection) => {
    setSelectionModel(newSelection.selectionModel);
  };

  const openDeleteUserModal = (id, status) => {
    if (status) {
      setModalMessage({
        headerText: "Disable Customer",
        message: "Are you sure you want to disable the customer?",
        paraText:
          "Customer will not be able to use his profile in the mobile app",
      });
    } else {
      setModalMessage({
        headerText: "Enable Customer",
        message: "Are you sure you want to enable the customer",
        paraText:
          "Customer will be able to use his profile again in the mobile app",
      });
    }
    setOpenDeleteModal(true);
    setSelectedDeletedId(id);
  };

  const handleStatus = async (id) => {
    setLoading(true);
    try {
      const result = await dispatch(updateCustomerInfoThunk(id)).unwrap();
      dispatch(
        getCustomersThunk({
          pageNumber: customersData?.currentPage,
          customerLimit: customerLimit,
          role: filterBy,
        })
      );
      enqueueSnackbar(result.data.status, {
        variant: "success",
      });
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error?.data, { variant: "error" });
      setLoading(false);
    }
    setOpenDeleteModal(false);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      flex: 0.6,
      renderCell: (params: GridCellParams) => {
        const userId = params.row.id;
        return (
          <UserRoute
            to={{
              pathname: CUSTOMER_DETAILS,
              state: {
                userId: userId,
              },
            }}
          >
            {userId}
          </UserRoute>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      resizable: false,
      flex: 1.2,
      renderCell: (params: GridCellParams) => {
        const userId = params.row.id;
        return (
          <UserRoute
            to={{
              pathname: CUSTOMER_DETAILS,
              state: {
                userId: userId,
              },
            }}
          >
            {params.row.name}
          </UserRoute>
        );
      },
    },
    {
      field: "phoneNumber",
      sortable: false,
      headerName: "Contact No",
      resizable: false,
      flex: 1,
      renderCell: RenderCellExpand,
    },
    {
      field: "crCprGccId",
      headerName: "CR/CPR/GCC ID",
      sortable: false,
      resizable: false,
      flex: 1,
      renderCell: RenderCellExpand,
    },
    {
      field: "email",
      sortable: false,
      headerName: "User Registration Date",
      resizable: false,
      flex: 1.3,
      renderCell: RenderCellExpand,
    },
    {
      field: "role",
      sortable: false,
      headerName: "Type of User",
      resizable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        if (params.row.role?.toLowerCase() === "owner") {
          return (
            <>
              <CustomTagGreen>{params.row.role}</CustomTagGreen>
              {Boolean(params.row.sfCaseNumber) ? (
                <CustomWidthTooltip title={errorTooltip} placement="top" arrow>
                  <ErrorIconDiv>
                    <ErrorIcon
                      style={{
                        marginLeft: 8,
                        marginTop: 20,
                        cursor: "pointer",
                      }}
                    />
                  </ErrorIconDiv>
                </CustomWidthTooltip>
              ) : (
                <></>
              )}
            </>
          );
        } else {
          return (
            <>
              <CustomTagRed>{params.row.role}</CustomTagRed>
              {Boolean(params.row.sfCaseNumber) ? (
                <CustomWidthTooltip title={errorTooltip} placement="top" arrow>
                  <ErrorIconDiv>
                    <ErrorIcon
                      style={{
                        marginLeft: 8,
                        marginTop: 20,
                        cursor: "pointer",
                      }}
                    />
                  </ErrorIconDiv>
                </CustomWidthTooltip>
              ) : (
                <></>
              )}
            </>
          );
        }
      },
    },
    {
      field: "active",
      headerName: "Status",
      sortable: false,
      resizable: false,
      flex: 0.7,
      renderCell: (params: GridCellParams) => {
        return (
          <CustomSwitch
            onClick={() =>
              openDeleteUserModal(params.row.id, params.row.active)
            }
            checked={params.row.active}
          />
        );
      },
    },
  ];

  const csvBuilder = (filename, rowsToCsv) => {
    const currentDateTime = format(new Date(), 'yyyy_MM_dd_HH_mm');
    const formattedFilename = `${filename}_${currentDateTime}.csv`;

    new CsvBuilder(formattedFilename)
      .setColumns(columns.map((col) => col?.headerName as string) as string[])
      .addRows(rowsToCsv as string[][])
      .exportFile();
  };

  /* const filterRowsData = (data) => {
    let res: Array<string> = [];
    columns.map((col) => {
      // if (col.field !== "name") {
      //   if (Object.keys(data).indexOf(col.field) === -1) {
      //     res.push("");
      //   }
      // }
      Object.entries(data).forEach(([key, value]) => {
        // if (col.field === "name" && key === "firstName") {
        //   res.push(
        //     ((data?.firstName as string) + " " + data?.lastName) as string
        //   );
        // }
        if (key === col.field) {
          res.push(value as string);
        }
      });
      return res;
    });
    return res;
  }; */

  const exportCustomerData = async () => {
    try {
      let request = {
        role: customersData.tabType,
        pageNumber: 1,
        customerLimit: customerLimit,
      }
      let response = await GetCustomerDetails(request)
      const totalRecords = response.data.totalRecords

      request = {
        role: customersData.tabType,
        pageNumber: 1,
        customerLimit: Math.ceil(totalRecords),
      }
      response = await GetCustomerDetails(request)
      allRecords = response.data.listEntityClass
      console.log(allRecords)

      let allRecordsArray = allRecords.map(obj => [
        obj.id,
        obj.name,
        obj.phoneNumber,
        obj.crCprGccId,
        obj.email,
        obj.role,
        obj.active ? 'Active' : 'Inactive'
      ]);

      csvBuilder('Customers_List', allRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  const exportFilteredCustomersData = async () => {
    try {

      let request = {
        userType: customersData.tabType,
        pageNumber: 1,
        customerLimit: customerLimit,
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        status: filterState.status,
      }

      let response = await CustomersFilter(request)
      const filteredCustomers = response.data.totalRecords

      request = {
        userType: customersData.tabType,
        pageNumber: 1,
        customerLimit: Math.ceil(filteredCustomers),
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        status: filterState.status,
      }
      response = await CustomersFilter(request)
      filteredRecords = response.data.listEntityClass
      console.log(filteredRecords)
      let filteredRecordsArray = filteredRecords.map(obj => [
        obj.id,
        obj.name,
        obj.phoneNumber,
        obj.crCprGccId,
        obj.email,
        obj.role,
        obj.active ? 'Active' : 'Inactive'
      ]);
      csvBuilder('Customers_List', filteredRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  useEffect(() => {
    if (exportData) {
      if (!filterFlag) {
        exportCustomerData()
      } else {
        exportFilteredCustomersData()
      }
      setExportData(false);
    }
  }, [exportData]);

  const handlePagination = (event, number) => {
    if (filterFlag) {
      filterFunc(number, customersData?.tabType);
    } else if (searchValue !== "") {
      dispatch(
        getCustomersBySearchThunk({
          pageNumber: number,
          customerLimit: customerLimit,
          role: customersData.tabType,
          searchContent: searchValue,
        })
      );
    } else {
      loadingHelper(
        dispatch(
          getCustomersThunk({
            pageNumber: number,
            customerLimit: customerLimit,
            role: filterBy,
          })
        ),
        setIsLoading,
        enqueueSnackbar
      );
    }
    dispatch(setCurrentPageReducer(number));
  };

  return (
    <>
      <MainContainer>
        <TableData
          rows={isLoading ? [] : (rows as GridRowModel[])}
          filterColumn={filterColumn}
          columns={columns}
          isCheckBox={true}
          handleCheckedData={handleCheckedData}
          pageSize={customerLimit}
          isPagination={true}
          isLoading={isLoading}
        />
      </MainContainer>
      <AddUserModal open={openDeleteModal} onClose={closeDeleteModal}>
        <DeleteUser
          params={selectedDeleteID}
          closeModal={closeDeleteModal}
          confirmDelete={handleStatus}
          loading={loading}
          headerText={modalMessage?.headerText as string}
          paraText={modalMessage?.paraText as string}
          message={modalMessage?.message as string}
        ></DeleteUser>
      </AddUserModal>
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={customersData?.currentPage}
        />
      ) : null}
    </>
  );
};

export default UsersTable;
