import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { getUserBasicInfoThunk } from "slices/user-profile/user-profile-thunks";
import { getDashboardDetailsThunk, setMonthReducer } from "slices/dashboard";

import LoaderSpin from "shared-components/loader";
import CustomerWidgets from "pages/dashboard/components/customers-widgets";
import AppUsage from "pages/dashboard/components/customers-left-widgets/app-usage-chart";
import OwnerVSGuests from "pages/dashboard/components/customers-left-widgets/owner-vs-guests";
import ServiceBookings from "pages/dashboard/components/customers-left-widgets/service-bookings";
import RecentActivities from "pages/dashboard/components/customers-right-widgets/recent-activity";
import TestDrivingBookings from "pages/dashboard/components/customers-right-widgets/test-driving-bookings";

// import ProfileImage from "assets/profile.jpeg";

import { DROPDOWN_MENU_PROPS } from "constants/style/props";
import { loadingHelper } from "constants/loadingHelper";
import { useSnackbar } from "notistack";

import {
  PRIMARY_TOYOTA,
  TERTIARY_TOYOTA,
  PRIMARY_LEXUS,
  TERTIARY_LEXUS,
  PRIMARY_MOTORCITY,
  TERTIARY_MOTORCITY,
} from "constants/style/color";

import {
  DashboardContainer,
  HeaderDiv,
  Heading,
  DropdownSelector,
  CalendarDiv,
  CalendarLogo,
  ChartContainer,
  CustomerLeftChartContainer,
  CustomerRightChartContainer,
  EmptyDiv,
  MonthMenuItem,
  LoaderDiv,
} from "pages/dashboard/styles";

// import { UpdateProfilePicture } from "services/user-profile-services";

function Dashboard(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const [chartColors, setChartColors] = useState<Array<string>>([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [loading, setLoading] = useState<boolean>(false);

  const brand = useAppSelector((state) => state.brand.brandType);
  const getUser = useAppSelector((state) => state.user.userInfo);
  const userBrand = useAppSelector((state: RootState) => {
    return state.user.userInfo.user;
  });

  // const basicInfo = useAppSelector((state) => {
  //   return state.UserProfile.userProfileInfo.basicInfo;
  // });

  const handleChange = (e) => {
    setMonth(e.target.value);
    dispatch(setMonthReducer(e.target.value));
  };

  // useEffect(() => {
  //   setMonth(dashboardDetails.selectedMonth);
  // }, [dashboardDetails.selectedMonth]);

  useEffect(() => {
    loadingHelper(
      dispatch(getDashboardDetailsThunk(month)),
      setLoading,
      enqueueSnackbar
    );
    // eslint-disable-next-line
  }, [dispatch, month, brand]);

  useEffect(() => {
    if (getUser.isLoggedIn) {
      dispatch(
        getUserBasicInfoThunk({
          userId: getUser?.user?.id,
          brand: userBrand?.defautbrand as string,
        })
      );
    }
  }, [dispatch, getUser.isLoggedIn, userBrand?.defautbrand, getUser?.user?.id]);

  // useEffect(() => {
  //   if(basicInfo.profilePicture === ProfileImage){
  //     UpdateProfilePicture({
  //       profileImage: ProfileImage,
  //       id: basicInfo?.id,
  //     });
  //   }
  //   // eslint-disable-next-line
  // },[basicInfo.profilePicture])


  const names = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    const brandColor =
      brand === "Toyota"
        ? [PRIMARY_TOYOTA, TERTIARY_TOYOTA]
        : brand === "Lexus"
        ? [PRIMARY_LEXUS, TERTIARY_LEXUS]
        : [PRIMARY_MOTORCITY, TERTIARY_MOTORCITY];
    setChartColors(brandColor);
  }, [brand]);

  return (
    <DashboardContainer>
      <HeaderDiv>
        <Heading>Dashboard</Heading>
        <CalendarDiv>
          <CalendarLogo color={chartColors[0]} />
          <DropdownSelector
            onChange={handleChange}
            value={month}
            MenuProps={DROPDOWN_MENU_PROPS}
          >
            {names.map((item, index) => {
              return (
                <MonthMenuItem key={`${item}_${index}`} value={index + 1}>
                  {item}
                </MonthMenuItem>
              );
            })}
          </DropdownSelector>
        </CalendarDiv>
      </HeaderDiv>
      {loading ? (
        <LoaderDiv>
          <LoaderSpin isLoading={loading} iscolor={true} />
        </LoaderDiv>
      ) : (
        <EmptyDiv>
          <CustomerWidgets />
          <ChartContainer>
            <CustomerLeftChartContainer>
              <AppUsage chartColors={chartColors} />
              <OwnerVSGuests chartColors={chartColors} />
              <ServiceBookings chartColors={chartColors} />
            </CustomerLeftChartContainer>
            <CustomerRightChartContainer>
              <RecentActivities chartColors={chartColors} />
              <TestDrivingBookings chartColors={chartColors} />
            </CustomerRightChartContainer>
          </ChartContainer>
        </EmptyDiv>
      )}
    </DashboardContainer>
  );
}

export default Dashboard;
