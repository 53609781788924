import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { useSnackbar } from "notistack";
import MenuItem from "@material-ui/core/MenuItem";

import AddImage from "shared-components/icons/add-image";
import LoaderButton from "shared-components/loader-button";
import CustomRichTextEditor from "shared-components/rich-text-editor";

import { EMPTY_INPUT } from "constants/snackbar-messages-constants";
import { BEST_DEALS } from "constants/route/route-constants";
import { URL_VERIFY_REGEX } from "constants/regex-validations-constants";

import {
  DROPDOWN_MENU_PROPS,
  loaderBtnStyleFunc,
  TEXTFIELD_FONTSIZE_INPUT_PROPS,
} from "constants/style/props";
import {
  ALL_PLUGINS,
  QUICK_DESC_BLOCK,
  QUICK_INSERT_TOOLBOX_DECS,
  TOOLBAR_FOR_DESCRIPTION,
} from "constants/text-editor-tools";

import {
  FlexBoxArabicEditor,
  FlexOne,
  FlexTwo,
  InnerFlexEditorCancelBtn,
  RichTextEditorDiv,
  RightFormFlex,
} from "pages/latest-blogs/components/new-blog/styles";

import { DealDetails } from "interfaces/best-deals-interfaces";

import {
  addNewDealThunk,
  setCurrentDealReducer,
  updateDealInfoThunk,
} from "slices/best-deals";
import {
  RemoveImageLogo,
  ChildImage,
  CancelButton,
  ImageFlexContainer,
  TextWrapper,
  Small,
  ImgWholeContainerTwo,
  AddImageBoxTwo,
  ChildImageDivTwo,
  ImgWholeContainerDeals,
} from "pages/location/components/add-location/styles";

import {
  LocationContainer,
  LeftDiv,
  RightDiv,
  HeaderDiv,
  HeadingDiv,
  ParentContainer,
  FormContainer,
  FormLeftSide,
  FormRightSide,
  FieldContainer,
  InputLabelText,
  InputTextField,
  DropdownSelector,
  ImageInput,
  ErrorHelperText,
  Required,
  FlexBoxArabicDeals,
  CancelInputDescription,
  EndTimeDatePicker,
  StartTimeDatePicker,
  TwoImageFlexCon,
  InputLabelTextTC,
  FieldContainerTC,
  CheckboxTC,
  CancelIcon,
} from "./styles";

const userArr = ["All", "Owner", "Guest"];
const statusArr = [
  { name: "Active", value: "Active" },
  { name: "Inactive", value: "In-Active" },
];

const linkToArr = [
  "None",
  "Service Booking",
  "Test Drive Booking",
  "Roadside assistance",
];

const textLocationArr = ["Left", "Right", "Bottom", "Top"];
const bannerTypeArr = ["Banner Only", "Banner with Text"];

const errorObj = {
  dealTitle: "Title",
  targetUserType: "User Type",
  dealEndDateTimeStamp: "End Time",
  dealTitleArabic: "Title",
  dealTextArabic: "Sub Text",
  dealDescriptionArabic: "Description",
  linkUrl: "Link URL",
  externalUrl: "External URL",
  linkName:"Link Name",
  linkNameArabic:"Link Name"
};

const newDealState: DealDetails = {
  dealId: null,
  bannerType: bannerTypeArr[0],
  dealTitle: "",
  dealTitleArabic: null,
  textLocation: textLocationArr[0],
  dealText: "",
  dealTextArabic: null,
  dealBadgeText: "",
  dealStatus: statusArr[1].value,
  linkName: "",
  linkNameArabic: null,
  targetUserType: "Select",
  linkUrl: "",
  externalUrl: "",
  linkTo: linkToArr[0],
  dealStartDateTimeStamp: `${new Date()}`,
  dealEndDateTimeStamp: `${new Date()}`,
  autoRescheduleDays: null,
  dealDescription: "",
  dealDescriptionArabic: null,
  bannerImage: [],
  detailImage: [],
  dealbrand: "",
  termsAndConditions: null,
  termsAndConditionsArabic: null,
};

function NewDeal(): ReactElement {
  const dispatch = useAppDispatch();
  const location = useLocation<any>();
  const history = useHistory<any>();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef<any>();

  const [dealDetails, setDealDetails] = useState(
    JSON.parse(JSON.stringify(newDealState))
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isUrlDisable, setIsUrlDisable] = useState<boolean>(true);
  const [isDateDisable, setIsDateDisable] = useState<boolean>(true);

  let [dateFlag, setDateFlag] = useState(false);

  const [inputErrors, setErrors] = useState({
    dealTitleError: "",
    targetUserTypeError: "",
    dealEndDateTimeStampError: "",
    dealTitleArabicError: "",
    linkUrlError: "",
    externalUrlError: "",
    linkNameError: "",
    linkNameArabicError: "",
  });

  // const bestDeals = useAppSelector((state) => state.bestDeals.bestDealsInfo);

  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });

  // for redirection only when edit and copy deal screen ( brand switches )
  useEffect(() => {
    if (
      location?.state?.detail === "edit" ||
      location?.state?.detail === "copy"
    ) {
      if (ref.current !== undefined && ref.current !== brandName) {
        return history.push(BEST_DEALS);
      }
      ref.current = brandName;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName, history]);

  useEffect(() => {
    if (
      location?.state?.detail === "copy" ||
      location?.state?.detail === "edit"
    ) {
      setDealDetails({
        ...JSON.parse(JSON.stringify(location?.state?.rowData)),
      });
      dispatch(setCurrentDealReducer({ ...location?.state?.rowData }));
      checkLinkTo(location?.state?.rowData?.linkTo);
      checkStartDate(location?.state?.rowData?.dealStatus);
      isCheckboxTrue(!!location?.state?.rowData?.termsAndConditions);
    } else {
      checkLinkTo(dealDetails?.linkTo);
      checkStartDate(dealDetails?.dealStatus);
    }
    // eslint-disable-next-line
  }, [location?.state?.rowData, location?.state?.detail]);

  const {
    dealId,
    targetUserType,
    textLocation,
    dealStatus,
    dealTitle,
    dealText,
    dealBadgeText,
    linkName,
    linkNameArabic,
    bannerType,
    // autoRescheduleDays,
    linkUrl,
    externalUrl,
    linkTo,
    dealDescription,
    termsAndConditions,
    termsAndConditionsArabic,
    dealStartDateTimeStamp,
    dealEndDateTimeStamp,
    dealTitleArabic,
    dealTextArabic,
    dealDescriptionArabic,
    bannerImage,
    detailImage,
  } = dealDetails;

  const {
    dealTitleError,
    targetUserTypeError,
    dealEndDateTimeStampError,
    dealTitleArabicError,
    linkUrlError,
    externalUrlError,
    linkNameError,
    linkNameArabicError,
  } = inputErrors;

  const handleEditorChange = (content, e, key) => {
    dealDetails[key] = content;
    setDealDetails({ ...dealDetails });
  };

  const handelArabicInputShow = (prop) => {
    dealDetails[prop] = "";
    setDealDetails({ ...dealDetails });
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    handelTcValue();
    setIsChecked(!isChecked);
  };

  const handelTcValue = () => {
    if (isChecked && !!dealDetails["termsAndConditions"]) {
      dealDetails["termsAndConditions"] = null;
      dealDetails["termsAndConditionsArabic"] = null;
      setDealDetails({ ...dealDetails });
    }
  };

  const isCheckboxTrue = (flag) => {
    if (flag) {
      setIsChecked(true);
    }
  };

  const handelArabicInputHide = (prop) => {
    dealDetails[prop] = null;
    if (prop === "dealTitleArabic" || prop === "linkNameArabic") {
      inputErrors[prop + "Error"] = "";
      setErrors({ ...inputErrors });
    }
    setDealDetails({ ...dealDetails });
  };

  const handelChangeArabic = (prop) => (e) => {
    const { value } = e.target;
    dealDetails[prop] = value;
    setDealDetails({ ...dealDetails });

    if (prop === "dealTitleArabic" || prop === "linkNameArabic") {
      if (value === "") {
        inputErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        inputErrors[prop + "Error"] = "";
      }
    }

    setErrors({ ...inputErrors });
  };

  const imageHandler = (e, key) => {
    const files = e.target.files;
    Array.from(files).forEach((file, ind) => {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[ind]);
      reader.onload = function () {
        dealDetails[key].push(reader.result as string);
        setDealDetails({ ...dealDetails });
      };
    });
  };

  const handelRemoveImage = (index, key) => {
    var filtered = dealDetails[key].filter((item, ind) => ind !== index);
    dealDetails[key] = filtered;
    setDealDetails({ ...dealDetails });
  };

  const handleInputChange = (prop) => (e) => {
    const { value } = e?.target;

    if (prop === "dealTitle" || prop==="linkName") {
      setDealDetails({ ...dealDetails, [prop]: value });
      if (value === "") {
        inputErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        inputErrors[prop + "Error"] = "";
      }
      setErrors({ ...inputErrors });
    } else if (prop === "targetUserType") {
      dealDetails[prop] = value;
      setDealDetails({ ...dealDetails });
      if (value === "Select") {
        inputErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        inputErrors[prop + "Error"] = "";
      }
      setErrors({ ...inputErrors });
    } else if (prop === "linkUrl" || prop === "externalUrl") {
      setDealDetails({ ...dealDetails, [prop]: value });
      if (value === "") {
        inputErrors[prop + "Error"] = ``;
      } else {
        const reg = new RegExp(URL_VERIFY_REGEX).test(value);
        if (!reg) {
          inputErrors[prop + "Error"] = errorObj[prop] + ` is invalid`;
        } else {
          inputErrors[prop + "Error"] = "";
        }
        setErrors({ ...inputErrors });
      }
    } else if (prop === "linkTo") {
      dealDetails[prop] = value;
      setDealDetails({ ...dealDetails });
      checkLinkTo(value);
      if (value !== "None") {
        dealDetails["linkUrl"] = "";
        dealDetails["externalUrl"] = "";
        setDealDetails({ ...dealDetails });
      }
    } else if (prop === "dealStatus") {
      dealDetails[prop] = value;
      setDealDetails({ ...dealDetails });
      checkStartDate(value);
      if (value === "Active") {
        dealDetails["dealStartDateTimeStamp"] = `${new Date()}`;
      }else{
        dealDetails["dealEndDateTimeStamp"] = `${new Date()}`;
      }
      setDealDetails({ ...dealDetails });
      // console.log(dealDetails);
    } else {
      dealDetails[prop] = value;
      setDealDetails({ ...dealDetails });
    }
    // console.log(dealDetails)
  };

  const handelEndTimeChange = (date) => {
    dealDetails[`dealEndDateTimeStamp`] = date;
    setDealDetails({ ...dealDetails });
    if (new Date(dealStartDateTimeStamp as string) >= new Date(date)) {
      inputErrors.dealEndDateTimeStampError = `${errorObj["dealEndDateTimeStamp"]} must be greater than start!!`;
      setDateFlag(false);
    } else {
      inputErrors.dealEndDateTimeStampError = "";
      setDateFlag(true);
    }
    setErrors({ ...inputErrors });
    // console.log(dealDetails,"endTime");
  };

  const handelStartTimeChange = (date) => {
    dealDetails["dealStartDateTimeStamp"] = date;
    setDealDetails({ ...dealDetails });

    if (new Date(date) >= new Date(dealEndDateTimeStamp as string)) {
      inputErrors.dealEndDateTimeStampError = `${errorObj["dealEndDateTimeStamp"]} must be greater than start!!`;
      setDateFlag(false);
    } else {
      inputErrors.dealEndDateTimeStampError = "";
      setDateFlag(true);
    }
    setErrors({ ...inputErrors });
    // console.log(dealDetails,"console");
  };

  const handelSave = async () => {
    if (dealTitleError === "" && dealTitle === "") {
      inputErrors.dealTitleError = `${errorObj["dealTitle"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }
    if (dealTitleArabic === "") {
      inputErrors.dealTitleArabicError = `${errorObj["dealTitleArabic"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }
    if (linkNameError === "" && linkName === "") {
      inputErrors.linkNameError = `${errorObj["linkName"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }
    if (linkNameArabic === "") {
      inputErrors.linkNameArabicError = `${errorObj["linkNameArabic"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }
    if (targetUserTypeError === "" && targetUserType === "Select") {
      inputErrors.targetUserTypeError = `${errorObj["targetUserType"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }

    if (linkUrl !== null && linkUrl !== "") {
      const reg = new RegExp(URL_VERIFY_REGEX).test(linkUrl as string);
      if (!reg) {
        inputErrors.linkUrlError = errorObj["linkUrl"] + ` is invalid`;
      }
      setErrors({ ...inputErrors });
    }

    if (externalUrl !== null && externalUrl !== "") {
      const reg = new RegExp(URL_VERIFY_REGEX).test(externalUrl as string);
      if (!reg) {
        inputErrors.externalUrlError = errorObj["externalUrl"] + ` is invalid`;
      }
      setErrors({ ...inputErrors });
    }

    if (
      new Date(dealStartDateTimeStamp as string) >=
      new Date(dealEndDateTimeStamp as string)
    ) {
      inputErrors.dealEndDateTimeStampError = `${errorObj["dealEndDateTimeStamp"]} must be greater than start!!`;
      setErrors({ ...inputErrors });
      setDateFlag(false);
    } else {
      dateFlag = true;
      setDateFlag(dateFlag);
    }

    if (
      dealTitleArabicError === "" &&
      targetUserTypeError === "" &&
      dealTitleError === "" &&
      linkUrlError === "" &&
      externalUrlError === "" &&
      dealEndDateTimeStampError === "" &&
      dealTitle !== "" &&
      dealTitleArabic !== "" &&
      targetUserType !== "Select" &&
      linkName !== "" &&
      linkNameArabic !== "" &&
      linkNameError === "" &&
      linkNameArabicError === "" &&
      dateFlag
    ) {
      try {
        setLoading(true);
        if (location?.state?.detail === "edit") {
          var result = await dispatch(
            updateDealInfoThunk(dealDetails)
          ).unwrap();
          enqueueSnackbar(result.data.status, {
            variant: "success",
          });
          setLoading(false);
          history.push({
            pathname: BEST_DEALS,
            state: { from: "edit" },
          });
        } else {
          var newResult = await dispatch(addNewDealThunk(dealDetails)).unwrap();
          enqueueSnackbar(newResult.data.status, {
            variant: "success",
          });
          setLoading(false);
          history.push({
            pathname: BEST_DEALS,
            state: { from: "edit" },
          });
        }
      } catch (error: any) {
        setLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    }
  };

  const handelCancel = () => {
    history.push({
      pathname: BEST_DEALS,
      state: { from: "edit" },
    });
  };

  const checkLinkTo = (value) => {
    if (value === "None") {
      setIsUrlDisable(false);
    } else {
      setIsUrlDisable(true);
    }
  };

  const checkStartDate = (value) => {
    if (value === "In-Active") {
      setIsDateDisable(false);
    } else {
      setIsDateDisable(true);
    }
  };

  return (
    <LocationContainer>
      <HeaderDiv>
        <LeftDiv>
          <HeadingDiv>{`${
            location?.state?.detail === "edit"
              ? "Edit Deal"
              : location?.state?.detail === "copy"
              ? "Copy Deal"
              : "New Deal"
          }`}</HeadingDiv>
        </LeftDiv>
        <RightDiv>
          <LoaderButton
            submitHandler={handelSave}
            isLoading={loading}
            buttonText="Save"
            styles={loaderBtnStyleFunc("40%", "2.8rem")}
          />
          <CancelButton
            variant="contained"
            color="default"
            onClick={handelCancel}
          >
            Cancel
          </CancelButton>
        </RightDiv>
      </HeaderDiv>
      <ParentContainer>
        <FormContainer>
          <FormLeftSide>
            <FieldContainer>
              <InputLabelText>ID Number</InputLabelText>
              <InputTextField
                value={dealId}
                variant="outlined"
                size="small"
                disabled
                inputProps={{ style: { fontSize: 14, background: "#F0F0F0" } }}
              />
            </FieldContainer>

            <FieldContainer>
              <InputLabelText>Banner Type</InputLabelText>
              <DropdownSelector
                onChange={handleInputChange("bannerType")}
                variant="outlined"
                value={bannerType}
                MenuProps={DROPDOWN_MENU_PROPS}
              >
                {bannerTypeArr.map((item, index) => {
                  return (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </DropdownSelector>
            </FieldContainer>

            <FieldContainer>
              <InputLabelText>Text Location</InputLabelText>
              <DropdownSelector
                onChange={handleInputChange("textLocation")}
                variant="outlined"
                value={textLocation}
                MenuProps={DROPDOWN_MENU_PROPS}
              >
                {textLocationArr.map((item, index) => {
                  return (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </DropdownSelector>
            </FieldContainer>

            <FieldContainer>
              <InputLabelText>
                Title <Required>*</Required>
              </InputLabelText>
              <InputTextField
                error={Boolean(dealTitleError)}
                helperText={dealTitleError}
                onChange={handleInputChange("dealTitle")}
                variant="outlined"
                size="small"
                value={dealTitle}
                inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
              />
              {dealTitleArabic === null && (
                <TextWrapper>
                  <Small
                    onClick={() => handelArabicInputShow("dealTitleArabic")}
                  >
                    Enter in arabic
                  </Small>
                </TextWrapper>
              )}
            </FieldContainer>

            <FieldContainer>
              {typeof dealTitleArabic === "string" && (
                <>
                  <InputLabelText>
                    Title (Arabic)<Required>*</Required>
                  </InputLabelText>
                  <FlexBoxArabicDeals>
                    <InputTextField
                      error={Boolean(dealTitleArabicError)}
                      helperText={dealTitleArabicError}
                      onChange={handelChangeArabic("dealTitleArabic")}
                      variant="outlined"
                      size="small"
                      dir="rtl"
                      value={dealTitleArabic}
                      inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                    />
                    <CancelIcon
                      onClick={() => handelArabicInputHide("dealTitleArabic")}
                    />
                  </FlexBoxArabicDeals>
                </>
              )}
            </FieldContainer>

            <FieldContainer>
                <InputLabelText>Badge Text</InputLabelText>
                <InputTextField
                  onChange={handleInputChange("dealBadgeText")}
                  variant="outlined"
                  size="small"
                  value={dealBadgeText}
                  inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                />
              </FieldContainer>

            <TwoImageFlexCon>
            <ImgWholeContainerDeals>
              <InputLabelText>Banner Image</InputLabelText>
              <ImageFlexContainer>
                <ImageInput
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => imageHandler(e, "bannerImage")}
                  id="input"
                  multiple
                />
                <AddImageBoxTwo htmlFor="input">
                  <AddImage />
                </AddImageBoxTwo>
                {bannerImage?.length !== 0 &&
                  bannerImage?.map((item, index) => (
                    <ChildImageDivTwo key={`images_${index}`}>
                      <ChildImage src={item} alt={index + " DealImage"} />
                      <RemoveImageLogo
                        onClick={() => handelRemoveImage(index, "bannerImage")}
                      />
                    </ChildImageDivTwo>
                  ))}
              </ImageFlexContainer>
            </ImgWholeContainerDeals>

            <ImgWholeContainerTwo>
              <InputLabelText>Detail Image</InputLabelText>
              <ImageFlexContainer>
                <ImageInput
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => imageHandler(e, "detailImage")}
                  id="inputTwo"
                  multiple
                />
                <AddImageBoxTwo htmlFor="inputTwo">
                  <AddImage />
                </AddImageBoxTwo>
                {detailImage?.length !== 0 &&
                  detailImage?.map((item, index) => (
                    <ChildImageDivTwo key={`images_${index}`}>
                      <ChildImage src={item} alt={index + "DealDetailImage"} />
                      <RemoveImageLogo
                        onClick={() => handelRemoveImage(index, "detailImage")}
                      />
                    </ChildImageDivTwo>
                  ))}
              </ImageFlexContainer>
            </ImgWholeContainerTwo>
          </TwoImageFlexCon>
          </FormLeftSide>


        <RightFormFlex>
          <FlexOne>
            <FormRightSide>
              <FieldContainer>
                <InputLabelText>Status</InputLabelText>
                <DropdownSelector
                  onChange={handleInputChange("dealStatus")}
                  variant="outlined"
                  value={dealStatus}
                  MenuProps={DROPDOWN_MENU_PROPS}
                >
                  {statusArr.map((item, index) => {
                    return (
                      <MenuItem key={`${item}_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </DropdownSelector>
              </FieldContainer>

              <FieldContainer>
                <InputLabelText>
                  Start time <Required>*</Required>
                </InputLabelText>
                <StartTimeDatePicker
                  width={90}
                  handelChangeParent={handelStartTimeChange}
                  time={`${dealDetails?.dealStartDateTimeStamp}`}
                  disabled={isDateDisable}
                />
              </FieldContainer>

              <FieldContainer>
                <InputLabelText>
                  End time <Required>*</Required>
                </InputLabelText>
                <EndTimeDatePicker
                  width={90}
                  errorType={dealEndDateTimeStampError}
                  handelChangeParent={handelEndTimeChange}
                  time={`${dealDetails?.dealEndDateTimeStamp}`}
                  normal={true}
                />
                {Boolean(dealEndDateTimeStampError) && (
                  <ErrorHelperText>{dealEndDateTimeStampError}</ErrorHelperText>
                )}
              </FieldContainer>

              <FieldContainer>
                <InputLabelText>Sub text</InputLabelText>
                <InputTextField
                  onChange={handleInputChange("dealText")}
                  variant="outlined"
                  size="small"
                  value={dealText}
                  inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                />
                {dealTextArabic === null && (
                  <TextWrapper>
                    <Small
                      onClick={() => handelArabicInputShow("dealTextArabic")}
                    >
                      Enter in arabic
                    </Small>
                  </TextWrapper>
                )}
              </FieldContainer>

              <FieldContainer>
                {typeof dealTextArabic === "string" && (
                  <>
                    <InputLabelText>Sub text (Arabic)</InputLabelText>
                    <FlexBoxArabicDeals>
                      <InputTextField
                        onChange={handelChangeArabic("dealTextArabic")}
                        variant="outlined"
                        size="small"
                        dir="rtl"
                        value={dealTextArabic}
                        inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                      />
                      <CancelIcon
                        onClick={() => handelArabicInputHide("dealTextArabic")}
                      />
                    </FlexBoxArabicDeals>
                  </>
                )}
              </FieldContainer>
            </FormRightSide>

            <FormRightSide>
              <FieldContainer>
                <InputLabelText>
                  User Type <Required>*</Required>
                </InputLabelText>
                <DropdownSelector
                  onChange={handleInputChange("targetUserType")}
                  variant="outlined"
                  value={targetUserType}
                  error={Boolean(targetUserTypeError)}
                  id="demo-simple-select-error"
                  MenuProps={DROPDOWN_MENU_PROPS}
                >
                  {" "}
                  <MenuItem value="Select" disabled>
                    Select
                  </MenuItem>
                  {userArr.map((item, index) => {
                    return (
                      <MenuItem key={`${item}_${index}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </DropdownSelector>
                {Boolean(targetUserTypeError) && (
                  <ErrorHelperText>{targetUserTypeError}</ErrorHelperText>
                )}
              </FieldContainer>

              <FieldContainer>
                <InputLabelText>Link to</InputLabelText>
                <DropdownSelector
                  onChange={handleInputChange("linkTo")}
                  variant="outlined"
                  value={linkTo}
                  MenuProps={DROPDOWN_MENU_PROPS}
                >
                  {linkToArr.map((item, index) => {
                    return (
                      <MenuItem key={`${item}_${index}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </DropdownSelector>
              </FieldContainer>

              {/* <FieldContainer>
                <InputLabelText>Auto Re-schedule in days</InputLabelText>
                <InputTextField
                  onChange={handleInputChange("autoRescheduleDays")}
                  variant="outlined"
                  size="small"
                  value={autoRescheduleDays}
                  inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                />
              </FieldContainer> */}

              <FieldContainer>
                <InputLabelText>Link URL</InputLabelText>
                <InputTextField
                  onChange={handleInputChange("linkUrl")}
                  variant="outlined"
                  size="small"
                  error={Boolean(linkUrlError)}
                  helperText={linkUrlError}
                  value={linkUrl}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      background: `${isUrlDisable ? "#F0F0F0" : ""}`,
                    },
                  }}
                  disabled={isUrlDisable}
                />
              </FieldContainer>

              {/* <FieldContainer>
                <InputLabelText>External URL</InputLabelText>
                <InputTextField
                  onChange={handleInputChange("externalUrl")}
                  variant="outlined"
                  size="small"
                  error={Boolean(externalUrlError)}
                  helperText={externalUrlError}
                  value={externalUrl}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      background: `${isUrlDisable ? "#F0F0F0" : ""}`,
                    },
                  }}
                  disabled={isUrlDisable}
                />
              </FieldContainer> */}

              <FieldContainer>
                <InputLabelText>Link Name</InputLabelText>
                <InputTextField
                  error={Boolean(linkNameError)}
                  helperText={linkNameError}
                  onChange={handleInputChange("linkName")}
                  variant="outlined"
                  size="small"
                  value={linkName}
                  inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                />
                {linkNameArabic === null && (
                  <TextWrapper>
                    <Small
                      onClick={() => handelArabicInputShow("linkNameArabic")}
                    >
                      Enter in arabic
                    </Small>
                  </TextWrapper>
                )}
              </FieldContainer>

              <FieldContainer>
                {typeof linkNameArabic === "string" && (
                  <>
                    <InputLabelText>Link Name (Arabic)</InputLabelText>
                    <FlexBoxArabicDeals>
                      <InputTextField
                        error={Boolean(linkNameArabicError)}
                        helperText={linkNameArabicError}
                        onChange={handelChangeArabic("linkNameArabic")}
                        variant="outlined"
                        size="small"
                        dir="rtl"
                        value={linkNameArabic}
                        inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                      />
                      <CancelIcon
                        onClick={() => handelArabicInputHide("linkNameArabic")}
                      />
                    </FlexBoxArabicDeals>
                  </>
                )}
              </FieldContainer>
            </FormRightSide>
          </FlexOne>
          <FlexTwo>

            <FieldContainerTC>
              <InputLabelText>Description</InputLabelText>
              <RichTextEditorDiv>
                <CustomRichTextEditor
                  pluginArray={ALL_PLUGINS}
                  customToolBar={TOOLBAR_FOR_DESCRIPTION}
                  quickAccessToolbar={QUICK_DESC_BLOCK}
                  editorState={dealDescription}
                  handelChangeEditorState={(content, e) =>
                    handleEditorChange(content, e, "dealDescription")
                  }
                  quickInsertToolbar={QUICK_INSERT_TOOLBOX_DECS}
                  editorHeight={180}
                  toolbarMode="wrap"
                  directionality="ltr"
                />
              </RichTextEditorDiv>

              {dealDescriptionArabic === null && (
                <TextWrapper>
                  <Small
                    onClick={() =>
                      handelArabicInputShow("dealDescriptionArabic")
                    }
                  >
                    Enter in arabic
                  </Small>
                </TextWrapper>
              )}
            </FieldContainerTC>

            <FieldContainerTC>
              {typeof dealDescriptionArabic === "string" && (
                <>
                  <InputLabelText>Description (Arabic)</InputLabelText>
                  <FlexBoxArabicEditor>
                    <InnerFlexEditorCancelBtn>
                      <CustomRichTextEditor
                        pluginArray={ALL_PLUGINS}
                        customToolBar={TOOLBAR_FOR_DESCRIPTION}
                        quickAccessToolbar={QUICK_DESC_BLOCK}
                        editorState={dealDescriptionArabic}
                        handelChangeEditorState={(content, e) =>
                          handleEditorChange(
                            content,
                            e,
                            "dealDescriptionArabic"
                          )
                        }
                        quickInsertToolbar={QUICK_INSERT_TOOLBOX_DECS}
                        editorHeight={180}
                        toolbarMode="wrap"
                        directionality="rtl"
                      />
                    </InnerFlexEditorCancelBtn>

                    <CancelInputDescription
                      onClick={() =>
                        handelArabicInputHide("dealDescriptionArabic")
                      }
                    />
                  </FlexBoxArabicEditor>
                </>
              )}
            </FieldContainerTC>

            <FieldContainerTC>
              <InputLabelTextTC>
                <CheckboxTC
                  value={isChecked}
                  color="primary"
                  size="small"
                  checked={isChecked}
                  onChange={handleCheckbox}
                />
                Terms and Conditions
              </InputLabelTextTC>
              {isChecked ? (
                <>
                  <RichTextEditorDiv>
                    <CustomRichTextEditor
                      pluginArray={ALL_PLUGINS}
                      customToolBar={TOOLBAR_FOR_DESCRIPTION}
                      quickAccessToolbar={QUICK_DESC_BLOCK}
                      editorState={termsAndConditions}
                      handelChangeEditorState={(content, e) =>
                        handleEditorChange(content, e, "termsAndConditions")
                      }
                      quickInsertToolbar={QUICK_INSERT_TOOLBOX_DECS}
                      editorHeight={180}
                      toolbarMode="wrap"
                      directionality="ltr"
                    />
                  </RichTextEditorDiv>

                  {termsAndConditionsArabic === null && (
                    <TextWrapper>
                      <Small
                        onClick={() =>
                          handelArabicInputShow("termsAndConditionsArabic")
                        }
                      >
                        Enter in arabic
                      </Small>
                    </TextWrapper>
                  )}

                  <FieldContainer>
                    {typeof termsAndConditionsArabic === "string" && (
                      <>
                        <InputLabelText>
                          Terms and Conditions (Arabic)
                        </InputLabelText>
                        <FlexBoxArabicEditor>
                          <InnerFlexEditorCancelBtn>
                            <CustomRichTextEditor
                              pluginArray={ALL_PLUGINS}
                              customToolBar={TOOLBAR_FOR_DESCRIPTION}
                              quickAccessToolbar={QUICK_DESC_BLOCK}
                              editorState={termsAndConditionsArabic}
                              handelChangeEditorState={(content, e) =>
                                handleEditorChange(
                                  content,
                                  e,
                                  "termsAndConditionsArabic"
                                )
                              }
                              quickInsertToolbar={QUICK_INSERT_TOOLBOX_DECS}
                              editorHeight={180}
                              toolbarMode="wrap"
                              directionality="rtl"
                            />
                          </InnerFlexEditorCancelBtn>

                          <CancelInputDescription
                            onClick={() =>
                              handelArabicInputHide("termsAndConditionsArabic")
                            }
                          />
                        </FlexBoxArabicEditor>
                      </>
                    )}
                  </FieldContainer>
                </>
              ) : null}
            </FieldContainerTC>

          </FlexTwo>
        </RightFormFlex>
        </FormContainer>

      </ParentContainer>
    </LocationContainer>
  );
}

export default NewDeal;
