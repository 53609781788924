import axios from "axios";

import {
  BASE_URL,
  SERVICE_BOOKING_FILTER_ENDPOINT,
  SERVICE_BOOKING_SEARCH_ENDPOINT,
  GET_ONGOING_SERVICE_BOOKING_ENDPOINT,
  GET_UPCOMING_SERVICE_BOOKING_ENDPOINT,
  GET_PREVIOUS_SERVICE_BOOKING_ENDPOINT,
  GET_FILTER_PARAMETERS_ENDPOINT,
} from "constants/api-config-constants";
import selectedBrand from "constants/brand";

import {
  ServiceBookingResponse,
  GetServiceBookingRequest,
  ServiceBookingFilterInterface,
  ServiceBookingSearchRequest,
  FilterParamsResponse,
} from "interfaces/service-booking.interface"

export const getOngoingServiceBookings = async (
  request: GetServiceBookingRequest
): Promise<ServiceBookingResponse> => {
  const {pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_ONGOING_SERVICE_BOOKING_ENDPOINT +
    "?brand=" +
    selectedBrand()+
    "&pageNo=" +
    pageNumber +
    "&limit=" +
    limit;
  return axios.get(url);
};

export const getUpcomingServiceBookings = async (
  request: GetServiceBookingRequest
): Promise<ServiceBookingResponse> => {
  const {pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_UPCOMING_SERVICE_BOOKING_ENDPOINT +
    "?brand=" +
    selectedBrand()+
    "&pageNo=" +
    pageNumber +
    "&limit=" +
    limit;
  return axios.get(url);
};

export const getPreviousServiceBookings = async (
  request: GetServiceBookingRequest
): Promise<ServiceBookingResponse> => {
  const {pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_PREVIOUS_SERVICE_BOOKING_ENDPOINT +
    "?brand=" +
    selectedBrand()+
    "&pageNo=" +
    pageNumber +
    "&limit=" +
    limit;
  return axios.get(url);
};

export const ServiceBookingSearch = async (
  request: ServiceBookingSearchRequest
): Promise<ServiceBookingResponse> => {
  const {serviceStatus, pageNumber,searchText,limit } = request;
  const url =
    BASE_URL +
    SERVICE_BOOKING_SEARCH_ENDPOINT +
    "?brand=" +
    selectedBrand()+
    "&pageno=" +
    pageNumber +
    "&limit=" +
    limit +
    "&serviceStatus="+
    serviceStatus?.toLowerCase();
  return axios.post(url, searchText, {
    headers: { "Content-Type": "text/plain" },
  });
};

export const ServiceBookingFilter = async (
  request: ServiceBookingFilterInterface
): Promise<ServiceBookingResponse> => {
  const{pageNumber, serviceStatus,startDate,endDate,center,serviceType,limit} = request;
  const url = BASE_URL + SERVICE_BOOKING_FILTER_ENDPOINT+    
  "?brand=" +
  selectedBrand()+
  "&pageno=" +
  pageNumber +
  "&limit=" +
  limit +
  "&serviceStatus="+
  serviceStatus?.toLowerCase()+
  "&center="+
  center+
  "&serviceType="+
  serviceType+
  "&fromDate="+
  startDate+
  "&toDate="+
  endDate;
  
  return axios.get(url);
};


export const getFilterParameters = async (
): Promise<FilterParamsResponse> => {
  const url =
    BASE_URL +
    GET_FILTER_PARAMETERS_ENDPOINT +
    "?brand=" +
    selectedBrand();
  return axios.get(url);
};
