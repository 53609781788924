import styled from "styled-components";

import { Button } from "@material-ui/core";
import {
  AddUserContainer,
  ButtonContainer,
} from "pages/user-profile/components/add-user/styles";

export const DeleteUserContainer = styled(AddUserContainer)`
  width: 30%;
  height: 40%;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConfirmDiv = styled.div``;

export const HeaderDiv = styled.div`
  padding-bottom: 1rem;
  font-weight: 600;
  font-size: 1.25rem;
`;

export const BtnContainer = styled(ButtonContainer)`
  height: 2.8rem;
`;

export const SubmitButton = styled(Button)`
  width: 6.25rem;
  text-transform: none !important;
`;
export const CancelButton = styled(Button)`
  width: 6.25rem;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 1rem !important;
`;

export const SpanDiv = styled.span`
  font-size: 0.75rem;
`;
