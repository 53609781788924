import React, { ReactElement } from "react";

import { ExpandMore } from "@material-ui/icons";
import { Menu } from "@material-ui/core";

import {
  SelectedLangDiv,
  FlagImage,
  ExpandDiv,
  LanguageList,
  MenuListItem,
  LabelSpan,
} from "shared-components/arabic-input/arabic-switch-dropdown/styles";

interface Props {
  selectedLang: any;
  handleClick: () => void;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  languageArr: any;
  handleLanguage: (val) => void;
}
export default function ArabicSwitchDropdown({
  selectedLang,
  handleClick,
  anchorEl,
  languageArr,
  handleClose,
  handleLanguage,
}: Props): ReactElement {
  return (
    <>
      <SelectedLangDiv>
        <FlagImage src={selectedLang} alt="brand" id="flagDropdown"></FlagImage>
        {
          <ExpandDiv onClick={handleClick}>
            <ExpandMore />
          </ExpandDiv>
        }
      </SelectedLangDiv>
      <Menu
        id="flag-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {languageArr.map((data, index) => {
          return (
            <LanguageList key={`flag_${index}`}>
              <MenuListItem onClick={() => handleLanguage(data.label)}>
                <FlagImage src={data.flag} alt="flags"></FlagImage>
                <LabelSpan>{data.label}</LabelSpan>
              </MenuListItem>
            </LanguageList>
          );
        })}
      </Menu>
    </>
  );
}
