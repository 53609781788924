import React, { ReactElement } from "react";

import Loader from "shared-components/loader";

import { SubmitButton } from "shared-components/loader-button/styles";

interface Styles {
  margin?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
}
interface Props {
  submitHandler: (id?) => void;
  isLoading?: boolean;
  buttonText: string;
  styles?: Styles;
  className?: string;
}

function LoaderButton({
  submitHandler,
  isLoading,
  buttonText,
  styles,
  className
}: Props): ReactElement {
  return (
    <>
      <SubmitButton
        styles={styles as Styles}
        onClick={(id) => submitHandler(id)}
        variant="contained"
        color="primary"
        style={{ pointerEvents: isLoading ? "none" : "auto" }}
        className={className}
      >
        {isLoading ? <Loader isLoading={isLoading as boolean} /> : buttonText}
      </SubmitButton>
    </>
  );
}

export default LoaderButton;
