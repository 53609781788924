import React, { ReactElement } from "react";

function BackIcon(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      enable-background="new 0 0 24 24"
      viewBox="0 0 32 32"
      cursor="pointer"
      {...props}
    >
      <path d="M29.885 3.372c.201-.363.135-.802-.162-1.092C29.541 2.094 29.292 2 29.043 2c-.144 0-.297.028-.431.104L2.02 15.067h21.528L29.885 3.372zM23.328 16.935H2l26.612 12.964c.373.187.823.112 1.11-.186.297-.29.354-.737.153-1.093L23.328 16.935z" />
    </svg>
  );
}

export default BackIcon;
