import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getNewsById,
  getNewsDetails,
} from "services/news-services";

import { GetNewsRequest, getSingleNewsRequest } from "interfaces/news.interface";

export const getAllNewsThunk = createAsyncThunk(
  "getAllNewsThunk",
  async (request: GetNewsRequest, { rejectWithValue }) => {
    try {
      const data = await getNewsDetails(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNewsByIdThunk = createAsyncThunk(
  "getNewsByIdThunk",
  async (request: getSingleNewsRequest, { rejectWithValue }) => {
    const { id } = request
    try {
      const data = await getNewsById(id);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const newsSearchThunk = createAsyncThunk(
//   "newsSearchThunk",
//   async (request: string, { rejectWithValue }) => {
//     try {
//       const data = await newsSearch(request);
//       return data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

