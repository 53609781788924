import React, { ReactElement, useEffect, useState } from "react";

import { TextField } from "@material-ui/core";

import {
  ParentDiv,
  AutocompleteSelect,
  BodyParameters,
  TextBoxField,
  InputDiv,
  HeadersContainer,
  LoadingComp,
  FlexDiv,
  ButtonContainer,
  LanguageText,
} from "./styles";
import {
  NavContainer,
  InputLabelText,
  CancelButton,
} from "pages/management/link-management/styles";
import { apiMgmtDetails } from "interfaces/link-management.interface";
import { useSnackbar } from "notistack";
import { getApiMgmtDetails, updateApiDetails } from "services/links-services";
import ArabicSwitchDropdown from "shared-components/arabic-input/arabic-switch-dropdown";
import BahrinFlag from "assets/bahrin-flag.png";
import UkFlag from "assets/uk-flag.png";
import { LANGUAGE_FLAG_ARRAY, loaderBtnStyleFunc } from "constants/style/props";
import LoaderButton from "shared-components/loader-button";

interface Props {
  type: string;
}

const apiMgmtObj = {
  id: undefined,
  brand: "",
  urlType: "",
  lang: "",
  httpMethod: "",
  url: "",
  headers: {
    accept: "",
    "user-agent": "",
  },
};

const langObj = {
  English: "en",
  Arabic: "ar",
};

function ParamterComponent({ type }: Props): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const [apiMgmtState, setApiMgmtState] = useState<apiMgmtDetails>(apiMgmtObj);
  const [apiMgmtDummyState, setApiMgmtDummyState] = useState<apiMgmtDetails>();
  const [loading, setLoading] = useState<boolean>(true);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const [lang, setLang] = useState<string>("en");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFlag, setSelectedFlag] = useState(UkFlag);

  const languageArr = LANGUAGE_FLAG_ARRAY;

  useEffect(() => {
    getApiDetails();
    // eslint-disable-next-line
  }, [type, lang]);

  const getApiDetails = async () => {
    try {
      const result = await getApiMgmtDetails({ type: type, lang: lang });
      setApiMgmtState({ ...result?.data?.data });
      setApiMgmtDummyState(
        JSON.parse(JSON.stringify({ ...result?.data?.data }))
      );
      setLoading(false);
    } catch (err: any) {
      if (err?.response?.data?.data) {
        enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
      }
      setLoading(false);
    }
  };

  const updateAPiDetails = async () => {
    setBtnLoading(true);
    try {
      const result = await updateApiDetails(apiMgmtState);
      if (result?.data?.response) {
        enqueueSnackbar(result.data.response, {
          variant: "success",
        });
      }
      getApiDetails();
      setBtnLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
      setBtnLoading(false);
    }
  };

  const { httpMethod, url, headers } = apiMgmtState;

  const handelOnChange = (value, prop, isHeader) => {
    if (isHeader) {
      setApiMgmtState({
        ...apiMgmtState,
        headers: { ...apiMgmtState.headers, [prop]: value },
      });
    } else {
      setApiMgmtState({ ...apiMgmtState, [prop]: value });
    }
  };


  const handleClick = () => {
    const elem = document.getElementById("flagDropdown");
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = (label) => {
    const newFlag = label === "English" ? UkFlag : BahrinFlag;
    setSelectedFlag(newFlag);
    setAnchorEl(null);
    setLang(langObj[label]);
  };

  const submitHandler = () => {
    updateAPiDetails();
  };

  const handelCancel = () => {
    setApiMgmtState(JSON.parse(JSON.stringify(apiMgmtDummyState)));
  };

  return (
    <ParentDiv>
      {loading ? (
        <LoadingComp color="primary" />
      ) : (
        <>
          <FlexDiv>
            <LanguageText>Language</LanguageText>
            <ArabicSwitchDropdown
              selectedLang={selectedFlag}
              handleClick={handleClick}
              anchorEl={anchorEl}
              languageArr={languageArr}
              handleClose={handleClose}
              handleLanguage={handleLanguage}
            />
          </FlexDiv>
          <HeadersContainer>
            <InputLabelText>Method & Url</InputLabelText>
          <BodyParameters>
            <InputDiv>
              <NavContainer>
                <InputLabelText>Method type</InputLabelText>
                <AutocompleteSelect
                  id={type}
                  options={["post", "get", "put", "delete"]}
                  size="small"
                  onChange={(e, value) =>
                    handelOnChange(value, "httpMethod", false)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth />
                  )}
                  value={httpMethod}
                />
              </NavContainer>
              <div></div>
              <NavContainer>
                <InputLabelText>Url</InputLabelText>
                <TextBoxField
                  onChange={(e) => handelOnChange(e.target.value, "url", false)}
                  id="url"
                  variant="outlined"
                  size="small"
                  value={url}
                />
              </NavContainer>
            </InputDiv>
          </BodyParameters>
          </HeadersContainer>

          <HeadersContainer>
            <InputLabelText>Header</InputLabelText>
            <BodyParameters>
              {Object.entries(headers).length > 0 &&
                Object.entries(headers).map(([key, value], index) => {
                  return (
                    <InputDiv key={index + key}>
                      <NavContainer>
                        <InputLabelText>Header Type</InputLabelText>
                        <TextBoxField
                          id="dataType"
                          variant="outlined"
                          size="small"
                          value={key}
                          disabled
                        />
                      </NavContainer>
                      <div></div>
                      <NavContainer>
                        <InputLabelText>Value</InputLabelText>
                        <TextBoxField
                          id="key"
                          variant="outlined"
                          size="small"
                          value={value}
                          onChange={(e) =>
                            handelOnChange(e.target.value, key, true)
                          }
                        />
                      </NavContainer>
                    </InputDiv>
                  );
                })}
            </BodyParameters>
          </HeadersContainer>
          <ButtonContainer>
            <LoaderButton
              isLoading={btnLoading}
              buttonText="Save"
              styles={loaderBtnStyleFunc("6.8rem", "2.8rem")}
              submitHandler={submitHandler}
            />
            <CancelButton
              variant="contained"
              onClick={handelCancel}
              color="default"
            >
              Cancel
            </CancelButton>
          </ButtonContainer>
        </>
      )}
    </ParentDiv>
  );
}

export default ParamterComponent;
