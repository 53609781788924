import DashboardState from "slices/dashboard/dashboard-interface";

const dashboardInitialState: DashboardState = {
  dashboardInfo: {
    dashboardDetails: {
      totalCustomers: 0,
      testDriveBookings: {},
      newCustomers: 0,
      recentActivities: [],
      owners: 0,
      ownersVSGuestUsers: {},
      guestUsers: 0,
      appUsage: {},
      serviceBookigs: {},
    },
    selectedMonth: new Date().getMonth() + 1,
  },
};

export default dashboardInitialState;
