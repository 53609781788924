import React, { ReactElement, ReactNode, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import BookingIcon from "shared-components/icons/bookings";
// import ServiceIcon from "shared-components/icons/service";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LocationIcon from "shared-components/icons/location";
import Header from "shared-components/header";

import { headerLinks } from "constants/siderbar-constants";

import {
  PRIMARY_TOYOTA,
  PRIMARY_LEXUS,
  PRIMARY_MOTORCITY,
} from "constants/style/color";

import {
  SideBarDiv,
  NavText,
  LogoContainer,
  NavLinkDiv,
  NavItem,
  NavDiv,
  MainDiv,
  ListItemDiv,
  ListIcon,
  ListItemContent,
  BrandLogo,
  ForwardArrow,
  DashboardLogo,
} from "./styles";
import { sidebarStyles } from "constants/style/props";

interface Props {
  children: ReactNode;
}

function SideBar({ children }: Props): ReactElement {
  const location = useLocation();
  const [selectedPath, setSelectedPath] = useState<string>();
  const [brandColor, setBrandColor] = useState<string>("");
  const UnprotectedRoutes = [
    "/login",
    "/forgot-password",
    "/reset-password",
    "/",
  ];
  const [open, setOpen] = useState({
    // services: false,
    management: false,
  });

  const brand = useAppSelector((state) => state.brand.brandType);
  const handleClick = (type) => {
    // if (type === "services") {
    //   setOpen((prevState) => ({
    //     ...prevState,
    //     services: !prevState.services,
    //   }));
    // } else {
    setOpen((prevState) => ({
      ...prevState,
      management: !prevState.management,
    }));
    // }
  };

  useEffect(() => {
    const brandColor =
      brand === "Toyota"
        ? PRIMARY_TOYOTA
        : brand === "Lexus"
          ? PRIMARY_LEXUS
          : PRIMARY_MOTORCITY;
    setBrandColor(brandColor);
  }, [brand]);

  useEffect(() => {
    setSelectedPath(location.pathname.slice(1));
  }, [location.pathname]);

  const showSidebar = () => {
    if (UnprotectedRoutes.indexOf(location.pathname) !== -1) {
      return false;
    } else return true;
  };

  const userDetails = useAppSelector((state: RootState) => {
    return state.UserProfile.userProfileInfo;
  });
  return showSidebar() ? (
    <>
      <SideBarDiv>
        <LogoContainer>
          <BrandLogo />
        </LogoContainer>
        <NavLinkDiv>
          <NavItem to="/dashboard">
            <NavDiv>
              <DashboardLogo
                color={selectedPath === "dashboard" ? brandColor : `black`}
              />
              <NavText>Dashboard</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/customers">
            <NavDiv
              color={sidebarStyles(
                selectedPath,
                brandColor,
                "customers",
                "customer-details"
              )}
            >
              <BookingIcon
                color={sidebarStyles(
                  selectedPath,
                  brandColor,
                  "customers",
                  "customer-details"
                )}
              />
              <NavText>Customers</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/location">
            <NavDiv
              color={sidebarStyles(
                selectedPath,
                brandColor,
                "location",
                "add-location",
                "edit-location"
              )}
            >
              <LocationIcon
                color={sidebarStyles(
                  selectedPath,
                  brandColor,
                  "location",
                  "add-location",
                  "edit-location"
                )}
              />
              <NavText>Locations</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/latest-cars">
            <NavDiv>
              <LocationIcon
                color={selectedPath === "latest-cars" ? brandColor : `black`}
              />
              <NavText>Latest Cars</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/news">
            <NavDiv
              color={sidebarStyles(
                selectedPath,
                brandColor,
                "news",
                "news-detail"
              )}
            >
              <BookingIcon
                color={sidebarStyles(
                  selectedPath,
                  brandColor,
                  "news",
                  "news-detail"
                )}
              />
              <NavText>News</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/best-deals">
            <NavDiv
              color={sidebarStyles(
                selectedPath,
                brandColor,
                "best-deals",
                "new-deal",
                "copy-deal",
                "edit-deal"
              )}
            >
              <BookingIcon
                color={sidebarStyles(
                  selectedPath,
                  brandColor,
                  "best-deals",
                  "new-deal",
                  "copy-deal",
                  "edit-deal"
                )}
              />
              <NavText>Best Deals</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/live-chat-config">
            <NavDiv>
              <BookingIcon
                color={
                  selectedPath === "live-chat-config" ? brandColor : `black`
                }
              />
              <NavText>Live Chat Config</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/latest-blogs">
            <NavDiv
              color={sidebarStyles(
                selectedPath,
                brandColor,
                "latest-blogs",
                "new-blog",
                "copy-blog",
                "edit-blog"
              )}
            >
              <BookingIcon
                color={sidebarStyles(
                  selectedPath,
                  brandColor,
                  "latest-blogs",
                  "new-blog",
                  "copy-blog",
                  "edit-blog"
                )}
              />
              <NavText>Latest Blogs</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/test-drive-bookings">
            <NavDiv
              color={sidebarStyles(
                selectedPath,
                brandColor,
                "test-drive-bookings",
                "test-drive-booking-details"
              )}
            >
              <LocationIcon
                color={sidebarStyles(
                  selectedPath,
                  brandColor,
                  "test-drive-bookings",
                  "test-drive-booking-details"
                )}
              />
              <NavText>Test Drive Bookings</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/service-bookings">
            <NavDiv>
              <LocationIcon
                color={
                  selectedPath === "service-bookings" ? brandColor : `black`
                }
              />
              <NavText>Service Bookings</NavText>
            </NavDiv>
          </NavItem>
          <NavItem to="/roadside-assistance">
            <NavDiv
              color={sidebarStyles(
                selectedPath,
                brandColor,
                "roadside-assistance",
                "edit-emergency-options"
              )}
            >
              <BookingIcon
                color={sidebarStyles(
                  selectedPath,
                  brandColor,
                  "roadside-assistance",
                  "edit-emergency-options"
                )}
              />
              <NavText>Roadside Assistance</NavText>
            </NavDiv>
          </NavItem>
          <ListItemContent button onClick={() => handleClick("management")}>
            <ListIcon>
              <BookingIcon
                color={selectedPath === "/management" ? brandColor : `black`}
              />
            </ListIcon>
            <ListItemDiv primary="Management" />
            {open.management ? <ExpandMore /> : <ForwardArrow />}
          </ListItemContent>
          <Collapse in={open.management} timeout="auto" unmountOnExit>
            {headerLinks.management.map(({ link, text }, index) => {
              return (
                <List
                  component={NavItem}
                  key={`NavItem_${index}`}
                  to={link}
                  disablePadding
                >
                  {userDetails?.basicInfo?.moduleList?.includes(link) ? (
                    link === "faq" ? (
                      <NavDiv
                        color={sidebarStyles(
                          selectedPath,
                          brandColor,
                          "faq",
                          "add-faq",
                          "edit-faq"
                        )}
                      >
                        <BookingIcon
                          color={sidebarStyles(
                            selectedPath,
                            brandColor,
                            "faq",
                            "add-faq",
                            "edit-faq"
                          )}
                        />
                        <NavText>{text}</NavText>
                      </NavDiv>
                    ) : (
                      <NavDiv>
                        <BookingIcon
                          color={
                            selectedPath === `${link}` ? brandColor : `black`
                          }
                        />
                        <NavText>{text}</NavText>
                      </NavDiv>
                    )
                  ) : null}
                </List>
              );
            })}
          </Collapse>
        </NavLinkDiv>
      </SideBarDiv>
      <MainDiv>
        <Header />
        {children}
      </MainDiv>
    </>
  ) : (
    <></>
  );
}

export default SideBar;
