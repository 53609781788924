import axios from "axios";
import { store, persistor } from "redux-store/store";
import { TYPE_LOGOUT } from "constants/action-types-constants";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.response?.status === 401) {
      store.dispatch({ type: TYPE_LOGOUT });
      persistor.purge();
    }
    throw err;
  }
);
