import LocationInfoState from "interfaces/location-interface";

const locationInitialState: LocationInfoState = {
  locationInfo: {
    allLocations: [],
    currentLocation: {},
    currentPage: 1,
    recordsLength: 0,
    currentTab:""
  },
};

export default locationInitialState;
