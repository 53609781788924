import blogsState from "slices/latest-blogs/latest-blogs-interface";

const blogsInitialState: blogsState = {
  blogsInfo: {
    allBlogs: [],
    currentBlog: {},
    currentPage: 1,
    recordsLength: 0,
    fromEditPage:false,
    tabIndicator: "",
  },
};

export default blogsInitialState;
