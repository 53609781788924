import axios from "axios";
import { BASE_URL, GET_SALES_FORCE_ENDPOINT, UPDATE_SALES_FORCE_ENDPOINT } from "constants/api-config-constants";
import { GetSalesForceResponse, SalesForceDetails } from "interfaces/sales-force.interface";


export const getSalesForceCredentials = async (): Promise<GetSalesForceResponse> => {
    const url =
      BASE_URL +
      GET_SALES_FORCE_ENDPOINT
    return axios.get(url);
};

export const updateSalesForceCredentials = async (
    request: SalesForceDetails
  ): Promise<GetSalesForceResponse> => {
    const url = BASE_URL + UPDATE_SALES_FORCE_ENDPOINT;
    return axios.post(url, request);
  };