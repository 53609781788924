import styled from "styled-components";

export const NotificationContainer = styled.div`
  padding: 1.5rem 2rem;
`;

export const TableDiv = styled.div`
  margin-top: 1rem;
`;
export const ArabicSwitch = styled.div`
  margin-left: 1rem;
  margin-top: 0.5rem;
`;
