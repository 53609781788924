import React, { ReactElement } from "react";
import {
  TextWrapper,
  Small,
} from "shared-components/arabic-input/enter-in-arabic-text/styles";

interface Props {
  handleArabicText: (val, type) => void;
  typeOfField: string;
}
export default function EnterArabicText({
  handleArabicText,
  typeOfField,
}: Props): ReactElement {
  return (
    <TextWrapper>
      <Small onClick={() => handleArabicText(typeOfField, "open")}>
        Enter in arabic
      </Small>
    </TextWrapper>
  );
}
