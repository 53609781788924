import UserProfileState from "slices/user-profile/user-profile-interface";

const userProfileInitialState: UserProfileState = {
  userProfileInfo: {
    basicInfo: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      phoneNumber: "",
      listofBrands: [],
      defautbrand: "",
      language: "",
      name: "",
      moduleList: [],
    },
    adminUsers: [],
    currentUser: {
      listofBrands: [],
      moduleList: [],
    },
    currentPage: 1,
    totalRecordsLength: 0,
  },
};

export default userProfileInitialState;
