import styled from "styled-components";

import { Link } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";

import Logo from "shared-components/icons/login-logo";

import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import {
  SUBTEXT_FONT_SIZE,
  PARAGRAPH_FONT_SIZE,
  SUBHEADER_FONT_SIZE,
} from "constants/style/font-size";
import { BLACK_COLOR, DISABLED_TEXT } from "constants/style/color";

export const EmptyDiv = styled.div`
  flex-grow: 5;
`;
export const LoginContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 1px 1px 2px 2px #ffffff;
  border-radius: 15px;
  padding: 0 6rem;  
  display: flex;
  flex-direction: column;
  align-items: center;


  @media only screen  and (min-width : 1920px) {
    padding: 5rem 10rem;
  }
`;
export const InputDiv = styled.div`
  width:130%;

  @media only screen  and (min-width : 1920px) {
    width: 160%;
  }
`;
export const NavContainer = styled.div`
  margin-top: 6%;
`;

export const MainContainer = styled.div`
  width:100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const ForgotPassword = styled(Link)`
  color: ${BLACK_COLOR};
  float: right;
  margin-top: 3%;
  text-decoration: none;
  font-family: ${PRIMARY_REGULAR_FONT};
  font-size: ${SUBTEXT_FONT_SIZE};
`;
export const SubmitButton = styled(Button)`
  width: 100%;
  height: 2.8rem;
  text-transform: none !important;
  margin: 2rem 0 3rem !important;
  background-color: #2d609f !important;
  font-size: ${SUBHEADER_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;

export const InputTextField = styled(TextField)`
  width: 100%;
  white-space: pre-line;
`;
export const InputLabelText = styled(InputLabel)`
  margin-bottom: 3%;
  color: ${BLACK_COLOR} !important;
  font-size: ${PARAGRAPH_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;
export const BrandLogo = styled(Logo)`
  margin: 3.5rem 0 1rem 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const LabelDiv = styled.div`
  display: flex;
  justify-content: space-between;

`;

export const ShowPasswordIcon = styled(VisibilityIcon)`
  color: ${DISABLED_TEXT} !important;
  font-size: 1.2em !important;
  cursor: pointer;
`;

export const HidePasswordIcon = styled(VisibilityOffIcon)`
  color: ${DISABLED_TEXT} !important;
  font-size: 1.2em !important;
  cursor: pointer;
`;
