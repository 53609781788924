import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { useSnackbar } from "notistack";

import LoaderButton from "shared-components/loader-button";
import ArabicInput from "shared-components/arabic-input/textfield";
import EnterArabicText from "shared-components/arabic-input/enter-in-arabic-text";
import { CancelInput } from "shared-components/arabic-input/multiline-textfield/styles";
import CustomRichTextEditor from "shared-components/rich-text-editor";
import PaperComponent from "shared-components/paper-component";

import {
  FaqsContainer,
  FieldContainerEditor,
  FlexBoxCon,
} from "pages/management/faqs/components/edit-faq/styles";

import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import { FAQ } from "constants/route/route-constants";
import { EMPTY_INPUT } from "constants/snackbar-messages-constants";
import { loaderBtnStyleFunc } from "constants/style/props";
import {
  ALL_PLUGINS,
  FAQ_QUICK_ACCESS_BLOCK,
  FULL_TOOLBAR,
  QUICK_INSERT_TOOLBOX,
} from "constants/text-editor-tools";

import {
  addNewFaqThunk,
  setCurrentFaqReducer,
  updateFaqThunk,
} from "slices/faqs";

import {
  FieldContainer,
  InputLabelText,
  InputTextField,
  // InputTextArea,
  CancelBtn,
} from "pages/management/faqs/components/edit-faq/styles";

const addFaq = {
  question: "",
  answer: "",
  questionArabic: null,
  answerArabic: null,
};

const errorObj = {
  question: "Question",
  answer: "Answer",
  questionArabic: "Question",
  answerArabic: "Answer",
};

function AddEditFaq(): ReactElement {
  const history = useHistory<any>();
  const dispatch = useAppDispatch();
  const location = useLocation<any>();
  const ref = useRef<any>();

  const [faqState, setFaqState] = useState({ ...addFaq });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [faqErrors, setFaqErrors] = useState({
    questionError: "",
  });

  // for redirection only when edit screen ( brand switches )
  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });

  useEffect(() => {
    if (location?.state?.detail === "edit") {
      if (ref.current !== undefined && ref.current !== brandName) {
        return history.push(FAQ);
      }
      ref.current = brandName;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName, history]);

  useEffect(() => {
    if (location?.state?.detail === "edit") {
      // faqState["question"] = location?.state?.rowData?.question;
      setFaqState({ ...location?.state?.rowData });
      dispatch(setCurrentFaqReducer({ ...location?.state?.rowData }));
    }
  }, [location?.state?.rowData, dispatch, location?.state?.detail]);

  const { question, answer, questionArabic, answerArabic } = faqState;
  const { questionError } = faqErrors;

  const handelChange = (prop) => (e) => {
    const { value } = e.target;
    faqState[prop] = value;
    if (value === "") {
      faqErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
    } else {
      faqErrors[prop + "Error"] = "";
    }
    setFaqState({ ...faqState });
    setFaqErrors({ ...faqErrors });
  };

  const handelSave = async () => {
    if (question === "") {
      faqErrors["questionError"] = `${errorObj["question"]} ${EMPTY_INPUT}`;
      setFaqErrors({ ...faqErrors });
    }
    // if (answer === "") {
    //   faqErrors["answerError"] = `${errorObj["answer"]} ${EMPTY_INPUT}`;
    //   setFaqErrors({ ...faqErrors });
    // }
    if (question !== "" && questionError === "") {
      try {
        setLoading(true);
        if (location?.state?.detail === "edit") {
          var result = await dispatch(updateFaqThunk(faqState)).unwrap();
          enqueueSnackbar(result.data.status, {
            variant: "success",
          });
          setLoading(false);
          history.push({
            pathname: `${FAQ}`,
            state: { from: "edit" },
          });
          setFaqState({
            question: "",
            answer: "",
            questionArabic: null,
            answerArabic: null,
          });
        } else {
          var AddNewResult = await dispatch(addNewFaqThunk(faqState)).unwrap();
          enqueueSnackbar(AddNewResult.data.status, {
            variant: "success",
          });
          setLoading(false);
          history.push({
            pathname: `${FAQ}`,
            state: { from: "edit" },
          });
          setFaqState({
            question: "",
            answer: "",
            questionArabic: null,
            answerArabic: null,
          });
        }
      } catch (error: any) {
        setLoading(false);
        enqueueSnackbar(error?.response, { variant: "error" });
      }
    }
  };

  const handleArabicText = (text, type) => {
    if (type === "hide") {
      faqState[text] = null;
    } else {
      faqState[text] = "";
    }
    setFaqState({ ...faqState });
  };

  const handleArabicChange = (e, prop) => {
    const { value } = e.target;
    faqState[prop] = value;
    setFaqState({ ...faqState });
  };

  const handelCancel = () => {
    history.push({
      pathname: `${FAQ}`,
      state: { from: "edit" },
    });
  };

  const handleEditorChange = (content, editor, key) => {
    faqState[key] = content;
    setFaqState({ ...faqState });
  };

  return (
    <PaperComponent
      heading={`${
        location?.state?.detail === "edit"
          ? "Edit Frequently Asked Question"
          : "Add Frequently Asked Question"
      }`}
    >
      <FaqsContainer>
        <FieldContainer>
          <InputLabelText>Question</InputLabelText>
          <InputTextField
            variant="outlined"
            size="small"
            error={Boolean(questionError)}
            helperText={questionError}
            onChange={handelChange("question")}
            inputProps={{
              style: { fontSize: 14, fontFamily: PRIMARY_REGULAR_FONT },
            }}
            value={question}
          />

          {questionArabic === null && (
            <EnterArabicText
              handleArabicText={handleArabicText}
              typeOfField={"questionArabic"}
            />
          )}
        </FieldContainer>

        <FieldContainer>
          {typeof questionArabic === "string" && (
            <>
              <ArabicInput
                labelText={"Question In "}
                value={questionArabic}
                typeOfField={"questionArabic"}
                handleArabicText={handleArabicText}
                handleArabicChange={handleArabicChange}
                width={"92%"}
              />
            </>
          )}
        </FieldContainer>

        <FieldContainerEditor>
          <InputLabelText>Answer</InputLabelText>

          <CustomRichTextEditor
            pluginArray={ALL_PLUGINS}
            customToolBar={FULL_TOOLBAR}
            quickAccessToolbar={FAQ_QUICK_ACCESS_BLOCK}
            editorState={answer}
            handelChangeEditorState={(c, e) =>
              handleEditorChange(c, e, "answer")
            }
            quickInsertToolbar={QUICK_INSERT_TOOLBOX}
            editorHeight={200}
          />

          {answerArabic === null && (
            <>
              <EnterArabicText
                handleArabicText={handleArabicText}
                typeOfField={"answerArabic"}
              />
            </>
          )}
        </FieldContainerEditor>
        <>
          {typeof answerArabic === "string" && (
            <>
              <InputLabelText>Answer (Arabic)</InputLabelText>
              <FlexBoxCon>
                <CustomRichTextEditor
                  pluginArray={ALL_PLUGINS}
                  customToolBar={FULL_TOOLBAR}
                  quickAccessToolbar={FAQ_QUICK_ACCESS_BLOCK}
                  quickInsertToolbar={QUICK_INSERT_TOOLBOX}
                  editorState={answerArabic}
                  handelChangeEditorState={(c, e) =>
                    handleEditorChange(c, e, "answerArabic")
                  }
                  toolbarMode="wrap"
                  directionality="rtl"
                  editorHeight={200}
                />
                <CancelInput
                  onClick={() => handleArabicText("answerArabic", "hide")}
                />
              </FlexBoxCon>
            </>
          )}
        </>

        <FieldContainer>
          <LoaderButton
            submitHandler={handelSave}
            isLoading={loading}
            buttonText="Save"
            styles={loaderBtnStyleFunc("11%", "2.5rem")}
          />

          <CancelBtn variant="outlined" color="primary" onClick={handelCancel}>
            Cancel
          </CancelBtn>
        </FieldContainer>
      </FaqsContainer>
    </PaperComponent>
  );
}

export default AddEditFaq;
