import styled from "styled-components";

export const TableContainer = styled.div`
  margin-top: 1.5rem;
`;

export const ArabicSwitch = styled.div`
  margin-left: 1rem;
  margin-top: 0.5rem;
`;
