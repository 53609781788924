import React, { ReactElement } from "react";

function AddIconLogo(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      width={20}
      height={22}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        opacity={.9}
        stroke="#000"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 5h18M6 5V3a2 2 0 012-2h4a2 2 0 012 2v2m3 0v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5h14zM8 10v6M12 10v6" />
      </g>
    </svg>
  );
}

export default AddIconLogo;
