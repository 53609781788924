import styled from "styled-components";

import { MenuItem, Select } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import { COMPONENT_HEADING } from "constants/style/font-size";
import { PRIMARY_MEDIUM_FONT } from "constants/style/fonts";

import CalendarIcon from "shared-components/icons/calendar";

export const DashboardContainer = styled.div`
  padding: 1.5rem 2rem;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const Heading = styled.p`
  font-size: ${COMPONENT_HEADING};
  margin: 0;
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-weight: bold;
  user-select: none;
`;

export const SelectDropDown = styled(Select)``;

export const CalendarDiv = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 11rem;
`;

export const CalendarLogo = styled(CalendarIcon)`
  width: 1rem !important;
`;
export const ChartContainer = styled.div`
  display: flex;
  flex-wrap: inline-wrap;
  gap: 1.5rem;
  margin-top: 1rem;
  width: 100%;
  /* display: grid;
  grid-template-columns: 49% 48%;
  grid-gap: 3%; */
  @media only screen and (max-width: 1268px) {
    flex-wrap: wrap;
    flex-flow: column wrap;
  }
`;

export const CustomerLeftChartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-flow: column wrap;
  gap: 1rem;
  flex-grow: 1;
`;

export const CustomerRightChartContainer = styled(CustomerLeftChartContainer)``;

export const EmptyDiv = styled.div``;

export const DropdownSelector = styled(Select)`
  height: 2rem;
  width: 7.2rem;
`;

export const MonthMenuItem = styled(MenuItem)`
  padding: 0 1.7rem !important;
`;

export const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  margin-top: 15rem;
`;
