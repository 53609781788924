import { createAsyncThunk } from "@reduxjs/toolkit";

import { BlogDetails, GetBlogsRequest, SearchBlogs } from "interfaces/latest-blogs.interfaces";

  import { 
    getAllBlogs,
    getBlogsBySearch,
    getBlogById,
    updateBlog,
    deleteBlog,
    addNewBlog
   } from 'services/latest-blogs-services';

   export const getAllBlogsThunk = createAsyncThunk(
    "getAllBlogsThunk",
    async (request: GetBlogsRequest, { rejectWithValue }) => {
      try {
        const data = await getAllBlogs(request);
        return data;
      } catch (err:any) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const getBlogsBySearchThunk = createAsyncThunk(
    "getBlogsBySearchThunk",
    async (request: SearchBlogs, { rejectWithValue }) => {
      try {
        const data = await getBlogsBySearch(request);
        return data;
      } catch (err:any) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const getBlogByIdThunk = createAsyncThunk(
    "getBlogByIdThunk",
    async (blogId:number, { rejectWithValue }) => {
      try {
        const data = await getBlogById(blogId);
        return data;
      } catch (err:any) {
        return rejectWithValue(err.response.data);
      }
    }
  );


  export const updateBlogInfoThunk = createAsyncThunk(
    "updateBlogInfoThunk",
    async (request: BlogDetails, { rejectWithValue }) => {
      try {
        const result = await updateBlog(request);
        return result;
      } catch (err:any) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const deleteBlogThunk = createAsyncThunk(
    "deleteBlogThunk",
    async (id: number, { rejectWithValue }) => {
      try {
        const result = await deleteBlog(id);
        const data = {
          id: id as number,
          response: result.data,
        };
        return data;
      } catch (err:any) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const addNewBlogThunk = createAsyncThunk(
    "addNewBlogThunk",
    async (request: BlogDetails, { rejectWithValue }) => {
      try {
        const result = await addNewBlog(request);
        return result;
      } catch (err:any) {
        return rejectWithValue(err.response.data);
      }
    }
  );

