import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TabSelectorDiv, TabContainer, OneTab } from "./styles";

interface TabProps {
  labelArray: Array<string>;
  children?: Array<ReactNode>;
}

const useStyles = makeStyles(() => ({
  tabs: {
    borderBottom: `4px solid`,
    borderColor: "primary",
  },
}));

export default function CustomTabSelector({ labelArray, children }: TabProps) {
  const [newValue, setNewValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setNewValue(newValue);
  };

  const classes = useStyles();

  return (
    <TabSelectorDiv>
      <TabContainer
        textColor={"primary"}
        value={newValue}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        scrollButtons="auto"
        variant="scrollable"
      >
        {labelArray.map((value, index) => {
          return (
            <OneTab
              key={`SingleTab_${value}_${index}`}
              label={value}
              className={`${newValue === index ? classes.tabs : ""}`}
            ></OneTab>
          );
        })}
      </TabContainer>
      {children?.map((child, index) => {
        return (
          <TabDetails
            key={`TabDetails_${index}`}
            value={newValue}
            index={index}
          >
            {child}
          </TabDetails>
        );
      })}
    </TabSelectorDiv>
  );
}

function TabDetails(props) {
  const { children, value, index } = props;
  return <div>{value === index && <div>{children}</div>}</div>;
}
