import React, { ReactElement } from "react";

import CustomizedRatings from "shared-components/ratings";
import PaperComponent from "shared-components/paper-component";

import {
  RatingDetails,
  SubHeading,
  Comment,
} from "pages/location/components/service-ratings/styles";

function ServiceRating(): ReactElement {
  const usersData = [
    {
      name: "Customer Name",
      rating: 3,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non egestas dignissim duis faucibus facilisis morbi adipiscing. Purus sapien aliquet augue urna, in. Eu interdum sapien nec amet. Condimentum aliquet egestas massa, urna, bibendum malesuada. Facilisi et rhoncus arcu volutpat faucibus leo. Risus in eu cras nunc eget. Amet, at felis libero mauris dictumst pulvinar. Nulla platea viverra ut sit eu lacinia adipiscing adipiscing ornare.",
    },
    {
      name: "Customer Name",
      rating: 5,
      comment: "Nice work",
    },
    {
      name: "Customer Name",
      rating: 2,
      comment: "Nice work",
    },
  ];
  return (
    <PaperComponent heading={"Service Ratings"}>
      {usersData.map(({ name, rating, comment }, index) => {
        return (
          <RatingDetails key={`name_${index}`}>
            <SubHeading>{name}</SubHeading>
            <CustomizedRatings val={rating} max={5} reviewAvg={rating} />
            <Comment>{comment}</Comment>
          </RatingDetails>
        );
      })}
    </PaperComponent>
  );
}

export default ServiceRating;
