import axios from "axios";
import selectedBrand from "constants/brand";
import {limit} from "constants/pagination-constants";
import {
  BASE_URL,
  GET_ALL_BLOGS_ENDPOINT,
  ADD_NEW_BLOG_ENDPOINT,
  EDIT_BLOG_ENDPOINT,
  SEARCH_BLOGS_ENDPOINT,
  DELETE_BLOG_ENDPOINT,
  GET_BLOG_BY_ID_ENDPOINT,
} from "constants/api-config-constants";

import {
  GetBlogsRequest,
  BlogDetails,
  GetBlogsResponse,
  DeleteBlogResponse,
  SearchBlogs,
} from "interfaces/latest-blogs.interfaces";

export const getAllBlogs = async (
  request: GetBlogsRequest
): Promise<GetBlogsResponse> => {
  const { status, pageno } = request;
  const url =
    BASE_URL +
    GET_ALL_BLOGS_ENDPOINT +
    "?status=" +
    status +
    "&pageno=" +
    pageno +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const getBlogsBySearch = async (
  request: SearchBlogs
): Promise<GetBlogsResponse> => {
  const { query,status,pageno,limit } = request;
  const url =
    BASE_URL +
    SEARCH_BLOGS_ENDPOINT +
    "?brand=" +
    selectedBrand()+
    "&keyword=" +
    query+
    "&status="+
    status+
    "&pageno="+
    pageno+
    "&limit="+
    limit
  return axios.get(url);
};


export const addNewBlog = async (
  request: BlogDetails
): Promise<GetBlogsResponse> => {
  const url = BASE_URL + ADD_NEW_BLOG_ENDPOINT + "?brand=" + selectedBrand();
  return axios.post(url, request);
};

export const getBlogById = async (
  blogId: number
): Promise<GetBlogsResponse> => {
  const url = BASE_URL + GET_BLOG_BY_ID_ENDPOINT+"?id="+blogId+`&brand=` + selectedBrand();
  return axios.get(url);
};

export const deleteBlog = async (
  blogId: number
): Promise<DeleteBlogResponse> => {
  const url = BASE_URL + DELETE_BLOG_ENDPOINT + `/${blogId}?brand=` + selectedBrand();
  return axios.delete(url);
};

export const updateBlog = async (
  request: BlogDetails
): Promise<GetBlogsResponse> => {
  const url = BASE_URL + EDIT_BLOG_ENDPOINT + `/${request.id}?brand=` + selectedBrand();
  return axios.put(url, request);
};


