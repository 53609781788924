import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { BLACK_COLOR } from "constants/style/color";
import { PARAGRAPH_FONT_SIZE } from "constants/style/font-size";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";

export const MainDiv = styled.div`
  display: grid;
  padding: 0.5rem 0.5rem 1rem;
  grid-template-columns: 44% 5% 51%;
  align-items: center;
  justify-content: center;
  margin:0 auto;
`;

export const InputLabelText = styled.div`
  margin-bottom: 1%;
  color: ${BLACK_COLOR} !important;
  font-size: ${PARAGRAPH_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;

export const InputTextField = styled(TextField)`
  width: 11.4rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
`;
