import styled from "styled-components";
import  {UserManagementContainer} from 'pages/user-profile/components/user-management/styles'
import {
    CancelBtn,
    SaveBtn,
} from "pages/management/faqs/components/edit-faq/styles";
import { FlexBoxArabicEditor } from "pages/latest-blogs/components/new-blog/styles";

export const DetailsDiv = styled(UserManagementContainer)``;

export const DetailsArrayContainer = styled.div`
    margin:1.25rem;
`;

export const SaveButton = styled(SaveBtn)`
    margin:0 0.625rem 0 0 !important;
`;
export const CancelButton = styled(CancelBtn)`
    margin:0 0.625rem 0 1rem !important;
`;

export const RichTextEditorDiv = styled.div`
  width: 97%;
`;

export const FlexBoxArabicEditorPP = styled(FlexBoxArabicEditor)`
  width: 102% !important;
`;




