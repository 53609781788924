import TestDriveState from "slices/test-drive-bookings/test-drive-interface";

const testDriveInitialState: TestDriveState = {
  testDriveInfo: {
    allTestDrive: [],
    recordsLength: 0,
    currentPage: 1,
  },
};

export default testDriveInitialState;
