import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  GetFaqsRequest,
  FaqObject,
  SearchFaqs,
  // getFaqById
} from "interfaces/faq-interfaces";

import {
  getAllFaqs,
  // getSingleFaq,
  addNewFaq,
  updateFaq,
  deleteFaq,
  getFaqsBySearch
} from "services/faq-services";

export const getAllFaqsThunk = createAsyncThunk(
  "getAllFaqsThunk",
  async (request: GetFaqsRequest, { rejectWithValue }) => {
    try {
      const data = await getAllFaqs(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFaqsBySearchThunk = createAsyncThunk(
  "getFaqsBySearchThunk",
  async (request: SearchFaqs, { rejectWithValue }) => {
    try {
      const data = await getFaqsBySearch(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const addNewFaqThunk = createAsyncThunk(
  "addNewFaq",
  async (request: FaqObject, { rejectWithValue }) => {
    try {
      const result = await addNewFaq(request);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateFaqThunk = createAsyncThunk(
  "updateFaqThunk",
  async (request: FaqObject, { rejectWithValue }) => {
    try {
      const result = await updateFaq(request);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



export const deleteFaqThunk = createAsyncThunk(
  "deleteFaqThunk",
  async (id: number, { rejectWithValue }) => {
    try {
      const result = await deleteFaq(id);
      const data = {
        id: id as number,
        response: result.data,
      };
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




