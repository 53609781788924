import axios from "axios";

import {
  BASE_URL,
  GET_LATEST_CARS_ENDPOINT,
} from "constants/api-config-constants";
import selectedBrand from "constants/brand";

import {
  LatestCarsResponse,
  LatestCarsRequest,
} from "interfaces/latest-cars-interface";

export const getLatestCarsDetails = async (
  request: LatestCarsRequest
): Promise<LatestCarsResponse> => {
  const { pageNumber,limit } = request;
  const url =
    BASE_URL +
    GET_LATEST_CARS_ENDPOINT +
    "?brand=" +
    selectedBrand() +
    "&pageno=" +
    pageNumber +
    "&limit=" +
    limit+
    "&lang=en";
  return axios.get(url);
};
