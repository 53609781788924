import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux-store/hooks";
import { RootState } from "redux-store/store";
import { persistor } from "redux-store/store";
import { brandEnum } from "slices/brand/brand-slice";
import Menu from "@material-ui/core/Menu";
import { setBrand } from "slices/brand";

import ExpandMore from "@material-ui/icons/ExpandMore";

import Toyota from "assets/toyota.png";
import MotorCity from "assets/motorcity.png";
import Lexus from "assets/lexus.png";

import { TYPE_LOGOUT } from "constants/action-types-constants";

import {
  HeaderDiv,
  ProfileDiv,
  ImageContainer,
  UserProfileDiv,
  ExpandDiv,
  MenuListItem,
  BrandImage,
  BrandList,
  SelectedBrandDiv,
  NavItem,
  NavMenu,
  FlexContainer,
  PowerIcon,
  ProfileIcon,
  NavMenuItemList,
  FlexChild,
  NavMenuItem,
  InnerFlex,
  MenuProfileDiv,
  ProfileFlex,
  Name,
  SpanComp,
  LogoutTag,
  ProfileImage,
} from "shared-components/header/styles";


function Header(): ReactElement {
  const dispatch = useAppDispatch();
  const { basicInfo } = useAppSelector((state: RootState) => {
    return state.UserProfile.userProfileInfo;
  });

  // console.log(basicInfo,"headers"); import Header

  const { firstName, lastName, role } = basicInfo;
  const firstNameData = ((firstName?.slice(0, 1)?.toUpperCase() as string) +
    firstName?.slice(1)) as string;
  const lastNameData = ((lastName?.slice(0, 1)?.toUpperCase() as string) +
    lastName?.slice(1)) as string;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const [selectedBrand, setSelectedBrand] = useState(Toyota);
  const [brands] = useState([
    {
      image: Toyota,
      name: "Toyota",
    },
    {
      image: Lexus,
      name: "Lexus",
    },
    {
      image: MotorCity,
      name: "motorcity",
    },
  ]);

  const handleLogout = () => {
    setAnchorE2(null);
    persistor.purge();
    dispatch({ type: TYPE_LOGOUT });
    handleClose();
  };

  const handelOpenMenu = (e) => {
    setAnchorE2(e.currentTarget);
  };

  const handleCloseMenu = (e) => {
    setAnchorE2(null);
  };

  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });

  const userBrand = useAppSelector((state: RootState) => {
    return state.user.userInfo.user;
  });


  useEffect(() => {
    const brand =
      brandName === "Toyota"
        ? Toyota
        : brandName === "Lexus"
        ? Lexus
        : brandName === "motorcity"
        ? MotorCity
        : "";
    setSelectedBrand(brand);
  }, [brandName]);

  const handleClick = () => {
    const elem = document.getElementById("brandDropdown");
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBrand = (brand) => {
    const brandType =
      brand === "Lexus"
        ? brandEnum.L
        : brand === "Toyota"
        ? brandEnum.T
        : brand === "motorcity"
        ? brandEnum.M
        : brandEnum.T;
    dispatch(setBrand(brandType));
    setAnchorEl(null);
  };
  return (
    <>
      <HeaderDiv>
        <UserProfileDiv>
          <SelectedBrandDiv>
            <BrandImage
              src={selectedBrand}
              alt="brand"
              id="brandDropdown"
            ></BrandImage>
            {userBrand?.listofBrands?.length > 1 && (
              <ExpandDiv onClick={handleClick}>
                <ExpandMore />
              </ExpandDiv>
            )}
          </SelectedBrandDiv>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {userBrand?.listofBrands?.length > 1 &&
              userBrand?.listofBrands?.map((data) => {
                return brands.map((item, index) => {
                  return (
                    <BrandList>
                      {data === item.name && item.name !== brandName ? (
                        <MenuListItem
                          key={`Brand_${index}`}
                          onClick={() => handleBrand(item.name)}
                        >
                          <BrandImage src={item.image} alt="brand"></BrandImage>
                        </MenuListItem>
                      ) : null}
                    </BrandList>
                  );
                });
              })}
          </Menu>
        </UserProfileDiv>
        <ProfileDiv>
          <ImageContainer onClick={handelOpenMenu} area-controls="menu">
            {/* {firstName?.slice(0, 1)?.toUpperCase()} */}
            <ProfileImage alt={basicInfo?.firstName} src={basicInfo?.profilePicture} />
          </ImageContainer>
          <NavItem to="/user-profile">
            <Name>{`${firstNameData} ${lastNameData}`}</Name>
          </NavItem>
        </ProfileDiv>

        <NavMenu
          id="simple-menu"
          anchorEl={anchorE2}
          keepMounted
          open={Boolean(anchorE2)}
          getContentAnchorEl={null}
          onClose={handleCloseMenu}
        >
          <FlexContainer>
            <MenuProfileDiv id="profileDiv">
              <ImageContainer>
                {/* {firstName?.slice(0, 1)?.toUpperCase()} */}
              <ProfileImage alt={basicInfo?.firstName} src={basicInfo?.profilePicture} />
              </ImageContainer>
              <ProfileFlex>
                <Name>{`${firstNameData} ${lastNameData}`}</Name>
                <SpanComp>{role}</SpanComp>
              </ProfileFlex>
            </MenuProfileDiv>
            <InnerFlex>
              <NavMenuItemList onClick={handleCloseMenu}>
                <FlexChild>
                  <ProfileIcon /> 
                </FlexChild>
                <NavMenuItem to="/user-profile">Profile</NavMenuItem>
              </NavMenuItemList>
              <NavMenuItemList onClick={handleLogout}>
                <FlexChild>
                  <PowerIcon />
                </FlexChild>
                <LogoutTag>Logout</LogoutTag>
              </NavMenuItemList>
            </InnerFlex>
          </FlexContainer>
        </NavMenu>
      </HeaderDiv>
    </>
  );
}

export default Header;
