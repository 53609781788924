import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import LocationInfoState from "interfaces/location-interface";
import locationInitialState from "slices/locations/location-initial-state";
import { LocationInfo } from "./locations.interface";
import {
  getPartCenterLocationsThunk,
  getServiceCenterLocationsThunk,
  getShowroomLocationsThunk,
  UpdateLocationThunk,
  deleteLocationInfoThunk,
  addLocationInfoThunk,
  filterLanguagesThunk
} from "slices/locations/location-thunks";


export const LocationSlice = createSlice({
  name: "LocationSlice",
  initialState: locationInitialState,
  reducers: {
    setCurrentLocationReducer:setCurrentLocationReducer,
    setCurrentTabReducer:setCurrentTabReducer,
    setCurrentPageReducer:setCurrentPageReducer,

  },
  extraReducers: {
    [getPartCenterLocationsThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.locationInfo.allLocations =
        listEntityClass === undefined ? [] : listEntityClass;
      state.locationInfo.recordsLength = totalRecords;
    },

    [getPartCenterLocationsThunk.rejected.type]: (state) => {
      state.locationInfo.allLocations = [];
    },

    [getServiceCenterLocationsThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.locationInfo.allLocations =
        listEntityClass === undefined ? [] : listEntityClass;
      state.locationInfo.recordsLength = totalRecords;
    },

    [getServiceCenterLocationsThunk.rejected.type]: (state) => {
      state.locationInfo.allLocations = [];
    },

    [getShowroomLocationsThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.locationInfo.allLocations =
        listEntityClass === undefined ? [] : listEntityClass;
      state.locationInfo.recordsLength = totalRecords;
    },

    [getShowroomLocationsThunk.rejected.type]: (state) => {
      state.locationInfo.allLocations =[];
    },

    [filterLanguagesThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.locationInfo.allLocations =
        listEntityClass === undefined ? [] : listEntityClass;
      state.locationInfo.recordsLength = totalRecords;
    },

    [filterLanguagesThunk.rejected.type]: (state) => {
      state.locationInfo.allLocations =[];
    },

    [UpdateLocationThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      const result = state.locationInfo.allLocations.map((item) => {
        if (item.locationId === data?.locationId) {
          item = { ...data };
        }
        return item;
      });
      state.locationInfo.allLocations = [...result];
    },
    
    [deleteLocationInfoThunk.fulfilled.type]: (state, action) => {
      const { id } = action.payload;
      state.locationInfo.allLocations =
        state.locationInfo.allLocations.filter((data) => data.locationId !== id);
    },

    [addLocationInfoThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      state.locationInfo.allLocations.push(data);
    },

    // [getLocationByIdThunk.fulfilled.type]: (state, action) => {
    //   const { data } = action.payload.data;
    //   state.locationInfo.currentLocation = data;
    // },
  },
});


function setCurrentLocationReducer(
  state: LocationInfoState,
  action: PayloadAction<LocationInfo>
) {
  state.locationInfo.currentLocation = action.payload;
}

function setCurrentTabReducer(
  state: LocationInfoState,
  action: PayloadAction<string>
) {
  state.locationInfo.currentTab = action.payload;
}


function setCurrentPageReducer(
  state: LocationInfoState,
  action: PayloadAction<number>
) {
  state.locationInfo.currentPage = action.payload;
}