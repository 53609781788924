import { createAsyncThunk } from "@reduxjs/toolkit";
// import { store } from "redux-store/store";

import {
  GetCustomersRequest,
  GetCustomersBookingHistoryRequest,
  GetCustomersByFilterRequest,
} from "interfaces/customers.interface";

import {
  GetCustomerDetails,
  GetCustomerById,
  UpdateCustomerStatus,
  GetCustomerCars,
  GetCustomerCarsBookingHistoryList,
  CustomersFilter,
  CustomersSearch,
} from "services/customers-services";

// const selectedBrand = store.getState().brand.brandType;

export const getCustomersThunk = createAsyncThunk(
  "getCustomersThunk",
  async (request: GetCustomersRequest, { rejectWithValue }) => {
    try {
      const data = await GetCustomerDetails({ ...request });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//filter api
export const getCustomersByFilterThunk = createAsyncThunk(
  "getCustomersByFilterThunk",
  async (request: GetCustomersByFilterRequest, { rejectWithValue }) => {
    try {
      const data = await CustomersFilter(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//search api
export const getCustomersBySearchThunk = createAsyncThunk(
  "getCustomersBySearchThunk",
  async (request: GetCustomersRequest, { rejectWithValue }) => {
    try {
      const data = await CustomersSearch(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//customer basic info
export const getCustomerById = createAsyncThunk(
  "getCustomerById",
  async (userId: number, { rejectWithValue }) => {
    try {
      const result = await GetCustomerById(userId);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//update customer status
export const updateCustomerInfoThunk = createAsyncThunk(
  "updateCustomerInfoThunk",
  async (id: number, { rejectWithValue }) => {
    try {
      const result = await UpdateCustomerStatus(id);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustomersOwnedCarsThunk = createAsyncThunk(
  "getCustomersOwnedCarsThunk",
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await GetCustomerCars(id);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustomersBookingHistoryThunk = createAsyncThunk(
  "getCustomersBookingHistoryThunk",
  async (request: GetCustomersBookingHistoryRequest, { rejectWithValue }) => {
    try {
      const data = await GetCustomerCarsBookingHistoryList(request);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
