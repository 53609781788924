import styled from "styled-components";

import { Doughnut } from "react-chartjs-2";

import { Paper } from "@material-ui/core";

import { BLACK_COLOR, WHITE_COLOR } from "constants/style/color";
import { SUBHEADER_FONT_SIZE } from "constants/style/font-size";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";

export const MainContainer = styled(Paper)`
  flex-grow: 1;
  background: ${WHITE_COLOR};
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06) !important;
  border-radius: 10px;
  padding: 2%;
  position: relative;
  width: 95%;
  max-height: 8rem;
  padding-left: 1rem;
  min-height: 18rem;
`;

export const WidgetName = styled.p`
  margin: 0;
  font-size: ${SUBHEADER_FONT_SIZE};
  font-family: ${PRIMARY_REGULAR_FONT};
  color: ${BLACK_COLOR};
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

export const DoughnutChart = styled(Doughnut)`
  width: 100%;
`;
export const WidgetMessage = styled(WidgetName)`
  margin-top: 8rem;
  margin-left: 8rem;
  font-weight: bold;
`;
