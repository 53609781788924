export const loadingHelper=(func,setLoading,snackbar)=>{
    setLoading(true);
    func.unwrap()
    .then((res) => {
      if(window.location.pathname==="/latest-cars"){
        if(res?.data?.data.totalResults===0){
          snackbar("No more records to show", { variant: "warning" });
        }
      }
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      if('error' in err){
        snackbar(err.error, { variant: "error" });
      }else{
        snackbar("Something went wrong..!!", { variant: "error" });
      }
    });
}