import axios from "axios";
import selectedBrand from "constants/brand";

import {
  BASE_URL,
  GET_NEWS_BY_ID_ENDPOINT,
  GET_NEWS_ENDPOINT,
} from "constants/api-config-constants";

import { NewsResponse, GetNewsRequest, SingleNewsResponse } from "interfaces/news.interface";

export const getNewsDetails = async (
  request: GetNewsRequest
): Promise<NewsResponse> => {
  const { pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_NEWS_ENDPOINT +
    "?brand=" +
    removeSpaces(selectedBrand()).toLowerCase() +
    "&pageno=" +
    pageNumber +
    "&limit=" +
    limit+
    "&lang=en";
  return axios.get(url);
};

// export const newsSearch = async (request: string): Promise<NewsResponse> => {
//   const url = BASE_URL + NEWS_SEARCH_ENDPOINT;
//   return axios.post(url, request);
// };
const removeSpaces = (str) => str.replace(/\s/g, "");

export const getNewsById = async (request: number): Promise<SingleNewsResponse> => {
  const url =
    BASE_URL +
    GET_NEWS_BY_ID_ENDPOINT +
    "?contentid=" +
    request +
    "&brand=" +
    removeSpaces(selectedBrand()).toLowerCase()+
    "&lang=en";
  return axios.get(url);
};
