import styled from "styled-components";

import {
  TAG_COLOR_GREEN,
  BLACK_COLOR,
  GREEN_TAG_FONT_COLOR,
  TERTIARY_MOTORCITY,
} from "constants/style/color";
import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";

export const customTag = styled.button`
  width: 10rem;
  height: 2rem;
  border-radius: 3px;
  border: none;
  font-weight: 400;
  outline: none;
`;

export const CustomTagGreen = styled(customTag)`
  background-color: ${TAG_COLOR_GREEN};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainContainer = styled.div`
  background-color: #ffffff;
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0px 10px 10px 10px;
  position: relative;
`;

export const InprogressCircle = styled.div`
  width: 1rem;
  border-radius: 50%;
  background-color: ${GREEN_TAG_FONT_COLOR};
  height: 1rem;
  margin: 0 !important;
`;
export const CompletedCircle = styled(InprogressCircle)`
  background-color: ${TERTIARY_MOTORCITY};
`;

export const StatusText = styled.p`
  margin: 0 !important;
  font-size: ${SUBTEXT_FONT_SIZE};
  font-family: ${PRIMARY_REGULAR_FONT};
  color: ${BLACK_COLOR};
`;
