import styled from "styled-components";
import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { TEXTFIELD_HEADING } from "constants/style/color";
import { InputLabel } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import { FlexBoxContainer } from "pages/location/components/add-location/styles";

interface Props {
  width: string;
}
export const CancelInput = styled(CancelIcon)`
  cursor: pointer;
  margin: 0.5rem 1.5rem;
  font-size: 1.375rem !important;
  color: ${({ theme }) => theme.color};
`;

// export const FlexBoxArabic = styled.div`
//   display: flex;
//   align-items: center;
//   /* width: 100%; */
//   /* justify-content: space-between; */
//   /* width: 100%; */
// `;

export const FlexBoxArabic = styled(FlexBoxContainer)`
  width:100%;
`;

export const InputArabicTextField = styled(TextField)`
  width: ${({ width }: Props) => width};
`;

export const InputLabelText = styled(InputLabel)`
  margin-bottom: 0.625rem;
  font-size: ${SUBTEXT_FONT_SIZE} !important;
  color: ${TEXTFIELD_HEADING} !important;
`;
