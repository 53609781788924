import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import blogsInitialState from "./latest-blogs-initial-state";
import {
  addNewBlogThunk,
  deleteBlogThunk,
  getAllBlogsThunk,
  getBlogByIdThunk,
  getBlogsBySearchThunk,
  updateBlogInfoThunk,
} from "./latest-blogs-thunks";
import blogsState from "./latest-blogs-interface";
import { BlogDetails } from "interfaces/latest-blogs.interfaces";

export const LatestBlogsSlice = createSlice({
  name: "LatestBlogsSlice",
  initialState: blogsInitialState,
  reducers: {
    setCurrentBlogReducer: setCurrentBlogReducer,
    setCurrentPageReducer: setCurrentPageReducer,
    setTabIndicatorReducer: setTabIndicatorReducer,
    setFromEditPageReducer: setFromEditPageReducer,
  },
  extraReducers: {
    //set allBlogs
    [getAllBlogsThunk.fulfilled.type]: (state, action) => {
      let { results, totalResults } = action.payload.data.data;
      state.blogsInfo.allBlogs =
        results === undefined ? [] : results;
      state.blogsInfo.recordsLength = totalResults;
    },

    [getAllBlogsThunk.rejected.type]: (state) => {
      state.blogsInfo.allBlogs = [];
    },

    // get deal by id deals
    [getBlogByIdThunk.fulfilled.type]: (state, action) => {
      let { data } = action.payload.data;
      state.blogsInfo.currentBlog = data;
    },

    [getBlogByIdThunk.rejected.type]: (state) => {
      state.blogsInfo.currentBlog = {};
    },

    // search deal by text and Title
    [getBlogsBySearchThunk.fulfilled.type]: (state, action) => {
      let { results, totalResults } = action.payload.data.data;
      state.blogsInfo.allBlogs =
        results === undefined ? [] : results;
      state.blogsInfo.recordsLength = totalResults;
    },

    [getBlogsBySearchThunk.rejected.type]: (state) => {
      state.blogsInfo.allBlogs = [];
    },

    //updateDeal info
    [updateBlogInfoThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      const result = state.blogsInfo.allBlogs.map((item) => {
        if (item.id === data.id) {
          item = { ...data };
        }
        return item;
      });
      state.blogsInfo.allBlogs = [...result];
    },

    //delete deal
    [deleteBlogThunk.fulfilled.type]: (state, action) => {
      const { id } = action.payload;
      state.blogsInfo.allBlogs = state.blogsInfo.allBlogs.filter(
        (data) => data.id !== id
      );
    },

    // add new deal
    [addNewBlogThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      state.blogsInfo.allBlogs.push(data);
    },
  },
});

function setCurrentBlogReducer(
  state: blogsState,
  action: PayloadAction<BlogDetails>
) {
  state.blogsInfo.currentBlog = action.payload;
}

function setCurrentPageReducer(
  state: blogsState,
  action: PayloadAction<number>
) {
  // console.log(action.payload)
  state.blogsInfo.currentPage = action.payload;
}

function setTabIndicatorReducer(
  state: blogsState,
  action: PayloadAction<string | undefined>
) {
  state.blogsInfo.tabIndicator = action.payload as string;
}

function setFromEditPageReducer(
  state: blogsState,
  action: PayloadAction<boolean>
) {
  state.blogsInfo.fromEditPage = action.payload;
}
