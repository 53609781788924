import styled from "styled-components";

import { Paper } from "@material-ui/core";

import { WHITE_COLOR, BLACK_COLOR } from "constants/style/color";
import { SUBHEADER_FONT_SIZE } from "constants/style/font-size";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";

import { Bar } from "react-chartjs-2";

export const TestDrivenBookings = styled(Paper)`
  background: ${WHITE_COLOR};
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06) !important;
  border-radius: 10px;
  padding: 2%;
  flex-grow: 1;
  width: 95%;
  max-height: 8rem;
  min-height: 18rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  position: relative;
`;

export const WidgetName = styled.p`
  margin: 0;
  font-size: ${SUBHEADER_FONT_SIZE};
  font-family: ${PRIMARY_REGULAR_FONT};
  color: ${BLACK_COLOR};
  position: absolute;
  top: 1rem;
  left: 2rem;
`;

export const BarChartGraph = styled(Bar)`
  /* width: 100%;
  padding-top: 1rem;
  height: inherit !important; */
  width: 95% !important;
  padding-top: 2.5rem;
`;

export const WidgetMessage = styled(WidgetName)`
  position: absolute;
  top: 45%;
  left: 32%;
  font-weight: bold;
`;
