import styled from "styled-components";

import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveIcon from "shared-components/icons/remove-icon";
import EditIcon from "shared-components/icons/edit-icon";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import { Paper, Modal, Select } from "@material-ui/core";

export const UserManagementContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 0px 10px 10px 10px;
`;

export const UserPaper = styled(Paper)``;

export const AddUserDiv = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
`;

export const AddUserModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddUserText = styled.text`
  margin: 2px 5px;
  /* cursor: pointer; */
`;

export const AddIconLogo = styled(AddCircleOutlineOutlinedIcon)`
  margin-left: 1rem;
  cursor: pointer;
  opacity: 0.8;
`;

export const RemoveIconLogo = styled(RemoveIcon)`
  margin: 5px;
  cursor: pointer;
`;

export const EditIconLogo = styled(EditIcon)`
  margin: 5px;
  cursor: pointer;
`;

export const DropdownSelector = styled(Select)`
  height: 2rem;
  width: 10rem;
`;

export const AutocompleteInput = styled(Autocomplete)`
  height: 2rem;
  width: 10rem;
`;
