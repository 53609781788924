import React, { ReactElement } from "react";

import TabSelector from "shared-components/tab-selector";

import LatestCarsTable from "pages/latest-cars/components/car-table";

import {
  CustomerContainer,
  Heading,
  LeftDiv,
  HeaderDiv,
} from "pages/latest-cars/styles";


function LatestCars(): ReactElement {


  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Latest Cars</Heading>
        </LeftDiv>
      </HeaderDiv>

      <TabSelector
        labelArray={["All Cars"]}
        children={
          [
            <LatestCarsTable />,
          ] as any
        }
      />
    </CustomerContainer>
  );
}

export default LatestCars;
