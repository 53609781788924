import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  GetNotificationsRequest,
  NotificationObject,
} from "interfaces/notifications-interfaces";

import {
  getAllNotifications,
  addNewNotification,
  // updateNotification,
  // deleteNotification,
} from "services/notifications-services";

export const getAllNotificationsThunk = createAsyncThunk(
  "getAllNotificationsThunk",
  async (request: GetNotificationsRequest, { rejectWithValue }) => {
    try {
      const data = await getAllNotifications(request);
      return data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addNewNotificationThunk = createAsyncThunk(
  "addNewNotification",
  async (request: NotificationObject, { rejectWithValue }) => {
    try {
      const result = await addNewNotification(request);
      return result;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const updateNotificationThunk = createAsyncThunk(
//   "updateNotificationThunk",
//   async (request: NotificationObject, { rejectWithValue }) => {
//     try {
//       const result = await updateNotification(request);
//       return result;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

// export const deleteNotificationThunk = createAsyncThunk(
//   "deleteNotificationThunk",
//   async (id: number, { rejectWithValue }) => {
//     try {
//       const result = await deleteNotification(id);
//       const data = {
//         id: id as number,
//         response: result.data,
//       };
//       return data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );
