import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getPartCenterLocations,
  getServiceCenterLocations,
  getShowroomLocations,
  updateLocation,
  addLocation,
  deleteLocation,
  filterLanguages,
  // getLocationById,
} from "services/location-services";

import {
  FilterLangRequest,
  GetLocationsRequest,
  LocationInfo,
} from "slices/locations/locations.interface";

export const getPartCenterLocationsThunk = createAsyncThunk(
  "getPartCenterLocationsThunk",
  async (request: GetLocationsRequest, { rejectWithValue }) => {
    try {
      const result = await getPartCenterLocations(request);
      return result;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getServiceCenterLocationsThunk = createAsyncThunk(
  "getServiceCenterLocationsThunk",
  async (request: GetLocationsRequest, { rejectWithValue }) => {
    try {
      const result = await getServiceCenterLocations(request);
      return result;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getShowroomLocationsThunk = createAsyncThunk(
  "getShowroomLocationsThunk",
  async (request: GetLocationsRequest, { rejectWithValue }) => {
    try {
      const result = await getShowroomLocations(request);
      return result;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const filterLanguagesThunk = createAsyncThunk(
  "filterLanguagesThunk",
  async (request: FilterLangRequest, { rejectWithValue }) => {
    try {
      const result = await filterLanguages(request);
      return result;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const UpdateLocationThunk = createAsyncThunk(
  "UpdateLocationThunk",
  async (request: LocationInfo, { rejectWithValue }) => {
    try {
      const result = await updateLocation(request);
      return result;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteLocationInfoThunk = createAsyncThunk(
  "deleteLocationInfoThunk",
  async (id: number, { rejectWithValue }) => {
    try {
      const result = await deleteLocation(id);
      const data = {
        id: id as number,
        response: result.data,
      };
      return data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addLocationInfoThunk = createAsyncThunk(
  "addLocationInfoThunk",
  async (request: LocationInfo, { rejectWithValue }) => {
    try {
      const result = await addLocation(request);
      return result;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const getLocationByIdThunk = createAsyncThunk(
//   "getLocationByIdThunk",
//   async (id: number, { rejectWithValue }) => {
//     try {
//       const result = await getLocationById(id);
//       return result;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );
