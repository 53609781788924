import styled from "styled-components";

import { Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core";
import {
  COMPONENT_HEADING,
  PARAGRAPH_FONT_SIZE,
} from "constants/style/font-size";
import {
  PRIMARY_MEDIUM_FONT,
  PRIMARY_REGULAR_FONT,
} from "constants/style/fonts";
import { BLACK_COLOR } from "constants/style/color";
import {
  CancelInput,
} from "pages/location/components/add-location/styles";

export const MainDiv = styled.div`
  padding: 1.5rem 2rem;
`;

export const LeftDiv = styled.div`
  display: flex;
`;

export const RightDiv = styled.div`
  width: 25%;
`;

export const Heading = styled.p`
  font-size: ${COMPONENT_HEADING};
  margin: 0;
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-weight: bold;
  user-select: none;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  /* margin: 3rem 0 1rem 0.5rem !important; */
`;

export const CancelButton = styled(Button)`
  width: 100px;
  height: 2.8rem;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 1rem !important;
`;

export const InputFieldContainer = styled(Paper)`
  padding: 1.5rem 3rem 1rem 2rem;
  margin-top: 1rem;
  min-height: 60vh !important;
`;
export const NavContainer = styled.div`
`;

export const InputLabelText = styled(InputLabel)`
  margin-bottom: 2%;
  color: ${BLACK_COLOR} !important;
  font-size: ${PARAGRAPH_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;

export const InputTextField = styled(TextField)`
  width: 18rem;
`;

export const InputTextFieldParent = styled(TextField)`
  width: 18rem;
  margin-bottom:3% !important;
`;

export const InputTextFieldChild = styled(InputTextFieldParent)`
  margin-bottom:0% !important;
`;

export const FieldContainer = styled.div`
  margin-top: .5%;
  margin-bottom: 2%;
`;

export const FlexBoxArabicEO = styled.div`
  display: flex;
`;

export const CancelInputEO = styled(CancelInput)`
  margin: 0.5rem 1.6rem;
`;
