import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import MenuItem from "@material-ui/core/MenuItem";
import { useSnackbar } from "notistack";

import CustomRichTextEditor from "shared-components/rich-text-editor";
import LoaderButton from "shared-components/loader-button";
import AddImage from "shared-components/icons/add-image";

import { LoadingComp } from "pages/news/components/news-details/styles";
import MaterialUIPickers from "pages/best-deals/components/date-time";

import { URL_VERIFY_REGEX } from "constants/regex-validations-constants";
import { EMPTY_INPUT } from "constants/snackbar-messages-constants";
import { LATEST_BLOGS } from "constants/route/route-constants";
import { loadingHelper } from "constants/loadingHelper";
import selectedBrand from "constants/brand";

import {
  DROPDOWN_MENU_PROPS,
  loaderBtnStyleFunc,
  TEXTFIELD_FONTSIZE_INPUT_PROPS,
} from "constants/style/props";

import {
  ALL_PLUGINS,
  QUICK_DESC_BLOCK,
  QUICK_INSERT_TOOLBOX_DECS,
  TOOLBAR_FOR_DESCRIPTION,
} from "constants/text-editor-tools";

import {
  RemoveImageLogo,
  ChildImage,
  CancelButton,
  ImageFlexContainer,
  TextWrapper,
  Small,
} from "pages/location/components/add-location/styles";

import {
  LocationContainer,
  LeftDiv,
  RightDiv,
  HeaderDiv,
  HeadingDiv,
  ParentContainer,
  FormContainer,
  FormLeftSide,
  FormRightSide,
  FieldContainer,
  InputLabelText,
  InputTextField,
  DropdownSelector,
  ImageInput,
  ErrorHelperText,
  Required,
  FlexBoxArabicDeals,
  CancelInputDescription,
  ImgWholeContainer,
  ChildImageContainer,
  AddImageContainer,
  FlexBoxArabicEditor,
  RichTextEditorDiv,
  InnerFlexEditorCancelBtn,
  CancelIcon,
  RightFormFlex,
  FlexOne,
  FlexTwo,
} from "./styles";

import { BlogDetails } from "interfaces/latest-blogs.interfaces";
import {
  addNewBlogThunk,
  getBlogByIdThunk,
  setCurrentBlogReducer,
  updateBlogInfoThunk,
} from "slices/latest-blogs";

const userArr = ["All", "Owner", "Guest"];
const statusArr = [
  { name: "Active", value: "Active" },
  { name: "Inactive", value: "In-Active" },
];
// const badgeLocationArr = ["Top right corner", "Top left corner", "Top center"];
const linkToArr = [
  "None",
  "Service Booking",
  "Test Drive Booking",
  "Roadside assistance",
];

const displaySectionArr = ["Hamburger Menu", "Home", "Hidden"];

const errorObj = {
  title: "Title",
  userType: "User Type",
  endTime: "End Time",
  titleArabic: "Title",
  textArabic: "Sub Text",
  descriptionArabic: "Description",
  linkUrl: "Link URL",
  externalUrl: "External URL",
  linkName:"Link Name",
  linkNameArabic:"Link Name"
};

const newBlogState: BlogDetails = {
  id: null,
  userType: "Select",
  status: statusArr[0].value,
  title: "",
  titleArabic: null,
  subText: "",
  subTextArabic: null,
  externalUrl: "",
  linkName: "",
  linkNameArabic: null,
  linkUrl: "",
  description: "",
  descriptionArabic: null,
  startTime: `${new Date()}`,
  endTime: `${new Date()}`,
  brand: selectedBrand(),
  images: [],
  linkTo: linkToArr[0],
  displaySection: displaySectionArr[0],
};

function NewBlog(): ReactElement {
  const dispatch = useAppDispatch();
  const location = useLocation<any>();
  const history = useHistory<any>();
  const { enqueueSnackbar } = useSnackbar();

  const [blogDetails, setBlogDetails] = useState(
    JSON.parse(JSON.stringify(newBlogState))
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [isUrlDisable, setIsUrlDisable] = useState<boolean>(true);
  const [isDateDisable, setIsDateDisable] = useState<boolean>(true);
  const [imageList, setImageList] = useState<string[]>([]);
  let [dateFlag, setDateFlag] = useState(false);

  const [inputErrors, setErrors] = useState({
    titleError: "",
    userTypeError: "",
    endTimeError: "",
    titleArabicError: "",
    linkUrlError: "",
    externalUrlError: "",
    linkNameError: "",
    linkNameArabicError: "",
  });

  const latestBlogs = useAppSelector((state) => state.blogs.blogsInfo);

  // for redirection only when edit and copy deal screen ( brand switches )
  const ref = useRef<any>();
  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });

  useEffect(() => {
    if (
      location?.state?.detail === "edit" ||
      location?.state?.detail === "copy"
    ) {
      if (ref.current !== undefined && ref.current !== brandName) {
        return history.push(LATEST_BLOGS);
      }
      ref.current = brandName;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName, history]);

  useEffect(() => {
    if (
      location?.state?.detail === "edit" ||
      location?.state?.detail === "copy"
    ) {
      loadingHelper(
        dispatch(getBlogByIdThunk(location?.state?.rowId)),
        setLoading,
        enqueueSnackbar
      );
    } else {
      dispatch(setCurrentBlogReducer({ ...newBlogState }));
    }
    // eslint-disable-next-line
  }, [location?.state?.detail, dispatch]);

  useEffect(() => {
    if (
      location?.state?.detail === "edit" ||
      location?.state?.detail === "copy"
    ) {
      var emt: any = [];
      latestBlogs?.currentBlog?.images?.forEach((item) => {
        emt.push(item);
      });
      setImageList(emt);
      setBlogDetails(JSON.parse(JSON.stringify(latestBlogs?.currentBlog)));
    }
    checkLinkTo(latestBlogs?.currentBlog?.linkTo);
    checkStartDate(latestBlogs?.currentBlog?.status);

    // eslint-disable-next-line
  }, [latestBlogs?.currentBlog]);

  const {
    id,
    userType,
    status,
    title,
    subText,
    linkName,
    linkUrl,
    externalUrl,
    description,
    startTime,
    endTime,
    titleArabic,
    subTextArabic,
    descriptionArabic,
    linkNameArabic,
    linkTo,
    displaySection,
    // images,
  } = blogDetails;

  const {
    titleError,
    userTypeError,
    endTimeError,
    titleArabicError,
    linkUrlError,
    linkNameError,
    linkNameArabicError,
    // externalUrlError,
  } = inputErrors;

  const checkLinkTo = (value) => {
    if (value === "None") {
      setIsUrlDisable(false);
    } else {
      setIsUrlDisable(true);
    }
  };

  const checkStartDate = (value) => {
    if (value === "In-Active") {
      setIsDateDisable(false);
    } else {
      setIsDateDisable(true);
    }
  };

  const handelArabicInputShow = (prop) => {
    blogDetails[prop] = "";
    setBlogDetails({ ...blogDetails });
  };

  const handelArabicInputHide = (prop) => {
    blogDetails[prop] = null;
    if (prop === "titleArabic" || prop === "linkNameArabic") {
      inputErrors[prop + "Error"] = "";
      setErrors({ ...inputErrors });
    }
    setBlogDetails({ ...blogDetails });
  };

  const handelChangeArabic = (prop) => (e) => {
    const { value } = e.target;
    blogDetails[prop] = value;
    setBlogDetails({ ...blogDetails });

    if (prop === "titleArabic" || prop === "linkNameArabic") {
      if (value === "") {
        inputErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        inputErrors[prop + "Error"] = "";
      }
    }

    setErrors({ ...inputErrors });
  };

  const imageHandler = (e) => {
    const files = e.target.files;
    Array.from(files).forEach((file, ind) => {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[ind]);
      reader.onload = function () {
        imageList.push(reader.result as string);
        setImageList([...imageList]);
      };
    });
    // console.log(imageList, "listImage");
  };

  const handelRemoveImage = (index) => {
    var filtered = imageList?.filter((item, ind) => ind !== index);
    setImageList([...filtered]);
  };

  const handleInputChange = (prop) => (e) => {
    const { value } = e?.target;

    if (prop === "title" || prop==="linkName") {
      setBlogDetails({ ...blogDetails, [prop]: value });
      if (value === "") {
        inputErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        inputErrors[prop + "Error"] = "";
      }
      setErrors({ ...inputErrors });
    } else if (prop === "userType") {
      blogDetails[prop] = value;
      setBlogDetails({ ...blogDetails });
      if (value === "Select") {
        inputErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        inputErrors[prop + "Error"] = "";
      }
      setErrors({ ...inputErrors });
    } else if (prop === "linkUrl" || prop === "externalUrl") {
      setBlogDetails({ ...blogDetails, [prop]: value });
      if (value === "") {
        inputErrors[prop + "Error"] = ``;
      } else {
        const reg = new RegExp(URL_VERIFY_REGEX).test(value);
        if (!reg) {
          inputErrors[prop + "Error"] = errorObj[prop] + ` is invalid`;
        } else {
          inputErrors[prop + "Error"] = "";
        }
        setErrors({ ...inputErrors });
      }
    } else if (prop === "linkTo") {
      blogDetails[prop] = value;
      setBlogDetails({ ...blogDetails });
      checkLinkTo(value);
      if (value !== "None") {
        blogDetails["linkUrl"] = "";
        blogDetails["externalUrl"] = "";
        setBlogDetails({ ...blogDetails });
      }
    } else if (prop === "status") {
      blogDetails[prop] = value;
      setBlogDetails({ ...blogDetails });
      checkStartDate(value);
      if (value === "Active") {
        blogDetails["startTime"] = `${new Date()}`;
        setBlogDetails({ ...blogDetails });
      }
      // console.log(dealDetails);
    } else {
      blogDetails[prop] = value;
      setBlogDetails({ ...blogDetails });
    }
    // console.log(blogDetails);
  };

  const handleEditorChange = (content, e, key) => {
    blogDetails[key] = content;
    setBlogDetails({ ...blogDetails });
  };

  const handelEndTimeChange = (date) => {
    blogDetails[`endTime`] = date;
    setBlogDetails({ ...blogDetails });
    if (new Date(startTime as string) >= new Date(date)) {
      inputErrors.endTimeError = `${errorObj["endTime"]} must be greater than start!!`;
      setDateFlag(false);
    } else {
      inputErrors.endTimeError = "";
      setDateFlag(true);
    }
    setErrors({ ...inputErrors });
    // console.log(blogDetails);
  };

  const handelStartTimeChange = (date) => {
    blogDetails["startTime"] = date;
    setBlogDetails({ ...blogDetails });

    if (new Date(date) >= new Date(endTime as string)) {
      inputErrors.endTimeError = `${errorObj["endTime"]} must be greater than start!!`;
      setDateFlag(false);
    } else {
      inputErrors.endTimeError = "";
      setDateFlag(true);
    }
    setErrors({ ...inputErrors });
    // console.log(blogDetails);
  };

  const checkLinkUrlExternalUrl = () => {
    var linkUrlFlag = true;
    var externalUrlFlag = true;

    if (linkUrl !== null && linkUrl !== "") {
      const reg = new RegExp(URL_VERIFY_REGEX).test(linkUrl as string);
      if (!reg) {
        inputErrors.linkUrlError = errorObj["linkUrl"] + ` is invalid`;
        linkUrlFlag = false;
      }
      setErrors({ ...inputErrors });
    }
    if (externalUrl !== null && externalUrl !== "") {
      const reg = new RegExp(URL_VERIFY_REGEX).test(externalUrl as string);
      if (!reg) {
        inputErrors.externalUrlError = errorObj["externalUrl"] + ` is invalid`;
        externalUrlFlag = false;
      }
      setErrors({ ...inputErrors });
    }
    if (linkUrlFlag && externalUrlFlag) {
      return true;
    } else {
      return false;
    }
  };

  const handelSave = async () => {
    const urlCheckFlg = checkLinkUrlExternalUrl();
    // console.log(urlCheckFlg);
    if (titleError === "" && title === "") {
      inputErrors.titleError = `${errorObj["title"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }
    if (titleArabic === "") {
      inputErrors.titleArabicError = `${errorObj["titleArabic"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }    
    if (linkNameError === "" && linkName === "") {
      inputErrors.linkNameError = `${errorObj["linkName"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }
    if (linkNameArabic === "") {
      inputErrors.linkNameArabicError = `${errorObj["linkNameArabic"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }
    if (userTypeError === "" && userType === "Select") {
      inputErrors.userTypeError = `${errorObj["userType"]} ${EMPTY_INPUT}`;
      setErrors({ ...inputErrors });
    }

    if (new Date(startTime as string) >= new Date(endTime as string)) {
      inputErrors.endTimeError = `${errorObj["endTime"]} must be greater than start!!`;
      setErrors({ ...inputErrors });
      setDateFlag(false);
    } else {
      dateFlag = true;
      setDateFlag(dateFlag);
    }

    if (
      titleArabicError === "" &&
      userTypeError === "" &&
      titleError === "" &&
      title !== "" &&
      titleArabic !== "" &&
      userType !== "Select" &&
      endTimeError === "" &&
      urlCheckFlg &&
      linkName !== "" &&
      linkNameArabic !== "" &&
      linkNameError === "" &&
      linkNameArabicError === "" &&
      dateFlag
    ) {
      try {
        setBtnLoading(true);
        if (location?.state?.detail === "edit") {
          var result = await dispatch(
            updateBlogInfoThunk({ ...blogDetails, images: imageList })
          ).unwrap();
          enqueueSnackbar(result.data.status, {
            variant: "success",
          });
          setBtnLoading(false);
          history.push({
            pathname: LATEST_BLOGS,
            state: { from: "edit" },
          });
        } else {
          var newResult = await dispatch(
            addNewBlogThunk({ ...blogDetails, images: imageList })
          ).unwrap();
          enqueueSnackbar(newResult.data.status, {
            variant: "success",
          });
          setBtnLoading(false);
          history.push({
            pathname: LATEST_BLOGS,
            state: { from: "edit" },
          });
        }
      } catch (error: any) {
        setBtnLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    }
  };

  const handelCancel = () => {
    history.push({
      pathname: LATEST_BLOGS,
      state: { from: "edit" },
    });
  };

  return (
    <LocationContainer>
      <HeaderDiv>
        <LeftDiv>
          <HeadingDiv>{`${
            location?.state?.detail === "edit"
              ? "Edit Latest Blogs"
              : location?.state?.detail === "copy"
              ? "Copy Latest Blogs"
              : "New Blog"
          }`}</HeadingDiv>
        </LeftDiv>
        <RightDiv>
          <LoaderButton
            submitHandler={handelSave}
            isLoading={btnLoading}
            buttonText="Save"
            styles={loaderBtnStyleFunc("40%", "2.5rem")}
          />
          <CancelButton
            variant="contained"
            color="default"
            onClick={handelCancel}
          >
            Cancel
          </CancelButton>
        </RightDiv>
      </HeaderDiv>

      {loading ? (
        <LoadingComp color="primary" />
      ) : (
        <ParentContainer>
          <FormContainer>
            <FormLeftSide>
              <FieldContainer>
                <InputLabelText>ID Number</InputLabelText>
                <InputTextField
                  value={id}
                  variant="outlined"
                  size="small"
                  disabled
                  inputProps={{
                    style: { fontSize: 14, background: "#F0F0F0" },
                  }}
                />
              </FieldContainer>

              <FieldContainer>
                <InputLabelText>Display Section</InputLabelText>
                <DropdownSelector
                  onChange={handleInputChange("displaySection")}
                  variant="outlined"
                  value={displaySection && displaySection}
                  MenuProps={DROPDOWN_MENU_PROPS}
                >
                  {displaySectionArr.map((item, index) => {
                    return (
                      <MenuItem key={`${item}_${index}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </DropdownSelector>
              </FieldContainer>

              <FieldContainer>
                <InputLabelText>
                  User Type <Required>*</Required>
                </InputLabelText>
                <DropdownSelector
                  onChange={handleInputChange("userType")}
                  variant="outlined"
                  value={userType && userType}
                  error={Boolean(userTypeError)}
                  id="demo-sim-error"
                  MenuProps={DROPDOWN_MENU_PROPS}
                >
                  {" "}
                  <MenuItem value="Select" disabled>
                    Select
                  </MenuItem>
                  {userArr.map((item, index) => {
                    return (
                      <MenuItem key={`${item}_${index}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </DropdownSelector>
                {Boolean(userTypeError) && (
                  <ErrorHelperText>{userTypeError}</ErrorHelperText>
                )}
              </FieldContainer>

              <FieldContainer>
                <InputLabelText>
                  Title <Required>*</Required>
                </InputLabelText>
                <InputTextField
                  error={Boolean(titleError)}
                  helperText={titleError}
                  onChange={handleInputChange("title")}
                  variant="outlined"
                  size="small"
                  value={title}
                  inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                />
                {titleArabic === null && (
                  <TextWrapper>
                    <Small onClick={() => handelArabicInputShow("titleArabic")}>
                      Enter in arabic
                    </Small>
                  </TextWrapper>
                )}
              </FieldContainer>

              <FieldContainer>
                {typeof titleArabic === "string" && (
                  <>
                    <InputLabelText>
                      Title (Arabic)<Required>*</Required>
                    </InputLabelText>
                    <FlexBoxArabicDeals>
                      <InputTextField
                        error={Boolean(titleArabicError)}
                        helperText={titleArabicError}
                        onChange={handelChangeArabic("titleArabic")}
                        variant="outlined"
                        size="small"
                        dir="rtl"
                        value={titleArabic}
                        inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                      />
                      <CancelIcon
                        onClick={() => handelArabicInputHide("titleArabic")}
                      />
                    </FlexBoxArabicDeals>
                  </>
                )}
              </FieldContainer>

              <ImgWholeContainer>
                <InputLabelText>Banner Image</InputLabelText>
                <ImageFlexContainer>
                  <ImageInput
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={imageHandler}
                    id="inputBlog"
                  />
                  <AddImageContainer htmlFor="inputBlog">
                    <AddImage />
                  </AddImageContainer>
                  {imageList?.length !== 0 &&
                    imageList?.map((item, index) => (
                      <ChildImageContainer key={`images_${index}`}>
                        <ChildImage src={item} alt={index + " BlogImage"} />
                        <RemoveImageLogo
                          onClick={() => handelRemoveImage(index)}
                        />
                      </ChildImageContainer>
                    ))}
                </ImageFlexContainer>
              </ImgWholeContainer>
            </FormLeftSide>

            <RightFormFlex>
              <FlexOne>
                <FormRightSide>
                  <FieldContainer>
                    <InputLabelText>Status</InputLabelText>
                    <DropdownSelector
                      onChange={handleInputChange("status")}
                      variant="outlined"
                      value={status && status}
                      MenuProps={DROPDOWN_MENU_PROPS}
                    >
                      {statusArr.map((item, index) => {
                        return (
                          <MenuItem key={`${item}_${index}`} value={item.value}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </DropdownSelector>
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabelText>
                      Start time <Required>*</Required>
                    </InputLabelText>
                    <MaterialUIPickers
                      handelChangeParent={handelStartTimeChange}
                      time={`${latestBlogs?.currentBlog?.startTime}`}
                      disabled={isDateDisable}
                    />
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabelText>
                      End time <Required>*</Required>
                    </InputLabelText>
                    <MaterialUIPickers
                      errorType={endTimeError}
                      handelChangeParent={handelEndTimeChange}
                      time={`${latestBlogs?.currentBlog?.endTime}`}
                    />
                    {Boolean(endTimeError) && (
                      <ErrorHelperText>{endTimeError}</ErrorHelperText>
                    )}
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabelText>Sub text</InputLabelText>
                    <InputTextField
                      onChange={handleInputChange("subText")}
                      variant="outlined"
                      size="small"
                      value={subText}
                      inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                    />
                    {subTextArabic === null && (
                      <TextWrapper>
                        <Small
                          onClick={() => handelArabicInputShow("subTextArabic")}
                        >
                          Enter in arabic
                        </Small>
                      </TextWrapper>
                    )}
                  </FieldContainer>

                  <FieldContainer>
                    {typeof subTextArabic === "string" && (
                      <>
                        <InputLabelText>Sub text (Arabic)</InputLabelText>
                        <FlexBoxArabicDeals>
                          <InputTextField
                            onChange={handelChangeArabic("subTextArabic")}
                            variant="outlined"
                            size="small"
                            dir="rtl"
                            value={subTextArabic}
                            inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                          />
                          <CancelIcon
                            onClick={() =>
                              handelArabicInputHide("subTextArabic")
                            }
                          />
                        </FlexBoxArabicDeals>
                      </>
                    )}
                  </FieldContainer>
                </FormRightSide>

                <FormRightSide>
                  <FieldContainer>
                    <InputLabelText>Link to</InputLabelText>
                    <DropdownSelector
                      onChange={handleInputChange("linkTo")}
                      variant="outlined"
                      value={linkTo && linkTo}
                      MenuProps={DROPDOWN_MENU_PROPS}
                    >
                      {linkToArr.map((item, index) => {
                        return (
                          <MenuItem key={`${item}_${index}`} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </DropdownSelector>
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabelText>Link URL</InputLabelText>
                    <InputTextField
                      onChange={handleInputChange("linkUrl")}
                      variant="outlined"
                      size="small"
                      error={Boolean(linkUrlError)}
                      helperText={linkUrlError}
                      value={linkUrl}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          background: `${isUrlDisable ? "#F0F0F0" : ""}`,
                        },
                      }}
                      disabled={isUrlDisable}
                    />
                  </FieldContainer>

                  {/* <FieldContainer>
                    <InputLabelText>External URL</InputLabelText>
                    <InputTextField
                      onChange={handleInputChange("externalUrl")}
                      variant="outlined"
                      size="small"
                      error={Boolean(externalUrlError)}
                      helperText={externalUrlError}
                      value={externalUrl}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          // background: `${isUrlDisable ? "#F0F0F0" : ""}`,
                          background: "#F0F0F0",
                        },
                      }}
                      // disabled={isUrlDisable}
                      disabled
                    />
                  </FieldContainer> */}

                  <FieldContainer>
                    <InputLabelText>Link Name</InputLabelText>
                    <InputTextField
                      error={Boolean(linkNameError)}
                      helperText={linkNameError}
                      onChange={handleInputChange("linkName")}
                      variant="outlined"
                      size="small"
                      value={linkName}
                      inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                    />
                    {linkNameArabic === null && (
                      <TextWrapper>
                        <Small
                          onClick={() =>
                            handelArabicInputShow("linkNameArabic")
                          }
                        >
                          Enter in arabic
                        </Small>
                      </TextWrapper>
                    )}
                  </FieldContainer>

                  <FieldContainer>
                    {typeof linkNameArabic === "string" && (
                      <>
                        <InputLabelText>Link Name (Arabic)</InputLabelText>
                        <FlexBoxArabicDeals>
                          <InputTextField
                            error={Boolean(linkNameArabicError)}
                            helperText={linkNameArabicError}
                            onChange={handelChangeArabic("linkNameArabic")}
                            variant="outlined"
                            size="small"
                            dir="rtl"
                            value={linkNameArabic}
                            inputProps={TEXTFIELD_FONTSIZE_INPUT_PROPS}
                          />
                          <CancelIcon
                            onClick={() =>
                              handelArabicInputHide("linkNameArabic")
                            }
                          />
                        </FlexBoxArabicDeals>
                      </>
                    )}
                  </FieldContainer>
                </FormRightSide>
              </FlexOne>
              <FlexTwo>
                <FieldContainer>
                  <InputLabelText>Description</InputLabelText>
                  <RichTextEditorDiv>
                    <CustomRichTextEditor
                      pluginArray={ALL_PLUGINS}
                      customToolBar={TOOLBAR_FOR_DESCRIPTION}
                      quickAccessToolbar={QUICK_DESC_BLOCK}
                      editorState={description && description}
                      handelChangeEditorState={(content, e) =>
                        handleEditorChange(content, e, "description")
                      }
                      quickInsertToolbar={QUICK_INSERT_TOOLBOX_DECS}
                      editorHeight={180}
                      toolbarMode="wrap"
                      directionality="ltr"
                    />
                  </RichTextEditorDiv>

                  {descriptionArabic === null && (
                    <TextWrapper>
                      <Small
                        onClick={() =>
                          handelArabicInputShow("descriptionArabic")
                        }
                      >
                        Enter in arabic
                      </Small>
                    </TextWrapper>
                  )}
                </FieldContainer>

                <FieldContainer>
                  {typeof descriptionArabic === "string" && (
                    <>
                      <InputLabelText>Description (Arabic)</InputLabelText>
                      <FlexBoxArabicEditor>
                        <InnerFlexEditorCancelBtn>
                          <CustomRichTextEditor
                            pluginArray={ALL_PLUGINS}
                            customToolBar={TOOLBAR_FOR_DESCRIPTION}
                            quickAccessToolbar={QUICK_DESC_BLOCK}
                            editorState={descriptionArabic && descriptionArabic}
                            handelChangeEditorState={(content, e) =>
                              handleEditorChange(
                                content,
                                e,
                                "descriptionArabic"
                              )
                            }
                            quickInsertToolbar={QUICK_INSERT_TOOLBOX_DECS}
                            editorHeight={180}
                            toolbarMode="wrap"
                            directionality="rtl"
                          />
                        </InnerFlexEditorCancelBtn>

                        <CancelInputDescription
                          onClick={() =>
                            handelArabicInputHide("descriptionArabic")
                          }
                        />
                      </FlexBoxArabicEditor>
                    </>
                  )}
                </FieldContainer>
              </FlexTwo>
            </RightFormFlex>
          </FormContainer>
        </ParentContainer>
      )}
    </LocationContainer>
  );
}

export default NewBlog;
