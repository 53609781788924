import React, { ReactElement } from "react";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste/plugin";
import "tinymce/plugins/print/plugin";
import "tinymce/plugins/link/plugin";
import "tinymce/plugins/image/plugin";
import "tinymce/plugins/advlist/plugin";
import "tinymce/plugins/quickbars/plugin";
import "tinymce/plugins/directionality/plugin";
import "tinymce/plugins/imagetools/plugin";
import "tinymce/plugins/preview/plugin";
import "tinymce/plugins/table/plugin";
import "tinymce/plugins/media/plugin";
import "tinymce/plugins/emoticons/plugin";
import "tinymce/plugins/emoticons/js/emojis";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import { SUBHEADER_FONT_SIZE } from "constants/style/font-size";
import { FONT_LIST } from "constants/text-editor-tools";

interface Props {
  quickAccessToolbar?: string;
  customToolBar?: string;
  pluginArray?: string[];
  editorState?: string |null;
  handelChangeEditorState?: (content: any, editor: any) => void;
  editorHeight?: number;
  toolbarMode?:any;
  directionality?:any;
  quickInsertToolbar?:any
}

function CustomRichTextEditor({
  quickAccessToolbar,
  customToolBar,
  pluginArray,
  editorState,
  editorHeight,
  toolbarMode,
  directionality,
  quickInsertToolbar,
  handelChangeEditorState,
}: Props): ReactElement {
  //   const [contentEditor, setContentEditor] = useState(
  //     "<p>This is the initial content of the editor</p>"
  //   );
  //   const handleEditorChange = (content, editor) => {
  //     setContentEditor(content);
  //   };

  return (
    <Editor
      // initialValue="<p>This is the initial content of the editor</p>"
      init={{
        skin: false,
        content_css: false,
        height: editorHeight? editorHeight:300,
        directionality:directionality?directionality:"ltr",
        menubar: false,
        plugins: pluginArray,
        toolbar: customToolBar,
        font_formats:FONT_LIST,
        content_style: `body { font-family:${PRIMARY_REGULAR_FONT}; font-size:${SUBHEADER_FONT_SIZE} } 
          blockquote {
            border-left: 5px solid #CDD0CB;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: .1em;
            margin: 2.5em auto;
            max-width: 800px;
            padding: 0 2rem;
            // text-transform: uppercase;
          }
          `,
        quickbars_selection_toolbar: quickAccessToolbar,
        quickbars_insert_toolbar: quickInsertToolbar?quickInsertToolbar:'',
        toolbar_mode:toolbarMode?toolbarMode:'sliding',
        branding: false,
        default_link_target:"_blank",
        link_default_protocol: 'https',
      }}
      value={editorState as string}
      onEditorChange={handelChangeEditorState}
    />
  );
}

export default CustomRichTextEditor;
// const absoluteUrl = new URL("images" in params.row? params.row.images: params.row.image).href