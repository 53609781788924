import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { PRIMARY_MEDIUM_FONT } from "constants/style/fonts";
import {
  ServiceBookingContainer,
  WidgetName,
  BarChartGraph,
  WidgetMessage,
} from "pages/dashboard/components/customers-left-widgets/service-bookings/styles";

interface Props {
  chartColors: Array<string>;
}

function ServiceBookings({ chartColors }: Props): ReactElement {
  const [graphData, setGraphData] = useState<Array<number>>([]);
  const [graphDatasetTwo, setDatasetTwo] = useState<Array<number>>([]);

  const dashboardDetails = useAppSelector((state: RootState) => {
    return state.dashboardDetails.dashboardInfo;
  });
  const userDetails = useAppSelector((state: RootState) => {
    return state.UserProfile.userProfileInfo;
  });
  useEffect(() => {
    setGraphData(
      dashboardDetails?.dashboardDetails?.serviceBookigs?.data1 as []
    );
    setDatasetTwo(
      dashboardDetails?.dashboardDetails?.serviceBookigs?.data2 as []
    );
  }, [
    dashboardDetails?.dashboardDetails?.serviceBookigs?.data1,
    dashboardDetails?.dashboardDetails?.serviceBookigs?.data2,
  ]);

  const data = {
    labels: dashboardDetails?.dashboardDetails?.serviceBookigs?.labels,
    datasets: [
      {
        label: "Completed",
        data: graphData,
        backgroundColor: chartColors[0],
      },
      {
        label: "Cancelled",
        data: graphDatasetTwo,
        backgroundColor: chartColors[1],
        // data: [0, 0, 0, 0, 0],
      },
    ],
  };
  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Weeks",
          font: {
            size: 14,
            family: PRIMARY_MEDIUM_FONT,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
          font: {
            size: 14,
            family: PRIMARY_MEDIUM_FONT,
          },
        },
      },
    },
    events: ['mousemove', 'mouseout'],
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
        },
      },
    },
    maintainAspectRatio: false,
  };
  const checkValue = () => {
    const isBelowThreshold = (currentValue) => currentValue === 0;
    return (
      graphData?.every(isBelowThreshold) &&
      graphDatasetTwo.every(isBelowThreshold)
    );
  };

  return (
    <React.Fragment>
      {userDetails?.basicInfo?.moduleList?.includes("service-bookings") ? (
        <ServiceBookingContainer>
          <WidgetName>Service Bookings</WidgetName>
          {!checkValue() ? (
            <BarChartGraph
              data={data}
              type="bar"
              height={280}
              options={options}
            />
          ) : (
            <WidgetMessage>No Service Bookings</WidgetMessage>
          )}
        </ServiceBookingContainer>
      ) : null}
    </React.Fragment>
  );
}

export default ServiceBookings;
