import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "redux-store/hooks";
import { useSnackbar } from "notistack";
import { MenuItem } from "@material-ui/core";

import ArabicSwitchDropdown from "shared-components/arabic-input/arabic-switch-dropdown";
import TabSelector from "shared-components/tab-selector";
import CustomPopover from "shared-components/custom-filter-popup";
import CustomTabSelector from "shared-components/custom-tab-selector";
import CheckboxContainer from "shared-components/checkbox-container";
import DateRange from "shared-components/date-range";

import { EDIT_EMERGENCY_OPTIONS } from "constants/route/route-constants";
import { DROPDOWN_MENU_PROPS, limitArr } from "constants/style/props";
import { LANGUAGE_FLAG_ARRAY } from "constants/style/props";

import RoadSideAssistanceTable from "pages/services/roadside-assistance/components/roadside-assistance-table";
import {
  DropdownDiv,
  LimitDiv,
  SpanTag,
} from "pages/customers/components/customers-table/styles";
import { DropdownSelector } from "pages/best-deals/components/new-deal/styles";

import BahrinFlag from "assets/bahrin-flag.png";
import UkFlag from "assets/uk-flag.png";

import { getEmergencyOptions } from "services/services/roadside-assistance-services";
import {
  getRoadsideAssistanceByFilter,
  getRoadsideAssistanceThunk,
  setCurrentPageReducer,
} from "slices/services/road-side-assistance";

import { RemoveFilter } from "../test-drive-bookings/styles";
import {
  CustomerContainer,
  Heading,
  ExportToExcel,
  FilterDiv,
  LeftDiv,
  RightDiv,
  FilterIcon,
  ExportIcon,
  SubHeading,
  FilterHeading,
  HeaderDiv,
  ArabicSwitch,
  OptionsButton,
  RelativeDiv,
} from "pages/services/roadside-assistance/styles";
import { TabSelectorContainer } from "pages/customers/styles";

interface filterInterface {
  id: number;
  label: string;
  englishMessage:string;
  status: boolean;
  type?: string;
}
const initState = {
  startDate: "",
  endDate: "",
  message: "",
};

function RoadSideAssistance(): ReactElement {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [exportData, setExportData] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [emergencyType, setEmergencyType] = useState<Array<filterInterface>>(
    []
  );
  const [filterState, setFilterState] = useState(
    JSON.parse(JSON.stringify(initState))
  );
  const [dummyFilterState] = useState(JSON.parse(JSON.stringify(initState)));
  const [anchorElSwitch, setAnchorElSwitch] = useState<null | HTMLElement>(
    null
  );
  const [selectedLang, setSelectedLang] = useState(UkFlag);
  const [filterClear, setFilterClear] = useState<boolean>(false);
  const [Language, setLanguage] = useState("English");
  const [recordLimit, setRecordLimit] = useState<number>(limitArr[0]);

  const languageArr = LANGUAGE_FLAG_ARRAY;

  const brandColor = useAppSelector((state) => state.brand.brandColor);
  const roadsideDetails = useAppSelector(
    (state) => state.roadsideDetails.roadsideAssistanceInfo
  );
  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });
  const { startDate, endDate } = filterState;

  const handleClickSwitch = () => {
    const elem = document.getElementById("flagDropdown");
    setAnchorElSwitch(elem);
  };

  const handleCloseSwitch = () => {
    setAnchorElSwitch(null);
  };

  const handelExport = () => {
    setExportData((prevExportData) => !prevExportData);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguage = (label) => {
    const newFlag = label === "English" ? UkFlag : BahrinFlag;
    setSelectedLang(newFlag);
    setAnchorElSwitch(null);
    setLanguage(label);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDate = (e) => {
    const { id, value } = e.target;
    filterState[id] = value;
    setFilterState({ ...filterState });

    checkIsFilterCleared();
  };

  useEffect(() => {
    getEmergencyOptionsApi();
    // eslint-disable-next-line
  }, [brandName, selectedLang]);

  useEffect(() => {
    if (JSON.stringify(filterState) !== JSON.stringify(dummyFilterState)) {
      setFilterClear(true);
      dispatch(
        getRoadsideAssistanceByFilter({
          ...filterState,
          pageNumber: 1,
          limit: recordLimit,
        })
      )
        .unwrap()
        .then((res) => {
          if ("data" in res.data) {
            enqueueSnackbar(res.data.data, { variant: "warning" });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.data, { variant: "error" });
        });
      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line
  }, [filterState]);

  const checkIsFilterCleared = () => {
    // console.log(JSON.stringify(filterState) === JSON.stringify(dummyFilterState),"flag")
    if (JSON.stringify(filterState) === JSON.stringify(dummyFilterState)) {
      dispatch(
        getRoadsideAssistanceThunk({ pageNumber: 1, limit: recordLimit })
      );
      setFilterClear(false);
    }
  };

  const filterFunc = (pageNumber) => {
    dispatch(
      getRoadsideAssistanceByFilter({
        ...filterState,
        pageNumber: pageNumber,
        limit: recordLimit,
      })
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const result = emergencyType?.map((data) => {
      if (parseInt(value) === data.id) {
        data.status = !data.status;
      }
      return data;
    });
    setEmergencyType([...result]);
    const emergencyMessage: Array<string> = [];
    result.map((data) => {
      if (data?.status) {
        emergencyMessage?.push(data.englishMessage);
      }
      return data;
    });

    filterState["message"] = emergencyMessage.join(",");
    setFilterState({ ...filterState });
    checkIsFilterCleared();
  };

  const handleClearFilter = () => {
    dispatch(
      getRoadsideAssistanceThunk({
        pageNumber: roadsideDetails.currentPage,
        limit: recordLimit,
      })
    );
    setFilterClear(false);
    setFilterState(JSON.parse(JSON.stringify(initState)));
    getEmergencyOptionsApi();
  };

  const redirectToEdit = () => {
    history.push(EDIT_EMERGENCY_OPTIONS);
  };

  const getEmergencyOptionsApi = async () => {
    try {
      const result = await getEmergencyOptions();
      if (result?.data) {
        const { messageList } = result?.data?.data;
        var emt: any = [];

        messageList.forEach((item, index) => {
          emt.push({
            id: index,
            label: Language === "English" ? item.message : item.arabicMessage,
            englishMessage:item.message,
            status: false,
          });
        });
        setEmergencyType([...emt]);
      }
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
    }
  };

  const handleLimitChange = (e) => {
    const { value } = e?.target;
    setRecordLimit(value);
  };

  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <Heading>Roadside Assistance</Heading>
          <ArabicSwitch>
            <ArabicSwitchDropdown
              selectedLang={selectedLang}
              handleClick={handleClickSwitch}
              anchorEl={anchorElSwitch}
              languageArr={languageArr}
              handleClose={handleCloseSwitch}
              handleLanguage={handleLanguage}
            />
          </ArabicSwitch>
        </LeftDiv>
        <RightDiv>
          <OptionsButton
            color="primary"
            onClick={redirectToEdit}
            variant="contained"
          >
            Emergency Options
          </OptionsButton>
          <ExportToExcel onClick={handelExport}>
            <ExportIcon color={brandColor} />
            <SubHeading>Export to Excel</SubHeading>
          </ExportToExcel>
          <RelativeDiv>
            <FilterDiv onClick={handleClick}>
              <FilterIcon />
              <FilterHeading>Filter</FilterHeading>
            </FilterDiv>
            {filterClear && <RemoveFilter onClick={handleClearFilter} />}
          </RelativeDiv>
        </RightDiv>
      </HeaderDiv>

      <CustomPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClose={handleClose}
        childComp={
          <CustomTabSelector
            labelArray={["Emergency", "Date Range"]}
            children={
              [
                <CheckboxContainer
                  checkboxLabelArr={emergencyType}
                  handleChange={handleChange}
                />,
                <DateRange
                  handleDate={handleDate}
                  endDate={endDate}
                  startDate={startDate}
                  styles={{ width: "100%", padding: "1rem 1.7rem 1rem .7rem" }}
                />,
              ] as any
            }
          />
        }
      />

      <TabSelectorContainer>
        <LimitDiv top={0}>
          <SpanTag>Limit - </SpanTag>
          <DropdownDiv>
            <DropdownSelector
              onChange={handleLimitChange}
              variant="outlined"
              value={recordLimit}
              MenuProps={DROPDOWN_MENU_PROPS}
            >
              {limitArr.map((item, index) => {
                return (
                  <MenuItem key={`${item}_${index}`} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </DropdownSelector>
          </DropdownDiv>
        </LimitDiv>
        <TabSelector
          labelArray={["All"]}
          children={[
            <RoadSideAssistanceTable
              exportData={exportData}
              setExportData={setExportData}
              filterFlag={filterClear}
              filterFunc={filterFunc}
              selectedLang={Language}
              recordLimit={recordLimit}
              filterState={filterState}
            />,
          ]}
        />
      </TabSelectorContainer>
    </CustomerContainer>
  );
}

export default RoadSideAssistance;
