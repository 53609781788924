import styled from "styled-components";

import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "shared-components/icons/search-icon";
import Filter from "shared-components/icons/filter-icon";
import {
  COMPONENT_HEADING,
  PARAGRAPH_FONT_SIZE,
} from "constants/style/font-size";
import { PRIMARY_MEDIUM_FONT } from "constants/style/fonts";
import { PRIMARY_LEXUS, WHITE_COLOR } from "constants/style/color";

export const MainDiv = styled.div`
  padding: 1.5rem 2rem;
`;

export const Heading = styled.p`
  font-size: ${COMPONENT_HEADING};
  margin: 0;
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-weight: bold;
  user-select: none;
`;

export const FilterDiv = styled(Paper)`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.2rem;
  margin-left: 1rem !important;
  background: ${WHITE_COLOR};
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06) !important;
  border-radius: 10px;
  cursor: pointer;
`;

export const InputTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.color} !important;
  }
  margin-left: 2rem !important;
`;

export const Search = styled(SearchIcon)``;

export const LeftDiv = styled.div`
  display: flex;
`;

export const RightDiv = styled.div`
  display: flex;
  position: relative;
`;

export const FilterIcon = styled(Filter)`
  margin-right: 0.6rem;
`;


export const RemoveFilter = styled(CancelIcon)`
  position: absolute;
  top: -20%;
  right: -10%;
  color: ${({ theme }) => theme.color};
  cursor: pointer;
`;

export const SubHeading = styled.p`
  font-size: ${PARAGRAPH_FONT_SIZE};
  margin: 0;
  color: ${({ theme }) => theme.color};
  font-family: ${PRIMARY_MEDIUM_FONT};
  margin-left: 0.6rem;
`;

export const FilterHeading = styled(SubHeading)`
  color: ${PRIMARY_LEXUS};
  margin-left: 0;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
