import React, { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import { useSnackbar } from "notistack";

import { LoadingComp } from "pages/news/components/news-details/styles";

import PaginationFooter from "shared-components/pagination";

import { limit } from "constants/pagination-constants";


import {
  getCustomerById,
  getCustomersOwnedCarsThunk,
  getCustomersBookingHistoryThunk,
  setCurrentPageReducer,
} from "slices/customers";

import {
  CustomerCarList,
  CustomersBookingHistory,
} from "interfaces/customers.interface";

import {
  DetailsDiv,
  DetailsArrayContainer,
  BoxDiv,
  BoxDivRow,
  BoxCol,
  FlexChild,
  FlexDiv,
  FlexChildTwo,
  MappedDiv,
  PlaceholderImageDiv,
} from "./styles";

import ToyotaPlaceholder from "shared-components/icons/toyota-placeh";
import selectedBrand from "constants/brand";
import LexusPlaceholder from "shared-components/icons/lexus-placeh";
import MotorcityPlaceholder from "shared-components/icons/motorcity-placeh";

interface DataProps {
  //   data: Array<any> | object;
  data: string;
}

const imageObj = {
  Toyota: <ToyotaPlaceholder />,
  Lexus: <LexusPlaceholder />,
  motorcity: <MotorcityPlaceholder />,
};

const basicInfoObj = {
  id: "Customer ID",
  name: "Full Name",
  phoneNumber: "Mobile Number",
  email: "Email ID",
  crCprGccId: "CR/CPR/GCC ID number",
  address: "Address",
  nationality: "Nationality",
  role: "Type of User",
};

const ownedCarsObj = {
  modelName: "Car Model",
  vinNumber: "VIN Number",
  registrationDate: "Registration date",
  lastOdometerReading: "Last Odometer Reading",
  insuranceExpiration: "Insurance Expiration",
};

const bookingHistoryObj = {
  id: "Booking ID",
  serviceCenter: "Service Center",
  serviceAdvisor: "Service Advisor",
  carName: "Car For Service",
  serviceDate: "Date and Time",
  serviceType: "Service Type",
  email: "Email ID",
};

export default function DetailsComponent({ data }: DataProps): ReactElement {
  const location = useLocation<any>();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [detailsObj, setDetailsObj] = useState<object>();
  const [detailsArr, setDetailsArr] = useState<
    CustomerCarList[] | CustomersBookingHistory[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [paginationSize, setPaginationSize] = useState<number>(0);

  const customersData = useAppSelector((state) => state.customers.customerInfo);

  const { currentCustomer, ownedCars, carsServiceHistory, totalRecordsLength, currentPage } =
    customersData;
  
  useEffect(() => {
    setLoading(true);
    if (data === "currentCustomer") {
      dispatch(getCustomerById(location?.state?.userId))
        .unwrap()
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          // enqueueSnackbar("No data available to show", { variant: "error" });
        });
    } else if (data === "ownedCars") {
      dispatch(getCustomersOwnedCarsThunk(location?.state?.userId))
        .unwrap()
        .then((res) => {
          if ("response" in res?.data) {
            enqueueSnackbar(res?.data?.response, { variant: "warning" });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(err?.response?.data, { variant: "error" });
        });
    } else if (data === "bookingHistory") {
      dispatch(
        getCustomersBookingHistoryThunk({
          customerId: currentCustomer?.id,
          pageno: 1,
        })
      )
        .unwrap()
        .then((res) => {
          if ('data' in res?.data) {
            enqueueSnackbar(res?.data?.data, { variant: "warning" });
          } else if (!("listEntityClass" in res?.data)) {
            enqueueSnackbar("No data available to show", { variant: "warning" });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(err?.response?.data, { variant: "error" });
        });
      dispatch(setCurrentPageReducer(1));

    } else {
      enqueueSnackbar("Api integration pending..!!", { variant: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data === "currentCustomer") {
      setDetailsObj(currentCustomer);
    } else if (data === "ownedCars") {
      setDetailsArr(ownedCars);
    } else if (data === "bookingHistory") {
      setDetailsArr(carsServiceHistory);
      setPaginationSize(Math.ceil(totalRecordsLength / limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersData]);

  const handlePagination = (event, number) => {
    setLoading(true);
    dispatch(
      getCustomersBookingHistoryThunk({
        customerId: currentCustomer?.id,
        pageno: number,
      })
    )
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    dispatch(setCurrentPageReducer(number));
    console.log(detailsObj)
  };
  

  return (
    <DetailsDiv>
      {loading ? (
        <LoadingComp color="primary" />
      ) : (
        <DetailsArrayContainer>
          {data === "currentCustomer" ? (
            <BoxDiv>
              {currentCustomer &&
                Object.keys(basicInfoObj).map((key, ind) => {
                  if (key in basicInfoObj) {
                    return (
                      <FlexDiv key={`BasicInfo${ind}`}>
                        <FlexChild>{basicInfoObj[key]}</FlexChild>
                        <FlexChildTwo>{currentCustomer[key]}</FlexChildTwo>
                      </FlexDiv>
                    );
                  }
                  return null;
                })}
            </BoxDiv>
          ) : (
            <>
              {detailsArr?.length > 0 &&
                (data === "bookingHistory"
                  ? detailsArr.map((item, index) => (
                      <MappedDiv key={`bookingHistory${index}`}>
                        {
                          <BoxDiv>
                            {Object.keys(bookingHistoryObj).map((key, i) => {
                              if (key in bookingHistoryObj) {
                                if (key === "serviceDate") {
                                  return (
                                    <FlexDiv
                                      key={`${key}bookingHistoryRow${i}`}
                                    >
                                      <FlexChild>
                                        {bookingHistoryObj[key]}
                                      </FlexChild>
                                      <FlexChildTwo>
                                        {item[key]} | {item["serviceTime"]}
                                      </FlexChildTwo>
                                    </FlexDiv>
                                  );
                                } else {
                                  return (
                                    <FlexDiv
                                      key={`${key}bookingHistoryRow${i}`}
                                    >
                                      <FlexChild>
                                        {bookingHistoryObj[key]}
                                      </FlexChild>
                                      <FlexChildTwo>{item[key]}</FlexChildTwo>
                                    </FlexDiv>
                                  );
                                }
                              }
                              return null;
                            })}
                          </BoxDiv>
                        }
                      </MappedDiv>
                    ))
                  : detailsArr.map((item, index) => (
                      <MappedDiv key={`ownedCar${index}`}>
                        {
                          <BoxDivRow>
                            <BoxCol>
                              {Object.keys(ownedCarsObj).map((key, i) => {
                                if (key in ownedCarsObj) {
                                  if (key === "carName") {
                                    return (
                                      <FlexDiv key={`${key}ownedCarsRow${i}`}>
                                        <FlexChild>
                                          {ownedCarsObj[key]}
                                        </FlexChild>
                                        <FlexChildTwo>
                                          {item[key]} {item["modelNumber"]}
                                        </FlexChildTwo>
                                      </FlexDiv>
                                    );
                                  } else {
                                    return (
                                      <FlexDiv key={`${key}ownedCarsRow${i}`}>
                                        <FlexChild>
                                          {ownedCarsObj[key]}
                                        </FlexChild>
                                        <FlexChildTwo>{item[key]}</FlexChildTwo>
                                      </FlexDiv>
                                    );
                                  }
                                }
                                return null;
                              })}
                            </BoxCol>
                            <BoxCol>
                              {Boolean(item.carImage) ? (
                                <PlaceholderImageDiv>
                                  <img src={item.carImage} alt="" />
                                </PlaceholderImageDiv>
                              ) : (
                                <PlaceholderImageDiv>
                                  {imageObj[selectedBrand()]}
                                </PlaceholderImageDiv>
                              )}
                            </BoxCol>
                          </BoxDivRow>
                        }
                      </MappedDiv>
                    )))}

              {paginationSize > 1 ? (
                <PaginationFooter
                  handleChange={(event, value) => {
                    handlePagination(event, value);
                  }}
                  dataSize={paginationSize}
                  pageNumber={currentPage}
                />
              ) : null}
            </>
          )}
        </DetailsArrayContainer>
      )}
    </DetailsDiv>
  );
}
