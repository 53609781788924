import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { CsvBuilder } from "filefy";
import { format } from 'date-fns'
import {
  GridCellParams,
  GridColDef,
  GridRowModel,
} from "@material-ui/data-grid";

import { setCurrentPageReducer } from "slices/test-drive-bookings";
import {
  getTestDriveThunk,
  testDriveSearchThunk,
} from "slices/test-drive-bookings/test-drive-thunks";

import TableData from "shared-components/table-data";
import PaginationFooter from "shared-components/pagination";
import { RenderCellExpand } from "shared-components/expanded-cel";
import { TEST_DRIVE_BOOKING_DETAILS } from "constants/route/route-constants";
import { UserRoute } from "pages/customers/components/customers-table/styles";
import { loadingHelper } from "constants/loadingHelper";
import { useSnackbar } from "notistack";
import { TestDrive } from "interfaces/test-drive.interface";
import { getTestDriveDetails, testDriveFilter } from "services/test-drive-services";
import { limit } from "constants/pagination-constants";

interface Props {
  exportData?: boolean;
  setExportData?: any;
  searchValue: string;
  filterFlag: boolean;
  filterFunc: (pageNumber) => void;
  recordLimit: number;
  dateRange: any;
}

const TestDriveTable = ({
  exportData,
  setExportData,
  searchValue,
  filterFlag,
  filterFunc,
  recordLimit,
  dateRange,
}: Props) => {

  let allRecords: TestDrive[] = []
  let filteredRecords: TestDrive[] = []

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory<any>();

  const [rows, setRows] = useState<Array<GridRowModel>>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  const testDriveBookings = useAppSelector(
    (state) => state.testDriveBookings.testDriveInfo
  );

  useEffect(() => {
    if (filterFlag) {
      filterFunc(1);
    }
    else if (history?.location?.state?.from === "Back") {
      loadingHelper(
        dispatch(
          getTestDriveThunk({
            pageNumber: testDriveBookings?.currentPage,
            limit: recordLimit,
          })
        ),
        setLoading,
        enqueueSnackbar
      );
      const state = { ...history.location.state };
      delete state.from;
      history.replace({ ...history.location, state });
    } else {
      loadingHelper(
        dispatch(getTestDriveThunk({ pageNumber: 1, limit: recordLimit })),
        setLoading,
        enqueueSnackbar
      );
      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line
  }, [brandName, recordLimit, filterFlag]);

  useEffect(() => {
    setRows(testDriveBookings?.allTestDrive);
    setPaginationSize(Math.ceil(testDriveBookings.recordsLength / recordLimit));
    // eslint-disable-next-line
  }, [testDriveBookings]);

  const handlePagination = (event, number) => {
    if (filterFlag) {
      filterFunc(number);
    } else if (searchValue !== "") {
      dispatch(
        testDriveSearchThunk({
          pageNumber: number,
          searchText: searchValue,
        })
      );
    } else {
      loadingHelper(
        dispatch(getTestDriveThunk({ pageNumber: number, limit: recordLimit })),
        setLoading,
        enqueueSnackbar
      );
    }

    dispatch(setCurrentPageReducer(number));
  };

  const columns: GridColDef[] = [
    {
      field: "sfCaseNumber",
      headerName: "Booking ID",
      sortable: false,
      flex: 0.7,
      renderCell: (params: GridCellParams) => {
        const rowData = params.row;
        return (
          <UserRoute
            to={{
              pathname: TEST_DRIVE_BOOKING_DETAILS,
              state: {
                detail: "view",
                rowId: rowData?.sfCaseNumber,
                rowData: rowData,
              },
            }}
          >
            {rowData?.sfCaseNumber}
          </UserRoute>
        );
      },
    },
    {
      field: "driverName",
      headerName: "Customer Name",
      sortable: false,
      resizable: false,
      flex: 1,
      renderCell: RenderCellExpand,
    },
    {
      field: "mobileNumber",
      sortable: false,
      headerName: "Contact No.",
      resizable: false,
      flex: 0.7,
    },
    {
      field: "carModel",
      headerName: "Car Model Name",
      sortable: false,
      resizable: false,
      flex: 1,
    },
    {
      field: "testDriveTime",
      sortable: false,
      headerName: "Date and Time",
      resizable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.testDriveDate} | {params.row.testDriveTime}
          </>
        );
      },
    },
    {
      field: "showroom",
      sortable: false,
      headerName: "Showroom",
      resizable: false,
      flex: 1,
      renderCell: RenderCellExpand,
    },
    {
      field: "comments",
      headerName: "Comments",
      sortable: false,
      resizable: false,
      flex: 1,
      renderCell: RenderCellExpand,
    },
  ];

  const csvBuilder = (filename, rowsToCsv) => {
    const currentDateTime = format(new Date(), 'yyyy_MM_dd_HH_mm');
    const formattedFilename = `${filename}_${currentDateTime}.csv`;

    new CsvBuilder(formattedFilename)
      .setColumns(columns.map((col) => col?.headerName as string) as string[])
      .addRows(rowsToCsv as string[][])
      .exportFile();
  };

  /* const filterRowsData = (data) => {
    let res: Array<string> = [];
    columns.map((col) => {
      Object.entries(data).forEach(([key, value]) => {
        if (key === col.field) {
          res.push(value as string);
        }
      });
      return res;
    });
    return res;
  }; */

  const exportTestDriveData = async () => {
    try {
      let request = {
        pageNumber: 1,
        limit: recordLimit,
      }
      let response = await getTestDriveDetails(request)
      const totalRecords = response.data.totalRecords

      request = {
        pageNumber: 1,
        limit: Math.ceil(totalRecords),
      }
      response = await getTestDriveDetails(request)
      allRecords = response.data.listEntityClass
      console.log(allRecords)

      let allRecordsArray = allRecords.map(obj => [
        obj.id,
        obj.driverName,
        obj.mobileNumber,
        obj.carModel,
        `${obj.testDriveDate} | ${obj.testDriveTime}`,
        obj.showroom,
        obj.comments
      ]);

      csvBuilder('TestDrive_Booking', allRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  const exportFilteredTestDriveData = async () => {
    try {

      let request = {
        pageNumber: 1,
        limit: recordLimit,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }

      let response = await testDriveFilter(request)
      const filteredcCustomers = response.data.totalRecords

      request = {
        pageNumber: 1,
        limit: Math.ceil(filteredcCustomers),
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }
      response = await testDriveFilter(request)
      filteredRecords = response.data.listEntityClass
      console.log(filteredRecords)
      let filteredRecordsArray = filteredRecords.map(obj => [
        obj.id,
        obj.driverName,
        obj.mobileNumber,
        obj.carModel,
        `${obj.testDriveDate} | ${obj.testDriveTime}`,
        obj.showroom,
        obj.comments
      ]);
      csvBuilder('TestDrive_Booking', filteredRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  useEffect(() => {
    if (exportData) {
      if (!filterFlag) {
        exportTestDriveData()
      } else {
        exportFilteredTestDriveData()
      }
      setExportData(false);
    }
  }, [exportData]);

  return (
    <>
      <TableData
        rows={loading ? [] : (rows as GridRowModel[])}
        isLoading={loading}
        getRowId={(r) => r.id}
        columns={columns}
        pageSize={recordLimit}
        isPagination={true}
      />
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={testDriveBookings?.currentPage}
        />
      ) : null}
    </>
  );
};

export default TestDriveTable;
