import styled from "styled-components";

import { Paper } from "@material-ui/core";

import CircleIcon from "shared-components/icons/dashboard-circle";

import { WHITE_COLOR, BLACK_COLOR } from "constants/style/color";
import {
  SUBHEADER_FONT_SIZE,
  SUBTEXT_FONT_SIZE,
} from "constants/style/font-size";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";

export const RecentActivityContainer = styled(Paper)`
  background: ${WHITE_COLOR};
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06) !important;
  border-radius: 10px;
  max-height: 15rem;
  min-height: 37.2rem;
  /* min-height: 37rem !important; */
  flex-grow: 5;
  padding: 4% 0 3% 4%;
  overflow-y: auto;
`;

export const WidgetName = styled.p`
  margin: 0;
  font-size: ${SUBHEADER_FONT_SIZE};
  font-family: ${PRIMARY_REGULAR_FONT};
  color: ${BLACK_COLOR};
`;

export const WidgetHeading = styled(WidgetName)`
  margin-bottom: 2%;
`;
export const WidgetCard = styled.div`
  display: flex;
  justify-content: flex-start;
  /* grid-template-columns: 30% 65%;
  grid-gap: 5%; */
  /* width: 50%; */
  padding: 2% 0 0 0;
  align-items: center;
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4%;
`;

export const CircleLogo = styled(CircleIcon)``;

export const WidgetValue = styled.p`
  font-size: ${SUBTEXT_FONT_SIZE};
  font-family: ${PRIMARY_REGULAR_FONT};
  margin: 0.2rem 0 0 0;
  color: ${BLACK_COLOR};
`;

export const WidgetMessage = styled(WidgetName)`
  margin-top: 8rem;
  margin-left: 8rem;
  font-weight: bold;
`;
