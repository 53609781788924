import styled from "styled-components";

import { Paper } from "@material-ui/core";

import { SUBHEADER_FONT_SIZE, BOLD_HEADING } from "constants/style/font-size";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import { BLACK_COLOR, WHITE_COLOR } from "constants/style/color";

export const CustomerContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
`;

export const WidgetsDiv = styled(Paper)`
  flex-grow: 1;
  background: ${WHITE_COLOR};
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06) !important;
  border-radius: 10px;
  padding: 2%;
  width: 8rem;
`;

export const WidgetName = styled.p`
  margin: 0;
  font-size: ${SUBHEADER_FONT_SIZE};
  font-family: ${PRIMARY_REGULAR_FONT};
  color: ${BLACK_COLOR};
`;

export const WidgetValue = styled.p`
  font-size: ${BOLD_HEADING};
  font-family: ${PRIMARY_REGULAR_FONT};
  margin: 0.2rem 0 0 0;
  color: ${({ theme }) => theme.color};
`;
