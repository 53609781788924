import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import { useSnackbar } from "notistack";
import {
  GridColDef,
  GridRowModel,
  GridCellParams,
} from "@material-ui/data-grid";

import TableData from "shared-components/table-data";
import { RenderCellExpand } from "shared-components/expanded-cel";
import PaginationFooter from "shared-components/pagination";
import { CustomSwitch } from "shared-components/switch";
// import CustomizedRatings from "shared-components/ratings";

import DeleteLocation from "../delete-location";
import { DeletePopupModal } from "pages/best-deals/components/deals-table/styles";
import { AddUserModal } from "pages/customers/components/customers-table/styles";
import DeleteUser from "pages/user-profile/components/delete-user";

import {
  EDIT_LOCATION,
  // SERVICE_RATINGS,
} from "constants/route/route-constants";
import { limit } from "constants/pagination-constants";
import { loadingHelper } from "constants/loadingHelper";

import { setCurrentTabReducer, setCurrentPageReducer } from "slices/locations";

import {
  RemoveIconLogo,
  EditIconLogo,
} from "pages/user-profile/components/user-management/styles";
// import { NavItem } from "pages/location/components/location-table/styles";

import {
  deleteLocationInfoThunk,
  getPartCenterLocationsThunk,
  getServiceCenterLocationsThunk,
  getShowroomLocationsThunk,
  UpdateLocationThunk,
} from "slices/locations/location-thunks";

interface LocationProps {
  filterBy?: string;
  selectedLang?: string;
  handelTabActive: (tabNumber: number) => void;
}

const tabObj = {
  "Service Center": 0,
  Showroom: 1,
  "Parts Center": 2,
};

const booleanObj = {
  Active: true,
  "In-Active": false,
};

// export const handelTimings = (normalTimings) => {
//   let obj = {};
//   let str;
//   let time;
//   normalTimings.forEach((item) => {
//     str = "";
//     time = "";
//     item.days.forEach((allDays) => {
//       if (allDays.isSelected) {
//         str += allDays.day + " ";
//       }
//     });

//     item.timeSlots.forEach((allTimes, ind) => {
//       time +=
//         ` ${ind + 1}) Open- ` + allTimes.open + " Close- " + allTimes.close;
//     });

//     obj[str] = time;
//   });

//   let value = Object.entries(obj).map(([key, value]) => {
//     return (
//       <div>
//         <p>
//           {key} :- {value}
//         </p>
//       </div>
//     );
//   });

//   return value;
// };

const LocationTable = ({
  filterBy,
  selectedLang,
  handelTabActive,
}: LocationProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory<any>();
  const location = useLocation<any>();

  const [rows, setRows] = useState<GridRowModel>([]);
  const [selectedDeleteID, setSelectedDeletedId] = useState<number>(0);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [statusUpdateID, setStatusUpdateID] = useState<number>(0);
  const [currentStatusFlag, setCurrentStatusFlag] = React.useState(false);
  const [openStatusModal, setOpenStatusModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [modalMessage, setModalMessage] = useState({
    headerText: "",
    message: "",
    paraText: "",
  });

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });
  const locations = useAppSelector((state) => state.locations.locationInfo);

  const openDeleteDealModal = (id) => {
    setOpenDeleteModal(true);
    setSelectedDeletedId(id);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeStatusUpdateModal = () => {
    setOpenStatusModal(false);
  };

  const confirmDelete = async (id) => {
    try {
      setLoading(true);
      const result = await dispatch(deleteLocationInfoThunk(id)).unwrap();
      enqueueSnackbar(result?.response?.data, {
        variant: "success",
      });
      setLoading(false);
      setOpenDeleteModal(false);
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error?.data, { variant: "error" });
      setOpenDeleteModal(false);
    }
  };

  const openStatusUpdateModal = (id, status) => {
    if (status) {
      setModalMessage({
        headerText: "Disable Location",
        message: "Are you sure you want to disable this Location?",
        paraText:
          "Customer will not be able to see this location in the mobile app",
      });
    } else {
      setModalMessage({
        headerText: "Enable Location",
        message: "Are you sure you want to enable this Location?",
        paraText:
          "Customer will be able to see this location in the mobile app",
      });
    }
    setOpenStatusModal(true);
    setStatusUpdateID(id);
    setCurrentStatusFlag(status);
  };

  useEffect(() => {
    if (location?.state?.from === "edit") {
      if (locations?.currentTab === "Service Center") {
        loadingHelper(
          dispatch(
            getServiceCenterLocationsThunk({
              pageNumber: locations?.currentPage,
              limit: limit,
            })
          ),
          setLoading,
          enqueueSnackbar
        );
      } else if (locations?.currentTab === "Showroom") {
        loadingHelper(
          dispatch(
            getShowroomLocationsThunk({
              pageNumber: locations?.currentPage,
              limit: limit,
            })
          ),
          setLoading,
          enqueueSnackbar
        );
      } else {
        loadingHelper(
          dispatch(
            getPartCenterLocationsThunk({
              pageNumber: locations?.currentPage,
              limit: limit,
            })
          ),
          setLoading,
          enqueueSnackbar
        );
      }
      const state = { ...history.location.state };
      delete state.from;
      history.replace({ ...history.location, state });
      // console.log(locations?.currentTab, "tabobj");
      handelTabActive(tabObj[`${locations?.currentTab}`]);
    } else {
      if (filterBy === "Service Center") {
        loadingHelper(
          dispatch(
            getServiceCenterLocationsThunk({ pageNumber: 1, limit: limit })
          ),
          setLoading,
          enqueueSnackbar
        );
      } else if (filterBy === "Showroom") {
        loadingHelper(
          dispatch(getShowroomLocationsThunk({ pageNumber: 1, limit: limit })),
          setLoading,
          enqueueSnackbar
        );
      } else {
        loadingHelper(
          dispatch(
            getPartCenterLocationsThunk({ pageNumber: 1, limit: limit })
          ),
          setLoading,
          enqueueSnackbar
        );
      }
      handelTabActive(tabObj[`${filterBy}`]);
      dispatch(setCurrentTabReducer(filterBy as string));
      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line
  }, [filterBy, dispatch, brandName]);

  useEffect(() => {
    setRows(locations?.allLocations);
    setPaginationSize(Math.ceil(locations.recordsLength / limit));
  }, [locations]);

  const handleStatusUpdate = async (id) => {
    setLoading(true);
    try {
      var index = rows.findIndex((r) => r.locationId === id);
      var newStatus = currentStatusFlag ? "In-Active" : "Active";
      const result = await dispatch(
        UpdateLocationThunk({ ...rows[index], status: newStatus })
      ).unwrap();
      enqueueSnackbar(result.data.status, {
        variant: "success",
      });
      setLoading(false);
    } catch (error: any) {
      enqueueSnackbar(error?.data, { variant: "error" });
      setLoading(false);
    }
    setOpenStatusModal(false);
  };

  const columns: GridColDef[] = [
    {
      field: `${
        selectedLang === "English" ? "locationName" : "locationNameArabic"
      }`,
      headerName: "Location Name",
      sortable: true,
      resizable: false,
      flex: 1.4,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(
          params,
          "locationNameArabic",
          300,
          selectedLang
        );
      },
    },
    {
      field: `${selectedLang === "English" ? "areaName" : "areaNameArabic"}`,
      sortable: false,
      headerName: "Area Name",
      resizable: false,
      flex: 1.0,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "addressArabic", 300, selectedLang);
      },
    },
    {
      field:"serviceCenter",
      sortable: false,
      headerName: "Service Center Name",
      resizable: false,
      flex: 1.0,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(
          params,
          "serviceCenter",
          300,
          selectedLang
        );
      },
    },
    // {
    //   field: "timingObj",
    //   sortable: false,
    //   headerName: "Timings",
    //   resizable: false,
    //   flex: 1.5,
    //   renderCell: (params: GridCellParams) => {
    //     return RenderCellExpand(params, "locationTimings", 350);
    //   },
    // },
    {
      field: "phoneNo",
      sortable: false,
      headerName: "Phone Number",
      resizable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "phoneNo", 300);
      },
    },
    // {
    //   field: "rating_val",
    //   headerName: "Rating",
    //   sortable: false,
    //   resizable: false,
    //   flex: 1,
    //   renderCell: (params: GridCellParams) => {
    //     const {
    //       row: { rating_val, total_reviews, rating_avg },
    //     } = params;
    //     return (
    //       <NavItem to={SERVICE_RATINGS}>
    //         <CustomizedRatings
    //           val={rating_val}
    //           max={5}
    //           numberOfReviews={total_reviews}
    //           reviewAvg={rating_avg}
    //           isOutOfReviewShow={true}
    //         />
    //       </NavItem>
    //     );
    //   },
    // },
    {
      field: "brandName",
      sortable: false,
      headerName: "Actions",
      resizable: false,
      flex: 0.4,
      renderCell: (params: GridCellParams) => {
        const rowData = params.row;
        return (
          <>
            <EditIconLogo
              onClick={() =>
                history.push({
                  pathname: EDIT_LOCATION,
                  state: {
                    detail: "edit",
                    rowId: rowData?.locationId,
                    rowData: rowData,
                  },
                })
              }
            />
            <RemoveIconLogo
              onClick={() => openDeleteDealModal(rowData.locationId)}
            />
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      resizable: false,
      flex: 0.4,
      renderCell: (params: GridCellParams) => {
        return (
          <CustomSwitch
            onClick={() =>
              openStatusUpdateModal(
                params.row.locationId,
                booleanObj[params.row.status]
              )
            }
            checked={booleanObj[params.row.status]}
          />
        );
      },
    },
  ];
  const columnsFinal = columns.filter((item) => {
    return item.headerName !== "Service Center Name";
  });
  const handlePagination = (event, number) => {
    if (filterBy === "Service Center") {
      loadingHelper(
        dispatch(
          getServiceCenterLocationsThunk({ pageNumber: number, limit: limit })
        ),
        setLoading,
        enqueueSnackbar
      );
    } else if (filterBy === "Showroom") {
      loadingHelper(
        dispatch(
          getShowroomLocationsThunk({ pageNumber: number, limit: limit })
        ),
        setLoading,
        enqueueSnackbar
      );
    } else {
      loadingHelper(
        dispatch(
          getPartCenterLocationsThunk({ pageNumber: number, limit: limit })
        ),
        setLoading,
        enqueueSnackbar
      );
    }

    dispatch(setCurrentPageReducer(number));
  };

  return (
    <>
      <TableData
        rows={loading ? [] : (rows as GridRowModel[])}
        isLoading={loading}
        getRowId={(r) => r.locationId}
        columns={filterBy === "Service Center" ? columns : columnsFinal}
        isCheckBox={false}
        pageSize={limit}
        isPagination={true}
      />
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={locations?.currentPage}
        />
      ) : null}

      <DeletePopupModal open={openDeleteModal} onClose={closeDeleteModal}>
        <DeleteLocation
          loading={loading}
          params={selectedDeleteID}
          closeModal={closeDeleteModal}
          confirmDelete={confirmDelete}
        ></DeleteLocation>
      </DeletePopupModal>

      <AddUserModal open={openStatusModal} onClose={closeStatusUpdateModal}>
        <DeleteUser
          params={statusUpdateID}
          closeModal={closeStatusUpdateModal}
          confirmDelete={handleStatusUpdate}
          loading={loading}
          headerText={modalMessage?.headerText as string}
          paraText={modalMessage?.paraText as string}
          message={modalMessage?.message as string}
        ></DeleteUser>
      </AddUserModal>
    </>
  );
};

export default LocationTable;
