import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FaqObject } from "interfaces/faq-interfaces";
import faqsInitialState from "slices/faqs/faqs-intitia-state";
// import CustomerState from "slices/customers/customers-interface";

import {
  getAllFaqsThunk,
  getFaqsBySearchThunk,
  addNewFaqThunk,
  updateFaqThunk,
  deleteFaqThunk
} from "slices/faqs/faqs-thunks";
import FaqState from "./faqs-interface";

export const FaqSlice = createSlice({
  name: "FaqSlice",
  initialState: faqsInitialState,
  reducers: {
    setCurrentFaqReducer:setCurrentFaqReducer,
    setCurrentPageReducer:setCurrentPageReducer
  },
  extraReducers: {
    //get all faqs 
    [getAllFaqsThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.faqsInfo.allFaqs =
        listEntityClass === undefined ? [] : listEntityClass;
        state.faqsInfo.recordsLength = totalRecords;
    },

    // search deal by text and Title
    [getFaqsBySearchThunk.fulfilled.type]: (state, action) => {
      let { listEntityClass, totalRecords } = action.payload.data;
      state.faqsInfo.allFaqs =
        listEntityClass === undefined ? [] : listEntityClass;
        state.faqsInfo.recordsLength = totalRecords;
    },

    //update faq
    [updateFaqThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      const result = state.faqsInfo.allFaqs.map((item) => {
        if (item.id === data.id) {
          item = { ...data };
        }
        return item;
      });
      state.faqsInfo.allFaqs = [...result];
    },


    // add new faq
    [addNewFaqThunk.fulfilled.type]: (state, action) => {
      const { data } = action.payload.data;
      state.faqsInfo.allFaqs.push(data);
    },

        //delete faq
    [deleteFaqThunk.fulfilled.type]: (state, action) => {
      const { id } = action.payload;
      state.faqsInfo.allFaqs = state.faqsInfo.allFaqs.filter(
        (data) => data.id !== id
      );
    },

  },
});


function setCurrentFaqReducer(
  state: FaqState,
  action: PayloadAction<FaqObject>
) {
  state.faqsInfo.currentFaq = action.payload;
}

function setCurrentPageReducer(
  state: FaqState,
  action: PayloadAction<number>
) {
  state.faqsInfo.currentPage = action.payload;
}

