import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import {
  MainContainer,
  WidgetName,
  DoughnutChart,
  WidgetMessage,
} from "pages/dashboard/components/customers-left-widgets/owner-vs-guests/styles";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";

interface Props {
  chartColors: Array<string>;
}
function OwnersVsGuests({ chartColors }: Props): ReactElement {
  const [graphData, setGraphData] = useState<Array<number>>([]);
  const [labels, setLabels] = useState<Array<string>>([]);

  const dashboardDetails = useAppSelector((state: RootState) => {
    return state.dashboardDetails.dashboardInfo.dashboardDetails;
  });
  // console.log(dashboardDetails, "dashboardDetails", labels);
  useEffect(() => {
    setGraphData(dashboardDetails?.ownersVSGuestUsers?.data as []);
    setLabels(dashboardDetails?.ownersVSGuestUsers.lables as []);
  }, [dashboardDetails]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: graphData,
        backgroundColor: chartColors,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    cutoutPercentage: 70,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          Font: PRIMARY_REGULAR_FONT,
        },
      },
    },
    maintainAspectRatio: false,
  };
  const checkValue = () => {
    const isBelowThreshold = (currentValue) => currentValue === 0;
    return graphData?.every(isBelowThreshold);
  };
  // console.log(graphData, checkValue(), "checkValue() ");
  return (
    <>
      <MainContainer>
        <WidgetName>Owners Vs Guest Users</WidgetName>

        {!checkValue() ? (
          <DoughnutChart
            type="doughnut"
            data={data}
            height={280}
            options={options}
          />
        ) : (
          <WidgetMessage>No Owners and Guests</WidgetMessage>
        )}
      </MainContainer>
    </>
  );
}

export default OwnersVsGuests;
