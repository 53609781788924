import styled from "styled-components";
import  {UserManagementContainer} from 'pages/user-profile/components/user-management/styles';

export const DetailsDiv = styled(UserManagementContainer)``;

export const DetailsArrayContainer = styled.div`
    margin:1.25rem;
`;
export const BoxDivRow = styled.div`
    display:flex;
    background-color:#F0F0F0;
    padding:1.25rem;
    margin-top:0.625rem;
    border-radius:0.625rem;
`;

export const BoxDiv = styled.div`
  background-color: #f0f0f0;
  padding: 1.25rem;
  margin:0.625rem;
  border-radius: 0.625rem;
`;

export const BoxCol = styled.div`
  width: 50%;
  background-color: #f0f0f0;
  padding: 1.25rem;
  margin: 0 0.625rem;
  border-radius: 0.625rem;
`;
export const MappedDiv = styled.div``;


export const FlexDiv = styled.div`
    display:flex;
    font-size:0.875rem;
    padding:5px 0.625rem;
    margin-top:0.625rem;
`;

export const FlexChild = styled.span`
    width:40%;
    margin: 0 0.625rem;
    font-weight: 600;
`;

export const FlexChildTwo = styled(FlexChild)`
    width:60%;
    font-weight: normal;
`;
export const PlaceholderImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18rem;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 0rem 3rem;
  opacity: 1;

  ${"img"} {
    border-radius: 8px;
    max-height: 100%;
    max-width: 100%;
  }
`;
