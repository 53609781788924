import React from "react";
import Rating from "@material-ui/lab/Rating";

import { NewBox, SpanTag } from "./styles";

interface ratingProps {
  val: number;
  max: number;
  numberOfReviews?: number;
  reviewAvg?: number;
  isOutOfReviewShow?: boolean;
}

export default function CustomizedRatings({
  val,
  max,
  numberOfReviews,
  reviewAvg,
  isOutOfReviewShow,
}: ratingProps) {
  return (
    <div>
      <NewBox borderColor="transparent">
        <SpanTag>{`${reviewAvg}`}.0</SpanTag>
        <Rating value={val} readOnly max={max} precision={0.1} size="small" />
        {isOutOfReviewShow ? <SpanTag>{`(${numberOfReviews})`}</SpanTag> : ""}
      </NewBox>
    </div>
  );
}
