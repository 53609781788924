import React, { ReactElement, useState, useEffect } from "react";
import { useAppSelector } from "redux-store/hooks";

import { useSnackbar } from "notistack";

import LoaderButton from "shared-components/loader-button";

import { LoadingComp } from "pages/news/components/news-details/styles";

import { loaderBtnStyleFunc } from "constants/style/props";

import { getLiveChat, updateLiveChat } from "services/live-chat-services";

import {
  MainDiv,
  LeftDiv,
  RightDiv,
  HeaderDiv,
  CancelButton,
  Heading,
  ButtonContainer,
  InputFieldContainer,
  NavContainer,
  InputTextField,
  InputLabelText,
} from "pages/live-chat-config/styles";

function LiveChatConfig(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const [script, setScript] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  const getLiveChats = async () => {
    setLoading(true);
    try {
      const result = await getLiveChat();
      if (result?.data) {
        setScript(result?.data?.data?.script);
      }
      setLoading(false);
    } catch (err: any) {
      // console.log(err.response);
      setLoading(false);
    }
  };

  const updateLiveChats = async () => {
    setBtnLoading(true);
    try {
      const result = await updateLiveChat({ script });
      if (result?.data?.response) {
        enqueueSnackbar(result.data.status, {
          variant: "success",
        });
      }
      setBtnLoading(false);
    } catch (err: any) {
      // console.log(err.response);
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    getLiveChats();
  }, [brandName]);

  const submitHandler = () => {
    if (script === "") {
      setError("Script should not be empty");
    } else {
      updateLiveChats();
    }
  };

  const handleClose = () => {
    setError("");
    getLiveChats();
  };

  const handleScript = (e) => {
    const { value } = e.target;
    if (value === "") {
      setError("Script should not be empty");
    } else {
      setError("");
    }
    setScript(value);
  };

  return (
    <MainDiv>
      {loading ? (
        <LoadingComp color="primary" />
      ) : (
        <>
          <HeaderDiv>
            <LeftDiv>
              <Heading>Live Chat</Heading>
            </LeftDiv>
            <RightDiv>
              <ButtonContainer>
                <LoaderButton
                  isLoading={btnLoading}
                  buttonText="Save"
                  styles={loaderBtnStyleFunc("6.8rem", "2.8rem")}
                  submitHandler={submitHandler}
                />
                <CancelButton
                  variant="contained"
                  onClick={handleClose}
                  color="default"
                >
                  Cancel
                </CancelButton>
              </ButtonContainer>
            </RightDiv>
          </HeaderDiv>
          <InputFieldContainer>
            <NavContainer>
              <InputLabelText>Live Chat Config</InputLabelText>
              <InputTextField
                variant="outlined"
                autoFocus
                rows={15}
                multiline
                value={script}
                onChange={handleScript}
                error={Boolean(error)}
                helperText={error}
              />
            </NavContainer>
          </InputFieldContainer>
        </>
      )}
    </MainDiv>
  );
}

export default LiveChatConfig;
