import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { RootState } from "redux-store/store";

import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Typography from "@mui/material/Typography";
import { Popover } from "@mui/material";

import PaginationFooter from "shared-components/pagination";
import TableData from "shared-components/table-data";
import { RenderCellExpand } from "shared-components/expanded-cel";
import { HoverPaper } from "shared-components/expanded-cel/styles";

import DeleteBlog from "pages/latest-blogs/components/delete-blog";

import { COPY_BLOG, EDIT_BLOG } from "constants/route/route-constants";
import { loadingHelper } from "constants/loadingHelper";
import { limit } from "constants/pagination-constants";

import { getAllBlogsThunk, getBlogsBySearchThunk } from "slices/latest-blogs";

import {
  deleteBlogThunk,
  setCurrentPageReducer,
  setTabIndicatorReducer,
} from "slices/latest-blogs";

import {
  GridColDef,
  // GridRowsProp,
  GridCellParams,
  GridRowModel,
  // GridRowId,
} from "@material-ui/data-grid";

import {
  CopyIconLogo,
  DeletePopupModal,
  DateDiv,
  CopyLinkToClipboard,
} from "pages/best-deals/components/deals-table/styles";

import {
  RemoveIconLogo,
  EditIconLogo,
} from "pages/user-profile/components/user-management/styles";
import selectedBrand from "constants/brand";

interface Props {
  filterBy?: string | undefined;
  filterColumn?: string;
  searchValue: string;
  selectedLang: string;
  handelTabActive: (tabNumber: number) => void;
}

const tabObj = {
  undefined: 0,
  Active: 1,
  "In-Active": 2,
};

const useStyles = makeStyles({
  root: {
    "& .active": {
      backgroundColor: "rgba(39, 174, 96, 0.1)",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#D6D2C4",
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: "#D6D2C4",
      },
      "&.MuiDataGrid-row": {
        borderBottom: ".625rem solid white",
        alignItems: "center",
      },
    },
    "& .inactive": {
      backgroundColor: "rgba(235, 10, 30, 0.1);",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#D6D2C4",
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: "#D6D2C4",
      },
      "&.MuiDataGrid-row": {
        borderBottom: ".625rem solid white",
        alignItems: "center",
      },
    },
  },
});

const BlogsTable = ({
  filterBy,
  filterColumn,
  searchValue,
  selectedLang,
  handelTabActive,
}: Props) => {
  const classes = useStyles();
  const history = useHistory<any>();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<GridRowModel>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [selectedDeleteID, setSelectedDeletedId] = useState<number>(0);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });
  const latestBlogs = useAppSelector((state) => state.blogs.blogsInfo);

  useEffect(() => {
    if (history?.location?.state?.from === "edit") {
      loadingHelper(
        dispatch(
          getAllBlogsThunk({
            status: `${latestBlogs?.tabIndicator}`,
            pageno: latestBlogs?.currentPage,
            limit: 5,
          })
        ),
        setLoading,
        enqueueSnackbar
      );

      const state = { ...history.location.state };
      delete state.from;
      history.replace({ ...history.location, state });
      handelTabActive(tabObj[`${latestBlogs?.tabIndicator}`]);
    } else {
      loadingHelper(
        dispatch(
          getAllBlogsThunk({
            status: `${filterBy}`,
            pageno: 1,
            limit: 5,
          })
        ),
        setLoading,
        enqueueSnackbar
      );

      handelTabActive(tabObj[`${filterBy}`]);
      dispatch(setTabIndicatorReducer(filterBy));
      dispatch(setCurrentPageReducer(1));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, brandName, filterBy]);

  useEffect(() => {
    setRows(latestBlogs.allBlogs);
    setPaginationSize(Math.ceil(latestBlogs.recordsLength / limit));
  }, [latestBlogs]);

  const handelDateFormat = (timedate) => {
    const day = new Date(timedate);
    const time = day.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    var dateTime =
      day.getDate() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getFullYear() +
      " " +
      time;

    return dateTime;
  };

  const handlePagination = (event, number) => {
    if (searchValue === "") {
      loadingHelper(
        dispatch(
          getAllBlogsThunk({ status: `${filterBy}`, pageno: number, limit: 5 })
        ),
        setLoading,
        enqueueSnackbar
      );
    } else {
      dispatch(
        getBlogsBySearchThunk({
          query: searchValue,
          pageno: number,
          limit: 5,
          status: latestBlogs?.tabIndicator as string,
        })
      );
    }
    dispatch(setCurrentPageReducer(number)); //
  };

  const openDeleteDealModal = (id) => {
    setOpenDeleteModal(true);
    setSelectedDeletedId(id);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const confirmDelete = async (id) => {
    try {
      setLoading(true);
      const result = await dispatch(deleteBlogThunk(id)).unwrap();
      enqueueSnackbar(result?.response?.data, {
        variant: "success",
      });
      setLoading(false);
      setOpenDeleteModal(false);
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error?.data, { variant: "error" });
      setOpenDeleteModal(false);
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setShow(true)
  };

  const handlePopoverClose = () => {
    setShow(false)
    setAnchorEl(null);
    setIsCopied(false);
  };

  const open = Boolean(anchorEl);

  const handelCopy = (dealId) => {
    setIsCopied(true);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      flex: 0.5,
    },
    {
      field: `${selectedLang === "English" ? "title" : "titleArabic"}`,
      headerName: "Title",
      sortable: true,
      resizable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "blogTitleArabic", 300, selectedLang);
      },
    },
    {
      field: `${selectedLang === "English" ? "subText" : "subTextArabic"}`,
      sortable: false,
      headerName: "Sub Text",
      resizable: false,
      flex: 1.4,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "blogSubtextArabic", 300, selectedLang);
      },
    },
    {
      field: "startTime",
      headerName: "Starts",
      sortable: false,
      resizable: false,
      flex: 1.1,
      renderCell: (params: GridCellParams) => {
        const { startTime } = params.row;
        return <DateDiv>{handelDateFormat(startTime)}</DateDiv>;
      },
    },
    {
      field: "endTime",
      sortable: false,
      headerName: "Ends",
      resizable: false,
      flex: 1.1,
      renderCell: (params: GridCellParams) => {
        const { endTime } = params.row;
        return <DateDiv>{handelDateFormat(endTime)}</DateDiv>;
      },
    },
    {
      field: "actions",
      sortable: false,
      headerName: "Actions",
      resizable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const rowData = params.row;
        return (
          <>
            <CopyToClipboard
              text={`${selectedBrand()}://blog/autogen/${rowData.id}`}
              onCopy={() => handelCopy(rowData.dealId)}
            >
              <CopyLinkToClipboard
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              ></CopyLinkToClipboard>
            </CopyToClipboard>
            {show && (
              <Popover
                elevation={0}
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <HoverPaper elevation={1}>
                  <Typography sx={{ p: 1 }}>
                    {isCopied ? (
                      <span>&#x2714; Copied to Clipboard</span>
                    ) : (
                      "Click to copy the link."
                    )}
                  </Typography>
                </HoverPaper>
              </Popover>
            )}
            <CopyIconLogo
              onClick={() =>
                history.push({
                  pathname: COPY_BLOG,
                  state: {
                    detail: "copy",
                    rowId: rowData?.id,
                    rowData: rowData,
                  },
                })
              }
            />
            <EditIconLogo
              onClick={() =>
                history.push({
                  pathname: EDIT_BLOG,
                  state: {
                    detail: "edit",
                    rowId: rowData?.id,
                    rowData: rowData,
                  },
                })
              }
            />
            <RemoveIconLogo onClick={() => openDeleteDealModal(rowData.id)} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <TableData
        className={classes.root}
        rows={loading ? [] : (rows as GridRowModel[])}
        isLoading={loading}
        getRowId={(r) => r.id}
        filterBy={filterBy}
        filterColumn={filterColumn}
        columns={columns}
        pageSize={limit}
        isPagination={true}
        getRowClassName={(params) => {
          if (params.row.status === "Active") {
            return "active";
          }
          return "inactive";
        }}
        rowHeight={65}
      />
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={latestBlogs?.currentPage}
        />
      ) : null}

      <DeletePopupModal open={openDeleteModal} onClose={closeDeleteModal}>
        <DeleteBlog
          loading={loading}
          params={selectedDeleteID}
          closeModal={closeDeleteModal}
          confirmDelete={confirmDelete}
        ></DeleteBlog>
      </DeletePopupModal>
    </>
  );
};

export default BlogsTable;
