import React, { ReactElement, useState, useEffect } from "react";
import { useAppDispatch } from "redux-store/hooks";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextField } from "@material-ui/core";

import { useSnackbar } from "notistack";

import CheckboxContainer from "shared-components/checkbox-container";
import LoaderButton from "shared-components/loader-button";

import {
  EMAIL_REGEX_EQUATION,
  IS_VALID_TEXT,
} from "constants/regex-validations-constants";

import { UserProfileInfo } from "interfaces/user-profile.interface";

import {
  updateAdminInfoThunk,
  addAdminInfoThunk,
  // getAdminsInfoThunk,
} from "slices/user-profile";

import {
  AddUserContainer,
  FieldContainer,
  InputTextField,
  InputLabelText,
  ButtonContainer,
  CancelButton,
  HeaderDiv,
  FormContainer,
  InputEmailTextField,
  // DropdownSelector,
  HelperText,
  AutocompleteSelect,
  CheckboxDiv,
  AddUserModal,
  RadioContent,
  RadioOption,
  HelperTextRadio,
} from "./styles";
import { loaderBtnStyleFunc } from "constants/style/props";

interface Props {
  modalType: string;
  closeModal: () => void;
  currentAdmin: UserProfileInfo;
  open: boolean;
}
const initialState: UserProfileInfo = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  listofBrands: [] as Array<string>,
  id: 0,
  defautbrand: "",
  profilePicture: "",
  moduleList: [],
};

interface roleInterface {
  id: number;
  label: string;
  status: boolean;
  type?: string;
}

export const dummy={
  Toyota:"Toyota",
  Lexus:"Lexus",
  Motorcity:"motorcity" 
}
export const dummyRev={
  Toyota:"Toyota",
  Lexus:"Lexus",
  motorcity:"Motorcity" 
}

export default function AddUser({
  modalType,
  closeModal,
  currentAdmin,
  open,
}: Props): ReactElement {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const roleItems: Array<string> = ["Admin", "Super Admin"];
  const initialBrands: Array<roleInterface> = [
    {
      id: 1,
      label: "Toyota",
      status: false,
    },
    {
      id: 2,
      label: "Lexus",
      status: false,
    },
    {
      id: 3,
      label: "Motorcity",
      status: false,
    },
  ];

  const initialErrors = {
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    roleError: "",
    listofBrandsError: "",
    languageError: "",
    nameError: "",
    defautbrandError: "",
    profilePictureError: "",
    moduleListError: "",
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [brandOptions, setBrandOptions] =
    useState<roleInterface[]>(initialBrands);

  const [adminData, setAdminData] = useState<UserProfileInfo>(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [defaultOptions, setDefaultOptions] = useState<Array<string>>([]);

  const listOfBrands = adminData.listofBrands as Array<string>;
  const moduleOptions = [
    "All",
    "customers",
    "location",
    "transaction",
    "news",
    "service-ratings",
    "latest-cars",
    "car-details",
    "test-drive-bookings",
    "live-chat-config",
    "best-deals",
    "edit-deal",
    "new-deal",
    "service-bookings",
    "roadside-assistance",
    "faq",
    "link-management",
    "terms-and-conditions",
    "notifications",
    "sales-force",
    "latest-blogs",
  ];
  // const adminProfile = useSelector(
  //   (state: RootState) => state.UserProfile.userProfileInfo
  // );
  const handleClose = () => {
    setErrors(initialErrors);
    setBrandOptions(initialBrands);
    setDefaultOptions([]);
    setAdminData(initialState);
    closeModal();
  };
  const handleChange = (e, value) => {
    if (value === "") {
      errors.roleError = "Role Should not be empty";
    } else {
      errors.roleError = "";
    }
    adminData.role = value;
    setErrors({ ...errors });
    setAdminData({ ...adminData });
  };

  useEffect(() => {
    if (modalType === "Edit User") {
      const selectedOptions: Array<string> = [];
      currentAdmin.listofBrands?.map((item) => {
        const result = brandOptions.map((data) => {
          if (dummyRev[item] === data.label) {
            data.status = true;
            selectedOptions.push(dummy[data.label]);
          }
          return data;
        });
        return result[0];
      });
      setBrandOptions([...brandOptions]);
      setDefaultOptions(selectedOptions);
      setAdminData({ ...initialState, ...currentAdmin });
    } else {
      setAdminData({ ...initialState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType, currentAdmin]);

  const setErrorText = (value, id) => {
    const fieldId = (id?.charAt(0)?.toUpperCase() as string) + id?.slice(1);
    if (value === "") {
      return (errors[id + "Error"] = fieldId + " should not be empty");
    }
    if (id === "email") {
      const isEmailValid = new RegExp(EMAIL_REGEX_EQUATION).test(value);
  
      if (
        !isEmailValid
      ) {
        return (errors[
          id + "Error"
        ] = `Invalid email address. Please re-renter the email address`);
      }
    } else {
      const isValidText = new RegExp(IS_VALID_TEXT).test(value);
      if (!isValidText) {
        return (errors[id + "Error"] =
          fieldId + " should not consists numbers and special characters");
      }
    }
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "firstName": {
        errors.firstNameError = setErrorText(value, id) as string;
        break;
      }
      case "lastName": {
        errors.lastNameError = setErrorText(value, id) as string;
        break;
      }
      case "email": {
        errors.emailError = setErrorText(value, id) as string;
        break;
      }
    }
    setErrors({ ...errors });
    setAdminData({
      ...adminData,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    for (var key in adminData) {
      if (
        key !== "userId" &&
        key !== "profilePicture" &&
        key !== "moduleList"
      ) {
        if (adminData[key] === "") {
          errors[key + "Error"] =
            (key?.charAt(0)?.toUpperCase() as string) +
            key?.slice(1) +
            " should not be empty";
        } else {
          errors[key + "Error"] = "";
        }
        if (
          adminData.listofBrands?.length === 0 &&
          adminData.role === "Admin"
        ) {
          errors.listofBrandsError = "Brand should not be empty";
        } else {
          errors.listofBrandsError = "";
        }
        if (adminData.role === "Super Admin") {
          adminData.defautbrand = "Toyota";
          adminData.listofBrands = ["Toyota", "Lexus", "motorcity"];
        }
        if (adminData.moduleList.includes("All")) {
          const result = adminData.moduleList.filter((data) => data !== "All");
          setAdminData({ ...adminData, moduleList: result });
        }
        if (adminData.moduleList.length === 0) {
          adminData.moduleList = moduleOptions;
          setAdminData({ ...adminData });
        }
        if (adminData.defautbrand === "" && adminData.role !== "Super Admin") {
          errors.defautbrandError = "Default Brand should not be empty";
        } else {
          errors.defautbrandError = "";
        }
      }
    }
    setErrors({ ...errors });
    setAdminData({ ...adminData });
    const result = Object.keys(errors).filter((key) => {
      return errors[key] !== "";
    });
    if (result.length === 0) {
      setLoading(true);
      if (modalType === "Edit User") {
        try {
          const result = await dispatch(
            updateAdminInfoThunk({ request: adminData })
          ).unwrap();
          enqueueSnackbar(result.response.data.status, {
            variant: "success",
          });
          setLoading(false);
          handleClose();
        } catch (error) {
          setLoading(false);
          enqueueSnackbar(error?.data, { variant: "error" });
          handleClose();
        }
      } else {
        try {
          const result = await dispatch(addAdminInfoThunk(adminData)).unwrap();
          enqueueSnackbar(result.data.status, {
            variant: "success",
          });
          setLoading(false);
          // dispatch(
          //   getAdminsInfoThunk({
          //     pageNumber: adminProfile.currentPage,
          //     limit: 5,
          //   })
          // )
          // .unwrap()
          // .then((res) => {
          //   setLoading(false);
          // })
          // .catch((err) => {
          //   setLoading(false);
          // });
          handleClose();
        } catch (error) {
          setLoading(false);
          enqueueSnackbar(error?.data, { variant: "error" });
          handleClose();
        }
      }
      setErrors(initialErrors);
    }
  };

  const handleBrandCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const options = brandOptions.map((data) => {
      if (data.id === parseInt(value)) {
        data.status = !data.status;
      }
      if (
        data.status === false &&
        data.label === name &&
        adminData.defautbrand === name
      ) {
        adminData.defautbrand = "" as string;
      }
      return data;
    });
    const selectedOptions: Array<string> = [];
    if (listOfBrands?.length > 0) {
      errors.listofBrandsError = "";
    }
    options.map((item) => {
      if (item.status === true) {
        selectedOptions.push(dummy[item.label]);
      }
      return item;
    });
    adminData.listofBrands = selectedOptions;

    setErrors({ ...errors });
    // defaultOptions=selectedOptions
    setDefaultOptions([...selectedOptions]);
    setAdminData({ ...adminData });
    setBrandOptions(options);
  };

  const handleDefaultBrandOption = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    adminData.defautbrand = (event.target as HTMLInputElement).value;
    setAdminData({ ...adminData });
    if (adminData.defautbrand !== "") {
      errors.defautbrandError = "";
    }
    setErrors({ ...errors });
  };

  const handleModulesChange = (e, value) => {
    if (
      value.includes("All") &&
      (value.length === 1 || value.length === moduleOptions.length)
    ) {
      setAdminData({ ...adminData, moduleList: moduleOptions });
    } else {
      setAdminData({
        ...adminData,
        moduleList: value.filter((data) => data !== "All"),
      });
    }
  };

  return (
    <AddUserModal open={open} onClose={handleClose}>
      <AddUserContainer>
        <HeaderDiv>{modalType}</HeaderDiv>
        <FormContainer>
          <FieldContainer>
            <InputLabelText>First Name</InputLabelText>
            <InputTextField
              value={adminData.firstName}
              variant="outlined"
              id="firstName"
              size="small"
              onChange={handleInput}
              helperText={errors.firstNameError}
              error={Boolean(errors.firstNameError)}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabelText>Last Name</InputLabelText>
            <InputTextField
              value={adminData.lastName}
              id="lastName"
              variant="outlined"
              size="small"
              onChange={handleInput}
              helperText={errors.lastNameError}
              error={Boolean(errors.lastNameError)}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabelText>Email ID</InputLabelText>
            <InputEmailTextField
              value={adminData?.email}
              variant="outlined"
              id="email"
              // isEdit={modalType}
              onChange={handleInput}
              size="small"
              helperText={errors.emailError}
              error={Boolean(errors.emailError)}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabelText>Modules</InputLabelText>
            <AutocompleteSelect
              id="modules"
              options={moduleOptions}
              onChange={handleModulesChange}
              multiple
              limitTags={2}
              size="small"
              value={adminData?.moduleList}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" fullWidth />
              )}
            />
          </FieldContainer>

          <FieldContainer>
            <InputLabelText>Role</InputLabelText>
            <AutocompleteSelect
              id="role"
              value={adminData.role}
              options={roleItems}
              onChange={handleChange}
              disableClearable
              size="small"
              getOptionLabel={(option) => option as string}
              renderOption={(option) => <>{option}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  helperText={errors.roleError}
                  error={Boolean(errors.roleError)}
                />
              )}
            />
          </FieldContainer>
          {adminData.role !== "" && adminData.role !== "Super Admin" ? (
            <div>
              <CheckboxDiv>
                <InputLabelText>Brand</InputLabelText>
                <CheckboxContainer
                  checkboxLabelArr={brandOptions}
                  handleChange={handleBrandCheckbox}
                  isStyleChanged={true}
                />
              </CheckboxDiv>
              <HelperText>{errors.listofBrandsError}</HelperText>
            </div>
          ) : (
            ""
          )}
          {adminData.role !== "Super Admin" && listOfBrands.length > 0 ? (
            <div>
              <FieldContainer>
                <InputLabelText>Default Brand</InputLabelText>
                <RadioContent
                  aria-label="defaultBrand"
                  name="defaultBrand"
                  value={adminData?.defautbrand}
                  onChange={handleDefaultBrandOption}
                  row
                >
                  {defaultOptions?.map((item, index) => {
                    return (
                      <>
                        <FormControlLabel
                          value={item}
                          key={index}
                          control={
                            <RadioOption
                              checked={adminData?.defautbrand ===item}
                              color="primary"
                            />
                          }
                          label={dummyRev[item]}
                        />
                      </>
                    );
                  })}
                </RadioContent>
              </FieldContainer>
              <HelperTextRadio>{errors.defautbrandError}</HelperTextRadio>
            </div>
          ) : (
            ""
          )}
        </FormContainer>
        <ButtonContainer>
          <CancelButton
            variant="contained"
            color="default"
            onClick={handleClose}
          >
            Cancel
          </CancelButton>
          <LoaderButton
            submitHandler={handleSubmit}
            isLoading={loading}
            buttonText="Save"
            styles={loaderBtnStyleFunc("6.4rem", "2.8rem")}
          />
        </ButtonContainer>
      </AddUserContainer>
    </AddUserModal>
  );
}
