import styled from "styled-components";
import { TimingDropdown } from "../add-location/styles";
import { InputLabel, Button } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIconLogo from "shared-components/icons/add-icon";
import TextField from "@material-ui/core/TextField";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  DateTimePicker
} from '@material-ui/pickers';

export const AccordianContainer = styled.div`
  width: 92%;
`;

export const DaysContainer = styled.div``;

export const AccordionDetailsContainer = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  width: 95%;

`;

export const CustomDateTimePicker = styled(DateTimePicker)`
  width:90% !important;
  cursor:pointer;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Days = styled.div`
  padding: 0.4rem 0.73rem;
  background: #dadada;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  border-radius: 0.4rem;
  font-size: 13px;
  cursor: pointer;
  align-items: center;
`;

export const DropDownTime = styled(TimingDropdown)`
  height: 30px;
  width: 29%;
`;

export const AddIconLogoSvg = styled(AddIconLogo)`
  cursor: pointer;
  margin-Left: 0.3rem;
`;

export const RemoveTimeSlot = styled(RemoveCircleOutlineIcon)`
    cursor:pointer;
    margin:0 0.4rem !important;
    font-size:1.375rem !important;
    color: ${({ theme }) => theme.color};
`;


export const RemoveWeek = styled(CancelIcon)`
    cursor:pointer;
    margin:0 0.2rem !important;
    font-size:1.375rem !important;
    color: ${({ theme }) => theme.color};
`;

export const DropdownBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  width: 95%;
`;

export const TimeInputLabel = styled(InputLabel)`
  font-size: 13px !important;
`;

export const AddWeekBtn = styled(Button)`
  width: 30%;
  margin:0 0.2rem !important;
`;

export const WeekContainer = styled.div`
  margin-top: 0.625rem;
`;

export const DatePicker = styled(DateTimePicker)`
    cursor:pointer;
    width: 35%;
    margin-right: 1rem !important;
`;


export const InputTextField = styled(TextField)`
  width:37%; 
  margin-right: 1rem !important;
`;

