import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";

import MenuItem from "@material-ui/core/MenuItem";

import { AsYouType } from "libphonenumber-js";
import { useSnackbar } from "notistack";

import LoaderButton from "shared-components/loader-button";
import AddImage from "shared-components/icons/add-image";

import { Required } from "pages/best-deals/components/new-deal/styles";
import TimingComponent from "../accordian";

import { DROPDOWN_MENU_PROPS, loaderBtnStyleFunc } from "constants/style/props";
import { EMPTY_INPUT } from "constants/snackbar-messages-constants";
import { LOCATION } from "constants/route/route-constants";

import { LocationInfo as LocationDtl } from "slices/locations/locations.interface";

import {
  addLocationInfoThunk,
  setCurrentLocationReducer,
  UpdateLocationThunk,
} from "slices/locations";

import {
  LocationContainer,
  LeftDiv,
  RightDiv,
  HeaderDiv,
  HeadingDiv,
  CancelButton,
  ParentContainer,
  FormContainer,
  FormLeftSide,
  FormRightSide,
  FieldContainer,
  InputLabelText,
  InputTextField,
  InputTextArea,
  AddIconLogo,
  FlexBoxContainer,
  CoordinateBox,
  CoordinateInputField,
  CoordinateInputLabel,
  DropdownSelector,
  FlexChildInput,
  ChildImage,
  ImageFlexContainer,
  ImageInput,
  RemoveImageLogo,
  TextWrapper,
  Small,
  CancelInput,
  FlexBoxArabic,
  FaxNumberInput,
  CancelInputTextArea,
  ImgWholeContainer,
  ChildImageContainer,
  AddImageContainer,
  PhoneNumberInput,
} from "./styles";

const locationArr = ["Service Center", "Showroom", "Parts Center"];

const errorObj = {
  locationName: "Location Name",
  serviceCenter: "Service Center Name",
  phoneNo: "Phone Number",
  googleXcord: "Latitude",
  googleYcord: "Longitude",
  faxNo: "Fax Number",
  locationNameArabic: "Location Name",
  addressArabic: "Address",
};

const initialState = [
  {
    days: [
      { day: "Mon", isSelected: false, isAlreadyAdded: false },
      { day: "Tue", isSelected: false, isAlreadyAdded: false },
      { day: "Wed", isSelected: false, isAlreadyAdded: false },
      { day: "Thu", isSelected: false, isAlreadyAdded: false },
      { day: "Fri", isSelected: false, isAlreadyAdded: false },
      { day: "Sat", isSelected: false, isAlreadyAdded: false },
      { day: "Sun", isSelected: false, isAlreadyAdded: false },
    ],
    timeSlots: [
      {
        open: "9:00 AM",
        close: "7:00 PM",
      },
    ],
  },
];

const statusArr = ["Active", "In-Active"];

function AddLocation(): ReactElement {
  const location = useLocation<any>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });
  const locations = useAppSelector((state) => {
    return state.locations.locationInfo;
  });

  const [tempPhone, setTempPhone] = useState("");
  const [tempFax, setTempFax] = useState("");

  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isFaxValid, setIsFaxValid] = useState(false);
  const [isTimingUpdated, setIsTimingUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState<string[]>([]);

  const [mainState, setMainState] = useState({
    normalTimings: JSON.parse(JSON.stringify(initialState)),
    customTimings: JSON.parse(JSON.stringify(initialState)),
    noteForCustomTimings: "",
    customStartDate: null,
    customEndDate: null,
  });

  const addLocationState = {
    locationType: locations?.currentTab,
    address: null,
    locationName: "",
    serviceCenter: null,
    phoneNo: [],
    googleXcord: "",
    googleYcord: "",
    faxNo: [],
    areaName: "",
    areaNameArabic: null,
    locationNameArabic: null,
    addressArabic: null,
    rating: 0,
    status: statusArr[0],
    brandName: brandName,
    locationImages: [],
    timingObj: {
      normalTimings: JSON.parse(JSON.stringify(initialState)),
      customTimings: JSON.parse(JSON.stringify(initialState)),
      noteForCustomTimings: "",
      customStartDate: null,
      customEndDate: null,
    },
  };

  const [locationDetails, setLocationDetails] =
    useState<LocationDtl>(addLocationState);

  const [inputErrors, setErrors] = useState({
    locationNameError: "",
    serviceCenterError: "",
    phoneNoError: "",
    googleXcordError: "",
    googleYcordError: "",
    faxNoError: "",
    phoneNoListError: new Array<string | null>(),
    faxNoListError: new Array<string | null>(),
    locationNameArabicError: "",
  });

  const {
    locationType,
    phoneNo,
    faxNo,
    locationName,
    serviceCenter,
    googleXcord,
    googleYcord,
    address,
    areaName,
    areaNameArabic,
    locationNameArabic,
    addressArabic,
    status,
    locationImages,
  } = locationDetails;

  const {
    locationNameError,
    serviceCenterError,
    phoneNoError,
    googleXcordError,
    googleYcordError,
    faxNoError,
    phoneNoListError,
    faxNoListError,
    locationNameArabicError,
  } = inputErrors;

  const ref = useRef<any>();
  useEffect(() => {
    if (location?.state?.detail === "edit") {
      if (ref.current !== undefined && ref.current !== brandName) {
        return history.push(LOCATION);
      }
      ref.current = brandName;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName, history]);

  useEffect(() => {
    if (location?.state?.detail === "edit") {
      setLocationDetails({
        ...location?.state?.rowData,
      });
      setImageList([...location?.state?.rowData?.locationImages]);
      dispatch(setCurrentLocationReducer({ ...location?.state?.rowData }));
    }
  }, [location?.state?.detail, location?.state?.rowData, dispatch]);

  useEffect(() => {
    if (location?.state?.detail === "edit") {
      setMainState(
        JSON.parse(JSON.stringify(location?.state?.rowData?.timingObj))
      );
      // setMainState({ ...mainState,normalTimings:editedState});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTimingUpdated) {
      setLocationDetails({ ...locationDetails, timingObj: mainState });
      handelTimeStatus(false);
    }
    // eslint-disable-next-line
  }, [mainState]);

  const addTempPhoneFaxToState = (mainState) => {
    const newVar = JSON.parse(JSON.stringify(mainState));
    if (tempPhone !== "" && isPhoneValid) {
      newVar["phoneNo"].push(tempPhone);
      // setLocationDetails(newVar);
    }
    if (tempFax !== "" && isFaxValid) {
      newVar["faxNo"].push(tempFax);
      // setLocationDetails(newVar);
    }
    return newVar;
  };

  const handelTimeStatus = (boolean) => {
    setIsTimingUpdated(boolean);
  };

  const handelArabicInput = (prop) => {
    locationDetails[prop] = "";
    setLocationDetails({ ...locationDetails });
  };

  const handelArabicInputHide = (prop) => {
    locationDetails[prop] = null;
    if (prop === "locationNameArabic") {
      inputErrors[prop + "Error"] = "";
      setErrors({ ...inputErrors });
    }
    setLocationDetails({ ...locationDetails });
  };

  const handelChangeArabic = (prop) => (e) => {
    const { value } = e.target;
    locationDetails[prop] = value;
    setLocationDetails({ ...locationDetails });
    if (prop === "locationNameArabic") {
      if (value === "") {
        inputErrors[prop + "Error"] = `${errorObj[prop]} ${EMPTY_INPUT}`;
      } else {
        inputErrors[prop + "Error"] = "";
      }
    }
    setErrors({ ...inputErrors });
    // console.log(locationDetails);
  };

  const handelDeleteNumber = (number, index) => {
    if (number === "phoneNo" || number === "faxNo") {
      locationDetails[number] = locationDetails[number]?.filter(
        (item, ind) => ind !== index
      );
      inputErrors[number + "ListError"] = inputErrors[
        number + "ListError"
      ]?.filter((item, ind) => ind !== index);
    }
    setLocationDetails({ ...locationDetails });
    setErrors({ ...inputErrors });
  };

  const imageHandler = (e) => {
    const files = e.target.files;

    if (location?.state?.detail === "edit") {
      Array.from(files).forEach((file, ind) => {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[ind]);
        reader.onload = function () {
          imageList.push(reader.result as string);
          setImageList([...imageList]);
        };
      });
    } else {
      Array.from(files).forEach((file, ind) => {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[ind]);
        reader.onload = function () {
          locationDetails?.locationImages?.push(reader.result as string);
          setLocationDetails({ ...locationDetails });
        };
      });
    }

    // console.log(locationDetails, "details")
  };

  const handelRemoveImage = (index) => {
    if (location?.state?.detail === "edit") {
      var filtered = imageList?.filter((item, ind) => ind !== index);
      setImageList([...filtered]);
    } else {
      var filter = locationImages?.filter((item, ind) => ind !== index);
      locationDetails["locationImages"] = filter;
      setLocationDetails({ ...locationDetails });
    }
    // console.log(locationDetails, "details")
  };

  // const regExForCords = /^-?[0-9]{1,10}(?:\[0-9]{1,10})?$/gm;
  const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;
  const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;

  const handlePhoneFaxChange = (prop) => (e) => {
    //with country code
    prop === "phoneNo" ? setTempPhone(e) : setTempFax(e);
    if (e === "") {
      inputErrors[prop + "Error"] = errorObj[prop] + " " + EMPTY_INPUT;
      prop === "phoneNo" ? setIsPhoneValid(false) : setIsFaxValid(false);
    } else {
      inputErrors[prop + "Error"] = null;
      var isValid = checkIsNumberValid(e);
      if (!isValid) {
        inputErrors[prop + "Error"] = errorObj[prop] + ` is invalid`;
        prop === "phoneNo" ? setIsPhoneValid(false) : setIsFaxValid(false);
      } else {
        inputErrors[prop + "Error"] = null;
        prop === "phoneNo" ? setIsPhoneValid(true) : setIsFaxValid(true);
      }
    }
    setErrors({ ...inputErrors });
  };

  const checkIsNumberValid = (number) => {
    const asYouType = new AsYouType();
    asYouType.input(number);
    return asYouType.isValid();
  };

  // const formatNumber =(number)=>{
  //   const asYouType = new AsYouType()
  //   asYouType.input(number)
  //   var num = asYouType.getChars()
  //   return num
  // }

  const handleInputChange = (e) => {
    const { value, id, name } = e?.target;
    locationDetails[name] = value;

    if (value === "") {
      if (name === "googleXcord" || name === "googleYcord") {
        inputErrors[id] = errorObj[name] + " required";
      } else {
        inputErrors[id] = errorObj[name] + " " + EMPTY_INPUT;
      }
    } else if (name === "googleXcord" || name === "googleYcord") {
      // const reg = new RegExp(regExForCords).test(value);
      const reg =
        name === "googleXcord" ? isLatitude(value) : isLongitude(value);
      if (!reg) {
        inputErrors[id] = `invalid ` + errorObj[name];
      } else {
        locationDetails[name] = value;
        inputErrors[id] = "";
      }
    } else {
      inputErrors[id] = "";
    }
    setLocationDetails({ ...locationDetails });
    setErrors({ ...inputErrors });
    // console.log(locationDetails);
    // console.log(inputErrors);
  };

  const handelAddNumber = (number) => {
    if (tempPhone === "" || tempFax === "") {
      number === "phoneNo" ? setIsPhoneValid(false) : setIsFaxValid(false);
    }
    if (number === "phoneNo" ? isPhoneValid : isFaxValid) {
      if (number === "phoneNo" || number === "faxNo") {
        locationDetails[number] = [
          ...locationDetails[number],
          number === "phoneNo" ? tempPhone : tempFax,
        ];
        number === "phoneNo" ? setTempPhone("") : setTempFax("");
        inputErrors[number + "Error"] = "";
      }
    } else {
      inputErrors[number + "Error"] = "Invalid entry !!";
    }
    setLocationDetails({ ...locationDetails });
    // console.log(locationDetails);
    setErrors({ ...inputErrors });
    // console.log(inputErrors);
  };

  const handleEditNumber = (e, number, index) => {
    const { value } = e?.target;
    var isValid = checkIsNumberValid(value);
    if (!isValid) {
      inputErrors[number + "ListError"][index] =
        errorObj[number] + ` is invalid`;
    } else {
      inputErrors[number + "ListError"][index] = null;
    }
    const newDetails = JSON.parse(JSON.stringify(locationDetails));
    newDetails[number][index] = value;
    setLocationDetails({ ...newDetails });
    // console.log(newDetails);
  };

  // console.log(locationDetails);
  const checkNumberValid = (array, id) => {
    array.forEach((item, index) => {
      var isValid = checkIsNumberValid(item);
      if (!isValid) {
        inputErrors[id + "ListError"][index] = errorObj[id] + ` is invalid`;
      } else {
        inputErrors[id + "ListError"][index] = null;
      }
    });
  };

  const handelSave = async () => {
    var flag1, flag2, cords;
    const filteredKeys = Object.keys(locationDetails).filter(
      (key) => locationDetails[key] === ""
    );
    var idx = filteredKeys.indexOf("addressArabic");
    var idx2 = filteredKeys.indexOf("address");

    if (idx !== -1) filteredKeys.splice(idx, 1);
    if (idx2 !== -1) filteredKeys.splice(idx2, 1);

    if (filteredKeys.length === 0) {
      flag1 = true;
    } else {
      filteredKeys.forEach((key) => {
        if (key === "googleXcord" || key === "googleYcord") {
          inputErrors[key + "Error"] = errorObj[key] + " required";
        } else {
          inputErrors[key + "Error"] = errorObj[key] + " " + EMPTY_INPUT;
        }
      });
    }

    if (googleXcord === "" || googleYcord === "") {
      if (googleXcord === "") {
        inputErrors["googleXcordError"] = errorObj["googleXcord"] + " required";
      }
      if (googleYcord === "") {
        inputErrors["googleYcordError"] = errorObj["googleYcord"] + " required";
      }
      setErrors({ ...inputErrors });
    } else {
      if (isLatitude(googleXcord) && isLongitude(googleYcord)) {
        cords = true;
      } else {
        cords = false;
        if (!isLatitude(googleXcord)) {
          inputErrors["googleXcordError"] =
            `invalid ` + errorObj["googleXcord"];
        }
        if (!isLongitude(googleYcord)) {
          inputErrors["googleYcordError"] =
            `invalid ` + errorObj["googleYcord"];
        }
        setErrors({ ...inputErrors });
      }
    }

    var phone = false;
    var fax = false;

    if (phoneNo && phoneNo.length === 0 && tempPhone === "") {
      inputErrors["phoneNoError"] = "Phone Number " + EMPTY_INPUT;
    } else {
      checkNumberValid(phoneNo, "phoneNo");
      var matchingLenPhone = phoneNoListError.filter((item) => {
        // console.log(!Boolean(item));
        return !Boolean(item);
      }).length;

      if (phoneNoListError.length === matchingLenPhone) {
        if (tempPhone !== "") {
          if (isPhoneValid) {
            phone = true;
          } else {
            phone = false;
          }
        } else {
          phone = true;
        }
      }
    }
    if (faxNo && faxNo.length === 0 && tempFax === "") {
      inputErrors["faxNoError"] = "Fax Number " + EMPTY_INPUT;
    } else {
      checkNumberValid(faxNo, "faxNo");
      var matchingLenFax = faxNoListError.filter(
        (item) => !Boolean(item)
      ).length;
      if (faxNoListError.length === matchingLenFax) {
        if (tempFax !== "") {
          if (isFaxValid) {
            fax = true;
          } else {
            fax = false;
          }
        } else {
          fax = true;
        }
      }
    }

    if (phone && fax) {
      flag2 = true;
    }

    setErrors({ ...inputErrors });

    if (flag1 && flag2 && cords) {
      var newLocationDetails = addTempPhoneFaxToState(locationDetails);
      try {
        setLoading(true);
        if (location?.state?.detail === "edit") {
          var result = await dispatch(
            UpdateLocationThunk({
              ...newLocationDetails,
              locationImages: imageList,
            })
          ).unwrap();
          enqueueSnackbar(result.data.response, {
            variant: "success",
          });
          setLoading(false);
          history.push({
            pathname: LOCATION,
            state: { from: "edit" },
          });
        } else {
          var addResult = await dispatch(
            addLocationInfoThunk(newLocationDetails)
          ).unwrap();
          enqueueSnackbar(addResult.data.response, {
            variant: "success",
          });
          setLoading(false);
          history.push({
            pathname: LOCATION,
            state: { from: "edit" },
          });
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    }
  };

  const handelCancel = () => {
    history.push({
      pathname: LOCATION,
      state: { from: "edit" },
    });
  };

  return (
    <LocationContainer>
      <HeaderDiv>
        <LeftDiv>
          <HeadingDiv>{`${
            location?.state?.detail === "edit"
              ? "Edit Location"
              : "Add Location"
          }`}</HeadingDiv>
        </LeftDiv>
        <RightDiv>
          <LoaderButton
            submitHandler={handelSave}
            isLoading={loading}
            buttonText="Save"
            styles={loaderBtnStyleFunc("40%", "2.8rem")}
          />
          <CancelButton
            variant="contained"
            color="default"
            onClick={handelCancel}
          >
            Cancel
          </CancelButton>
        </RightDiv>
      </HeaderDiv>
      <ParentContainer>
        <FormContainer>
          <FormLeftSide>
            <FieldContainer>
              <InputLabelText>Location Type</InputLabelText>
              <DropdownSelector
                onChange={handleInputChange}
                variant="outlined"
                name="locationType"
                value={locationType}
                MenuProps={DROPDOWN_MENU_PROPS}
              >
                {locationArr.map((item, index) => {
                  return (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </DropdownSelector>
            </FieldContainer>
            <FieldContainer>
              <InputLabelText>
                Location Name<Required>*</Required>
              </InputLabelText>
              <InputTextField
                name="locationName"
                value={locationName}
                error={Boolean(locationNameError)}
                helperText={locationNameError}
                onChange={handleInputChange}
                id="locationNameError"
                variant="outlined"
                size="small"
              />
              {locationNameArabic === null && (
                <TextWrapper>
                  <Small
                    onClick={() => handelArabicInput("locationNameArabic")}
                  >
                    Enter in arabic
                  </Small>
                </TextWrapper>
              )}
            </FieldContainer>

            <FieldContainer>
              {typeof locationNameArabic === "string" && (
                <>
                  <InputLabelText>
                    Location Name (Arabic)<Required>*</Required>
                  </InputLabelText>
                  <FlexBoxArabic>
                    <InputTextField
                      name="locationNameArabic"
                      value={locationNameArabic}
                      onChange={handelChangeArabic("locationNameArabic")}
                      error={Boolean(locationNameArabicError)}
                      helperText={locationNameArabicError}
                      variant="outlined"
                      size="small"
                      dir="rtl"
                    />
                    <CancelInput
                      onClick={() =>
                        handelArabicInputHide("locationNameArabic")
                      }
                    />
                  </FlexBoxArabic>
                </>
              )}
            </FieldContainer>
            {locationType === "Service Center" ? (
              <FieldContainer>
                <InputLabelText>
                  Service Center Name<Required>*</Required>
                </InputLabelText>
                <InputTextField
                  name="serviceCenter"
                  value={serviceCenter}
                  error={Boolean(serviceCenterError)}
                  helperText={serviceCenterError}
                  onChange={handleInputChange}
                  id="serviceCenterError"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    style: {
                      fontSize: 14,
                      background: `${
                        locationType === "Service Center" ? "" : "#F0F0F0"
                      }`,
                    },
                  }}
                />
              </FieldContainer>
            ) : null}
            <FieldContainer>
              <InputLabelText>Area Name</InputLabelText>
              <InputTextField
                name="areaName"
                value={areaName}
                onChange={handleInputChange}
                variant="outlined"
                size="small"
              />
              {areaNameArabic === null && (
                <TextWrapper>
                  <Small onClick={() => handelArabicInput("areaNameArabic")}>
                    Enter in arabic
                  </Small>
                </TextWrapper>
              )}
            </FieldContainer>

            <FieldContainer>
              {typeof areaNameArabic === "string" && (
                <>
                  <InputLabelText>Area Name (Arabic)</InputLabelText>
                  <FlexBoxArabic>
                    <InputTextField
                      name="areaNameArabic"
                      value={areaNameArabic}
                      onChange={handelChangeArabic("areaNameArabic")}
                      variant="outlined"
                      size="small"
                      dir="rtl"
                    />
                    <CancelInput
                      onClick={() => handelArabicInputHide("areaNameArabic")}
                    />
                  </FlexBoxArabic>
                </>
              )}
            </FieldContainer>

            <FieldContainer>
              <InputLabelText>
                Phone Number<Required>*</Required>
              </InputLabelText>
              <FlexBoxContainer>
                <PhoneNumberInput
                  defaultCountry={"bh"}
                  error={Boolean(phoneNoError)}
                  helperText={phoneNoError}
                  onChange={handlePhoneFaxChange("phoneNo")}
                  value={tempPhone}
                  variant="outlined"
                  size="small"
                  disableAreaCodes={true}
                />
                <AddIconLogo onClick={() => handelAddNumber("phoneNo")} />
              </FlexBoxContainer>
              {phoneNo && phoneNo?.length > 0
                ? phoneNo.map((value, index) => {
                    return (
                      <FlexBoxContainer key={`input_${index}`}>
                        <FlexChildInput
                          variant="outlined"
                          size="small"
                          autoFocus
                          value={value}
                          name="phoneNo"
                          error={Boolean(inputErrors.phoneNoListError[index])}
                          helperText={inputErrors.phoneNoListError[index]}
                          onChange={(e) =>
                            handleEditNumber(e, "phoneNo", index)
                          }
                        />
                        <CancelInput
                          id="phoneNo"
                          onClick={() => handelDeleteNumber("phoneNo", index)}
                        />
                      </FlexBoxContainer>
                    );
                  })
                : null}
            </FieldContainer>

            <FieldContainer>
              <InputLabelText>Address</InputLabelText>
              <InputTextArea
                onChange={handleInputChange}
                value={address}
                name="address"
                id="addressError"
                variant="outlined"
                size="small"
                multiline={true}
                rows={3}
              />
              {addressArabic === null && (
                <TextWrapper>
                  <Small onClick={() => handelArabicInput("addressArabic")}>
                    Enter in arabic
                  </Small>
                </TextWrapper>
              )}
            </FieldContainer>

            <FieldContainer>
              {typeof addressArabic === "string" && (
                <>
                  <InputLabelText>Address (Arabic)</InputLabelText>
                  <FlexBoxArabic>
                    <InputTextArea
                      onChange={handelChangeArabic("addressArabic")}
                      value={addressArabic}
                      name="addressArabic"
                      variant="outlined"
                      size="small"
                      multiline={true}
                      rows={3}
                      dir="rtl"
                    />
                    <CancelInputTextArea
                      onClick={() => handelArabicInputHide("addressArabic")}
                    />
                  </FlexBoxArabic>
                </>
              )}
            </FieldContainer>
          </FormLeftSide>

          <FormRightSide>
            <FieldContainer>
              <TimingComponent
                mainState={mainState}
                setMainState={setMainState}
                handelTimeStatus={handelTimeStatus}
              />
            </FieldContainer>

            <FieldContainer>
              <InputLabelText>Google Coordinates</InputLabelText>
              <CoordinateBox>
                <CoordinateInputLabel>
                  Latitude<Required>*</Required>
                </CoordinateInputLabel>
                <CoordinateInputField
                  name="googleXcord"
                  value={googleXcord}
                  error={Boolean(googleXcordError)}
                  helperText={googleXcordError}
                  onChange={handleInputChange}
                  id="googleXcordError"
                  variant="outlined"
                  size="small"
                />
                <CoordinateInputLabel>
                  Longitude<Required>*</Required>
                </CoordinateInputLabel>
                <CoordinateInputField
                  name="googleYcord"
                  value={googleYcord}
                  error={Boolean(googleYcordError)}
                  helperText={googleYcordError}
                  onChange={handleInputChange}
                  id="googleYcordError"
                  variant="outlined"
                  size="small"
                />
              </CoordinateBox>
            </FieldContainer>

            <FieldContainer>
              <InputLabelText>Status</InputLabelText>
              <DropdownSelector
                onChange={handleInputChange}
                variant="outlined"
                name="status"
                value={status}
                MenuProps={DROPDOWN_MENU_PROPS}
              >
                {statusArr.map((item, index) => {
                  return (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </DropdownSelector>
            </FieldContainer>

            <FieldContainer>
              <InputLabelText>
                Fax Number<Required>*</Required>
              </InputLabelText>
              <FlexBoxContainer>
                <FaxNumberInput
                  defaultCountry={"bh"}
                  error={Boolean(faxNoError)}
                  helperText={faxNoError}
                  onChange={handlePhoneFaxChange("faxNo")}
                  value={tempFax}
                  variant="outlined"
                  size="small"
                  disableAreaCodes={true}
                />
                <AddIconLogo
                  onClick={() => handelAddNumber("faxNo")}
                  id="faxNo"
                />
              </FlexBoxContainer>

              {faxNo && faxNo?.length > 0
                ? faxNo.map((value, index) => {
                    return (
                      <FlexBoxContainer key={`inputFax_${index}`}>
                        <FlexChildInput
                          value={value}
                          name="faxNo"
                          variant="outlined"
                          size="small"
                          error={Boolean(faxNoListError[index])}
                          helperText={faxNoListError[index]}
                          onChange={(e) => handleEditNumber(e, "faxNo", index)}
                        />
                        <CancelInput
                          onClick={() => handelDeleteNumber("faxNo", index)}
                        />
                      </FlexBoxContainer>
                    );
                  })
                : null}
            </FieldContainer>
          </FormRightSide>
        </FormContainer>

        <ImgWholeContainer>
          <InputLabelText>Images</InputLabelText>
          <ImageFlexContainer>
            <ImageInput
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={imageHandler}
              id="inputId"
              multiple
            />
            <AddImageContainer htmlFor="inputId">
              <AddImage />
            </AddImageContainer>
            {location?.state?.detail === "edit"
              ? imageList?.length !== 0 &&
                imageList?.map((item, index) => (
                  <ChildImageContainer key={`images_${index}`}>
                    <ChildImage src={item} alt={index + " LocationImage"} />
                    <RemoveImageLogo onClick={() => handelRemoveImage(index)} />
                  </ChildImageContainer>
                ))
              : locationImages?.length !== 0 &&
                locationImages?.map((item, index) => (
                  <ChildImageContainer key={`images_${index}`}>
                    <ChildImage src={item} alt={index + " LocationImage"} />
                    <RemoveImageLogo onClick={() => handelRemoveImage(index)} />
                  </ChildImageContainer>
                ))}
          </ImageFlexContainer>
        </ImgWholeContainer>
      </ParentContainer>
    </LocationContainer>
  );
}

export default AddLocation;
