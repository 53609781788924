import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux-store/hooks";
import {
  getRoadsideAssistanceThunk,
  setCurrentPageReducer,
} from "slices/services/road-side-assistance";
import PaginationFooter from "shared-components/pagination";
import {
  GridColDef,
  GridRowModel,
  GridRowId,
  GridCellParams,
} from "@material-ui/data-grid";

import TableData from "shared-components/table-data";
import { CsvBuilder } from "filefy";
import { format } from 'date-fns'

import { RoadSideAssistance } from "interfaces/services/roadside-assistance-interface";

import { MainContainer } from "pages/services/roadside-assistance/components/roadside-assistance-table/styles";
import { RenderCellExpand } from "shared-components/expanded-cel";
import { loadingHelper } from "constants/loadingHelper";
import { useSnackbar } from "notistack";

import { getRoadsideAssistanceDetails } from "services/services/roadside-assistance-services";
import { roadsideAssistanceFilter } from "services/services/roadside-assistance-services";


interface Props {
  exportData?: boolean;
  setExportData?: any;
  filterFunc: (pageNumber) => void;
  filterFlag: boolean;
  selectedLang: string;
  recordLimit: number;
  filterState: any,
}
const UsersTable = ({
  exportData,
  setExportData,
  filterFunc,
  filterFlag,
  selectedLang,
  recordLimit,
  filterState,
}: Props) => {

  let allRecords: RoadSideAssistance[] = []
  let filteredRecords: RoadSideAssistance[] = []

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<Array<RoadSideAssistance>>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  const handleCheckedData = (newSelection) => {
    setSelectionModel(newSelection.selectionModel);
  };
  useEffect(() => {
    if (filterFlag) {
      filterFunc(1);
    } else {
      setLoading(true)
      dispatch(getRoadsideAssistanceThunk({ pageNumber: 1, limit: recordLimit }))
        .unwrap()
        .then((res) => {
          setLoading(false)
          if ("data" in res.data) {
            enqueueSnackbar(res.data.data, { variant: "warning" });
          }
        })
        .catch((err) => {
          setLoading(false)
          enqueueSnackbar(err.message, { variant: "error" });
        });
      dispatch(setCurrentPageReducer(1));
    }
    // eslint-disable-next-line
  }, [dispatch, recordLimit, brandName]);

  const roadsideDetails = useAppSelector(
    (state) => state.roadsideDetails.roadsideAssistanceInfo
  );

  useEffect(() => {
    setRows(roadsideDetails.roadsideAssistanceDetails);
    setPaginationSize(Math.ceil(roadsideDetails.recordsLength / recordLimit));
    // eslint-disable-next-line
  }, [
    roadsideDetails.roadsideAssistanceDetails,
    roadsideDetails.recordsLength,
  ]);

  const handlePagination = (event, number) => {
    if (filterFlag) {
      filterFunc(number);
    } else {
      loadingHelper(
        dispatch(
          getRoadsideAssistanceThunk({ pageNumber: number, limit: recordLimit })
        ),
        setLoading,
        enqueueSnackbar
      );
    }
    dispatch(setCurrentPageReducer(number));
  };

  const columns: GridColDef[] = [
    {
      field: "roadSideAsisstanceId",
      headerName: "Booking ID",
      sortable: false,
      flex: 0.8,
    },
    {
      field: "name",
      headerName: "Customer Name",
      sortable: false,
      resizable: false,
      flex: 1.2,
      renderCell: RenderCellExpand,
    },
    {
      field: "vinNumber",
      sortable: false,
      headerName: "Vehicle Vin Number",
      resizable: false,
      flex: 1.3,
    },
    {
      field: `${selectedLang === "English" ? "emergencyType" : "emergencyTypeArabic"
        }`,
      headerName: "Emergency",
      sortable: false,
      resizable: false,
      flex: 1.4,
      renderCell: (params: GridCellParams) => {
        return RenderCellExpand(params, "emergencyTypeArabic", 300, selectedLang);
      },
    },
    {
      field: "bookingDate",
      sortable: false,
      headerName: "Date and Time",
      resizable: false,
      flex: 1.4,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.bookingDate} | {params.row.bookingTime}
          </>
        );
      },
    },
    // {
    //   field: "serviceStatus",
    //   headerName: "Service Status",
    //   sortable: false,
    //   resizable: false,
    //   flex: 1,
    //   renderCell: (params: GridCellParams) => {
    //     if (params.row.serviceStatus) {
    //       return (
    //         <CustomTagGreen>
    //           <InprogressCircle />
    //           <StatusText>{params.row.serviceStatus}</StatusText>
    //         </CustomTagGreen>
    //       );
    //     } else {
    //       return (
    //         <CustomTagGreen>
    //           <CompletedCircle />
    //           <StatusText>{params.row.serviceStatus}</StatusText>
    //         </CustomTagGreen>
    //       );
    //     }
    //   },
    // },
  ];

  const csvBuilder = (filename, rowsToCsv) => {
    const currentDateTime = format(new Date(), 'yyyy_MM_dd_HH_mm');
    const formattedFilename = `${filename}_${currentDateTime}.csv`;

    new CsvBuilder(formattedFilename)
      .setColumns(columns.map((col) => col?.headerName as string) as string[])
      .addRows(rowsToCsv as string[][])
      .exportFile();
  };

  const filterRowsData = (data) => {
    let res: Array<string> = [];
    columns.map((col) => {
      Object.entries(data).forEach(([key, value]) => {
        if (selectedLang === "English") {
          if (key === col.field) {
            res.push(value as string);
          }
        } else {
          if (key === col.field) {
            if (key === "emergencyTypeArabic" && !value) {
              res.push(data['emergencyType']);
            } else {
              res.push(value as string);
            }
          }
        }
      });
      return res;
    });
    return res;
  };

  const exportRoadSideAssistanceData = async () => {
    try {
      let request = {
        pageNumber: 1,
        limit: recordLimit,
      }
      let response = await getRoadsideAssistanceDetails(request)
      const totalRecords = response.data.totalRecords

      request = {
        pageNumber: 1,
        limit: Math.ceil(totalRecords),
      }
      response = await getRoadsideAssistanceDetails(request)
      allRecords = response.data.listEntityClass
      console.log(allRecords)

      let allRecordsArray = allRecords.map(obj => [
        obj.roadSideAsisstanceId,
        obj.name,
        obj.vinNumber,
        obj.emergencyType,
        `${obj.bookingDate} | ${obj.bookingTime}`,
      ]);

      csvBuilder('Roadside_Assistance', allRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  const exportFilteredRoadSideAssistanceData = async () => {
    try {

      let request = {
        pageNumber: 1,
        limit: recordLimit,
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        message: filterState.message,
      }

      let response = await roadsideAssistanceFilter(request)
      const filteredCustomers = response.data.totalRecords

      request = {
        pageNumber: 1,
        limit: Math.ceil(filteredCustomers),
        startDate: filterState.startDate,
        endDate: filterState.endDate,
        message: filterState.message,
      }
      response = await roadsideAssistanceFilter(request)
      filteredRecords = response.data.listEntityClass
      console.log(filteredRecords)
      let filteredRecordsArray = filteredRecords.map(obj => [
        obj.roadSideAsisstanceId,
        obj.name,
        obj.vinNumber,
        obj.emergencyType,
        `${obj.bookingDate} | ${obj.bookingTime}`,
      ]);
      csvBuilder('Roadside_Assistance', filteredRecordsArray)

    } catch (error) {
      console.log("error")
    }
  }

  useEffect(() => {

    if (exportData) {
      if (!filterFlag) {
        exportRoadSideAssistanceData()
      } else {
        exportFilteredRoadSideAssistanceData()
      }
      setExportData(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportData]);


  return (
    <>
      <MainContainer>

        <TableData
          rows={loading ? [] : (rows as GridRowModel[])}
          isLoading={loading}
          getRowId={(r) => r.roadSideAsisstanceId}
          columns={columns}
          isCheckBox={true}
          handleCheckedData={handleCheckedData}
          isPagination={true}
          pageSize={recordLimit}
        />
      </MainContainer>
      {paginationSize > 1 ? (
        <PaginationFooter
          handleChange={(event, value) => {
            handlePagination(event, value);
          }}
          dataSize={paginationSize}
          pageNumber={roadsideDetails?.currentPage}
        />
      ) : null}
    </>
  );
};

export default UsersTable;
