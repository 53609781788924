import NotificationState from "slices/notifications/notifications-interface";

const notificationsInitialState: NotificationState = {
  notificationsInfo: {
    allNotifications: [],
    currentNotification: {},
    recordsLength: 0,
    currentPage: 1,
  },
};

export default notificationsInitialState;
