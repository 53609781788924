import { Button } from '@material-ui/core';
import styled from 'styled-components'

export const Container = styled.div`
    margin:1rem 2rem;
    padding-top: 2rem;
`;

export const InnerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const H1Tag = styled.h1`
    font-size: 120px;
    margin-bottom: 0.5rem;
    @media (max-width: 575.98px) {
        font-size: 86px;
    }
`;

export const SpanTag = styled.span`
    color: ${({ theme }) => theme.color} !important;
    position: relative;

    .error-img{
        position: absolute;
        width: 120px;
        left: -23px;
        right: 0;
        bottom: 47px;

        @media (max-width: 575.98px) {
            width: 86px;
            left: -12px;
            bottom: 38px;
        }
    }
`;

export const H3Tag = styled.h3`
    text-transform: uppercase;
    margin-top: 0.5rem;
`;

export const BtnDiv = styled.div`
    margin-top:0.5rem;
    text-align: center;
`;

export const NewButton = styled(Button)`
  text-transform: none !important;
  font-weight: 800;
`;