import CustomerState from "slices/customers/customers-interface";

const customerInitialState: CustomerState = {
  customerInfo: {
    customerDetails: [],
    // customerOwnerDetails: [],
    // customerGuestDetails: [],
    currentCustomer: {},
    ownedCars: [],
    carsServiceHistory: [],
    transactionHistory: [],
    currentPage: 1,
    tabType: "",
    totalRecordsLength: 0,
  },
};

export default customerInitialState;
