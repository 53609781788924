import axios from "axios";
import selectedBrand from "constants/brand";

import {
  ADD_FAQ_ENDPOINT,
  BASE_URL,
  DELETE_FAQ_ENDPOINT,
  GET_ALL_FAQS_ENDPOINT,
  SEARCH_FAQ_ENDPOINT,
  UPDATE_FAQ_ENDPOINT,
} from "constants/api-config-constants";

import { limit } from "constants/pagination-constants";

import {
  GetFaqsRequest,
  FaqObject,
  GetFaqsResponse,
  DeleteFaqResponse,
  SearchFaqs,
} from "interfaces/faq-interfaces";

// get all faqs from database
export const getAllFaqs = async (
  request: GetFaqsRequest
): Promise<GetFaqsResponse> => {
  const { pageno } = request;
  const url =
    BASE_URL +
    GET_ALL_FAQS_ENDPOINT +
    "?pageno=" +
    pageno +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

// get single faq by id from database
// export const getSingleFaq = async (
//   request: getFaqById
// ): Promise<GetFaqsResponse> => {
//   const url = BASE_URL + "GET_SINGLE_FAQ_ENDPOINT" + "?id=" + request.faqId;
//   return axios.post(url, request);
// };

// add new faq in database
export const addNewFaq = async (
  request: FaqObject
): Promise<GetFaqsResponse> => {
  const url = BASE_URL + ADD_FAQ_ENDPOINT + "?brand=" + selectedBrand();
  return axios.post(url, request);
};

// update faq by id in database
export const updateFaq = async (
  request: FaqObject
): Promise<GetFaqsResponse> => {
  const url = BASE_URL + UPDATE_FAQ_ENDPOINT + "?id=" + request.id;
  return axios.put(url, request);
};

// delete faq by id from database
export const deleteFaq = async (faqId: number): Promise<DeleteFaqResponse> => {
  const url = BASE_URL + DELETE_FAQ_ENDPOINT + "?id=" + faqId;
  return axios.delete(url);
};

// Search Faqs from particular brand
export const getFaqsBySearch = async (
  request: SearchFaqs
): Promise<GetFaqsResponse> => {
  const { query, pageno } = request;
  const url =
    BASE_URL +
    SEARCH_FAQ_ENDPOINT +
    "?pageno=" +
    pageno +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.post(url, query, { headers: { "Content-Type": "text/plain" } });
};
