import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { PARAGRAPH_FONT_SIZE } from "constants/style/font-size";
import { PRIMARY_REGULAR_FONT } from "constants/style/fonts";
import BackIcon from "shared-components/icons/back-icon";

export const NavItem = styled(Link)`
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  font-family: ${PRIMARY_REGULAR_FONT};
  font-size: ${PARAGRAPH_FONT_SIZE};
`;

export const NewsContainer = styled.div`
  padding: 0.5rem 1rem 0.5rem 1rem;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
`;

export const Content = styled.div`
  margin: 1.5rem 0.5rem;
`;

export const HeadingContainer= styled.div`
  margin: 1.5rem 0.5rem;
  position: relative;
`;

export const HeadingComp = styled.h4``;

export const HeadingDiv= styled.div`
  position: absolute;
  bottom: -7%;
  width: 100%;
  display: flex;
  justify-content: center;
`;


export const ParaContent = styled.div`
  font-family: ${PRIMARY_REGULAR_FONT};
  font-size: ${PARAGRAPH_FONT_SIZE};
  line-height: 1.5rem;

  ${"img"} {
    width: 100%;
    height: 75vh;
  }
`;

export const HeadingContent = styled.div`
  background-color:#fff;
  padding:1.5rem;
  border-radius: 3rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  font-size: 1.1rem;
  font-weight: bold;
  border-left: 5px solid black;
  border-right: 5px solid black;
`;

export const LoadingComp = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 55%;
`;

export const ImageDiv =styled.div`
  position: relative;
  height:28rem;

  ${"img"} {
    position: absolute;
    width: 80%;
    height: 100%;
    top:5px;
    left:100px;
    border-radius: 1rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  }
`;

export const NewImageDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height:25rem;
    background-color:#F4F4F4;
    border-radius: 20px;
`;

export const BodyContainer = styled.div`
  margin-top:4rem;
`;

export const BackIconLogo = styled(BackIcon)`
  margin-right: 1.25rem;
  fill: ${({ theme }) => theme.color};
`;