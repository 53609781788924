import React, { ReactElement, useEffect, ReactNode, useRef } from "react";
import { useHistory } from "react-router-dom";

import { RootState } from "redux-store/store";
import { useAppSelector } from "redux-store/hooks";

import TabSelector from "shared-components/tab-selector";

import DetailsComponent from "pages/customers/components/details";
import { BackIconLogo } from "pages/news/components/news-details/styles";

import { CUSTOMERS } from "constants/route/route-constants";
import { customer } from "constants/dummy-data/customer-details";
// import { useDispatch,useSelector } from "react-redux";

import {
  CustomerContainer,
  Heading,
  LeftDiv,
  HeaderDiv,
} from "pages/customers/styles";

// import {
//   getCustomerById,
//   getCustomersOwnedCarsThunk,
//   getCustomersBookingHistoryThunk
// } from 'slices/customers/customers-thunks'

function CustomerDetails(): ReactElement {
  const history = useHistory();
  const ref = useRef<any>();
  // const dispatch = useDispatch();
  // const location = useLocation<any>();
  // const customersData = useSelector(
  //   (state: RootState) => state.customers.customerInfo
  // );
  // const {currentCustomer,ownedCars} = customersData;

  // for redirection only when ( brand switches ) on info screen
  const brandName = useAppSelector((state: RootState) => {
    return state.brand.brandType;
  });

  useEffect(() => {
    if (ref.current !== undefined && ref.current !== brandName) {
      return history.push(CUSTOMERS);
    }
    ref.current = brandName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName, history]);

  // const [userId, setUserId] = useState();
  // console.log(userId);

  // useEffect(() => {
  //   setUserId(location?.state?.userId);
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  // useEffect(()=>{
  //   dispatch(getCustomerById(location?.state.userId))
  //   dispatch(getCustomersOwnedCarsThunk(location?.state.userId))
  //   dispatch(getCustomersBookingHistoryThunk({cprNo:currentCustomer?.crCprGccId as string,showServiceHistory:"yes"}))
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  const components: Array<ReactNode> = [
    <DetailsComponent data="currentCustomer" />,
    <DetailsComponent data="ownedCars" />,
    <DetailsComponent data="bookingHistory" />,
    // <DetailsComponent data="transactionHistory" />,
  ];

  const handelBack = () => {
    history.push({
      pathname: `${CUSTOMERS}`,
      state: { from: "Back" },
    });
  };

  return (
    <CustomerContainer>
      <HeaderDiv>
        <LeftDiv>
          <BackIconLogo onClick={handelBack} />
          <Heading>Customer Details</Heading>
        </LeftDiv>
      </HeaderDiv>
      <TabSelector
        labelArray={Object.keys(customer)}
        children={components as Array<ReactNode>}
      />
    </CustomerContainer>
  );
}

export default CustomerDetails;
