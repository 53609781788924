import React, { ReactElement, useEffect, useState } from "react";
import LoaderButton from "shared-components/loader-button";
import {
  LeftDiv,
  MainDiv,
  RightDiv,
  HeaderDiv,
  CancelButton,
  Heading,
  ButtonContainer,
  InputTextField,
  InputFieldContainer,
  NavContainer,
  FieldContainer,
  FlexBoxArabicEO,
  CancelInputEO,
  InputTextFieldParent,
  InputTextFieldChild,
} from "pages/services/roadside-assistance/components/edit-emergency-options/styles";

import {
  InputLabelText,
  OptionsContainer,
  AddIconLogo,
  RemoveIconLogo,
} from "pages/live-chat/styles";
import {
  Small,
  TextWrapper,
} from "pages/location/components/add-location/styles";
import { useSnackbar } from "notistack";
import {
  getEmergencyOptions,
  updateEmergencyOptions,
} from "services/services/roadside-assistance-services";
import { useAppSelector } from "redux-store/hooks";
import { LoadingComp } from "pages/news/components/news-details/styles";
import { ROADSIDE_ASSISTANCE } from "constants/route/route-constants";
import { useHistory } from "react-router";
import { loaderBtnStyleFunc } from "constants/style/props";


interface OptionObj {
  id?: number;
  message?: string;
  arabicMessage?: string | null;
}

interface initialStateInterface {
  emergencyPhone: string;
  messageList: Array<OptionObj>;
  deletedList: Array<number>;
}

const initialState: initialStateInterface = {
  emergencyPhone: "",
  messageList: [],
  deletedList: [],
};

interface OptionObjErrors {
  messageError?: string;
  arabicMessageError?: string;
}

const initialErrors: {
  mobileNumberError: string;
  messageListErrors: Array<OptionObjErrors>;
} = {
  mobileNumberError: "",
  messageListErrors: [],
};


function EmergencyOptions(): ReactElement {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [messages, setMessages] = useState<initialStateInterface>(initialState);
  const [dummyErrors, setDummyErrors] = useState({ ...initialErrors });
  const [errors, setErrors] = useState({ ...initialErrors });
  const [initialInputError, setInitialInputError] = useState("");
  const [initialInputValue, setInitialInputValue] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const { messageListErrors, mobileNumberError } = errors;

  const { emergencyPhone } = messages;

  const brandName = useAppSelector((state) => {
    return state.brand.brandType;
  });

  const getEmergencyOptionsApi = async () => {
    setLoading(true);
    try {
      const result = await getEmergencyOptions();
      if (result?.data) {
        const { data } = result?.data;
        setMessages({ ...data, deletedList: [] });
        var emt: any = [];
        data?.messageList?.forEach((i, ind) => {
          emt.push({ messageError: "", arabicMessageError: "" });
        });
        setErrors({ ...errors, messageListErrors: emt });
        setDummyErrors(
          JSON.parse(JSON.stringify({ ...errors, messageListErrors: emt }))
        );
      }
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.data, { variant: "error" });
      setLoading(false);
    }
  };

  const updateEmergencyOptionsApi = async () => {
    setBtnLoading(true);
    try {
      const result = await updateEmergencyOptions(messages);
      if (result.data.response) {
        enqueueSnackbar(result.data.response, {
          variant: "success",
        });
      }
      setBtnLoading(false);
      getEmergencyOptionsApi()
    } catch (err: any) {
      if(err.response.status === 500){
        enqueueSnackbar("You must add arabic text to the options", {
          variant: "error",
        });
      }else{
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      }
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    getEmergencyOptionsApi();
    // eslint-disable-next-line
  }, [brandName]);

  const handleInput = (e) => {
    const { value } = e.target;
    if (value === "") {
      setInitialInputError("Option Should not be Empty");
    } else {
      setInitialInputError("");
    }
    setInitialInputValue(value);
  };

  const addInputValue = () => {
    if (initialInputValue === "") {
      setInitialInputError("Option Should not be Empty");
    } else {
      messages?.messageList.push({
        message: initialInputValue,
        arabicMessage: null,
      });
      errors?.messageListErrors.push({
        messageError: "",
        arabicMessageError: "",
      });
      dummyErrors?.messageListErrors.push({
        messageError: "",
        arabicMessageError: "",
      });
      setMessages({ ...messages });
      setInitialInputError("");
      setInitialInputValue("");
      setErrors({ ...errors });
      setDummyErrors({ ...dummyErrors });
    }
  };

  const handleOptionInput = (e, index) => {
    const { value } = e.target;
    const { messageList } = messages;
    if (value === "") {
      messageListErrors[index].messageError = "Option should not be empty";
    } else {
      messageListErrors[index].messageError = "";
    }
    messageList[index].message = value;
    setMessages({ ...messages });
    setErrors({ ...errors });
  };

  const handleChangeArabicInput = (e, index) => {
    const { value } = e.target;
    const { messageList } = messages;
    if (value === "") {
      messageListErrors[index].arabicMessageError =
        "Option should not be empty";
    } else {
      messageListErrors[index].arabicMessageError = "";
    }
    messageList[index].arabicMessage = value;
    setMessages({ ...messages });
    setErrors({ ...errors });
  };

  const deleteOptionInput = (e, index, id) => {
    // console.log(id);
    const { messageList, deletedList } = messages;
    if (!!id) {
      deletedList.push(id);
    }
    messageList.splice(index, 1);
    setMessages({ ...messages });
    messageListErrors.splice(index, 1);
    setErrors({ ...errors });
    dummyErrors.messageListErrors.splice(index, 1);
    setDummyErrors({ ...dummyErrors });
  };

  const handleEmergencyPhone = (e) => {
    const { value } = e.target;
    if (value === "") {
      errors.mobileNumberError = "Phone Number should not be empty";
    } else {
      errors.mobileNumberError = "";
    }
    if(!isNaN(value)) {
      messages.emergencyPhone = value;
      setMessages({ ...messages });
    }
    setErrors({ ...errors });
  };

  const handelArabicInputShow = (index) => {
    const res = messages?.messageList.map((item, ind) => {
      if (ind === index) {
        item.arabicMessage = "";
      }
      return item;
    });
    setMessages({ ...messages, messageList: res });
  };

  const handelArabicInputHide = (index) => {
    const res = messages?.messageList.map((item, ind) => {
      if (ind === index) {
        item.arabicMessage = null;
      }
      return item;
    });
    setMessages({ ...messages, messageList: res });

    const result = errors?.messageListErrors.map((item, ind) => {
      if (ind === index) {
        item.arabicMessageError = "";
      }
      return item;
    });
    setErrors({ ...errors, messageListErrors: result });
  };

  const handleSubmit = () => {
    if (JSON.stringify(errors) === JSON.stringify(dummyErrors)) {
      updateEmergencyOptionsApi();
    }
  };

  const handleClose = () => {
    history.push(ROADSIDE_ASSISTANCE);
    setInitialInputError("");
    setInitialInputValue("");
  };



  return (
    <>
      {loading ? (
        <LoadingComp color="primary" />
      ) : (
        <MainDiv>
          <HeaderDiv>
            <LeftDiv>
              <Heading>Emergency Options</Heading>
            </LeftDiv>
            <RightDiv>
              <ButtonContainer>
                <LoaderButton
                  isLoading={btnLoading}
                  buttonText="Save"
                  styles={loaderBtnStyleFunc("40%", "2.8rem")}
                  submitHandler={handleSubmit}
                />
                <CancelButton
                  variant="contained"
                  onClick={handleClose}
                  color="default"
                >
                  Cancel
                </CancelButton>
              </ButtonContainer>
            </RightDiv>
          </HeaderDiv>
          <InputFieldContainer>
            <NavContainer>
              <InputLabelText>Phone Number</InputLabelText>
              <InputTextField
                id="into"
                variant="outlined"
                size="small"
                type="text"
                value={emergencyPhone}
                onChange={handleEmergencyPhone}
                error={Boolean(mobileNumberError)}
                helperText={mobileNumberError}
              />
            </NavContainer>
            <OptionsContainer>
              <InputLabelText>Options</InputLabelText>
            </OptionsContainer>

            <InputTextFieldParent
              variant="outlined"
              size="small"
              id="message"
              onChange={handleInput}
              error={Boolean(initialInputError)}
              helperText={initialInputError}
              value={initialInputValue}
            />
            <AddIconLogo onClick={addInputValue} />

            {messages?.messageList.length > 0
              ? messages.messageList.map((value, index) => {
                  return (
                    <div key={index}>
                      <NavContainer key={`input_${index}`}>
                        <InputTextFieldChild
                          variant="outlined"
                          size="small"
                          id="option"
                          value={value.message}
                          error={Boolean(
                            messageListErrors[index]?.messageError
                          )}
                          helperText={messageListErrors[index]?.messageError}
                          onChange={(e) => handleOptionInput(e, index)}
                        />
                        <RemoveIconLogo
                          onClick={(e) => {
                            deleteOptionInput(e, index, value.id);
                          }}
                        />
                      </NavContainer>
                      {value.arabicMessage === null && (
                        <TextWrapper>
                          <Small onClick={() => handelArabicInputShow(index)}>
                            Enter in arabic
                          </Small>
                        </TextWrapper>
                      )}

                      <FieldContainer>
                        {typeof value.arabicMessage === "string" && (
                          <>
                            <FlexBoxArabicEO>
                              <InputTextField
                                onChange={(e) =>
                                  handleChangeArabicInput(e, index)
                                }
                                variant="outlined"
                                size="small"
                                dir="rtl"
                                error={Boolean(
                                  messageListErrors[index]?.arabicMessageError
                                )}
                                helperText={
                                  messageListErrors[index]?.arabicMessageError
                                }
                                value={value.arabicMessage}
                                inputProps={{ style: { fontSize: 14 } }}
                              />
                              <CancelInputEO
                                onClick={() => handelArabicInputHide(index)}
                              />
                            </FlexBoxArabicEO>
                          </>
                        )}
                      </FieldContainer>
                    </div>
                  );
                })
              : null}
          </InputFieldContainer>
        </MainDiv>
      )}
    </>
  );
}

export default EmergencyOptions;
