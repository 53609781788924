import React, { ReactElement, ReactNode, useState, useEffect } from "react";
import { RootState } from "redux-store/store";
import { useAppSelector } from "redux-store/hooks";

import ChangePassword from "pages/user-profile/components/change-password";
import BasicInfo from "pages/user-profile/components/basic-info";
import UserManagement from "pages/user-profile/components/user-management";

import TabSelector from "shared-components/tab-selector";

import { UserProfileContainer, Heading } from "./styles";

export default function UserProfile(): ReactElement {
  const [labelArray, setLabelArray] = useState<string[]>([]);

  const userBrand = useAppSelector((state: RootState) => {
    return state.user.userInfo.user;
  });
  const role = userBrand?.role?.toLowerCase();

  useEffect(() => {
    if (role === "admin") {
      setLabelArray(["Basic Info", "Change Password"]);
    } else {
      setLabelArray(["Basic Info", "Change Password", "User Management"]);
    }
  }, [role]);

  const components: Array<ReactNode> = [
    <BasicInfo />,
    <ChangePassword />,
    role?.includes("super") ? <UserManagement /> : null,
  ];

  return (
    <UserProfileContainer>
      <Heading>User Profile</Heading>
      <TabSelector
        labelArray={labelArray}
        children={components as Array<ReactNode>}
      />
    </UserProfileContainer>
  );
}
