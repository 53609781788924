import React, { useEffect } from "react";

import DateFnsUtils from "@date-io/date-fns";
import "date-fns";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IconButton, InputAdornment } from "@material-ui/core";

import { CustomDateTimePicker, CalenderIcon } from "./styles";

interface dateProps {
  errorType?: string;
  handelChangeParent: (date: any) => void;
  time: string;
  width?: number;
  disabled?: boolean;
  normal?: boolean;
}

export default function MaterialUIPickers({
  errorType,
  handelChangeParent,
  time,
  width,
  disabled,
  normal
}: dateProps) {

  useEffect(() => {
    setSelectedDate(new Date(time));
    // eslint-disable-next-line
  }, [time,disabled]);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>();
  const handleDateChange = (date: Date | null) => {
    if(disabled){
      setSelectedDate(new Date());
      handelChangeParent(String(new Date()));
    }else{
      setSelectedDate(date);
      handelChangeParent(String(date));
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomDateTimePicker
        autoOk
        value={selectedDate}
        onChange={handleDateChange}
        format="dd-MM-yyyy    HH:mm a"
        inputVariant="outlined"
        size="small"
        width={width}
        disablePast
        disabled={disabled}
        helperText={null}
        error={Boolean(errorType)}
        leftArrowButtonProps={{ "aria-label": "Prev month" }}
        rightArrowButtonProps={{ "aria-label": "Next month" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <CalenderIcon />
              </IconButton>
            </InputAdornment>
          ),
          style: { background: `${disabled ? "#F0F0F0" : ""}` }
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
