import axios from "axios";
import selectedBrand from "constants/brand";

import {
  BASE_URL,
  GET_EMERGENCY_OPTIONS_ENDPOINT,
  GET_ROADSIDE_ASSISTANCE_DETAILS_ENDPOINT,
  ROADSIDE_ASSISTANCE_FILTERED_DATA,
  UPDATE_EMERGENCY_OPTIONS_ENDPOINT,
} from "constants/api-config-constants";

import {
  RoadSideAssistanceResponse,
  GetRoadSideAssistanceRequest,
  RoadSideAssistanceFilterInterface,
  GetEmergencyOptionsResponse,
  EmergencyOptions,
  UpdateEmergencyOptionsResponse,
} from "interfaces/services/roadside-assistance-interface";

export const getRoadsideAssistanceDetails = async (
  request: GetRoadSideAssistanceRequest
): Promise<RoadSideAssistanceResponse> => {
  const { pageNumber, limit } = request;
  const url =
    BASE_URL +
    GET_ROADSIDE_ASSISTANCE_DETAILS_ENDPOINT +
    "?pageno=" +
    pageNumber +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const roadsideAssistanceFilter = async (
  request: RoadSideAssistanceFilterInterface
): Promise<RoadSideAssistanceResponse> => {
  const {startDate, endDate,message, pageNumber,limit} = request
  const url =
    BASE_URL +
    ROADSIDE_ASSISTANCE_FILTERED_DATA +
    "?pageno=" +
    pageNumber +
    "&limit=" +
    limit+    
    "&brand=" +
    selectedBrand()+
    "&message=" +
    message+
    "&fromDate="+
    startDate+
    "&toDate="+
    endDate;
  return axios.get(url);
};


export const getEmergencyOptions = async (): Promise<GetEmergencyOptionsResponse> => {
  const url =
    BASE_URL +
    GET_EMERGENCY_OPTIONS_ENDPOINT+"?brand=" +
    selectedBrand();
  return axios.get(url);
};

export const updateEmergencyOptions  = async (
  request: EmergencyOptions
): Promise<UpdateEmergencyOptionsResponse> => {
  const url = BASE_URL + UPDATE_EMERGENCY_OPTIONS_ENDPOINT+"?brand=" +
  selectedBrand();;
  return axios.post(url, request);
};
