import bestDealState from "slices/best-deals/best-deals-interface";

const bestDealsInitialState: bestDealState = {
  bestDealsInfo: {
    allDeals: [],
    currentDeal: {},
    currentPage: 1,
    recordsLength: 0,
    tabIndicator: "",
    fromEditPage:false,
  },
};

export default bestDealsInitialState;
