import styled from "styled-components";
import { SUBTEXT_FONT_SIZE } from "constants/style/font-size";
import { TEXTFIELD_HEADING } from "constants/style/color";
import {InputLabel } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { DropdownSelector } from "pages/location/components/add-location/styles";

export const NotificationContainer = styled.div`
  padding: .5rem 1rem .5rem 1rem;
`;

export const FieldContainer = styled.div`
  margin-top: 2%;
  margin-bottom: 2%;
`;

export const NotificationTypeDropdown = styled(DropdownSelector)`
  width:35%;
`;
export const UserTypeDropdown = styled(DropdownSelector)`
  width: 35%;
`;


export const InputLabelText = styled(InputLabel)`
  margin-bottom: 0.625rem;
  font-size: ${SUBTEXT_FONT_SIZE} !important;
  color: ${TEXTFIELD_HEADING} !important;
`;

export const InputTextField = styled(TextField)`
  width:35%;
`;







