import styled from "styled-components";
import { Link } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import Logo from "shared-components/icons/login-logo";

import {
  PRIMARY_MEDIUM_FONT,
  PRIMARY_REGULAR_FONT,
} from "constants/style/fonts";
import {
  HEADING_BOLD_FONT_SIZE,
  PARAGRAPH_FONT_SIZE,
  SUBTEXT_FONT_SIZE,
  SUBHEADER_FONT_SIZE,
} from "constants/style/font-size";
import {
  BLACK_COLOR,
  DISABLED_TEXT,
  NAVLINK_COLOR,
} from "constants/style/color";


export const EmptyDiv = styled.div`
  flex-grow: 5;
`;

export const Heading = styled.p`
  color: ${BLACK_COLOR};
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-size: ${HEADING_BOLD_FONT_SIZE};
  margin: 0;
`;
export const SubmitButton = styled(Button)`
  width: 100%;
  height: 2.8rem;
  text-transform: none !important;
  margin: 2rem 0 3rem !important;
  background-color: #2d609f !important;
  font-size: ${SUBHEADER_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;

export const InputTextField = styled(TextField)`
  width: 100%;
  white-space: pre-line;
`;
export const InputLabelText = styled(InputLabel)`
  margin-bottom: 3%;
  color: ${BLACK_COLOR} !important;
  font-size: ${PARAGRAPH_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;
export const BrandLogo = styled(Logo)`
  margin: 3.5rem 0 2rem 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const InstructionText = styled.p`
  color: ${BLACK_COLOR};
  font-family: ${PRIMARY_REGULAR_FONT};
  font-size: ${PARAGRAPH_FONT_SIZE};
  white-space: pre-wrap;
  margin: 1rem 0;
`;

export const RememberPasswordText = styled(InstructionText)`
  margin-bottom: 3rem;
`;
export const NavItem = styled(Link)`
  color: ${NAVLINK_COLOR};
  font-family: ${PRIMARY_REGULAR_FONT};
  font-size: ${SUBTEXT_FONT_SIZE};
  white-space: pre-wrap;
  margin: 1rem 0;
`;

export const LabelDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ShowPasswordIcon = styled(VisibilityIcon)`
  color: ${DISABLED_TEXT} !important;
  font-size: 1.2em !important;
  cursor: pointer;
`;

export const HidePasswordIcon = styled(VisibilityOffIcon)`
  color: ${DISABLED_TEXT} !important;
  font-size: 1.2em !important;
  cursor: pointer;
`;
