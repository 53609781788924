import styled from "styled-components";
import { Link } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";

import Logo from "shared-components/icons/login-logo";

import {
  PRIMARY_MEDIUM_FONT,
  PRIMARY_REGULAR_FONT,
} from "constants/style/fonts";
import {
  HEADING_BOLD_FONT_SIZE,
  PARAGRAPH_FONT_SIZE,
  SUBTEXT_FONT_SIZE,
  SUBHEADER_FONT_SIZE,
} from "constants/style/font-size";
import { BLACK_COLOR, NAVLINK_COLOR } from "constants/style/color";

export const LoginContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 1px 1px 2px 2px #ffffff;
  border-radius: 15px;
  flex-grow: 1;
`;
export const InputDiv = styled.div`
  padding: 0 4rem;

`;
export const NavContainer = styled.div`
  margin-top: 6%;

`;
export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const EmptyDiv = styled.div`
  flex-grow: 40;
`;
export const ForgotPasswordDiv = styled.p`
  color: ${BLACK_COLOR};
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-size: ${HEADING_BOLD_FONT_SIZE};
  margin: 0;
`;
export const SubmitButton = styled(Button)`
  width: 100%;  
  height: 2.8rem;
  text-transform: none !important;
  margin: 2rem 0 0 !important;
  background-color: #2d609f !important;
  font-size: ${SUBHEADER_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;

export const InputTextField = styled(TextField)`
  width: 100%;
`;
export const InputLabelText = styled(InputLabel)`
  margin-bottom: 3%;
  color: ${BLACK_COLOR} !important;
  font-size: ${PARAGRAPH_FONT_SIZE} !important;
  font-family: ${PRIMARY_REGULAR_FONT} !important;
`;
export const BrandLogo = styled(Logo)`
  margin: 3.5rem 0 2rem 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const InstructionText = styled.p`
  color: ${BLACK_COLOR};
  font-family: ${PRIMARY_REGULAR_FONT};
  font-size: ${PARAGRAPH_FONT_SIZE};
  white-space: pre-wrap;
  margin: 1rem 0 2rem;
`;

export const RememberPasswordText = styled(InstructionText)`
  margin-bottom: 3rem;
`;
export const NavItem = styled(Link)`
  color: ${NAVLINK_COLOR};
  font-family: ${PRIMARY_REGULAR_FONT};
  font-size: ${SUBTEXT_FONT_SIZE};
  white-space: pre-wrap;
  margin: 1rem 0;
`;
