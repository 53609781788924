import axios from "axios";
import selectedBrand from "constants/brand";
import { limit } from "constants/pagination-constants";
import {
  BASE_URL,
  GET_DEALS_ENDPOINT,
  ADD_NEW_DEAL_ENDPOINT,
  DELETE_DEAL_ENDPOINT,
  UPDATE_DEAL_ENDPOINT,
  DEAL_INFO_ENDPOINT,
  SET_ACTIVE_DEAL_ENDPOINT,
  SET_FINISHED_DEAL_ENDPOINT,
  DEAL_SEARCH_ENDPOINT,
} from "constants/api-config-constants";
import {
  GetDealsRequest,
  DealDetails,
  SearchDetails,
  GetBestDealsResponse,
  DeleteDealResponse,
  makeDealActiveRequest,
  SearchDeals,
} from "interfaces/best-deals-interfaces";

export const getAllBestDeals = async (
  request: GetDealsRequest
): Promise<GetBestDealsResponse> => {
  const { status, pageno } = request;
  const url =
    BASE_URL +
    GET_DEALS_ENDPOINT +
    "?status=" +
    status +
    "&pageno=" +
    pageno +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.get(url);
};

export const getDealsBySearch = async (
  request: SearchDeals
): Promise<GetBestDealsResponse> => {
  const { query, pageno, status } = request;
  const url =
    BASE_URL +
    DEAL_SEARCH_ENDPOINT +
    "?status=" +
    status +
    "&pageno=" +
    pageno +
    "&limit=" +
    limit +
    "&brand=" +
    selectedBrand();
  return axios.post(url, query.toString(), {
    headers: { "Content-Type": "text/plain" },
  });
};

export const addNewDeal = async (
  request: DealDetails
): Promise<GetBestDealsResponse> => {
  const url = BASE_URL + ADD_NEW_DEAL_ENDPOINT + "?brand=" + selectedBrand();
  return axios.post(url, request);
};

export const getDealById = async (
  request: SearchDetails
): Promise<GetBestDealsResponse> => {
  const url = BASE_URL + DEAL_INFO_ENDPOINT;
  return axios.post(url, request);
};

//   Need to ask this

export const deleteDeal = async (
  dealId: number
): Promise<DeleteDealResponse> => {
  const url = BASE_URL + DELETE_DEAL_ENDPOINT + "?id=" + dealId;
  return axios.delete(url);
};

export const updateDeal = async (
  request: DealDetails
): Promise<GetBestDealsResponse> => {
  const url = BASE_URL + UPDATE_DEAL_ENDPOINT + "?id=" + request.dealId;
  return axios.put(url, request);
};

export const setDealsActive = async (
  request: makeDealActiveRequest
): Promise<GetBestDealsResponse> => {
  const { array } = request;
  const url = BASE_URL + SET_ACTIVE_DEAL_ENDPOINT + "?brand=" + selectedBrand();
  return axios.put(url, array);
};

export const setDealsInActive = async (
  request: makeDealActiveRequest
): Promise<GetBestDealsResponse> => {
  const { array } = request;
  const url =
    BASE_URL + SET_FINISHED_DEAL_ENDPOINT + "?brand=" + selectedBrand();
  return axios.put(url, array);
};
