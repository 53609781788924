import NewsInfoState from "slices/news/news-interface";

const newsInitialState: NewsInfoState = {
  newsInfo: {
    allNews: [],
    currentNews:{},
    recordsLength: 0,
    currentPage: 1,
  },
};

export default newsInitialState;
