import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import DateFnsUtils from "@date-io/date-fns";
import "date-fns";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TimePicker } from "@material-ui/pickers";

interface TimeProps {
  handelChangeParent: (date: any) => void;
  time?: string;
  style?: React.CSSProperties | undefined;
  displayTime?: string;
}

export default function MaterialUiTimePicker({
  handelChangeParent,
  time,
  style,
}: TimeProps) {
  const location = useLocation<any>();
  useEffect(() => {
    if (
      location?.state?.detail === "edit" ||
      location?.state?.detail === "copy"
    ) {
      setSelectedDate(new Date(new Date(`1-1-21 ${time}`)));
    }
    // eslint-disable-next-line
  }, [time]);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  const extractTimeFromDate = (dateTime) => {
    const day = new Date(dateTime);
    return day.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    var extractedTime = extractTimeFromDate(date);
    handelChangeParent(String(extractedTime));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        inputVariant="outlined"
        size="small"
        autoOk
        value={selectedDate}
        onChange={handleDateChange}
        style={{ ...style }}
        helperText={null}
        error={false}
      />
    </MuiPickersUtilsProvider>
  );
}
