import styled from "styled-components";

import { 
  DeleteUserContainer,
  ConfirmDiv,
  HeaderDiv,
  BtnContainer,
  SubmitButton,
  CancelButton,
  SpanDiv
} from "pages/user-profile/components/delete-user/styles"

export const DeleteDealsContainer=styled(DeleteUserContainer)``;
export const ConfirmBox=styled(ConfirmDiv)``;
export const HeaderCon=styled(HeaderDiv)``;
export const ButtonCon=styled(BtnContainer)``;
export const SubmitBtn=styled(SubmitButton)``;
export const CancelBtn=styled(CancelButton)``;
export const SpanCon=styled(SpanDiv)``;
