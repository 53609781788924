import axios from "axios";
import { BASE_URL, GET_API_MGMT, GET_LINK_MGMT,UPDATE_API_MGMT,UPDATE_LINK_MGMT } from "constants/api-config-constants";
import selectedBrand from "constants/brand";
import { apiMgmtDetails, getApiMgmtRequest, GetApiMgmtResponse, GetLinkResponse, UpdateLinksRequest } from "interfaces/link-management.interface";


export const getLinks = async (): Promise<GetLinkResponse> => {
    const url =
      BASE_URL +
      GET_LINK_MGMT +
      "?brand=" +
      selectedBrand();
    return axios.get(url);
};

export const updateLinks = async (
    request: UpdateLinksRequest
  ): Promise<GetLinkResponse> => {
    const url = BASE_URL + UPDATE_LINK_MGMT + "?brand=" + selectedBrand();
    return axios.post(url, request);
  };


  export const getApiMgmtDetails = async (request: getApiMgmtRequest): Promise<GetApiMgmtResponse> => {
    const { type,lang } = request;
    const url =
      BASE_URL +
      GET_API_MGMT +
      "?brand=" +
      selectedBrand()+
      "&type="+
      type+
      "&lang="+
      lang;
    return axios.get(url);
};

export const updateApiDetails = async (request: apiMgmtDetails): Promise<GetApiMgmtResponse> => {
  const url =
    BASE_URL +
    UPDATE_API_MGMT
  return axios.put(url,request);
};