import styled from "styled-components";

import { Paper } from "@material-ui/core";

import { COMPONENT_HEADING } from "constants/style/font-size";
import { PRIMARY_MEDIUM_FONT } from "constants/style/fonts";

export const Heading = styled.p`
  font-size: ${COMPONENT_HEADING};
  margin: 0;
  font-family: ${PRIMARY_MEDIUM_FONT};
  font-weight: bold;
  user-select: none;
  margin-bottom: 1.5rem;
`;

export const MainDiv = styled.div`
  padding: 1.5rem 2rem;
`;

export const ChildrenDiv = styled(Paper)`
  padding: 1rem 3rem 1rem 1rem;
`;
