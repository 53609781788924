import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    GetDealsRequest,
    DealDetails,
    SearchDetails,
    makeDealActiveRequest,
    SearchDeals
  } from "interfaces/best-deals-interfaces";

  import { 
    getAllBestDeals,
    addNewDeal,
    deleteDeal,
    updateDeal,
    getDealById,
    setDealsInActive,
    setDealsActive,
    getDealsBySearch
   } from 'services/best-deals-services'

   export const getAllDealsThunk = createAsyncThunk(
    "getAllDealsThunk",
    async (request: GetDealsRequest, { rejectWithValue }) => {
      try {
        const data = await getAllBestDeals(request);
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const getDealsBySearchThunk = createAsyncThunk(
    "getDealsBySearchThunk",
    async (request: SearchDeals, { rejectWithValue }) => {
      try {
        const data = await getDealsBySearch(request);
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const getDealByIdThunk = createAsyncThunk(
    "getDealBySearchThunk",
    async (request: SearchDetails, { rejectWithValue }) => {
      try {
        const data = await getDealById(request);
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );


  export const updateDealInfoThunk = createAsyncThunk(
    "updateDealInfoThunk",
    async (request: DealDetails, { rejectWithValue }) => {
      try {
        const result = await updateDeal(request);
        return result;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const deleteDealThunk = createAsyncThunk(
    "deleteDealThunk",
    async (id: number, { rejectWithValue }) => {
      try {
        const result = await deleteDeal(id);
        const data = {
          id: id as number,
          response: result.data,
        };
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const addNewDealThunk = createAsyncThunk(
    "addNewDealThunk",
    async (request: DealDetails, { rejectWithValue }) => {
      try {
        const result = await addNewDeal(request);
        return result;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );


  export const setActiveDealThunk = createAsyncThunk(
    "setActiveDealThunk",
    async (request: makeDealActiveRequest, { rejectWithValue }) => {
      try {
        const result = await setDealsActive(request);
        return result;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const setFinishedDealThunk = createAsyncThunk(
    "setFinishedDealThunk",
    async (request: makeDealActiveRequest, { rejectWithValue }) => {
      try {
        const result = await setDealsInActive(request);
        return result;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );